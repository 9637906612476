import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Pikaday from 'pikaday'
import classNames from 'classnames'
import Icon from '../Icon'

import './style.styl'

// ALMOST SHAMELESSLY COPIED FROM react-pikaday-component:
// https://github.com/voronianski/react-pikaday-component

export default class ReactPikadayComponent extends Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    name: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    valueLink: PropTypes.shape({
      value: PropTypes.instanceOf(Date),
      requestChange: PropTypes.func.isRequired,
    }),

    // see Pikaday options at https://github.com/dbushell/Pikaday#configuration
    // except `onSelect` and `field`
  }

  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
    this.clear = this.clear.bind(this)
  }

  componentDidMount() {
    const { value } = this.getValueLink(this.props)

    this.setupPikaday()
    this.setDateIfChanged(value)
  }

  componentWillReceiveProps(nextProps) {
    const newDate = this.getValueLink(nextProps).value
    const lastDate = this.getValueLink(this.props).value

    this.setDateIfChanged(newDate, lastDate)
    this.setMinDateIfChanged(nextProps.minDate, this.props.minDate)
    this.setMaxDateIfChanged(nextProps.maxDate, this.props.maxDate)
  }

  componentDidUpdate(prevProps) {
    const newDate = this.getValueLink(this.props).value
    const lastDate = this.getValueLink(prevProps).value

    this.pikaday.destroy()
    this.setupPikaday()
    this.setDateIfChanged(newDate, lastDate)
  }

  componentWillUnmount() {
    this.pikaday.destroy()
  }

  getValueLink(props) {
    return (
      props.valueLink || {
        value: props.value,
      }
    )
  }

  handleInput(date) {
    this.props.onChange(this.pikadayElement.value, date)
  }

  setupPikaday() {
    const el = this.pikadayElement
    const { value, onChange, valueLink, ...pikadayOptions } = this.props // eslint-disable-line no-unused-vars
    const options = Object.assign({}, pikadayOptions, {
      field: el,
      onSelect: this.handleInput,
      format: 'DD/MM-YYYY',
      i18n: {
        previousMonth: 'Forrige måned',
        nextMonth: 'Næste måned',
        weekdays: [
          'Søndag',
          'Mandag',
          'Tirsdag',
          'Onsdag',
          'Torsdag',
          'Fredag',
          'Lørdag',
        ],
        weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
        months: [
          'Januar',
          'Februar',
          'Marts',
          'April',
          'Maj',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'December',
        ],
      },
    })

    this.pikaday = new Pikaday(options)
  }

  setDateIfChanged(newDate, prevDate) {
    this.setDate(newDate, prevDate, (newTime) => {
      if (isNaN(newTime)) {
        // workaround for pikaday not clearing value when date set to false
        const el = this.pikadayElement
        el.value = ''
      }
      this.pikaday.setDate(newDate, true) // not trigger onSelect
    })
  }

  setMinDateIfChanged(newDate, prevDate) {
    this.setDate(newDate, prevDate, () => {
      this.pikaday.setMinDate(newDate)
    })
  }

  setMaxDateIfChanged(newDate, prevDate) {
    this.setDate(newDate, prevDate, () => {
      this.pikaday.setMaxDate(newDate)
    })
  }

  setDate(newDate, prevDate, setter) {
    const newTime = newDate ? newDate.getTime() : null
    const prevTime = prevDate ? prevDate.getTime() : null

    if (newTime !== prevTime) {
      setter(newTime)
    }
  }

  clear() {
    this.pikadayElement.value = ''
    this.handleInput()
  }

  render() {
    const { id, className, name, disabled, placeholder, readOnly, style } =
      this.props
    const classes = classNames(className, 'date-picker')

    return (
      <div className={classes}>
        <input
          id={id}
          type="text"
          ref={(element) => (this.pikadayElement = element)}
          name={name}
          onInput={this.handleInput}
          className="date-picker__input"
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Icon
          icon="cross"
          className="date-picker__cross"
          onClick={this.clear}
        />
      </div>
    )
  }
}
