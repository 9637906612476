import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as BuildingPropertyActions from '../actions/BuildingPropertyActions'
import * as ResultCorrectionActions from '../actions/ResultCorrectionActions'
import * as BuildingPropertyResultValueActions from '../actions/BuildingPropertyResultValueActions'

import {
  isFetching,
  getBuildingProperty,
} from '../selectors/BuildingPropertySelectors'

import ResultValueCorrections from '../components/ResultValueCorrections'

class ResultCorrectionsContainer extends Component {
  static propTypes = {
    buildingPropertyId: PropTypes.string,

    fetchBuildingProperty: PropTypes.func.isRequired,
    fetchBuildingPropertyResultValues: PropTypes.func.isRequired,
    createResultCorrection: PropTypes.func.isRequired,
    updateResultCorrection: PropTypes.func.isRequired,
    deleteResultCorrection: PropTypes.func.isRequired,

    isLoading: PropTypes.bool.isRequired,
    buildingProperty: PropTypes.object,
  }

  componentDidMount() {
    const { fetchBuildingProperty, buildingPropertyId } = this.props
    fetchBuildingProperty(buildingPropertyId, {
      query: {
        include: ['building_property_result_values', 'result_corrections'],
      },
    })
  }

  refetchBuildingPropertyResultValues() {
    const { buildingPropertyId, fetchBuildingPropertyResultValues } = this.props
    fetchBuildingPropertyResultValues(buildingPropertyId)
  }

  handleResultCorrectionSubmit = (id, attributes) => {
    const { createResultCorrection, updateResultCorrection } = this.props

    let promise
    if (!id) {
      promise = createResultCorrection(attributes)
    } else {
      promise = updateResultCorrection(id, attributes)
    }

    promise.then(() => this.refetchBuildingPropertyResultValues())
  }

  handleResultCorrectionDelete = (id) => {
    this.props
      .deleteResultCorrection(id)
      .then(() => this.refetchBuildingPropertyResultValues())
  }

  renderBuildingPropertyResultValue = (buildingPropertyResultValue) => {
    const {
      buildingProperty: { resultCorrections },
    } = this.props

    return (
      <ResultValueCorrections
        key={buildingPropertyResultValue.id}
        buildingPropertyResultValue={buildingPropertyResultValue}
        resultCorrections={resultCorrections.filter(
          (rc) => rc.result === buildingPropertyResultValue.id
        )}
        onSubmit={this.handleResultCorrectionSubmit}
        onDelete={this.handleResultCorrectionDelete}
      />
    )
  }

  render() {
    const {
      buildingProperty: { buildingPropertyResultValues },
    } = this.props
    if (!buildingPropertyResultValues) return false
    const correctableValues = buildingPropertyResultValues.filter(
      (bprv) => bprv.unitType !== 'pcs'
    )

    return (
      <div>{correctableValues.map(this.renderBuildingPropertyResultValue)}</div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { buildingPropertyId } = props
  const params = { buildingPropertyId }

  return {
    isLoading: isFetching(state),
    buildingProperty: getBuildingProperty(state, { params }, [
      'buildingPropertyResultValues',
      'resultCorrections',
    ]),
  }
}

export default connect(mapStateToProps, {
  ...BuildingPropertyActions,
  ...ResultCorrectionActions,
  ...BuildingPropertyResultValueActions,
})(ResultCorrectionsContainer)
