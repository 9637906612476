import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import DialogButton from './DialogButton'

const DialogCancelButton = (props) => (
  <DialogButton
    {...props}
    className={classNames('dialog__cancel-btn', props.className)}
  />
)

DialogCancelButton.propTypes = {
  className: PropTypes.string,
}

export default DialogCancelButton
