import React from 'react'
import PropTypes from 'react-proptypes'

const ToggleVisible = ({ visible, ...props }) => (
  <div style={{ display: visible ? '' : 'none' }} {...props} />
)

ToggleVisible.propTypes = {
  visible: PropTypes.bool,
}

export default ToggleVisible
