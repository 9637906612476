import './style.styl'
import React from 'react'
import classNames from 'classnames'
import Icon from '../../Icon'

const SidebarMenuStatsItem = (props) => {
  const classes = classNames('sidebar__menu__item sidebar__menu__stats__item')

  return (
    <li>
      <a className={classes} href="/backend/">
        <Icon icon="people" />
        Min salgsstatistik
      </a>
    </li>
  )
}

export default SidebarMenuStatsItem
