import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import { getBuildingPropertiesForBuilding } from '../selectors/BuildingPropertySelectors'
import { getPropertyCategoriesForWizard } from '../selectors/PropertyCategorySelectors'
import { getWizardBuilding } from '../selectors/WizardBuildingSelectors'

import { fetchBuildingProperties } from '../actions/BuildingPropertyActions'
import { fetchWizard } from '../actions/WizardActions'
import { openDialog } from '../actions/DialogActions'

import OrderWizardStepTitle from '../components/OrderWizard/StepTitle'
import Spinner from '../components/Spinner'
import Cell from '../components/Cell'
import Icon from '../components/Icon'
import Button from '../components/Button'
import { Margin, Row } from '../components/Grid'

class buildingPropertiesContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,

    buildingId: PropTypes.string.isRequired,
    wizardId: PropTypes.string.isRequired,

    buildingProperties: PropTypes.array,
    propertyCategories: PropTypes.array,

    fetchBuildingProperties: PropTypes.func.isRequired,
    fetchWizard: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
  }

  handlePropertyCategoryClick = (propertyCategoryId) => {
    const { buildingId } = this.props

    this.props.openDialog('buildingPropertyCreation', {
      buildingId,
      propertyCategoryId,
    })
  }

  handleCreatePropertyClick = () => {
    const { buildingId } = this.props
    this.props.openDialog('buildingPropertyCreation', { buildingId })
  }

  handleBuildingPropertyClick = (buildingPropertyId) => {
    this.props.openDialog('buildingProperty', { buildingPropertyId })
  }

  renderMissingCategories() {
    const { buildingProperties, propertyCategories } = this.props

    const providedCategoryIds = buildingProperties.map(
      (bp) => bp.property.propertyCategory
    )

    return (
      <div>
        <OrderWizardStepTitle>Mål til tilbudspakke</OrderWizardStepTitle>
        <p>Disse mål påkrævede for tilbudspakkens produkter</p>
        <Row>
          {propertyCategories.map((propertyCategory) => (
            <Cell
              key={propertyCategory.id}
              size={4}
              imageUrl={propertyCategory.imageUrl}
              onClick={this.handlePropertyCategoryClick}
              clickPayload={propertyCategory.id}
            >
              <p>{propertyCategory.name}</p>
              <Margin top size={1}>
                <Icon
                  icon={
                    providedCategoryIds.indexOf(propertyCategory.id) === -1
                      ? 'exclamation-circle'
                      : 'checkmark'
                  }
                />
              </Margin>
            </Cell>
          ))}
        </Row>
      </div>
    )
  }

  renderProperties() {
    const { buildingProperties } = this.props

    return (
      <div>
        <OrderWizardStepTitle>Opmålinger</OrderWizardStepTitle>
        {buildingProperties.length !== 0 ? (
          <Row>
            {buildingProperties.map((buildingProperty) => (
              <Cell
                key={buildingProperty.id}
                clickPayload={buildingProperty.id}
                size={4}
                onClick={this.handleBuildingPropertyClick}
                imageUrl={buildingProperty.property.imageUrl}
              >
                <p>{buildingProperty.name}</p>
                <p className="text-muted">
                  {buildingProperty.quantity} ✕ {buildingProperty.property.name}
                </p>
              </Cell>
            ))}
          </Row>
        ) : (
          <p>Der er ikke foretaget nogen målinger for bygningen endnu</p>
        )}
      </div>
    )
  }

  render() {
    const { isFetching, wizardId } = this.props

    if (isFetching) {
      return <Spinner center />
    }

    return (
      <Margin all size={2}>
        {wizardId && this.renderMissingCategories()}
        {this.renderProperties()}
        {!wizardId && (
          <Margin top size={1}>
            <Button onClick={this.handleCreatePropertyClick}>
              Ny opmåling
            </Button>
          </Margin>
        )}
      </Margin>
    )
  }
}

function mapStateToProps(state, props) {
  const { building: buildingId, wizard: wizardId } = getWizardBuilding(
    state,
    props
  )

  return {
    isFetching: false,
    buildingId,
    wizardId,

    // We assume that building properties and property categories were fetched earlier
    // by a parent
    buildingProperties: getBuildingPropertiesForBuilding(state, {
      params: { buildingId },
    }),
    propertyCategories: getPropertyCategoriesForWizard(state, {
      params: { wizardId },
    }),
  }
}

export default connect(mapStateToProps, {
  fetchBuildingProperties,
  fetchWizard,
  openDialog,
})(buildingPropertiesContainer)
