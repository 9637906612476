import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { isValidEmail } from '../../utils/validationUtils'
import { FormField } from '../Form'
import Input from '../Input'
import Button from '../Button'
import Alert from '../Alert'
import { VerticalAlign } from '../Grid'

class PasswordResetPage extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isSent: PropTypes.bool.isRequired,
    email: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = { email: props.email }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange({ target }) {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { onSubmit, isSubmitting, isSent } = this.props
    const { state } = this
    const disabled = isSubmitting || !isValidEmail(this.state.email)

    if (isSent) {
      return (
        <div className="password-reset-page">
          <VerticalAlign>
            <div className="reset-password-page__success">
              <Alert type="success">
                Du vil nu modtage en e-mail med instruktioner til hvordan du
                ændre din adgangskode.
              </Alert>
            </div>
          </VerticalAlign>
        </div>
      )
    }

    return (
      <div className="password-reset-page">
        <VerticalAlign>
          <form
            className="password-reset-form"
            disabled={isSubmitting}
            onSubmit={(event) => {
              event.preventDefault()
              onSubmit(state)
            }}
          >
            <FormField>
              <Input
                type="email"
                name="email"
                placeholder="Din e-mail"
                disabled={isSubmitting}
                onChange={this.handleInputChange}
                value={state.email}
                autoFocus
              />
            </FormField>
            <Button disabled={disabled} type="submit" block>
              {isSubmitting
                ? 'Nulstiller adgangskode...'
                : 'Nulstil adgangskode'}
            </Button>
          </form>
        </VerticalAlign>
      </div>
    )
  }
}

export default PasswordResetPage
