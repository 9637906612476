import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const Button = (props) => {
  const classes = classNames(
    'btn-addon',
    {
      'btn-addon--block': props.block,
      'btn-addon--borderless': props.borderless,
    },
    props.className
  )

  return <div className={classes}>{props.children}</div>
}

Button.propTypes = {
  children: PropTypes.any,
  block: PropTypes.bool,
  className: PropTypes.string,
  borderless: PropTypes.bool,
}

Button.defaultProps = {
  block: false,
}

export default Button
