import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createApi from '../middleware/api'
import localStorageMiddleware from '../middleware/localStorage'
import { hashHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import apiKey from '../constants/ApiKey'
import rootReducer from '../reducers'

const api = createApi({
  apiUrl: '/api/v1',
  apiKey,
})

const createStoreWithMiddleware = compose(
  applyMiddleware(thunk, api),
  applyMiddleware(localStorageMiddleware),
  applyMiddleware(routerMiddleware(hashHistory)),
  window.devToolsExtension ? window.devToolsExtension() : (f) => f
)(createStore)

export default function configureStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState)
}
