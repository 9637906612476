import React from 'react'
import PropTypes from 'react-proptypes'
import Label from '../Label'
import OrderStates from '../../constants/OrderStates'

const OrderStateLabel = ({ state }) => (
  <Label type={OrderStates[state].labelType}>{OrderStates[state].label}</Label>
)

OrderStateLabel.propTypes = {
  state: PropTypes.oneOf(Object.getOwnPropertyNames(OrderStates)),
}

export default OrderStateLabel
