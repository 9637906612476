import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import { Link } from 'react-router'
import './style.styl'

import { Column } from '../Grid'

class Cell extends Component {
  handleClick = (event) => {
    const { onClick, clickPayload } = this.props

    if (clickPayload) onClick(clickPayload)
    else onClick(event)
  }

  render() {
    const {
      imageUrl,
      widthHeightRatio = 16 / 9,
      size = 3,
      children,
      onClick,
      linkTo,
    } = this.props

    if (onClick && linkTo)
      throw new Error('onClick and linkTo are mutually exclusive')

    const className = classNames('cell__inner', this.props.className, {
      'cell__inner--link': onClick || linkTo,
    })
    const InnerCellTag = linkTo ? Link : 'div'

    return (
      <Column size={size} className="cell">
        <InnerCellTag
          className={className}
          onClick={onClick && this.handleClick}
          to={linkTo}
        >
          <div
            className="cell__image"
            style={{
              backgroundImage: `url(${imageUrl})`,
              paddingTop: `${(1 / widthHeightRatio) * 100}%`,
            }}
          />
          <div>{children}</div>
        </InnerCellTag>
      </Column>
    )
  }
}

Cell.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  widthHeightRatio: PropTypes.number,
  children: PropTypes.any,
  clickPayload: PropTypes.any,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  size: PropTypes.number,
}

export default Cell
