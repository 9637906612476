import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  LabelList,
  Cell,
} from 'recharts'
import { FlexContainer, Flex } from '../Grid'
import Page, { PageHeader, PageContent } from '../Page'
import Button from '../Button'

export default class ConversionRatesPage extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    menuVisible: PropTypes.bool,
    onMenuToggle: PropTypes.func.isRequired,
  }

  tableRowSorter(rowA, rowB) {
    if (rowA.name === 'Total') {
      return 1
    } else if (rowB.name === 'Total') {
      return -1
    }

    return rowB.value - rowA.value
  }

  tableRowToData(tableRow) {
    const { values, type } = tableRow

    const department = (values[0] || values[1]).replace(
      'Uden afdeling',
      'Ukendt'
    )
    const name = values[1]
    const visitCount = values[2]
    const ordersSold = values[3]
    const salesRate = values[4]
    const salesPrice = values[5]

    const value = Number(
      salesRate.slice(0, salesRate.length - 1).replace(/,/, '.')
    )
    const label = `${salesRate}\n(${visitCount}/${ordersSold})\n(${salesPrice
      .slice(2, salesPrice.length - 3)
      .replace(/\s/g, '')})`
    const axisText = type === 'sum' ? department : name.replace(/[^A-Z]+/g, '')
    const color = {
      Hinnerup: '#ff001a',
      Kolding: '#00aa34',
      Greve: '#1514f5',
      'Uden afdeling': '#787878',
      Total: '#ffff57',
    }[department]

    const rowData = {
      type,
      name,
      axisText,
      visitCount,
      ordersSold,
      salesRate,
      salesPrice,
      department,
      label,
      color,
      value,
    }
    return rowData
  }

  renderTooltip({ payload }) {
    if (!payload.length) return

    const {
      type,
      axisText,
      name,
      department,
      salesRate,
      ordersSold,
      visitCount,
      salesPrice,
      color,
    } = payload[0].payload

    return (
      <div className="ConversionRatesPage__tooltip">
        {type === 'sum' ? (
          <p>
            <strong>{axisText}</strong>
          </p>
        ) : (
          <div>
            <p>
              <strong>{axisText}</strong>: {name}
            </p>
            <p style={{ color }}>{department}</p>
          </div>
        )}
        <p>{salesRate}</p>
        <p>
          {ordersSold} / {visitCount}
        </p>
        <p>{salesPrice}</p>
      </div>
    )
  }

  renderChart(data) {
    return (
      <BarChart
        data={data}
        margin={{ top: 48, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="axisText" />
        <Tooltip content={this.renderTooltip} cursor={{ fill: '#f1f1f1' }} />
        <Text />

        <Bar dataKey="value">
          <LabelList dataKey="label" position="top" fill="#333" fontSize={10} />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    )
  }

  render() {
    const { data, menuVisible, onMenuToggle } = this.props
    const { tableData } = data

    const { 'date-from': fromUTC, 'date-to': toUTC } = data.query
    const fromFormatted = new Date(fromUTC).toJSON().slice(0, 10)
    const toFormatted = new Date(toUTC).toJSON().slice(0, 10)

    const dataForSalesmen = tableData
      .filter(({ type }) => type === 'default')
      .map(this.tableRowToData)
      .sort(this.tableRowSorter)

    const dataForTotals = tableData
      .filter(({ type }) => type === 'sum')
      .map(this.tableRowToData)
      .sort(this.tableRowSorter)

    return (
      <Page>
        <PageHeader className="ConversionRatesPage__header" title={data.title}>
          <Button
            className="ConversionRatesPage__menu-toggle"
            onClick={onMenuToggle}
          >
            {menuVisible ? '<' : '>'}
          </Button>
        </PageHeader>
        <PageContent>
          <FlexContainer fill className="ConversionRatesPage__content">
            <Flex noGrow noShrink className="ConversionRatesPage__header">
              <h2>
                {fromFormatted} - {toFormatted}
              </h2>
            </Flex>
            <Flex>
              <FlexContainer fill className="ConversionRatesPage__charts">
                <Flex className="ConversionRatesPage__chart">
                  <ResponsiveContainer height="100%" width="100%">
                    {this.renderChart(dataForSalesmen)}
                  </ResponsiveContainer>
                </Flex>
                <Flex className="ConversionRatesPage__chart">
                  <ResponsiveContainer height="100%" width="33%">
                    {this.renderChart(dataForTotals)}
                  </ResponsiveContainer>
                </Flex>
              </FlexContainer>
            </Flex>
          </FlexContainer>
        </PageContent>
      </Page>
    )
  }
}
