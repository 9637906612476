import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'
import { getAssessmentCategoryTypesForCategory } from './AssessmentCategoryTypeSelectors'

export const getBuildingId = (state, props) => props.params.buildingId
export const getAssessmentCategoryTypeEntities = (state) =>
  state.api.entities.assessmentCategoryTypes
export const getBuildingAssessments = createEntitiesSelector(
  'buildingAssessments'
)
export const getAssessmentEntities = (state) => state.api.entities.assessments

export const getBuildingAssessmentCategoryTypes = createEntitiesSelector(
  'buildingAssessmentCategoryTypes'
)

const buildingAssessmentCategoryTypeWithRelations = (bact, actes, bas, aes) => {
  if (!actes || !bas || !aes) {
    return null
  }
  const assessmentCategoryType = actes[bact.assessmentCategoryType]
  const buildingAssessment = bas.find(
    (ba) => ba.buildingAssessmentCategoryType === bact.id
  )
  const assessment = buildingAssessment && aes[buildingAssessment.assessment]
  if (!assessmentCategoryType) {
    return null
  }

  return { ...bact, assessmentCategoryType, buildingAssessment, assessment }
}

export const getBuildingAssessmentCategoryTypesForBuilding = createSelector(
  getBuildingAssessmentCategoryTypes,
  getBuildingId,
  (types, buildingId) => types.filter((t) => t.building === buildingId)
)

export const getBuildingAssessmentCategoryTypeForBuildingAndCategory =
  createSelector(
    getBuildingAssessmentCategoryTypesForBuilding,
    getAssessmentCategoryTypesForCategory,
    (relations, types) =>
      relations.find((r) =>
        types.some((t) => t.id === r.assessmentCategoryType)
      )
  )

export const getBuildingAssessmentCategoryTypeForBuildingWithRelations =
  createSelector(
    getBuildingAssessmentCategoryTypesForBuilding,
    getAssessmentCategoryTypeEntities,
    getBuildingAssessments,
    getAssessmentEntities,
    (bacts, actes, bas, aes) => {
      if (!bacts) {
        return null
      }
      const buildingAssessmentCategoryTypesWithRelations = []
      for (let i = 0; i < bacts.length; i++) {
        const bact = buildingAssessmentCategoryTypeWithRelations(
          bacts[i],
          actes,
          bas,
          aes
        )
        if (bact) {
          buildingAssessmentCategoryTypesWithRelations.push(bact)
        } else {
          return []
        }
      }
      return buildingAssessmentCategoryTypesWithRelations
    }
  )
