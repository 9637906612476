import React from 'react'
import PropTypes from 'react-proptypes'

const DialogTitle = ({ children }) => (
  <div className="dialog__title">{children}</div>
)

DialogTitle.propTypes = {
  children: PropTypes.any,
}

export default DialogTitle
