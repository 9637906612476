import React from 'react'
import PropTypes from 'react-proptypes'

const ListItemActions = ({ children }) => (
  <div className="list__item__actions">{children}</div>
)

ListItemActions.propTypes = {
  children: PropTypes.any,
}

export default ListItemActions
