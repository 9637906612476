import React from 'react'
import PropTypes from 'react-proptypes'
import { Link } from 'react-router'
import { formatDate, formatUserName } from '../../utils/formatUtils'
import './style.styl'

const titleTemplates = {
  'api/v1/order_mails': {
    create: (a) => {
      let str = `${formatUserName(a.user)} sendte email til kunden`
      if (a.meta && a.meta.mail && a.meta.mail['acceptance-url']) {
        str = (
          <span>
            {formatUserName(a.user)} sendte email med{' '}
            <strong>godkendelseslink</strong> til kunden
          </span>
        )
      }
      if (a.meta && a.meta.mail && a.meta.mail['attach-pdf']) {
        str = (
          <span>
            {formatUserName(a.user)} sendte email med{' '}
            <strong>vedhæftet ordre</strong> til kunden
          </span>
        )
      }

      return (
        <span>
          {str}
          {a.meta && a.meta.mail && a.meta.mail.body ? ':' : ''}
        </span>
      )
    },
  },
  'api/v1/comments': {
    create: (a) =>
      `${formatUserName(a.user)} ${choose_title(a.meta.comment.type)}`,
  },
  'api/v1/order_acceptances': {
    public_accept: () => 'Ordren blev accepteret af kunden',
    propose: (a) => `${formatUserName(a.user)} færdiggjorde ordren`,
    accept: (a) => `${formatUserName(a.user)} satte ordren til "accepteret"`,
    reject: (a) => `${formatUserName(a.user)} satte ordren til "afvist"`,
    review_accept: (a) =>
      `${formatUserName(a.user)} gennemgik og bekræftede ordren`,
    review_reject: (a) =>
      `${formatUserName(a.user)} gennemgik og afviste ordren`,
    export_to_ax: (a) => `${formatUserName(a.user)} lagde ordren i AX`,
    archive: (a) => `${formatUserName(a.user)} arkiverede ordren`,
    reset: (a) => `${formatUserName(a.user)} nulstillede ordrestatussen`,
    reset_review: (a) => `${formatUserName(a.user)} nulstillede ordrestatussen`,
    cancel: (a) => `${formatUserName(a.user)} annulleret ordren`,
    reset_cancel: (a) => `${formatUserName(a.user)} nulstillede ordrestatussen`,
  },
  'api/v1/orders': {
    merge: (a) => {
      return (
        <span>
          {formatUserName(a.user)} slog ordren sammen med #{a.meta.source.id}
        </span>
      )
    },
    update: (a) => {
      if (a.meta && a.meta.order && a.meta.order.params) {
        const params = a.meta.order.params
        if (params['follow-up-at']) {
          const d = params['follow-up-at']
          return (
            <span>
              {formatUserName(a.user)} satte opfølgningsdato til d.{' '}
              <strong>{formatDate(d, 'j. F Y')}</strong>
            </span>
          )
        } else if (params['expected-start-up-time']) {
          const d = params['expected-start-up-time']
          return (
            <span>
              {formatUserName(a.user)} satte forventet opstartsdato til:{' '}
              <strong>{d}</strong>
            </span>
          )
        }
      }
      return titleTemplates.default.default(a)
    },
    copy: (a) => {
      if (a.meta.copy) {
        const parentId = a.meta['parent-order'].id
        const link = (
          <Link to={`/orders/${parentId}`}>
            <strong>#{parentId}</strong>
          </Link>
        )
        return (
          <span>
            {formatUserName(a.user)} oprettede ordren som en kopi af: {link}
          </span>
        )
      } else {
        const childId = a.meta['child-order'].id
        const link = (
          <Link to={`/orders/${childId}`}>
            <strong>#{childId}</strong>
          </Link>
        )
        return (
          <span>
            {formatUserName(a.user)} oprettede en kopi af denne ordre: {link}
          </span>
        )
      }
    },
    disable_reminders: (a) => `${formatUserName(a.user)} slog påmindelser fra`,
  },
  'order_reminder_service': {
    first_reminder: (a) => `Systemet sendte den første sms-påmindelse til kunden (${a.meta.phone})`,
    second_reminder: (a) => `Systemet sendte den anden sms-påmindelse til kunden (${a.meta.phone})`,
  },
  default: {
    default: (a) => {
      const trackable = { orders: 'ordren' }[a.trackableType]
      const action = {
        create: 'oprettede',
        destroy: 'slettede',
        reset: 'nulstillede',
        update: 'rettede',
      }[a.action]
      return `${formatUserName(a.user)} ${action} ${trackable}`
    },
  },
}

const choose_title = (type) => {
  if (type == 'followUpPhone') {
    return 'ringede til kunden'
  } else {
    return 'tilføjede en kommentar til ordren:'
  }
}

const descriptionTemplates = {
  'api/v1/comments': {
    default: (a) => a.meta.comment.content,
  },
  'api/v1/order_mails': {
    create: (a) => {
      if (a.meta && a.meta.mail && a.meta.mail.body) {
        return a.meta.mail.body
      }
      return null
    },
  },
  default: { default: () => null },
}

const renderTitle = (a) => {
  const controllerTemplates =
    titleTemplates[a.controller] || titleTemplates.default
  const titleTemplate =
    controllerTemplates[a.action] ||
    controllerTemplates.default ||
    titleTemplates.default.default

  return titleTemplate(a)
}

const renderDescription = (a) => {
  const controllerTemplates =
    descriptionTemplates[a.controller] || descriptionTemplates.default
  const descriptionTemplate =
    controllerTemplates[a.action] ||
    controllerTemplates.default ||
    descriptionTemplates.default.default

  return descriptionTemplate(a)
}

export const ActivityItem = (props) => {
  const { activity } = props
  const title = renderTitle(activity)
  const description = renderDescription(activity)
  return (
    <div className="activity-item">
      <div className="activity-item__date">
        {formatDate(activity.createdAt, 'j. F Y, k\\l: H:i')}
      </div>
      {title && <div className="activity-item__title">{title}</div>}
      {description && (
        <div className="activity-item__description">{description}</div>
      )}
    </div>
  )
}

ActivityItem.propTypes = {
  activity: PropTypes.object,
}

export default ActivityItem
