import React from 'react'
import PropTypes from 'react-proptypes'
import { List, ListItem } from '../List'
import Icon from '../Icon'
import classNames from 'classnames'
import './style.styl'

export default class ReportAssessmentsNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.renderNavigationItem = this.renderNavigationItem.bind(this)
  }

  renderNavigationItem(category) {
    const { currentCategoryId } = this.props
    const active = currentCategoryId === category.id
    const className = classNames('report-assessments-navigation__item', {
      'report-assessments-navigation__item--active': active,
    })
    return (
      <ListItem
        className={className}
        title={category.name}
        description={
          category.assessmentCategoryType &&
          category.assessmentCategoryType.name
        }
        key={category.id}
        onClick={() => this.props.onNavigationClick(category.id)}
      >
        {category.assessment && (
          <Icon
            icon={`smiley-${category.assessment.smiley}`}
            className={`report-assessments-navigation__smiley--${category.assessment.smiley}`}
          />
        )}
      </ListItem>
    )
  }

  render() {
    const items = this.props.categoriesWithCategoryTypeAndAssessment
      .filter((c) => !c.hideInPdf)
      .sort((a, b) => (b.position < a.position ? 1 : -1))

    return (
      <List noPadding itemRenderer={this.renderNavigationItem} items={items} />
    )
  }
}

ReportAssessmentsNavigation.propTypes = {
  currentCategoryId: PropTypes.string,
  categoriesWithCategoryTypeAndAssessment: PropTypes.array.isRequired,
  onNavigationClick: PropTypes.func.isRequired,
}
