import 'core-js/stable'
import 'whatwg-fetch'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route, IndexRoute, Redirect, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import parseSession from './utils/parseSession'
import * as types from './constants/ActionTypes'
import configureStore from './store/configureStore'
import AppContainer from './containers/AppContainer'
import ConversionRatesContainer from './containers/ConversionRatesContainer'
import OrdersContainer from './containers/OrdersContainer'
import OrderContainer from './containers/OrderContainer'
import ReportsContainer from './containers/ReportsContainer'
import ReportContainer from './containers/ReportContainer'
import NewReportContainer from './containers/NewReportContainer'
import NewReportTemplateContainer from './containers/NewReportTemplateContainer'
import NewReportCustomerContainer from './containers/NewReportCustomerContainer'
import NewReportBuildingsContainer from './containers/NewReportBuildingsContainer'
import NewReportBuildingDetailsContainer from './containers/NewReportBuildingDetailsContainer'
import NewReportAssessmentsContainer from './containers/NewReportAssessmentsContainer'
import NewReportAssessmentCategoryContainer from './containers/NewReportAssessmentCategoryContainer'
import NewReportEnergyMarkContainer from './containers/NewReportEnergyMarkContainer'
import NewWizardBuildingContainer from './containers/NewWizardBuildingContainer'
import ReferenceListContainer from './containers/ReferenceListContainer'
import PasswordResetContainer from './containers/PasswordResetContainer'
import PasswordUpdateContainer from './containers/PasswordUpdateContainer'
import MergeOrderContainer from './containers/MergeOrderContainer'
import DraftsPerCustomerContainer from './containers/DraftsPerCustomerContainer'
import DraftContainer from './containers/DraftContainer'
import DraftWizardBuildingContainer from './containers/DraftWizardBuildingContainer'
import BuildingPropertiesContainer from './containers/BuildingPropertiesContainer'
import WizardBuildingImagesContainer from './containers/WizardBuildingImagesContainer'
import WizardBuildingStepContainer from './containers/WizardBuildingStepContainer'
import ManagerRatingContainer from './containers/ManagerRatingContainer'
import './styles/index.styl'

const store = configureStore()
const history = syncHistoryWithStore(hashHistory, store)
const session = parseSession(localStorage.getItem('session'))
const orderQuery = localStorage.getItem('algoliaOrderQuery')

if (orderQuery) {
  store.dispatch({
    type: types.ALGOLIA_SET_QUERY,
    entityType: 'orders',
    query: JSON.parse(orderQuery),
  })
}

if (session) {
  store.dispatch({
    type: types.LOGIN_SUCCESS,
    payload: session,
  })
}

render(
  <Provider store={store}>
    <Router history={history}>
      <Redirect from="/" to="/orders" />
      <Route path="/" component={AppContainer}>
        {/* SESSION */}
        <Route path="/password-reset" component={PasswordResetContainer} />
        <Route path="/password-update" component={PasswordUpdateContainer} />

        {/* ORDERS */}
        <Route path="/orders" component={OrdersContainer} />
        <Route path="/orders/:orderId" component={OrderContainer} />
        <Route path="/orders/:orderId/merge" component={MergeOrderContainer} />

        {/* KPI REPORTS */}
        <Route path="/conversion-rates" component={ConversionRatesContainer} />

        {/* REFERENCES */}
        <Route path="/reference-list" component={ReferenceListContainer} />

        {/* REPORTS */}
        <Route path="/reports" component={ReportsContainer} />
        <Route path="/reports/new" component={NewReportContainer}>
          <IndexRoute component={NewReportTemplateContainer} />
          <Route path=":templateId" component={NewReportCustomerContainer} />
          <Route
            path=":templateId/:customerId"
            component={NewReportBuildingsContainer}
          />
          <Route
            path=":buildingId/:reportId/energy-mark"
            component={NewReportEnergyMarkContainer}
          />
          <Route
            path=":templateId/:customerId/:buildingId"
            component={NewReportBuildingDetailsContainer}
          />
          <Route
            path=":templateId/:customerId/:buildingId/assessments"
            component={NewReportAssessmentsContainer}
          >
            <Route
              path=":assessmentCategoryId"
              component={NewReportAssessmentCategoryContainer}
            />
          </Route>
        </Route>
        <Route path="/reports/:reportId" component={ReportContainer} />

        {/* DRAFTS (ORDER CREATION) */}
        <Route
          path="/drafts/per-customer(/:customerId)"
          component={DraftsPerCustomerContainer}
        />
        <Route path="/drafts/:orderId" component={DraftContainer} />
        <Route
          path="/drafts/:orderId/wizard-buildings/new"
          component={NewWizardBuildingContainer}
        />
        <Route
          path="/drafts/:orderId/wizard-buildings/:wizardBuildingId"
          component={DraftWizardBuildingContainer}
        >
          <Route path="properties" component={BuildingPropertiesContainer} />
          <Route path="images" component={WizardBuildingImagesContainer} />
          <Route
            path="wizard-building-steps/:wizardBuildingStepId"
            component={WizardBuildingStepContainer}
          />
        </Route>

        {/* MANAGER RATING */}
        <Route
          path="/manager-rating(/:customerId)"
          component={ManagerRatingContainer}
        />
      </Route>
    </Router>
  </Provider>,
  document.getElementById('main')
)
