import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import * as KpiReportActions from '../actions/KpiReportActions'
import * as AppActions from '../actions/AppActions'
import * as Selectors from '../selectors/KpiReportSelectors'
import Spinner from '../components/Spinner'
import ConversionRatesPage from '../components/ConversionRatesPage'

class ConversionRatesContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    fetchKpiReport: PropTypes.func.isRequired,
    conversionRates: PropTypes.object,
    menuVisible: PropTypes.bool,
    showMenu: PropTypes.func.isRequired,
    hideMenu: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.fetchConversionRates()
  }

  // Reload automatically
  componentDidUpdate() {
    this.scheduleReload()
  }

  componentWillUnmount() {
    this.unscheduleReload()
  }

  scheduleReload() {
    this.unscheduleReload()
    this.reloadTimer = setTimeout(this.fetchConversionRates, 1000 * 60)
  }

  unscheduleReload() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer)
      this.reloadTimer = null
    }
  }

  fetchConversionRates = (options) => {
    this.props.fetchKpiReport('conversion-rates')
  }

  handleMenuToggle = () => {
    const { hideMenu, showMenu, menuVisible } = this.props

    if (menuVisible) {
      hideMenu()
    } else {
      showMenu()
    }
  }

  render() {
    const { isFetching, conversionRates, menuVisible } = this.props

    if (!conversionRates || (!conversionRates && isFetching)) {
      return <Spinner center />
    }

    return (
      <ConversionRatesPage
        style={{ opacity: isFetching ? 0.5 : 1 }}
        data={conversionRates}
        menuVisible={menuVisible}
        onMenuToggle={this.handleMenuToggle}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const params = { kpiReportId: 'conversion-rates' }
  return {
    isFetching: Selectors.isFetching(state, { params }),
    conversionRates: Selectors.getKpiReport(state, { params }),
    menuVisible: state.app.menuVisible,
  }
}

export default connect(mapStateToProps, {
  ...KpiReportActions,
  ...AppActions,
})(ConversionRatesContainer)
