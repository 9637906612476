import apiKey from '../constants/ApiKey'
import algoliaApplicationId from '../constants/AlgoliaApplicationId'
import AlgoliaClient from '../utils/AlgoliaClient'
import * as types from '../constants/ActionTypes'

const cachedClients = {}
const getClient = (apiSessionToken, type) => {
  const cacheKey = [apiSessionToken, type].join('-')
  if (cachedClients[cacheKey]) return cachedClients[cacheKey]

  cachedClients[cacheKey] = new AlgoliaClient(
    algoliaApplicationId,
    apiKey,
    apiSessionToken,
    type
  )
  return cachedClients[cacheKey]
}

export const insertFakeAlgoliaResult = (type, data) => ({
  type: types.ALGOLIA_INSERT_FAKE_RESULT,
  result: { type, data },
})

export const searchAlgolia = (type, customQuery) => (dispatch, getState) => {
  const {
    auth: { token: sessionToken },
    algolia,
  } = getState()
  const {
    queries: { [type]: query = {} },
  } = algolia

  dispatch({ type: types.ALGOLIA_SEARCH_REQUEST, entityType: type })
  getClient(sessionToken, type)
    .search(customQuery || query)
    .then((content) =>
      dispatch({
        type: types.ALGOLIA_SEARCH_SUCCESS,
        content,
        entityType: type,
      })
    )
    .catch((error) => {
      console.error(error)
      const { type: errorType, message: errorMessage } = error
      dispatch({
        type: types.ALGOLIA_SEARCH_FAILURE,
        entityType: type,
        errorType,
        errorMessage,
      })
    })
}

export const searchMoreAlgolia = (type) => (dispatch, getState) => {
  const {
    auth: { token: sessionToken },
    algolia,
  } = getState()
  const {
    queries: { [type]: query = {} },
    pages: {
      [type]: { current },
    },
  } = algolia

  dispatch({ type: types.ALGOLIA_SEARCH_REQUEST, entityType: type })
  getClient(sessionToken, type)
    .search(query, current + 1)
    .then((content) =>
      dispatch({
        type: types.ALGOLIA_SEARCH_MORE_SUCCESS,
        content,
        entityType: type,
      })
    )
    .catch((error) => {
      console.error(error)
      dispatch({ type: types.ALGOLIA_SEARCH_FAILURE, entityType: type })
    })
}

export const setAlgoliaQuery = (entityType, query) => ({
  type: types.ALGOLIA_SET_QUERY,
  entityType,
  query,
})

export const clearAlgoliaCache = (entityType) => (dispatch, getState) => {
  const {
    auth: { token: sessionToken },
  } = getState()
  getClient(sessionToken, entityType).clearCache()
}
