import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const SettingsList = (props) => (
  <div className="settings-list">{props.children}</div>
)

SettingsList.propTypes = {
  children: PropTypes.any,
}

const SettingsListItem = ({
  label,
  description,
  children,
  error,
  borderless,
  narrow,
}) => {
  const className = classNames('settings-list__item', {
    'settings-list__item--borderless': borderless,
    'settings-list__item--narrow': narrow,
  })

  return (
    <div className={className}>
      <div className="settings-list__item__label">
        {label}
        {description && (
          <div className="settings-list__item__description">{description}</div>
        )}
      </div>
      <div className="settings-list__item__content">
        {children}
        {error ? (
          <div className="settings-list__item__error">{error}</div>
        ) : null}
      </div>
    </div>
  )
}

SettingsListItem.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.any,
  children: PropTypes.any,
  borderless: PropTypes.bool,
  narrow: PropTypes.bool,
}

export default SettingsList
export { SettingsListItem }
