import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as AuthSelectors from '../selectors/AuthSelectors'
import { initialize } from '../actions/AppActions'
import Spinner from '../components/Spinner'
import LoginContainer from './LoginContainer'
import SidebarContainer from './SidebarContainer'
import DialogContainer from './DialogContainer'

class AppContainer extends Component {
  static propTypes = {
    initialize: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isInitializing: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.element,
  }

  componentDidMount() {
    this.props.initialize()
  }

  render() {
    const { children, isAuthenticated, isInitializing, menuVisible, location } =
      this.props

    if (isInitializing) {
      return (
        <div className="app">
          <Spinner center />
        </div>
      )
    }

    if (!isAuthenticated) {
      const { pathname } = location
      return (
        <div className="app">
          {pathname === '/password-reset' || pathname === '/password-update' ? (
            children
          ) : (
            <LoginContainer />
          )}
        </div>
      )
    }

    const secondaryContentClasses = classNames('content__secondary', {
      'content__secondary--hidden': !menuVisible,
    })
    const primaryContentClasses = classNames('content__primary', {
      'content__primary--wide': !menuVisible,
    })

    return (
      <div className="app">
        <div className="content">
          <div className={secondaryContentClasses}>
            <SidebarContainer location={location} />
          </div>
          <div className={primaryContentClasses}>{children}</div>
        </div>
        <DialogContainer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isInitializing: state.app.isInitializing,
    isAuthenticated: AuthSelectors.isAuthenticated(state),
    menuVisible: state.app.menuVisible,
  }
}

export default connect(mapStateToProps, {
  initialize,
})(AppContainer)
