import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import PasswordResetPage from '../components/PasswordResetPage'
import * as AuthActions from '../actions/AuthActions'

class PasswordResetContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      isSent: false,
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSendSuccess = this.handleSendSuccess.bind(this)
  }

  handleFormSubmit(values) {
    const { email } = values
    const { sendPasswordResetRequest, location } = this.props

    this.setState({ isSubmitting: true, error: null })

    sendPasswordResetRequest({ email }).then(this.handleSendSuccess)
  }

  handleSendSuccess() {
    this.setState({ isSubmitting: false, isSent: true })
  }

  handleSendError() {
    this.setState({ isSubmitting: false, error: 'Der opstod en fejl' })
  }

  render() {
    const { location } = this.props
    const { isSubmitting, isSent } = this.state
    const email = location.query.email || ''

    return (
      <PasswordResetPage
        onSubmit={this.handleFormSubmit}
        isSubmitting={isSubmitting}
        isSent={isSent}
        email={email}
      />
    )
  }
}

export default connect(null, {
  ...AuthActions,
})(PasswordResetContainer)
