import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import Select from 'react-select'
import './style.styl'

export const Multiselect = (props) => (
  <Select
    placeholder="Vis alle"
    noResultsText="Ingen resultater"
    {...props}
    multi
    className={classNames('multiselect', props.className)}
  />
)

Multiselect.propTypes = {
  className: PropTypes.string,
}

export default Multiselect
