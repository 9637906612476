import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { login } from '../actions/AuthActions'
import LoginPage from '../components/LoginPage'

class LoginContainer extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticating: PropTypes.bool,
    error: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.handleLogin = this.handleLogin.bind(this)
  }

  handleLogin(credentials) {
    this.props.login(credentials)
  }

  render() {
    const { isAuthenticating, error } = this.props

    return (
      <LoginPage
        isAuthenticating={isAuthenticating}
        onLogin={this.handleLogin}
        error={error}
      />
    )
  }
}

function mapStateToProps({ auth }) {
  return {
    isAuthenticating: auth.isAuthenticating,
    error: auth.error,
  }
}

export default connect(mapStateToProps, {
  login,
})(LoginContainer)
