import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const PreviewPanel = (props) => {
  const className = classNames(
    'preview-panel',
    props.className,
    { 'preview-panel--seamless-top': props.seamless || props.seamlessTop },
    { 'preview-panel--seamless-bottom': props.seamless || props.seamlessBottom }
  )

  const divProps = { ...props }
  delete divProps.seamlessTop
  delete divProps.seamlessBottom
  delete divProps.seamless

  return <div {...divProps} className={className} />
}

PreviewPanel.propTypes = {
  className: PropTypes.string,
  seamlessTop: PropTypes.bool,
  seamlessBottom: PropTypes.bool,
  seamless: PropTypes.bool,
}

export default PreviewPanel
