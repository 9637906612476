import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'

export const getBuildingId = (state, props) => props.params.buildingId
export const getCategoryId = (state, props) => props.params.assessmentCategoryId
export const getBuildingAssessmentCategoryImages = createEntitiesSelector(
  'buildingAssessmentCategoryImages'
)

export const getBuildingAssessmentCategoryImagesForBuildingAndCategory =
  createSelector(
    getBuildingAssessmentCategoryImages,
    getBuildingId,
    getCategoryId,
    (images, buildingId, categoryId) =>
      images.filter(
        (i) => i.building === buildingId && i.assessmentCategory === categoryId
      )
  )
