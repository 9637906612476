import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import * as AuthSelectors from '../selectors/AuthSelectors'
import SidebarItems from '../constants/SidebarItems'
import { logout } from '../actions/AuthActions'
import { openDialog } from '../actions/DialogActions'
import Sidebar, {
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuStatsItem,
} from '../components/Sidebar'

class SidebarContainer extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    push: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
    active: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this)
    this.handleSettingsClick = this.handleSettingsClick.bind(this)
    this.handlePasswordClick = this.handlePasswordClick.bind(this)
    this.handleSignOutClick = this.handleSignOutClick.bind(this)
  }

  handleMenuItemClick(item) {
    if (item.external) {
      window.location = item.path
    } else {
      this.props.push(item.path)
    }
  }

  handleSettingsClick() {
    this.props.openDialog('userSettings')
  }

  handlePasswordClick() {
    this.props.openDialog('userPassword')
  }

  handleSignOutClick() {
    this.props.logout()
  }

  render() {
    const { items, active, user } = this.props
    const { handleMenuItemClick } = this

    return (
      <Sidebar
        user={user}
        onSettingsClick={this.handleSettingsClick}
        onPasswordClick={this.handlePasswordClick}
        onSignOutClick={this.handleSignOutClick}
      >
        <SidebarMenu>
          {items.map((item) => (
            <SidebarMenuItem
              active={item === active}
              key={item.path}
              title={item.label}
              path={item.path}
              icon={item.icon}
              external={item.external}
              onClick={() => handleMenuItemClick(item)}
            />
          ))}
          <SidebarMenuStatsItem />
        </SidebarMenu>
      </Sidebar>
    )
  }
}

function mapStateToProps(state, { location }) {
  const user = AuthSelectors.getCurrentUser(state)
  const items = SidebarItems.filter((item) =>
    item.roles.some((role) => role === user.role)
  )

  return {
    user,
    items,
    active: items.find((item) => location.pathname.startsWith(item.path)),
  }
}

export default connect(mapStateToProps, {
  push,
  logout,
  openDialog,
})(SidebarContainer)
