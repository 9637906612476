import React from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'
import { Column } from '../Grid'

export const BuildingPropertyResultValue = ({
  unitsPerQuantity,
  unitType,
  name,
}) => (
  <Column size={3} className="building-property-result-value">
    <div className="building-property-result-value__inner">
      <div className="building-property-result-value__value">
        {unitsPerQuantity}
        <span> {unitType}</span>
      </div>
      <div className="building-property-result-value__name">{name}</div>
    </div>
  </Column>
)

BuildingPropertyResultValue.propTypes = {
  unitsPerQuantity: PropTypes.string.isRequired,
  unitType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default BuildingPropertyResultValue
