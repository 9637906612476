import {
  fetchEntity,
  fetchEntities,
  deleteEntity,
  updateEntity,
  createEntity,
} from './ApiActions'
import { commentablelizeString } from '../utils/formatUtils'

const attributesToFormData = (attributes) => {
  const key = 'comment'
  const formData = new FormData()
  const { content, commentableId, commentableType, meta = {} } = attributes

  formData.append(`${key}[content]`, content)
  formData.append(`${key}[commentable_id]`, commentableId)
  formData.append(
    `${key}[commentable_type]`,
    commentablelizeString(commentableType)
  )
  formData.append(`${key}[meta][type]`, meta.type)

  return formData
}

export const fetchComments = () => fetchEntities('comments')

export const fetchCommentsForCommentable = (params) =>
  fetchEntities('comments', {
    query: {
      commentable_id: params.commentableId,
      commentable_type: params.commentableType,
      include: ['user'],
    },
  })

export const fetchComment = (id) => fetchEntity({ type: 'comments', id })

export const deleteComment = (id) => deleteEntity({ type: 'comments', id })

export const updateComment = (id, attributes) =>
  updateEntity(
    { type: 'comments', id },
    {
      body: attributesToFormData(attributes),
    }
  )

export const createComment = (attributes) =>
  createEntity(
    { type: 'comments' },
    {
      body: attributesToFormData(attributes),
    }
  )
