import React from 'react'
import PropTypes from 'react-proptypes'
import omit from 'lodash/omit'
import Button from '../Button'

const LoadMoreButton = (props) => {
  const { isLoading } = props
  const otherProps = omit(props, 'disabled', 'isLoading', 'canLoadMore')

  return (
    <Button disabled={isLoading} {...otherProps}>
      {isLoading ? 'Indlæser flere…' : 'Indlæs flere'}
    </Button>
  )
}

LoadMoreButton.propTypes = {
  isLoading: PropTypes.bool,
}

export default LoadMoreButton
