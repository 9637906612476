import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import Icon from '../../Icon'

const SidebarMenuItem = (props) => {
  const classes = classNames('sidebar__menu__item', {
    active: props.active,
  })

  return (
    <li className={classes} onClick={props.onClick}>
      {props.icon ? <Icon icon={props.icon} /> : null}
      {props.title}
    </li>
  )
}

SidebarMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

SidebarMenuItem.defaultProps = {
  title: '',
  active: false,
}

export default SidebarMenuItem
