import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'

import SearchInput from '../SearchInput'
import DatePicker from '../DatePicker'
import Popover from '../Popover'
import Icon from '../Icon'
import { Margin, FlexContainer, Flex } from '../Grid'
import Select from '../Select'
import Multiselect from '../Multiselect'
import isEqual from 'lodash/isEqual'

import { isValidDateString } from '../../utils/validationUtils'
import { formatUserName } from '../../utils/formatUtils'
import { OrderStateOptions } from '../../constants/OrderStates'

export default class OrderFilters extends Component {
  static propTypes = {
    query: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    salesmen: PropTypes.array,
    departments: PropTypes.array,
    wizards: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this)
    this.handleAfterDateChange = this.handleAfterDateChange.bind(this)
    this.handleBeforeDateChange = this.handleBeforeDateChange.bind(this)
    this.handleSalesmanChange = this.handleSalesmanChange.bind(this)
    this.togglePopover = this.togglePopover.bind(this)
    this.handleStatesChange = this.handleMultiselectChange.bind(
      this,
      'aasm_state'
    )
    this.handleDepartmentsChange = this.handleMultiselectChange.bind(
      this,
      'department.id'
    )
    this.handleWizardsChange = this.handleMultiselectChange.bind(
      this,
      '_wizard_ids'
    )

    this.state = { popoverOpened: false }
  }

  handleSearchQueryChange(query) {
    this.props.onChange({
      q: query,
      'user.id': null,
      'department.id': null,
      aasm_state: null,
      _wizard_ids: null,
      created_at_timestamp: null,
    })
  }

  handleAfterDateChange(value) {
    const { query } = this.props
    const createdAtQuery = query.created_at_timestamp || {}

    if (value === '') {
      const newCreatedAtQuery = { ...createdAtQuery }
      delete newCreatedAtQuery['>=']
      this.props.onChange({ created_at_timestamp: newCreatedAtQuery })
    } else if (isValidDateString(value)) {
      const date = new Date(value.split(/\/|-/).reverse().join('-')) / 1000
      if (date !== createdAtQuery['>=']) {
        this.props.onChange({
          created_at_timestamp: { ...createdAtQuery, '>=': date },
        })
      }
    }
  }

  handleBeforeDateChange(value) {
    const { query } = this.props
    const createdAtQuery = query.created_at_timestamp || {}

    if (value === '') {
      const newCreatedAtQuery = { ...createdAtQuery }
      delete newCreatedAtQuery['<']
      this.props.onChange({ created_at_timestamp: newCreatedAtQuery })
    } else if (isValidDateString(value)) {
      let date = new Date(value.split(/\/|-/).reverse().join('-')) / 1000
      date += 24 * 3600 // We want to include this date

      if (date !== createdAtQuery['<']) {
        this.props.onChange({
          created_at_timestamp: { ...createdAtQuery, '<': date },
        })
      }
    }
  }

  handleSalesmanChange({ target: { value } }) {
    if (value !== (this.props.query['user.id'] || '')) {
      this.props.onChange({ 'user.id': value ? parseInt(value) : undefined })
    }
  }

  handleMultiselectChange(field, selected) {
    const current = this.props.query[field] || []

    const values = (selected || []).map((o) => o.value)
    if (!isEqual(current, values)) {
      this.props.onChange({ [field]: values })
    }
  }

  togglePopover() {
    this.setState({ popoverOpened: !this.state.popoverOpened })
  }

  renderAdditionalFilters() {
    const { salesmen, departments, wizards, query } = this.props
    const salesmanOptions = salesmen
      .map((u) => ({ value: u.id, label: formatUserName(u) }))
      .sort((a, b) => {
        if (a.label < b.label) return -1
        else if (b.label < a.label) return 1
        return 0
      })
    salesmanOptions.unshift({ value: '', label: 'Vis alle' })

    const departmentOptions = departments.map((d) => ({
      value: parseInt(d.id),
      label: d.name,
    }))
    const selectedDepartments = departmentOptions.filter(
      (d) => (query['department.id'] || []).indexOf(d.value) !== -1
    )

    const selectedStates = OrderStateOptions.filter(
      (d) => (query.aasm_state || []).indexOf(d.value) !== -1
    )

    const wizardOptions = wizards.map((w) => ({
      value: parseInt(w.id),
      label: w.name,
    }))
    const selectedWizards = wizardOptions.filter(
      (d) => (query._wizard_ids || []).indexOf(d.value) !== -1
    )

    return (
      <Margin all size={1} className="order-filters--additional-filters">
        <FlexContainer horizontal className="margin--bottom-1">
          <label
            className="order-filters--label"
            htmlFor="order-filters--salesman"
          >
            Sælger
          </label>
          <Flex>
            <Select
              id="order-filters--salesman"
              className="flex--grow margin--left-1"
              options={salesmanOptions}
              value={query['user.id'] ? query['user.id'].toString() : undefined}
              onChange={this.handleSalesmanChange}
            />
          </Flex>
        </FlexContainer>

        <FlexContainer horizontal className="margin--bottom-1">
          <label
            className="order-filters--label"
            htmlFor="order-filters--states"
          >
            Status
          </label>
          <Flex>
            <Multiselect
              id="order-filters--states"
              className="flex--grow margin--left-1"
              options={OrderStateOptions}
              value={selectedStates}
              onChange={this.handleStatesChange}
            />
          </Flex>
        </FlexContainer>

        <FlexContainer horizontal className="margin--bottom-1">
          <label
            className="order-filters--label"
            htmlFor="order-filters--department_ids"
          >
            Afdelinger
          </label>
          <Flex>
            <Multiselect
              id="order-filters--department_ids"
              className="flex--grow margin--left-1"
              options={departmentOptions}
              value={selectedDepartments}
              onChange={this.handleDepartmentsChange}
            />
          </Flex>
        </FlexContainer>

        <FlexContainer horizontal className="margin--bottom-1">
          <label
            className="order-filters--label"
            htmlFor="order-filters--wizards"
          >
            Tilbudspakker
          </label>
          <Flex>
            <Multiselect
              id="order-filters--states"
              className="flex--grow margin--left-1"
              options={wizardOptions}
              value={selectedWizards}
              onChange={this.handleWizardsChange}
            />
          </Flex>
        </FlexContainer>
      </Margin>
    )
  }

  render() {
    const { query } = this.props
    const createdAtQuery = query.created_at_timestamp || {}
    const { popoverOpened } = this.state
    const from = createdAtQuery['>='] && createdAtQuery['>='] * 1000
    const to = createdAtQuery['<'] && (createdAtQuery['<'] - 24 * 3600) * 1000

    return (
      <div className="order-filters">
        Fra
        <DatePicker
          value={new Date(from)}
          onChange={this.handleAfterDateChange}
          className="margin--right-2 margin--left-1"
          placeholder="vælg dato"
        />
        Til
        <DatePicker
          value={new Date(to)}
          onChange={this.handleBeforeDateChange}
          className="margin--right-2 margin--left-1"
          placeholder="vælg dato"
        />
        <Popover
          className="margin--right-2"
          position="bottom center"
          body={this.renderAdditionalFilters()}
          open={popoverOpened}
        >
          <div onClick={this.togglePopover}>
            Flere filtre <Icon icon="caret" size={11} />
          </div>
        </Popover>
        <SearchInput
          onSubmit={this.handleSearchQueryChange}
          defaultValue={query.q}
        />
      </div>
    )
  }
}
