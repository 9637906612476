import React from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'

export const CheckboxItem = (props) => (
  <label className="checkbox-item">
    <input
      type="checkbox"
      value={props.value}
      checked={props.checked}
      onChange={props.onChange}
      name={props.name}
    />{' '}
    {props.label}
  </label>
)

CheckboxItem.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
}

export default CheckboxItem
