import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import SidebarMenu from './Menu'
import SidebarMenuItem from './MenuItem'
import SidebarMenuStatsItem from './MenuStatsItem'
import SidebarHeader from './Header'

const Sidebar = ({
  user,
  children,
  onSettingsClick,
  onPasswordClick,
  onSignOutClick,
}) => (
  <div className="sidebar hide-print">
    <SidebarHeader
      user={user}
      onSettingsClick={onSettingsClick}
      onPasswordClick={onPasswordClick}
      onSignOutClick={onSignOutClick}
    />
    <div className="sidebar__content">{children}</div>
    <div className="sidebar__footer"></div>
  </div>
)

Sidebar.propTypes = {
  children: PropTypes.element,
  user: PropTypes.object,
  onSettingsClick: PropTypes.func.isRequired,
  onPasswordClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
}

export { SidebarHeader, SidebarMenu, SidebarMenuItem, SidebarMenuStatsItem }
export default Sidebar
