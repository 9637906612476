import React from 'react'
import PropTypes from 'react-proptypes'

import ChecklistNavigationListItem from '../../components/ChecklistNavigationListItem'
import List from '../../components/List'

const WizardBuildingSteps = ({ wizardBuildingSteps, baseURL }) => (
  <List noPadding>
    {wizardBuildingSteps.map((wizardBuildingStep) => (
      <ChecklistNavigationListItem
        icon={wizardBuildingStep.lineItems.length !== 0 ? 'cart-full' : null}
        key={wizardBuildingStep.id}
        title={wizardBuildingStep.step.name}
        linkTo={`${baseURL}/wizard-building-steps/${wizardBuildingStep.id}`}
      />
    ))}
  </List>
)

WizardBuildingSteps.propTypes = {
  wizardBuildingSteps: PropTypes.array.isRequired,
  baseURL: PropTypes.string.isRequired,
}

export default WizardBuildingSteps
