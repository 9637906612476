import React, { Component } from 'react'
import PropTypes from 'react-proptypes'

import Select from '../Select'
import Label from '../Label'
import { IconButton } from '../Button'

export default class CouponRow extends Component {
  static propTypes = {
    onToggleActive: PropTypes.func.isRequired,
    onNameSelected: PropTypes.func.isRequired,
    discountNames: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    coupon: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      discount: PropTypes.shape({
        humanizedDiscountInclVatWithSymbol: PropTypes.string.isRequired,
        name: PropTypes.string,
      }),
    }).isRequired,
  }

  handleToggleActive = () => {
    const { onToggleActive, coupon } = this.props
    onToggleActive(coupon)
  }

  handleNameSelected = ({ target: { value } }) => {
    const { onNameSelected, coupon } = this.props
    onNameSelected(coupon, value)
  }

  render() {
    const {
      coupon: { name, discount, isActive },
      discountNames,
    } = this.props
    const active = !!discount

    const discountNameOptions = discountNames.map((discountName) => ({
      value: discountName.name,
      label: discountName.name,
    }))
    discountNameOptions.unshift({
      value: '',
      label: 'Brug oprindeligt navn',
    })

    return (
      <tr>
        <td>
          <span onClick={this.handleToggleActive}>
            <IconButton icon={active ? 'checkbox-checked' : 'checkbox'} />{' '}
            {name || 'Slettet rabattype'}{' '}
          </span>
          {!isActive && <Label>Uddateret</Label>}
        </td>
        <td>
          <Select
            disabled={!active}
            value={active ? discount.name : ''}
            options={discountNameOptions}
            onChange={this.handleNameSelected}
          />
        </td>
        <td>{active && discount.humanizedDiscountInclVatWithSymbol}</td>
      </tr>
    )
  }
}
