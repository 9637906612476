import {
  fetchEntities,
  updateEntity,
  createEntity,
  deleteEntity,
} from './ApiActions'

const attributesToFormData = (attributes) => {
  const key = 'building_assessment_category_type'
  const formData = new FormData()
  const { buildingId, assessmentCategoryTypeId } = attributes
  formData.append(`${key}[building_id]`, buildingId)
  formData.append(
    `${key}[assessment_category_type_id]`,
    assessmentCategoryTypeId
  )
  return formData
}

export const fetchBuildingAssessmentCategoryTypes = (options) =>
  fetchEntities('buildingAssessmentCategoryTypes', options)

export const updateBuildingAssessmentCategoryType = (id, attributes) =>
  updateEntity(
    { type: 'buildingAssessmentCategoryTypes', id },
    {
      body: attributesToFormData(attributes),
    }
  )

export const createBuildingAssessmentCategoryType = (attributes) =>
  createEntity(
    { type: 'buildingAssessmentCategoryTypes' },
    {
      body: attributesToFormData(attributes),
    }
  )

export const deleteBuildingAssessmentCategoryType = (id) =>
  deleteEntity({ type: 'buildingAssessmentCategoryTypes', id })
