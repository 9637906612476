import { CALL_API } from '../middleware/api'
import * as types from '../constants/ActionTypes'
import {
  fetchEntity,
  createEntity,
  updateEntity,
  deleteEntity,
} from './ApiActions'
import { hashToFormData } from '../utils/formUtils'
import { objectKeysToSnakeCase } from '../utils/formatUtils'

const attributesToFormData = (attributes) =>
  hashToFormData({
    'line_item[addition]': attributes.addition,
    'line_item[product_units_per_quantity]': attributes.productUnitsPerQuantity,
    'line_item[product_variant_id]': attributes.productVariantId,
    'line_item[building_property_id]': attributes.buildingPropertyId,
    'line_item[quantity]': attributes.quantity,
    'line_item[step_product_id]': attributes.stepProductId,
    'line_item[wizard_building_step_id]': attributes.wizardBuildingStepId,
    'line_item[Name]': attributes.name,
  })

export const fetchLineItem = (id, options) =>
  fetchEntity({ type: 'lineItems', id }, options)

export const createLineItem = (attributes) =>
  createEntity(
    { type: 'line_items' },
    { body: attributesToFormData(attributes) }
  )

export const fetchLineItemPrice = (attributes) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: '/line_item_get_price',
    entityType: 'lineItems',
    data: { line_item: objectKeysToSnakeCase(attributes) },
    types: [
      types.API_CUSTOM_REQUEST,
      types.API_CUSTOM_SUCCESS,
      types.API_CUSTOM_FAILURE,
    ],
  },
})

export const updateLineItem = (id, attributes, options) => {
  return updateEntity(
    { id, type: 'lineItems' },
    {
      ...options,
      data: {
        line_item: objectKeysToSnakeCase(attributes),
      },
    }
  )
}

export const deleteLineItem = (id) => deleteEntity({ type: 'lineItems', id })
