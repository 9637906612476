import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import Spinner from '../../../components/Spinner'
import * as BuildingPropertySelectors from '../../../selectors/BuildingPropertySelectors'
import * as BuildingImageSelectors from '../../../selectors/BuildingImageSelectors'
import * as ImageTagSelectors from '../../../selectors/ImageTagSelectors'
import {
  getEntities,
  getActionCountForEntityTypes,
} from '../../../selectors/ApiSelectors'
import { fetchBuildingProperties } from '../../../actions/BuildingPropertyActions'
import {
  fetchBuildingImages,
  createBuildingImage,
} from '../../../actions/BuildingImageActions'
import { fetchImageTags } from '../../../actions/ImageTagActions'
import BuildingInfoDialogPropertyContainer from './BuildingInfoDialogPropertyContainer'
import { Margin, Row } from '../../../components/Grid'
import BuildingImage from '../../../components/BuildingImage'
import BuildingImageForm from '../../../components/BuildingImageForm'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
} from '../../../components/Dialog'

class BuildingInfoDialog extends Component {
  static propTypes = {
    buildingId: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    buildingProperties: PropTypes.array,
    buildingImages: PropTypes.array,
    imageTags: PropTypes.array,
    imageTagEntities: PropTypes.object,

    onClose: PropTypes.func.isRequired,
    fetchBuildingProperties: PropTypes.func.isRequired,
    fetchBuildingImages: PropTypes.func.isRequired,
    fetchImageTags: PropTypes.func.isRequired,
    createBuildingImage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  constructor(props) {
    super(props)
    this.handleImageSubmit = this.handleImageSubmit.bind(this)
  }

  componentDidMount() {
    const { buildingId } = this.props
    this.props.fetchBuildingProperties({ query: { building_id: buildingId } })
    this.props.fetchBuildingImages({ query: { building_id: buildingId } })
    this.props.fetchImageTags()
  }

  handleImageSubmit(params) {
    const { buildingId } = this.props
    return this.props.createBuildingImage({ ...params, buildingId })
  }

  renderContent() {
    const {
      isFetching,
      isSaving,
      buildingProperties,
      buildingImages,
      imageTagEntities,
      imageTags,
    } = this.props

    if (isFetching) {
      return <Spinner center />
    }

    return (
      <Margin all>
        <Margin bottom key="images">
          <Row>
            {buildingImages.map((image) => (
              <BuildingImage
                key={image.id}
                image={image}
                tagEntities={imageTagEntities}
              />
            ))}
            <BuildingImageForm
              imageTags={imageTags}
              saving={isSaving}
              onSubmit={this.handleImageSubmit}
            />
          </Row>
        </Margin>
        <div>
          {buildingProperties.map((buildingProperty) => (
            <Margin bottom key={buildingProperty.id}>
              <BuildingInfoDialogPropertyContainer
                buildingProperty={buildingProperty}
              />
            </Margin>
          ))}
        </div>
      </Margin>
    )
  }

  render() {
    const { onClose } = this.props
    return (
      <Dialog>
        <DialogHeader>
          <DialogTitle>Oplysninger</DialogTitle>
          <DialogButton onClick={onClose}>Ok</DialogButton>
        </DialogHeader>
        {this.renderContent()}
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { onClose } = props
  const { buildingId } = props.params
  return {
    onClose,
    buildingId,
    isFetching:
      getActionCountForEntityTypes(state, 'fetching', [
        'buildingProperties',
        'buildingImages',
        'ImageTags',
      ]) !== 0,
    isSaving:
      getActionCountForEntityTypes(state, 'updating', 'buildingImages') !== 0,
    buildingProperties:
      BuildingPropertySelectors.getBuildingPropertiesForBuilding(state, props),
    buildingImages: BuildingImageSelectors.getBuildingImagesForBuilding(
      state,
      props
    ),
    imageTags: ImageTagSelectors.getImageTags(state),
    imageTagEntities: getEntities('imageTags')(state),
  }
}

export default connect(mapStateToProps, {
  fetchBuildingProperties,
  fetchBuildingImages,
  fetchImageTags,
  createBuildingImage,
})(BuildingInfoDialog)
