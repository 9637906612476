import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import List, { ListItem } from '../List'
import { formatDate } from '../../utils/formatUtils'
import omit from 'lodash/omit'

class ReportList extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    reports: PropTypes.arrayOf(
      PropTypes.shape({
        objectID: PropTypes.string.isRequired,
        customer: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        building: PropTypes.shape({
          fullAddress: PropTypes.string,
        }).isRequired,
        reportTemplate: PropTypes.shape({
          name: PropTypes.string,
          thumbnailImageUrl: PropTypes.string,
        }).isRequired,
        user: PropTypes.shape({
          name: PropTypes.string,
        }).isRequired,
        createdAt: PropTypes.string.isRequired,
      })
    ).isRequired,
  }

  constructor(props) {
    super(props)
    this.renderItem = this.renderItem.bind(this)
  }

  renderItem(item) {
    const { onClick } = this.props
    const { objectID, customer, building, reportTemplate, user, createdAt } =
      item

    const title = [customer && customer.name, building.fullAddress]
      .filter((present) => present)
      .join(', ')

    return (
      <ListItem
        key={objectID}
        title={title}
        description={`${reportTemplate.name}, ${user.name}`}
        onClick={() => onClick(objectID)}
        actions={formatDate(createdAt, 'j. F Y')}
      />
    )
  }

  render() {
    const { reports } = this.props
    const otherProps = omit(this.props, 'onClick', 'reports')

    return (
      <List {...otherProps} items={reports} itemRenderer={this.renderItem} />
    )
  }
}

export default ReportList
