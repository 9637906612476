import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import { fetchAssessmentsForCategoryType } from '../actions/AssessmentActions'
import {
  fetchBuildingAssessmentsForBuilding,
  createBuildingAssessment,
  updateBuildingAssessment,
} from '../actions/BuildingAssessmentActions'

import { getAssessmentsForAssessmentCategoryType } from '../selectors/AssessmentSelectors'
import { getBuildingAssessmentForBuildingAndCategoryType } from '../selectors/BuildingAssessmentSelectors'

import { getFetchCountForEntityTypes } from '../selectors/ApiSelectors'

import Spinner from '../components/Spinner'
import NewReportHeader from '../components/NewReportHeader'
import AssessmentOptionItem from '../components/AssessmentOptionItem'
import { List } from '../components/List'

class NewReportAssessmentContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,

    buildingId: PropTypes.string.isRequired,
    categoryTypeId: PropTypes.string.isRequired,
    buildingAssessmentCategoryTypeId: PropTypes.string.isRequired,

    assessments: PropTypes.array,
    buildingAssessment: PropTypes.object,
    selectedAssessment: PropTypes.object,

    fetchBuildingAssessmentsForBuilding: PropTypes.func.isRequired,
    fetchAssessmentsForCategoryType: PropTypes.func.isRequired,
    createBuildingAssessment: PropTypes.func.isRequired,
    updateBuildingAssessment: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  constructor(props) {
    super(props)
    this.onBuildingAssessmentChange = this.onBuildingAssessmentChange.bind(this)
    this.renderAssessmentOption = this.renderAssessmentOption.bind(this)
  }

  componentDidMount() {
    const { categoryTypeId } = this.props
    this.props.fetchAssessmentsForCategoryType(categoryTypeId)
  }

  componentWillReceiveProps(nextProps) {
    const { categoryTypeId } = this.props
    if (categoryTypeId !== nextProps.categoryTypeId) {
      this.props.fetchAssessmentsForCategoryType(nextProps.categoryTypeId)
    }
  }

  onBuildingAssessmentChange(assessmentId) {
    const { buildingAssessment, buildingAssessmentCategoryTypeId } = this.props
    const attributes = { buildingAssessmentCategoryTypeId, assessmentId }

    if (buildingAssessment) {
      this.props.updateBuildingAssessment(buildingAssessment.id, attributes)
    } else {
      this.props.createBuildingAssessment(attributes)
    }
  }

  renderAssessmentOption(assessment) {
    const active = this.props.selectedAssessment === assessment
    return (
      <AssessmentOptionItem
        key={assessment.id}
        assessment={assessment}
        onClick={this.onBuildingAssessmentChange}
        active={active}
      />
    )
  }

  render() {
    const { isFetching, assessments } = this.props

    if (isFetching || !assessments) {
      return <Spinner center />
    }

    return (
      <div>
        <NewReportHeader>Vurdering</NewReportHeader>
        <List
          noPadding
          items={assessments}
          itemRenderer={this.renderAssessmentOption}
        />
      </div>
    )
  }
}

const fetchedTypes = ['buildingAssessments', 'assessments']

const mapStateToProps = (state, props) => {
  const propsWithParams = { params: { ...props } }
  const assessments = getAssessmentsForAssessmentCategoryType(
    state,
    propsWithParams
  )
  const buildingAssessment = getBuildingAssessmentForBuildingAndCategoryType(
    state,
    propsWithParams
  )

  const selectedAssessment =
    (buildingAssessment &&
      assessments.find((a) => a.id === buildingAssessment.assessment)) ||
    null

  return {
    isFetching: getFetchCountForEntityTypes(state, fetchedTypes) !== 0,
    assessments,
    buildingAssessment,
    selectedAssessment,
    ...props,
  }
}

export default connect(mapStateToProps, {
  fetchBuildingAssessmentsForBuilding,
  fetchAssessmentsForCategoryType,
  createBuildingAssessment,
  updateBuildingAssessment,
})(NewReportAssessmentContainer)
