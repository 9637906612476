import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { createComment } from '../../actions/CommentActions'
import { fetchActivitiesForTrackable } from '../../actions/ActivityActions'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import { Margin } from '../../components/Grid'
import TextArea from '../../components/TextArea'
import Button from '../../components/Button'

class OrderNewCommentDialog extends Component {
  static propTypes = {
    orderId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      inputs: {},
      submitting: false,
    }
    this.handleContentChange = this.handleContentChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  handleContentChange({ target: { value } }) {
    this.setState({ inputs: { ...this.state.inputs, content: value } })
  }

  handleButtonClick() {
    const { inputs } = this.state
    const { orderId, onClose } = this.props

    this.setState({ submitting: true })

    this.props
      .createComment({
        ...inputs,
        commentableId: orderId,
        commentableType: 'orders',
        meta: { type: 'followUp' },
      })
      // Close and reload order activities
      .then(() =>
        Promise.all([
          this.props.fetchActivitiesForTrackable({
            trackableTypes: ['orders'],
            trackableId: orderId,
          }),
          onClose(),
        ])
      )
      .catch(() => this.setState({ submitting: false }))
  }

  render() {
    const { onClose } = this.props
    const { submitting, inputs } = this.state
    const disabled = submitting || !inputs.content

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={submitting}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>Ny note</DialogTitle>
        </DialogHeader>
        <Margin all>
          <p>Note</p>
          <TextArea
            defaultValue={inputs.content}
            name="inputs[content]"
            onChange={this.handleContentChange}
          />
        </Margin>
        <Margin all className="text-right">
          <Button disabled={disabled} onClick={this.handleButtonClick}>
            {(submitting && 'Gemmer...') || 'Gem'}
          </Button>
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  orderId: props.params.orderId,
})

export default connect(mapStateToProps, {
  createComment,
  fetchActivitiesForTrackable,
})(OrderNewCommentDialog)
