import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PasswordUpdatePage from '../components/PasswordUpdatePage'
import * as AuthActions from '../actions/AuthActions'

class PasswordUpdateContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      error: null,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUpdateSuccess = this.handleUpdateSuccess.bind(this)
    this.handleUpdateFailure = this.handleUpdateFailure.bind(this)
  }

  handleSubmit({ password, passwordConfirmation }) {
    const {
      location: { query },
    } = this.props
    const { updatePasswordRequest } = this.props

    this.setState({ isSubmitting: true, error: null })

    updatePasswordRequest(query.password_reset_token, {
      password,
      password_confirmation: passwordConfirmation,
    })
      .then(this.handleUpdateSuccess)
      .catch(this.handleUpdateFailure)
  }

  handleUpdateSuccess() {
    this.setState({ isSubmitting: false, error: null })
    this.props.push('/')
  }

  handleUpdateFailure() {
    this.setState({ isSubmitting: false, error: 'Der opstod en uventet fejl' })
  }

  render() {
    const { isSubmitting, error } = this.state
    const {
      location: { query },
    } = this.props

    return (
      <PasswordUpdatePage
        isSubmitting={isSubmitting}
        error={error}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

export default connect(null, {
  ...AuthActions,
  push,
})(PasswordUpdateContainer)
