import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import classNames from 'classnames'
import pick from 'lodash/pick'

import * as CustomerActions from '../../actions/CustomerActions'
import * as DialogActions from '../../actions/DialogActions'
import * as CustomerSelectors from '../../selectors/CustomerSelectors'

import { formatAddressLine } from '../../utils/formatUtils'
import Spinner from '../Spinner'
import Button from '../Button'
import EmptyPanel from '../EmptyPanel'
import { Margin } from '../Grid'
import { List, ListItem } from '../List'

class BuildingList extends Component {
  static propTypes = {
    customerId: PropTypes.string,
    buildingId: PropTypes.string,
    customer: PropTypes.object,
    buildings: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,

    fetchCustomer: PropTypes.func.isRequired,

    onBuildingSelect: PropTypes.func,
  }

  componentDidMount() {
    this.fetchCustomer()
  }

  componentWillReceiveProps({ customerId: nextCustomerId }) {
    if (nextCustomerId && nextCustomerId !== this.props.customerId) {
      this.props.fetchCustomer(nextCustomerId)
    }
  }

  fetchCustomer(customerId = this.props.customerId) {
    this.props.fetchCustomer(customerId, { query: { include: ['buildings'] } })
  }

  handleBuildingClick = (building) => {
    const { onBuildingSelect } = this.props

    if (onBuildingSelect) {
      onBuildingSelect(building)
    }
  }

  handleCreateBuildingClick = () => {
    const { customer, buildings, openDialog } = this.props

    let initialValues
    if (buildings.length === 0) {
      initialValues = {
        name: 'Hovedhus',
        ...pick(customer, [
          'addressStreetName',
          'addressStreetNumber',
          'addressZipCode',
          'addressCityName',
          'addressCountryName',
        ]),
      }
    }

    openDialog('createBuilding', { customerId: customer.id, initialValues })
  }

  renderBuildingItem = (building) => {
    const { buildingId } = this.props
    const classes = classNames('building-list-item', {
      'is-selected': buildingId === building.id,
    })

    return (
      <ListItem
        className={classes}
        key={building.id}
        title={building.name}
        description={formatAddressLine(building)}
        onClick={() => this.handleBuildingClick(building.id)}
      />
    )
  }

  renderEmptyState() {
    return (
      <div className="building-list">
        <EmptyPanel>
          Der blev ikke fundet nogle bygninger.
          <Margin top>
            <Button block onClick={this.handleCreateBuildingClick}>
              Opret ny bygning
            </Button>
          </Margin>
        </EmptyPanel>
      </div>
    )
  }

  render() {
    const { isFetching, buildings } = this.props
    const isEmpty = buildings.length === 0

    if (isFetching && isEmpty) {
      return (
        <div className="building-list">
          <Spinner center />
        </div>
      )
    }

    if (isEmpty) {
      return this.renderEmptyState()
    }

    return (
      <div className="building-list">
        <List
          className="building-list__items"
          items={buildings}
          itemRenderer={this.renderBuildingItem}
          noPadding
        />
        <Button block onClick={this.handleCreateBuildingClick}>
          Opret ny bygning
        </Button>
      </div>
    )
  }
}

const mapStateToProps = (state, { customerId }) => {
  const params = { customerId }

  const customer =
    customerId &&
    CustomerSelectors.getCustomer(state, { params }, ['buildings'])
  const { buildings = [] } = customer || {}

  return {
    customer,
    buildings,
    isFetching: CustomerSelectors.isFetching(state),
  }
}

export default connect(mapStateToProps, {
  ...CustomerActions,
  ...DialogActions,
})(BuildingList)
