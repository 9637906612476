import {
  fetchEntities,
  updateEntity,
  createEntity,
  deleteEntity,
} from './ApiActions'

const attributesToFormData = (attributes) => {
  const key = 'building_assessment'
  const formData = new FormData()
  const { buildingAssessmentCategoryTypeId, assessmentId } = attributes
  formData.append(
    `${key}[building_assessment_category_type_id]`,
    buildingAssessmentCategoryTypeId
  )
  formData.append(`${key}[assessment_id]`, assessmentId)
  return formData
}

export const fetchBuildingAssessmentsForBuilding = (buildingId) =>
  fetchEntities('buildingAssessments', { query: { building_id: buildingId } })

export const updateBuildingAssessment = (id, attributes) =>
  updateEntity(
    { type: 'buildingAssessments', id },
    { body: attributesToFormData(attributes) }
  )

export const createBuildingAssessment = (attributes) =>
  createEntity(
    { type: 'buildingAssessments' },
    { body: attributesToFormData(attributes) }
  )

export const deleteBuildingAssessment = (id) =>
  deleteEntity({ type: 'buildingAssessments', id })
