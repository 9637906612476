import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { fetchBuildingsForCustomer } from '../actions/BuildingActions'
import { openDialog } from '../actions/DialogActions'
import * as Selectors from '../selectors/BuildingSelectors'
import { Row, Column, VerticalAlign } from '../components/Grid'
import NewReportHeader from '../components/NewReportHeader'
import BuildingList from '../components/BuildingList'

class NewReportBuildingsContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    customerId: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    buildings: PropTypes.array,
    fetchBuildingsForCustomer: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  constructor(props) {
    super(props)
    this.handleOnBuildingClick = this.handleOnBuildingClick.bind(this)
  }

  handleOnBuildingClick(buildingId) {
    const { templateId, customerId } = this.props
    this.props.push(`/reports/new/${templateId}/${customerId}/${buildingId}`)
  }

  render() {
    const { customerId } = this.props

    return (
      <VerticalAlign>
        <Row>
          <Column size={6} offset={3}>
            <NewReportHeader>Tilknyt bygning</NewReportHeader>
            <BuildingList
              customerId={customerId}
              onBuildingSelect={this.handleOnBuildingClick}
            />
          </Column>
        </Row>
      </VerticalAlign>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { customerId, templateId } = props.routeParams
  return {
    isFetching: Selectors.isFetching(state),
    customerId,
    templateId,
    buildings: Selectors.getBuildingsForCustomer(state, props),
  }
}

export default connect(mapStateToProps, {
  fetchBuildingsForCustomer,
  push,
  openDialog,
})(NewReportBuildingsContainer)
