import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { findDOMNode } from 'react-dom'
import Input from '../Input'
import SettingsList, { SettingsListItem } from '../SettingsList'
import DatePicker from '../DatePicker'
import RadioGroup from '../RadioGroup'
import SignatureInput from '../SignatureInput'
import Select from '../Select'

const mapDepartmentToOption = ({ name, id }) => ({ label: name, value: id })

class UserForm extends Component {
  static fields = [
    'name',
    'email',
    'password',
    'passwordConfirmation',
    'role',
    'signature',
    'phone',
    'department',
    'startedAt',
  ]

  static propTypes = {
    user: PropTypes.object,
    userRoles: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    submitting: PropTypes.bool.isRequired,
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: false,
  }

  componentDidMount() {
    this.focusNameInput()
  }

  focusNameInput() {
    const form = findDOMNode(this)
    const name = form.querySelector('[name=name]')
    name.focus()
  }

  submit() {
    this.buttonElement.dispatchEvent(new MouseEvent('click'))
  }

  renderStartedAtField() {
    const {
      user,
      fields: { startedAt },
      submitting,
    } = this.props

    let value = startedAt.value ? startedAt.value : new Date()
    if (!(value instanceof Date)) {
      value = new Date(value)
    }

    return (
      <SettingsListItem label="Startdato">
        <DatePicker
          {...startedAt}
          onChange={(string, date) => startedAt.onChange(date)}
          disabled={submitting}
          value={value}
        />
      </SettingsListItem>
    )
  }

  render() {
    const {
      user,
      departments,
      fields: {
        name,
        email,
        phone,
        department,
        password,
        passwordConfirmation,
        signature,
        role,
      },
      userRoles,
      handleSubmit,
      submitting,
    } = this.props

    return (
      <form
        ref={(element) => (this.formElement = element)}
        onSubmit={handleSubmit}
      >
        <SettingsList>
          <SettingsListItem label="Navn" error={name.error}>
            <Input disabled={submitting} type="text" {...name} />
          </SettingsListItem>
          <SettingsListItem label="Email" error={email.error}>
            <Input disabled={submitting} type="email" {...email} />
          </SettingsListItem>
          <SettingsListItem label="Afdeling" error={department.error}>
            <Select
              disabled={submitting}
              options={departments.map(mapDepartmentToOption)}
              {...department}
            />
          </SettingsListItem>
          {role.value === 'salesman' ? this.renderStartedAtField() : null}
          <SettingsListItem label="Tlf. Nr." error={phone.error}>
            <Input disabled={submitting} type="text" {...phone} />
          </SettingsListItem>
          {!user && (
            <SettingsListItem label="Adgangskode" error={password.error}>
              <Input disabled={submitting} type="password" {...password} />
            </SettingsListItem>
          )}
          {!user && (
            <SettingsListItem
              label="Gentag adgangskode"
              error={passwordConfirmation.error}
            >
              <Input
                disabled={submitting}
                type="password"
                {...passwordConfirmation}
              />
            </SettingsListItem>
          )}
          <SettingsListItem label="Type" error={role.error}>
            <RadioGroup options={userRoles} {...role} />
          </SettingsListItem>

          <SettingsListItem label="Underskrift" error={signature.error}>
            <SignatureInput {...signature} name={name.value} />
          </SettingsListItem>
        </SettingsList>
        <button
          ref={(element) => (this.buttonElement = element)}
          type="submit"
          style={{ display: 'none' }}
        />
      </form>
    )
  }
}

export default UserForm
