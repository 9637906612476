import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import * as ActivityActions from '../actions/ActivityActions'
import { getActivitiesForTrackableReversed } from '../selectors/ActivitySelectors'
import { getFetchCountForEntityTypes } from '../selectors/ApiSelectors'
import Spinner from '../components/Spinner'
import ActivityItem from '../components/ActivityItem'
import EmptyPanel from '../components/EmptyPanel'
import { Margin } from '../components/Grid'

class ActivityContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    activities: PropTypes.array.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
    trackableTypes: PropTypes.array.isRequired,
    trackableId: PropTypes.string.isRequired,
  }

  componentDidMount() {
    const { fetchActivitiesForTrackable, trackableTypes, trackableId } =
      this.props
    fetchActivitiesForTrackable({ trackableTypes, trackableId })
  }

  renderActivity(activity) {
    return <ActivityItem key={activity.id} activity={activity} />
  }

  render() {
    const { isFetching, activities } = this.props

    if (isFetching) {
      return <Spinner center />
    }

    return (
      <div>
        {(activities.length && activities.map(this.renderActivity)) || (
          <Margin size={2} top>
            <EmptyPanel>Der er ingen hændelser på ordren endnu</EmptyPanel>
          </Margin>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const selectorProps = { params: props }
  return {
    ...props,
    isFetching: getFetchCountForEntityTypes(state, 'activities') !== 0,
    activities: getActivitiesForTrackableReversed(state, selectorProps),
  }
}

export default connect(mapStateToProps, {
  ...ActivityActions,
})(ActivityContainer)
