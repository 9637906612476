import * as types from '../constants/ActionTypes'
import { createReducer } from '../utils/reducerUtils'

const initialState = {
  searchString: '',
}

export default createReducer(initialState, {
  [types.USERS_SET_SEARCH_STRING](state, { searchString }) {
    return { ...state, searchString }
  },
})
