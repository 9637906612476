import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { scheduleFollowupRequest as scheduleFollowup } from '../../actions/ScheduledFollowupActions'
import { sendOrder, updateOrder } from '../../actions/OrderActions'
import { createComment } from '../../actions/CommentActions'
import { fetchActivitiesForTrackable } from '../../actions/ActivityActions'
import { updateCustomer } from '../../actions/CustomerActions'
import FollowUpTemplates from '../../constants/FollowUpTemplates'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import RadioGroup from '../../components/RadioGroup'
import * as OrderSelectors from '../../selectors/OrderSelectors'
import { Margin } from '../../components/Grid'
import DatePicker from '../../components/DatePicker'
import TextArea from '../../components/TextArea'
import TimePicker from '../../components/TimePicker'
import Button from '../../components/Button'
import CheckboxItem from '../../components/CheckboxItem'
import Select from '../../components/Select'
import { formatArray, formatTemplate } from '../../utils/formatUtils'
import { isValidEmail } from '../../utils/validationUtils'
import { UnconnectedCustomerEmailInputContainer } from '../CustomerEmailInputContainer'
import PreviewPanel from '../../components/PreviewPanel'
import Alert from '../../components/Alert'
const followUpOptions = [
  { label: 'E-mail', value: 'email' },
  { label: 'Telefon', value: 'phone' },
  { label: 'Kommentar', value: 'comment' },
  { label: 'Planlæg opfølgning', value: 'scheduled' },
]
import { userRoleLabels } from '../../constants/UserRoles'
import { getCurrentUser } from '../../selectors/AuthSelectors'
const buttonLabels = {
  email: ['Send', 'Sender...'],
  phone: ['Gem', 'Gemmer...'],
  comment: ['Gem', 'Gemmer...'],
  scheduled: ['Send', 'Sender...'],
}

const oneDayInMs = 24 * 3600 * 1000
const emailBodyVariableRegExp = /\[\[(.*?)\]\]/gi

function makeDateForDaysInFuture(daysInFuture, nowDate) {
  const date = new Date(nowDate.getTime() + daysInFuture * oneDayInMs)
  normalizeDateToNextHour(date)
  return date
}

function normalizeDateToNextHour(date, dateForTime) {
  dateForTime = dateForTime || date
  date.setHours(dateForTime.getHours() + 1)
  date.setMinutes(0)
  date.setSeconds(0)
  return date
}

class OrderFollowUpDialog extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    sendOrder: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    updateCustomer: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.nowDate = new Date()
    this.minDate = makeDateForDaysInFuture(1, this.nowDate)
    this.state = {
      error: null,
      followUpType: 'email',
      emailInputs: { attach_pdf: 1 },
      phoneInputs: {},
      commentInputs: {},
      scheduledInputs: {},
      submitting: false,
      isFollowUpTimeSet: false,
      followUpAt: makeDateForDaysInFuture(5, this.nowDate),
    }
    this.handleFollowUpTypeChange = this.handleFollowUpTypeChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleFollowUpDateChange = this.handleFollowUpDateChange.bind(this)
    this.handleFollowUpTimeChange = this.handleFollowUpTimeChange.bind(this)
    this.handleEmailTemplateChange = this.handleEmailTemplateChange.bind(this)
  }

  componentWillMount() {
    const { email } = this.props.order.customer
    this.setState({
      emailInputs: { ...this.state.emailInputs, email: email && email.trim() },
    })
  }

  componentWillReceiveProps(props) {
    const { email } = props.order.customer
    this.setState({
      emailInputs: { ...this.state.emailInputs, email: email && email.trim() },
    })
  }

  handleFollowUpTypeChange(followUpType) {
    this.setState({ followUpType })
  }

  handleInputChange({ target: { value, name, checked } }) {
    switch (name) {
      case 'emailInputs[email]':
        this.setState({
          emailInputs: { ...this.state.emailInputs, email: value },
        })
        break

      case 'emailInputs[body]':
        this.setState({
          emailInputs: { ...this.state.emailInputs, body: value },
        })
        break

      case 'emailInputs[attach_pdf]':
        this.setState({
          emailInputs: {
            ...this.state.emailInputs,
            attach_pdf: (checked && 1) || 0,
          },
        })
        break

      case 'phoneInputs[content]':
        this.setState({
          phoneInputs: { ...this.state.phoneInputs, content: value },
        })
        break

      case 'commentInputs[content]':
        this.setState({
          commentInputs: { ...this.state.commentInputs, content: value },
        })
        break

      case 'scheduledInputs[content]':
        this.setState({
          scheduledInputs: { ...this.state.scheduledInputs, content: value },
        })
        break

      // no default
    }
  }

  checkEmailBodyForEmptyVariables() {
    const { emailInputs } = this.state
    const content = emailInputs.body || ''
    const missingVariables = content.match(emailBodyVariableRegExp)

    if (missingVariables && missingVariables.length) {
      const names = formatArray(
        missingVariables
          .map((varName) => varName.slice(2, -2))
          .map((name) => `"${name}"`)
      )
      const error = `Du mangler at udfylde ${names} i e-mail-feltet`

      this.setState({ error })

      return error
    }

    return null
  }

  handleButtonClick(event) {
    event.preventDefault()

    const {
      followUpType,
      followUpAt,
      emailInputs,
      commentInputs,
      phoneInputs,
      scheduledInputs,
    } = this.state
    const { order, onClose, currentUser } = this.props

    // Validate email body if needed
    if (followUpType === 'email') {
      const error = this.checkEmailBodyForEmptyVariables()

      if (error) {
        return
      }
    }

    this.setState({ submitting: true })

    // Update followupAt for order
    let promise = this.props.updateOrder(order.id, { followUpAt })

    // Send mail or add comment
    if (followUpType === 'email') {
      promise = promise.then(() =>
        this.props.sendOrder({
          ...emailInputs,
          order_id: order.id,
          from_email: currentUser.email,
          cc: currentUser.email,
          type: 'emailFollowUp',
        })
      )
    } else if (followUpType === 'phone') {
      promise = promise.then(() =>
        this.props.createComment({
          ...phoneInputs,
          commentableId: order.id,
          commentableType: 'orders',
          meta: { type: 'followUpPhone' },
        })
      )
    } else if (followUpType === 'comment') {
      promise = promise.then(() =>
        this.props.createComment({
          ...commentInputs,
          commentableId: order.id,
          commentableType: 'orders',
          meta: { type: 'followUp' },
        })
      )
    } else if (followUpType === 'scheduled') {
      promise = promise.then(() =>
        this.props.scheduleFollowup({
          comment: scheduledInputs.content,
          followUpAt,
          orderId: order.id,
        })
      )
    }

    // Close and reload order activities
    promise
      .then(() =>
        Promise.all([
          this.props.fetchActivitiesForTrackable({
            trackableTypes: ['orders'],
            trackableId: order.id,
          }),
          onClose(),
        ])
      )
      .catch(() => this.setState({ submitting: false }))
  }

  handleFollowUpDateChange(dateString, followUpAt) {
    const { isFollowUpTimeSet } = this.state

    if (!isFollowUpTimeSet) {
      normalizeDateToNextHour(followUpAt, this.nowDate)
    }

    this.setState({ followUpAt })
  }

  handleFollowUpTimeChange(followUpAt) {
    this.setState({ isFollowUpTimeSet: true, followUpAt })
  }

  handleEmailTemplateChange(event) {
    const value = event.target.value
    const selected = FollowUpTemplates[value]

    if (selected && selected.template) {
      const {
        order,
        order: { customer },
      } = this.props
      const body = formatTemplate(selected.template, {
        customerName: customer.name,
        orderId: order.id,
      })

      this.setState({
        emailInputs: { ...this.state.emailInputs, body },
      })
    } else {
      this.setState({
        emailInputs: { ...this.state.emailInputs, body: '' },
      })
    }
  }

  optionsForTemplateSelector() {
    const placeholderOption = { key: '__placeholder', label: 'Vælg skabelon' }
    const options = Object.keys(FollowUpTemplates).map((value) => ({
      value,
      label: FollowUpTemplates[value].label,
    }))

    return [placeholderOption].concat(options)
  }

  renderFollowUpTemplateSelector() {
    return (
      <Margin vertical>
        <Select
          style={{ width: '100%' }}
          onChange={this.handleEmailTemplateChange}
          options={this.optionsForTemplateSelector()}
        />
      </Margin>
    )
  }

  renderEmailForm() {
    const { emailInputs } = this.state
    const {
      order: { user, customer },
    } = this.props

    return (
      <Margin className="OrderFollowUpDialog__EmailForm" all>
        {this.renderFollowUpTemplateSelector()}
        <UnconnectedCustomerEmailInputContainer
          placeholder="Modtagers E-mail-adresse"
          defaultValue={emailInputs.email}
          customerId={customer.id}
          customerEmail={customer.email}
          name="emailInputs[email]"
          type="email"
          onChange={this.handleInputChange}
          updateCustomer={this.props.updateCustomer}
        />

        <Margin vertical>
          <PreviewPanel seamlessBottom>{`Hej ${customer.name}`}</PreviewPanel>
          <TextArea
            placeholder="Besked til kunde"
            value={emailInputs.body}
            name="emailInputs[body]"
            rows={7}
            onChange={this.handleInputChange}
            seamless
          />
          <PreviewPanel seamlessTop>
            Med venlig hilsen
            <br />
            {user.name && (
              <span>
                {user.name}
                <br />
              </span>
            )}
            {user.role && (
              <span>
                {userRoleLabels[user.role]}
                <br />
              </span>
            )}
            {user.phone && (
              <span>
                {user.phone}
                <br />
              </span>
            )}
            {user.email && (
              <span>
                {user.email}
                <br />
              </span>
            )}
            Jydsk Tagteknik A/S
          </PreviewPanel>
        </Margin>

        <CheckboxItem
          name="emailInputs[attach_pdf]"
          label="Vedhæft tilbud og produktbeskrivelser"
          checked={!!emailInputs.attach_pdf}
          onChange={this.handleInputChange}
        />
      </Margin>
    )
  }

  renderCommentForm() {
    const { commentInputs } = this.state
    return (
      <Margin className="OrderFollowUpDialog__CommentForm" all>
        <p>Bemærk</p>
        <TextArea
          defaultValue={commentInputs.content}
          name="commentInputs[content]"
          onChange={this.handleInputChange}
        />
      </Margin>
    )
  }

  renderPhoneForm() {
    const { phoneInputs } = this.state
    return (
      <Margin className="OrderFollowUpDialog__PhoneForm" all>
        <p>Telefonsamtale note</p>
        <TextArea
          defaultValue={phoneInputs.content}
          name="phoneInputs[content]"
          onChange={this.handleInputChange}
        />
      </Margin>
    )
  }

  renderScheduledForm() {
    const { followUpAt, scheduledInputs } = this.state
    return (
      <Margin className="OrderFollowUpDialog__ScheduledForm" all>
        <TextArea
          className="CalendarCustomMessage"
          name="scheduledInputs[content]"
          defaultValue={scheduledInputs.content}
          onChange={this.handleInputChange}
        />
        <DatePicker
          value={followUpAt}
          minDate={this.minDate}
          onChange={this.handleFollowUpDateChange}
        />
        <TimePicker
          date={followUpAt}
          onDateChange={this.handleFollowUpTimeChange}
        />
      </Margin>
    )
  }

  render() {
    const { onClose } = this.props
    const {
      error,
      followUpType,
      submitting,
      followUpAt,
      emailInputs,
      commentInputs,
      scheduledInputs,
      phoneInputs,
    } = this.state

    const buttonLabel = buttonLabels[followUpType][(submitting && 1) || 0]

    let disabled
    if (followUpType === 'email') {
      disabled =
        submitting ||
        !followUpAt ||
        !emailInputs.body ||
        !isValidEmail(emailInputs.email)
    } else if (followUpType === 'phone') {
      disabled = submitting || !followUpAt || !phoneInputs.content
    } else if (followUpType === 'scheduled') {
      disabled = submitting || !followUpAt || !scheduledInputs.content
    } else {
      disabled = submitting || !followUpAt || !commentInputs.content
    }

    return (
      <Dialog style={{ width: '500px' }}>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={submitting}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>Opfølgning</DialogTitle>
        </DialogHeader>
        <Margin all>
          <RadioGroup
            options={followUpOptions}
            value={followUpType}
            onChange={this.handleFollowUpTypeChange}
          />
        </Margin>
        {error ? (
          <Margin all>
            <Alert type="danger">{error}</Alert>
          </Margin>
        ) : null}
        {followUpType === 'email' && this.renderEmailForm()}
        {followUpType === 'phone' && this.renderPhoneForm()}
        {followUpType === 'comment' && this.renderCommentForm()}
        {followUpType === 'scheduled' && this.renderScheduledForm()}

        <Margin all className="text-right">
          {followUpType !== 'scheduled' ? (
            <div
              className="OrderFollowUpDialog__NextFollowUp"
              style={{ marginRight: '12px', display: 'inline-block' }}
            >
              Følg op igen
              <DatePicker
                style={{ marginLeft: '12px' }}
                minDate={this.minDate}
                value={followUpAt}
                onChange={this.handleFollowUpDateChange}
              />
            </div>
          ) : null}
          <Button
            className="OrderFollowUpDialog__Submit"
            disabled={disabled}
            onClick={this.handleButtonClick}
          >
            {buttonLabel}
          </Button>
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  order: OrderSelectors.getOrderWithRelations(state, props),
  currentUser: getCurrentUser(state),
})

export const UnconnectedOrderFollowUpDialog = OrderFollowUpDialog

export default connect(mapStateToProps, {
  updateOrder,
  sendOrder,
  scheduleFollowup,
  createComment,
  fetchActivitiesForTrackable,
  updateCustomer,
})(OrderFollowUpDialog)
