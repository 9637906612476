import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import * as ReportTemplateActions from '../actions/ReportTemplateActions'
import { connect } from 'react-redux'
import Page, { PageHeader, PageContent } from '../components/Page'

class NewReportContainer extends Component {
  static propTypes = {
    children: PropTypes.any,
    params: PropTypes.object.isRequired,
    entities: PropTypes.object.isRequired,
    fetchReportTemplate: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.checkForInvalidatedResources()
  }

  fetchReportTemplateIfNeeded() {
    const {
      params: { templateId },
      entities,
    } = this.props
    const { reportTemplates } = entities

    if (templateId && (!reportTemplates || !reportTemplates[templateId])) {
      this.props.fetchReportTemplate(templateId)
    }
  }

  checkForInvalidatedResources() {
    this.fetchReportTemplateIfNeeded()
  }

  render() {
    const { children } = this.props
    let childComponent
    if (typeof children.type === 'function') {
      childComponent = children.type.WrappedComponent
    } else {
      childComponent = children.type
    }

    const title = childComponent.pageTitle || 'Rapporter > Ny Rapport'

    return (
      <Page>
        <PageHeader title={title} />
        <PageContent>{children}</PageContent>
      </Page>
    )
  }
}

const mapStateToProps = (state) => ({
  entities: state.api.entities,
})

export default connect(mapStateToProps, {
  ...ReportTemplateActions,
})(NewReportContainer)
