export const INITIALIZE_REQUEST = 'INITIALIZE_REQUEST'
export const INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS'
export const INITIALIZE_FAILURE = 'INITIALIZE_FAILURE'
export const HIDE_MENU = 'HIDE_MENU'
export const SHOW_MENU = 'SHOW_MENU'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE'
export const USERS_SET_SEARCH_STRING = 'USERS_SET_SEARCH_STRING'
export const PRODUCTS_FETCH_REQUEST = 'PRODUCTS_FETCH_REQUEST'
export const PRODUCTS_FETCH_SUCCESS = 'PRODUCTS_FETCH_SUCCESS'
export const PRODUCTS_FETCH_FAILURE = 'PRODUCTS_FETCH_FAILURE'
export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAILURE = 'PRODUCT_DELETE_FAILURE'
export const DIALOG_OPEN = 'DIALOG_OPEN'
export const DIALOG_CLOSE = 'DIALOG_CLOSE'
export const API_FETCH_REQUEST = 'API_FETCH_REQUEST'
export const API_FETCH_SUCCESS = 'API_FETCH_SUCCESS'
export const API_FETCH_FAILURE = 'API_FETCH_FAILURE'
export const API_CREATE_REQUEST = 'API_CREATE_REQUEST'
export const API_CREATE_SUCCESS = 'API_CREATE_SUCCESS'
export const API_CREATE_FAILURE = 'API_CREATE_FAILURE'
export const API_DELETE_REQUEST = 'API_DELETE_REQUEST'
export const API_DELETE_SUCCESS = 'API_DELETE_SUCCESS'
export const API_DELETE_FAILURE = 'API_DELETE_FAILURE'
export const API_UPDATE_REQUEST = 'API_UPDATE_REQUEST'
export const API_UPDATE_SUCCESS = 'API_UPDATE_SUCCESS'
export const API_UPDATE_FAILURE = 'API_UPDATE_FAILURE'
export const API_CUSTOM_REQUEST = 'API_CUSTOM_REQUEST'
export const API_CUSTOM_FAILURE = 'API_CUSTOM_FAILURE'
export const API_CUSTOM_SUCCESS = 'API_CUSTOM_SUCCESS'
export const API_INVALIDATE = 'API_INVALIDATE'
export const API_SET_ENTITY_QUERY = 'API_SET_ENTITY_QUERY'
export const SEND_PASSWORD_RESET_REQUEST = 'SEND_PASSWORD_RESET_REQUEST'
export const SEND_PASSWORD_RESET_SUCCESS = 'SEND_PASSWORD_RESET_SUCCESS'
export const SEND_PASSWORD_RESET_FAILURE = 'SEND_PASSWORD_RESET_FAILURE'

// Algolia
export const ALGOLIA_SEARCH_REQUEST = 'ALGOLIA_SEARCH_REQUEST'
export const ALGOLIA_SEARCH_FAILURE = 'ALGOLIA_SEARCH_FAILURE'
export const ALGOLIA_SEARCH_SUCCESS = 'ALGOLIA_SEARCH_SUCCESS'
export const ALGOLIA_SEARCH_MORE_SUCCESS = 'ALGOLIA_SEARCH_MORE_SUCCESS'
export const ALGOLIA_SET_QUERY = 'ALGOLIA_SET_QUERY'
export const ALGOLIA_INSERT_FAKE_RESULT = 'ALGOLIA_INSERT_FAKE_RESULT'
