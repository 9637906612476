import { createSelector } from 'reselect'
import {
  createIsFetchingSelector,
  createEntitiesSelector,
} from './ApiSelectors'

const getBuildingPropertyId = (state, props) => props.params.buildingPropertyId

export const isFetching = createIsFetchingSelector(
  'buildingPropertyResultValues'
)
export const getBuildingPropertyResultValues = createEntitiesSelector(
  'buildingPropertyResultValues'
)

export const getBuildingPropertiesForBuilding = createSelector(
  getBuildingPropertyResultValues,
  getBuildingPropertyId,
  (bprvs, bpId) => bprvs.filter((bprv) => bprv.building_property === bpId)
)
