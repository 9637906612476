import { createSelector } from 'reselect'
import { createEntitiesSelector, createEntitySelector } from './ApiSelectors'

export const getWizardBuildingSteps = createEntitiesSelector(
  'wizardBuildingSteps'
)
export const getSteps = createEntitiesSelector('steps')

export const getWizardBuildingStep = createEntitySelector('wizardBuildingSteps')

export const getWizardBuildingStepsWithRelationsForWizardBuilding =
  createSelector(
    (state, props) => props.params.wizardBuildingId,
    getWizardBuildingSteps,
    createEntitiesSelector('lineItems'),
    (state) => state.api.entities.steps,
    (wizardBuildingId, wizardBuildingSteps, lineItems, stepEntities) =>
      wizardBuildingSteps
        .filter((s) => s.wizardBuilding === wizardBuildingId)
        .map((wizardBuildingStep) => ({
          ...wizardBuildingStep,
          lineItems: lineItems.filter(
            (li) => li.wizardBuildingStep === wizardBuildingStep.id
          ),
          step: stepEntities[wizardBuildingStep.step],
        }))
  )

export const getOptionalOrderedWizardBuildingStepsForWizardBuilding =
  createSelector(
    (state, props) => props.params.wizardBuildingId,
    getWizardBuildingSteps,
    createEntitiesSelector('lineItems'),
    (state) => state.api.entities.steps,
    (wizardBuildingId, wizardBuildingSteps, lineItems, stepEntities) =>
      wizardBuildingSteps
        .filter((wbs) => wbs.wizardBuilding === wizardBuildingId)
        .map((wbs) => ({
          ...wbs,
          step: stepEntities[wbs.step],
          lineItems: lineItems.filter((li) => li.wizardBuildingStep === wbs.id),
        }))
        .filter((wbs) => wbs.step)
        .filter((wbs) => !wbs.step.mandatory)
        .sort((a, b) => a.step.position - b.step.position)
  )
