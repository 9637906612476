import * as types from '../constants/ActionTypes'
import { createReducer } from '../utils/reducerUtils'

const initialState = {
  userId: null,
  token: null,
  isAuthenticating: false,
  error: null,
}

const login = (state, payload) => {
  const { results, entities } = payload
  const user = entities.users[results.users[0]]
  const { id, role, authToken } = user

  if (!role) {
    return { ...state }
  } // Force relogin for old session's without role

  return {
    ...state,
    userId: id,
    isAuthenticating: false,
    token: authToken,
  }
}

export default createReducer(initialState, {
  [types.INITIALIZE_FAILURE]() {
    return initialState
  },
  [types.LOGIN_REQUEST](state) {
    return { ...state, isAuthenticating: true }
  },
  [types.LOGIN_SUCCESS](state, { payload }) {
    return login(state, payload)
  },
  [types.LOGIN_FAILURE](state, { error }) {
    return { ...state, isAuthenticating: false, error: error[0].detail }
  },
  [types.LOGOUT_COMPLETE]() {
    return initialState
  },
})
