import { CALL_API } from '../middleware/api'
import apiKey from '../constants/ApiKey'
import querystring from 'querystring'
import { getToken } from '../selectors/AuthSelectors'
import * as types from '../constants/ActionTypes'
import {
  createEntity,
  fetchEntity,
  updateEntity,
  invalidateEntities,
} from './ApiActions'
import {
  formatOrderAcceptanceUrl,
  objectKeysToSnakeCase,
} from '../utils/formatUtils'
import { hashToFormData } from '../utils/formUtils'

export const fetchOrder = (id, options = {}) =>
  fetchEntity({ type: 'orders', id }, options)

export const fetchOrderWithRelations = (id) =>
  fetchOrder(id, {
    query: {
      include: ['user', 'customer', 'discounts', 'wizard_buildings'],
    },
  })

export const invalidateOrders = (options) =>
  invalidateEntities('orders', options)

export const getOrderPDFLink =
  (order, withLogo = false) =>
  (dispatch, getState) => {
    const query = querystring.stringify({
      api_key_token: apiKey,
      user_token: getToken(getState()),
      merge_with_template: withLogo === true ? 1 : 0,
    })

    return `/api/v1/orders/${order.id}.pdf?${query}`
  }

  export const getOrderConditionsPDFLink = () => (dispatch, getState) => {
    const query = querystring.stringify({
      api_key_token: apiKey,
      user_token: getToken(getState()),
    })

    return `/api/v1/pdfs/conditions.pdf?${query}`
  }

export const getOrderGuaranteesPDFLink = (order) => (dispatch, getState) => {
  const query = querystring.stringify({
    api_key_token: apiKey,
    user_token: getToken(getState()),
  })

  return `/api/v1/orders/${order.id}/guarantees.pdf?${query}`
}

export const getOrderConfirmationPDFLink = (order) => (dispatch, getState) => {
  const query = querystring.stringify({
    api_key_token: apiKey,
    user_token: getToken(getState()),
    merge_with_template: 1,
  })

  return `/api/v1/orders/${order.id}/confirmation.pdf?${query}`
}

export const getOrderWorklistPDFLink = (order) => (dispatch, getState) => {
  const query = querystring.stringify({
    api_key_token: apiKey,
    user_token: getToken(getState()),
  })

  return `/api/v1/orders/${order.id}/worklist.pdf?${query}`
}

export const getWorksheetPDFLink = (order) => (dispatch, getState) => {
  const query = querystring.stringify({
    api_key_token: apiKey,
    user_token: getToken(getState()),
  })

  return `/api/v1/orders/${order.id}/worksheet.pdf?${query}`
}

const orderAcceptanceAttributesToFormData = (attributes) => {
  const formData = new FormData()
  const { customerSignature, acceptanceDocument } = attributes

  if (customerSignature) {
    formData.append(
      'order[customer_signature]',
      customerSignature,
      'signature.png'
    )
  }
  if (acceptanceDocument) {
    formData.append('order[acceptance_document]', acceptanceDocument)
  }
  return formData
}

export const createOrder = (attributes) =>
  createEntity(
    { type: 'orders' },
    {
      data: {
        order: objectKeysToSnakeCase(attributes),
      },
    }
  )

export const updateOrder = (id, attributes, options) =>
  updateEntity(
    { id, type: 'orders' },
    {
      ...options,
      data: {
        order: objectKeysToSnakeCase(attributes),
      },
    }
  )

export const disableOrderReminders = (id) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/orders/${id}/disable_reminders`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ]
  }
})

export const updateOrderWorksheet = (id, attrs) =>
  updateEntity(
    { id, type: 'orders' },
    {
      data: {
        order: attrs,
      },
    }
  )

export const acceptOrder = (id, attributes) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/accept`,
    body: orderAcceptanceAttributesToFormData(attributes),
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const rejectOrder = (id, reason) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/reject`,
    body: hashToFormData({ 'order[reject_reason]': reason }),
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const sendOrder = (attrs, options) => ({
  [CALL_API]: {
    method: 'POST',
    query: {
      mail: { ...attrs },
    },
    endpoint: '/order_mails',
    types: [
      types.API_CUSTOM_REQUEST,
      types.API_CUSTOM_SUCCESS,
      types.API_CUSTOM_FAILURE,
    ],
    ...options,
  },
})

export const copyOrder = (id, options) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/orders/${id}/copy`,
    types: [
      types.API_CUSTOM_REQUEST,
      types.API_CUSTOM_SUCCESS,
      types.API_CUSTOM_FAILURE,
    ],
    ...options,
  },
})

export const acceptOrderReview = (id, { replyTo, body, email }) => ({
  [CALL_API]: {
    method: 'PUT',
    contentType: 'application/json',
    endpoint: `/order_acceptances/${id}/review_accept`,
    data: { mail: { reply_to: replyTo, body, email } },
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const rejectOrderReview = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/review_reject`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const sendOrderAcceptanceEmail = (attrs, options) => ({
  [CALL_API]: {
    method: 'POST',
    query: {
      mail: {
        order_id: attrs.id,
        body: attrs.body,
        email: attrs.email,
        acceptance_url: formatOrderAcceptanceUrl(attrs.id, attrs.token),
      },
    },
    endpoint: '/order_mails',
    types: [
      types.API_CUSTOM_REQUEST,
      types.API_CUSTOM_SUCCESS,
      types.API_CUSTOM_FAILURE,
    ],
    ...options,
  },
})

export const sendOrderConfirmationEmail = (attrs, options) => ({
  [CALL_API]: {
    method: 'POST',
    query: {
      order_id: attrs.id,
      mail: {
        body: attrs.body,
        email: attrs.email,
      },
    },
    endpoint: '/order_confirmation_mails',
    types: [
      types.API_CUSTOM_REQUEST,
      types.API_CUSTOM_SUCCESS,
      types.API_CUSTOM_FAILURE,
    ],
    ...options,
  },
})

export const exportOrderToAx = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/export_to_ax`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const archiveOrder = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/archive`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const proposeOrder = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/propose`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const resetOrderAcceptance = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/reset`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const resetOrderReview = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/reset_review`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const resetOrderFromAX = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/reset_ax`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const resetOrderCancel = (id) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/reset_cancel`,
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const mergeOrder = (sourceOrderId, destinationOrderId) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/orders/${destinationOrderId}/merge`,
    data: { source_id: parseInt(sourceOrderId, 10) },
    entity: { id: destinationOrderId, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const cancelOrder = (id, canceledAt) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/order_acceptances/${id}/cancel`,
    body: hashToFormData({ 'order[canceled_at]': canceledAt }),
    entity: { id, type: 'orders' },
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})
