import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as PropertyActions from '../../actions/PropertyActions'
import * as BuildingPropertyActions from '../../actions/BuildingPropertyActions'

import { getBuildingProperty } from '../../selectors/BuildingPropertySelectors'
import { getProperty, isFetching } from '../../selectors/PropertySelectors'

import orderBy from '../../utils/orderBy'

import BuildingPropertyForm from '../../components/BuildingPropertyForm'

class BuildingPropertyFormContainer extends Component {
  static propTypes = {
    propertyId: PropTypes.string.isRequired,
    buildingId: PropTypes.string.isRequired,
    buildingPropertyId: PropTypes.string,

    fetchProperty: PropTypes.func.isRequired,
    fetchBuildingProperty: PropTypes.func.isRequired,

    isLoading: PropTypes.bool.isRequired,
    property: PropTypes.object,
    buildingProperty: PropTypes.object,
  }

  componentDidMount() {
    const {
      buildingPropertyId,
      propertyId,
      fetchProperty,
      fetchBuildingProperty,
    } = this.props
    if (buildingPropertyId) {
      fetchBuildingProperty(buildingPropertyId, {
        query: {
          include: ['building_property_measurements'],
        },
      })
    }
    fetchProperty(propertyId, { query: { include: ['measurements'] } })
  }

  render() {
    const { isLoading, property, buildingId, propertyId, buildingProperty } =
      this.props

    if (isLoading) return null

    const { measurements } = property

    const orderedMeasurements = measurements.sort(orderBy('position'))

    const fields = [
      'buildingId',
      'propertyId',
      'name',
      'quantity',
      ...measurements.map((m) => m.id),
    ]

    const initialValues = {
      buildingId,
      propertyId,
      name: '',
      quantity: 1,
    }

    if (buildingProperty) {
      initialValues.name = buildingProperty.name
      initialValues.quantity = buildingProperty.quantity
      buildingProperty.buildingPropertyMeasurements.forEach(
        (buildingPropertyMeasurement) => {
          if (fields.indexOf(buildingPropertyMeasurement.measurement) === -1)
            return
          initialValues[buildingPropertyMeasurement.measurement] =
            buildingPropertyMeasurement.value
        }
      )
    }

    return (
      <BuildingPropertyForm
        {...this.props}
        fields={fields}
        initialValues={initialValues}
        imageUrl={property.imageDetailedUrl}
        measurements={orderedMeasurements}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const { buildingPropertyId } = props
  const buildingProperty = getBuildingProperty(
    state,
    { params: { buildingPropertyId } },
    ['buildingPropertyMeasurements']
  )

  const buildingId =
    props.buildingId || (buildingProperty && buildingProperty.building)
  const propertyId =
    props.propertyId || (buildingProperty && buildingProperty.property)
  const property = getProperty(state, { params: { propertyId } }, [
    'measurements',
  ])

  const isLoading = !!(
    !property ||
    !property.measurements.length ||
    isFetching(state) ||
    (buildingPropertyId && !buildingProperty)
  )

  return {
    buildingProperty,
    propertyId,
    buildingId,
    property,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  ...PropertyActions,
  ...BuildingPropertyActions,
})(BuildingPropertyFormContainer)
