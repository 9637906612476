const Roles = {
  SALESMAN: 'salesman',
  MANAGER: 'manager',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
}

const resettableOrderStates = [
  'accepted',
  'rejected',
  'reviewed_accepted',
  'reviewed_rejected',
]

const orderManagerUserRoles = [Roles.MANAGER, Roles.ADMIN, Roles.SUPER_ADMIN]

const orderResetStateRoles = orderManagerUserRoles.concat(Roles.SALESMAN)

export const canRoleManageOrder = (role) =>
  role && orderManagerUserRoles.includes(role)

export const canRoleResetOrder = (role = null, order = {}) => {
  // Only super admins can reset order state from `exported_to_ax`
  if (
    role === Roles.SUPER_ADMIN &&
    (order.state === 'exported_to_ax' || order.state === 'cancelled')
  ) {
    return true
  }

  return (
    orderResetStateRoles.includes(role) &&
    resettableOrderStates.includes(order.state)
  )
}

export const canRoleDisableReminders = (role, order) => {
  if (order.state != 'proposed') {
    return false
  }

  return canRoleManageOrder(role) || role === Roles.SALESMAN
}
