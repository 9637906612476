import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import { fetchOrder, rejectOrder } from '../../actions/OrderActions'
import { createComment } from '../../actions/CommentActions'
import { fetchActivitiesForTrackable } from '../../actions/ActivityActions'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import * as OrderSelectors from '../../selectors/OrderSelectors'
import { Margin } from '../../components/Grid'
import TextArea from '../../components/TextArea'
import Select from '../../components/Select'
import Button from '../../components/Button'
import OrderTreeHeader from '../../components/OrderTreeHeader'
import OrderTreeItem from '../../components/OrderTreeItem'

const rejectionReasons = [
  {
    label: 'For dyrt – har valgt anden håndværker',
    value: 'too_expensive_have_choosen_other_provider',
  },
  {
    label: 'For dyrt – investeringen overstiger rådighedsbeløb',
    value: 'too_expensive_exceed_disposable_income',
  },
  {
    label: 'For dyrt – investeringen er ikke rentabel',
    value: 'too_expensive_investment_not_profitable',
  },
  { label: 'Afvist – skriv selv årsag', value: 'other' },
]

class OrderRejectDialog extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    rejectOrder: PropTypes.func.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      reason: rejectionReasons[0].value,
      description: '',
      submitting: false,
    }
    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  handleReasonChange({ target: { value } }) {
    this.setState({ reason: value })
  }

  handleDescriptionChange({ target: { value } }) {
    this.setState({ description: value })
  }

  handleButtonClick() {
    const { reason, description } = this.state
    const { order, onClose } = this.props

    this.setState({ submitting: true })

    // Reject order
    this.props
      .rejectOrder(order.id, reason)
      // Create rejection comment
      .then(() =>
        this.props.createComment({
          content: description,
          commentableId: order.id,
          commentableType: 'orders',
          meta: { type: 'rejection' },
        })
      )
      // Close and reload order activities
      .then(() =>
        Promise.all([
          this.props.fetchActivitiesForTrackable({
            trackableTypes: ['orders'],
            trackableId: order.id,
          }),
          onClose(),
        ])
      )
      .catch(() => this.setState({ submitting: false }))
  }

  flattenFamily(treeOrder) {
    const r = [treeOrder]
    treeOrder.children.forEach((child) =>
      Array.prototype.push.apply(r, this.flattenFamily(child))
    )
    return r
  }

  render() {
    const { onClose, order } = this.props
    const { rejectionReason, submitting, description } = this.state
    const archiveList = this.flattenFamily(order.familyTree).filter(
      (o) => o.id !== order.id
    )

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={submitting}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>Afvis tilbud</DialogTitle>
        </DialogHeader>
        <Margin all>
          <p>Overordnet årsag</p>
          <Select
            options={rejectionReasons}
            value={rejectionReason}
            onChange={this.handleReasonChange}
          />
        </Margin>
        <Margin all>
          <p>Note</p>
          <TextArea
            name="description"
            onChange={this.handleDescriptionChange}
          />
        </Margin>
        <Margin all>
          {archiveList.length > 0 && (
            <div>
              <p>Følgende relaterede ordrer vil blive arkiveret:</p>
              <OrderTreeHeader />
              {archiveList.map((orderTreeOrder) => (
                <OrderTreeItem
                  key={orderTreeOrder.id}
                  orderTreeOrder={orderTreeOrder}
                />
              ))}
            </div>
          )}
        </Margin>
        <Margin all className="text-right">
          <Button
            disabled={submitting || !description}
            onClick={this.handleButtonClick}
          >
            {(submitting && 'Afviser...') || 'Afvis tilbud'}
          </Button>
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  order: OrderSelectors.getOrderWithRelations(state, props),
})

export default connect(mapStateToProps, {
  fetchOrder,
  rejectOrder,
  createComment,
  fetchActivitiesForTrackable,
})(OrderRejectDialog)
