import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import omit from 'lodash/omit'
import classNames from 'classnames'

const Label = (props) => {
  const classes = classNames('label', props.className, `label--${props.type}`)

  return <div className={classes} {...omit(props, 'className')} />
}

Label.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'primary', 'success', 'danger', 'faded']),
}

Label.defaultProps = {
  type: 'default',
}

export default Label
