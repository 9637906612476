import React from 'react'
import PropTypes from 'react-proptypes'
import WizardBuildingLineItemRow from '../WizardBuildingLineItemRow'
import orderBy from '../../utils/orderBy'

const WizardBuildingStepTableBody = (props) => {
  const { step, lineItems, allowLineItemEditing, noStepTitle } = props

  if (lineItems.length === 0) {
    return null
  }

  const orderedLineItems = lineItems.slice()
  orderedLineItems.sort(orderBy('addition'))

  let stepTitle
  if (noStepTitle) {
    stepTitle = null
  } else {
    stepTitle = !step ? 'Slettet trin' : step.name
  }

  return (
    <tbody key={props.id}>
      {orderedLineItems.map((lineItem, index) => (
        <WizardBuildingLineItemRow
          key={lineItem.id}
          lineItem={lineItem}
          stepTitle={index === 0 ? stepTitle : null}
          totalItems={lineItems.length}
          allowEditing={allowLineItemEditing}
        />
      ))}
    </tbody>
  )
}

WizardBuildingStepTableBody.propTypes = {
  id: PropTypes.string.isRequired,
  step: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  allowLineItemEditing: PropTypes.bool,
  noStepTitle: PropTypes.bool,
}

export default WizardBuildingStepTableBody
