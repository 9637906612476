import React from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'

export const BuildingProperty = ({ name, children = null }) => (
  <div className="building-property">
    <h4>{name}</h4>
    <div className="building-property__children">{children}</div>
  </div>
)

BuildingProperty.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default BuildingProperty
