import { createSelector } from 'reselect'
import {
  createIsFetchingSelector,
  createEntitiesSelector,
} from './ApiSelectors'

const getBuildingId = (state, props) => props.params.buildingId

export const isFetching = createIsFetchingSelector('buildingImages')
export const getBuildingImages = createEntitiesSelector('buildingImages')

export const getBuildingImagesForBuilding = createSelector(
  getBuildingImages,
  getBuildingId,
  (buildingImages, buildingId) =>
    buildingImages.filter((bp) => bp.building === buildingId)
)

export const getBuildingImagesForBuildingAndImageTag = createSelector(
  getBuildingImagesForBuilding,
  (_state, props) => props.params.imageTagId,
  (buildingImages, imageTagId) =>
    buildingImages.filter((bi) => bi.imageTag === imageTagId)
)
