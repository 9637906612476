import {
  fetchEntities,
  createEntity,
  deleteEntity,
  fetchEntity,
  updateEntity,
} from './ApiActions'
import { hashToFormData } from '../utils/formUtils'
import { omit } from 'lodash'
import {
  createBuildingPropertyMeasurement,
  updateBuildingPropertyMeasurement,
} from './BuildingPropertyMeasurementActions'

import { getBuildingProperty } from '../selectors/BuildingPropertySelectors'

const attributesToFormData = ({ buildingId, propertyId, name, quantity }) =>
  hashToFormData({
    'building_property[building_id]': buildingId,
    'building_property[property_id]': propertyId,
    'building_property[name]': name,
    'building_property[quantity]': quantity,
  })

export const fetchBuildingProperties = (options) =>
  fetchEntities('buildingProperties', options)

export const fetchAvailableBuildingProperties = (
  wizardBuildingStepId,
  stepProductId
) =>
  fetchEntities('buildingProperties', {
    query: {
      wizard_building_step_id: wizardBuildingStepId,
      step_product_id: stepProductId,
      include: ['property'],
    },
    endpoint: '/building_properties/available',
  })

export const fetchBuildingProperty = (id, options) =>
  fetchEntity({ type: 'buildingProperties', id }, options)

const upsertMeasurements =
  (buildingPropertyId, attributes) => (dispatch, getState) => {
    const measurementAttributes = omit(attributes, [
      'buildingId',
      'propertyId',
      'name',
      'quantity',
    ])
    const { buildingPropertyMeasurements } = getBuildingProperty(
      getState(),
      { params: { buildingPropertyId } },
      ['buildingPropertyMeasurements']
    )

    const promises = Object.entries(measurementAttributes).map(
      ([measurementId, value]) => {
        const existing = buildingPropertyMeasurements.find(
          (bpm) =>
            bpm.buildingProperty === buildingPropertyId &&
            bpm.measurement === measurementId
        )

        const updatedAttributes = { buildingPropertyId, measurementId, value }

        // Create new
        if (!existing) {
          return dispatch(createBuildingPropertyMeasurement(updatedAttributes))
        }

        // Update existing, if necessary
        if (existing.value.toString() === value) return Promise.resolve()
        return dispatch(
          updateBuildingPropertyMeasurement(existing.id, updatedAttributes)
        )
      }
    )

    return Promise.all(promises)
  }

export const createBuildingProperty = (attributes) => (dispatch) => {
  let buildingPropertyResponse
  const body = attributesToFormData(attributes)
  return dispatch(createEntity({ type: 'buildingProperties' }, { body }))
    .then((response) => {
      buildingPropertyResponse = response
      const buildingPropertyId = response.results.buildingProperties[0]
      return dispatch(upsertMeasurements(buildingPropertyId, attributes))
    })
    .then(() => buildingPropertyResponse)
}

export const updateBuildingProperty = (id, attributes) => (dispatch) => {
  let buildingPropertyResponse
  const body = attributesToFormData(attributes)
  return dispatch(updateEntity({ id, type: 'buildingProperties' }, { body }))
    .then((response) => {
      buildingPropertyResponse = response
      const buildingPropertyId = response.results.buildingProperties[0]
      return dispatch(upsertMeasurements(buildingPropertyId, attributes))
    })
    .then(() => buildingPropertyResponse)
}

export const deleteBuildingProperty = (id) =>
  deleteEntity({ type: 'buildingProperties', id })
