import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Input from '../Input'
import Icon from '../Icon'

class SearchInput extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      query: props.defaultValue || '',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.query)
    }
  }

  handleInputChange({ target }) {
    this.setState({ query: target.value })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="search-input">
        <Input
          {...this.prop}
          onChange={this.handleInputChange}
          defaultValue={this.state.query}
        />
        <div className="search-input__btn" onClick={this.handleSubmit}>
          <Icon icon="search" size={22} />
        </div>
      </form>
    )
  }
}

export default SearchInput
