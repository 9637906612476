import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import classNames from 'classnames'
import './style.styl'

import { attachResponseRelations } from '../../utils/attachRelations'
import { formatUnitType } from '../../utils/formatUtils'

import * as LineItemActions from '../../actions/LineItemActions'
import * as QuantityActions from '../../actions/QuantityActions'
import * as BuildingPropertyActions from '../../actions/BuildingPropertyActions'

import { getFetchCountForEntityTypes } from '../../selectors/ApiSelectors'

import { Margin } from '../Grid'
import Button from '../Button'
import Checkbox from '../Forms/Checkbox'
import Spinner from '../Spinner'

class LineItemCreateFormWithResult extends Component {
  static propTypes = {
    stepProductId: PropTypes.string.isRequired,
    productVariantId: PropTypes.string.isRequired,
    wizardBuildingStepId: PropTypes.string.isRequired,

    fetchAvailableBuildingProperties: PropTypes.func.isRequired,
    fetchQuantities: PropTypes.func.isRequired,
    fetchLineItemPrice: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,

    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const { stepProductId, productVariantId, wizardBuildingStepId } = props

    this.state = {
      buildingProperties: [],
      selectedBuildingPropertyIds: [],
      formData: {
        stepProductId,
        productVariantId,
        wizardBuildingStepId,
        addition: false,
        quantity: 1,
      },
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let buildingProperties

    const {
      fetchAvailableBuildingProperties,
      wizardBuildingStepId,
      stepProductId,
    } = this.props

    fetchAvailableBuildingProperties(wizardBuildingStepId, stepProductId)
      .then((response) => {
        const attacher = attachResponseRelations(response, ['property'])
        buildingProperties = Object.values(
          response.entities.buildingProperties || {}
        ).map(attacher)
      })
      .then(() => Promise.all(buildingProperties.map(this.fetchQuantity)))
      .then((quantities) => {
        buildingProperties = buildingProperties.map(
          (buildingProperty, index) => ({
            ...buildingProperty,
            quantityResult: quantities[index],
          })
        )
      })
      .then(() => Promise.all(buildingProperties.map(this.fetchPrice)))
      .then((prices) => {
        buildingProperties = buildingProperties.map(
          (buildingProperty, index) => ({
            ...buildingProperty,
            priceResult: prices[index],
          })
        )
      })
      .then(() => this.setState({ buildingProperties }))
  }

  fetchQuantity = (buildingProperty) => {
    const { fetchQuantities, stepProductId } = this.props
    return fetchQuantities(buildingProperty.id, stepProductId)
      .then(({ rawData: { attributes } }) => ({
        quantity: attributes.quantity,
        productUnitsPerQuantity: attributes['product-units-per-quantity'],
        productUnitsPerQuantityUnitType: attributes['product-unit-type'],
      }))
      .catch(() => null)
  }

  fetchPrice = (buildingProperty) => {
    const { fetchLineItemPrice } = this.props
    const { quantityResult } = buildingProperty
    if (!quantityResult) return null

    const attributes = {
      ...this.state.formData,
      quantity: quantityResult.quantity,
      productUnitsPerQuantity: quantityResult.productUnitsPerQuantity,
    }
    return fetchLineItemPrice(attributes)
      .then(
        ({ rawData }) =>
          rawData.attributes['humanized-total-price-incl-vat-with-symbol']
      )
      .catch(() => null)
  }

  isValid() {
    const { selectedBuildingPropertyIds } = this.state
    return selectedBuildingPropertyIds.length !== 0
  }

  handleBuildingPropertySelect = (id) => {
    const { selectedBuildingPropertyIds } = this.state

    const nextIds = selectedBuildingPropertyIds.slice()
    const index = nextIds.indexOf(id)

    if (index === -1) nextIds.push(id)
    else nextIds.splice(index, 1)

    this.setState({ selectedBuildingPropertyIds: nextIds })
  }

  handleInput = ({ target: { name, checked } }) => {
    const { formData } = this.state
    this.setState({ formData: { ...formData, [name]: checked } })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { onSubmit } = this.props
    const { buildingProperties, selectedBuildingPropertyIds } = this.state

    const attributesArray = buildingProperties
      .filter(({ id }) => selectedBuildingPropertyIds.indexOf(id) !== -1)
      .map((buildingProperty) => ({
        ...this.state.formData,
        buildingPropertyId: buildingProperty.id,
        quantity: buildingProperty.quantityResult.quantity,
        productUnitsPerQuantity:
          buildingProperty.quantityResult.productUnitsPerQuantity,
      }))

    onSubmit(attributesArray)
  }

  renderBuildingProperty = ({
    id,
    name,
    property,
    priceResult,
    quantityResult,
  }) => {
    const { selectedBuildingPropertyIds } = this.state
    const isSelected = selectedBuildingPropertyIds.indexOf(id) !== -1
    const disabled = !quantityResult
    const onClick = disabled
      ? undefined
      : () => this.handleBuildingPropertySelect(id)

    const className = classNames(
      'LineItemCreateFormWithResult__building-property',
      {
        'LineItemCreateFormWithResult__building-property--disabled': disabled,
      }
    )

    return (
      <tr key={id} className={className} onClick={onClick}>
        <td>
          <Checkbox disabled={disabled} checked={isSelected} />
        </td>
        <td>
          <p>{name}</p>
          <p className="text-small text-muted">{property.name}</p>
          <p className="text-small text-muted">
            {quantityResult &&
              [
                quantityResult.productUnitsPerQuantity,
                formatUnitType(quantityResult.productUnitsPerQuantityUnitType),
              ].join(' ')}
          </p>
        </td>
        <td className="text-center">
          {quantityResult && quantityResult.quantity}
        </td>
        <td className="text-right">
          {priceResult || (
            <span className="text-small">Kunne ikke udregnes</span>
          )}
        </td>
      </tr>
    )
  }

  render() {
    const { isLoading } = this.props
    const {
      buildingProperties,
      formData: { addition },
    } = this.state
    const canSave = this.isValid() && !isLoading

    return (
      <div className="LineItemCreateFormWithResult">
        <Margin horizontal size={1}>
          <form onSubmit={this.handleSubmit}>
            {isLoading ? (
              <Margin all>
                <Spinner centerHorizontal />
              </Margin>
            ) : (
              <table>
                <tbody className="LineItemCreateFormWithResult__table">
                  <tr>
                    <th></th>
                    <th>Navn</th>
                    <th className="text-center">Antal</th>
                    <th className="text-right">Pris</th>
                  </tr>
                  {buildingProperties.map(this.renderBuildingProperty)}
                </tbody>
              </table>
            )}
            <Margin top size={1}>
              <label>
                <Checkbox
                  name="addition"
                  checked={addition}
                  onChange={this.handleInput}
                />
                <span>Tilføj som tillægsproduct</span>
              </label>
            </Margin>
            <Margin vertical size={1}>
              <Button type="submit" block active disabled={!canSave}>
                Tilføj til tilbud
              </Button>
            </Margin>
          </form>
        </Margin>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading:
    getFetchCountForEntityTypes(state, [
      'lineItems',
      'quantities',
      'buildingProperties',
    ]) !== 0,
})

export default connect(mapStateToProps, {
  ...BuildingPropertyActions,
  ...LineItemActions,
  ...QuantityActions,
})(LineItemCreateFormWithResult)
