import React from 'react'
import PropTypes from 'react-proptypes'
import { Link } from 'react-router'
import { ListItem } from '../List'
import Icon from '../Icon'
import classNames from 'classnames'
import './style.styl'

export default class ChecklistNavigationListItem extends React.Component {
  handleClick = (event) => {
    const { onClick, id } = this.props

    if (id) onClick(id)
    else onClick(event)
  }

  renderItem() {
    const {
      active,
      title,
      description,
      icon,
      iconColor = 'black',
      onClick,
    } = this.props

    const className = classNames('checklist-navigation-item', {
      'checklist-navigation-item--active': active,
    })
    return (
      <ListItem
        className={className}
        title={title}
        description={description}
        onClick={onClick && this.handleClick}
      >
        {icon && <Icon icon={icon} style={{ fill: iconColor }} />}
      </ListItem>
    )
  }

  render() {
    const { onClick, linkTo } = this.props

    if (onClick && linkTo)
      throw new Error('onClick and linkTo are mutually exclusive')

    if (!linkTo) return this.renderItem()

    return (
      <Link
        to={linkTo}
        className="checklist-navigation-item__link"
        activeClassName="checklist-navigation-item__link--active"
      >
        {this.renderItem()}
      </Link>
    )
  }
}

ChecklistNavigationListItem.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
}
