import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import * as ReferenceActions from '../actions/ReferenceActions'
import { getFetchCountForEntityTypes } from '../selectors/ApiSelectors'
import { fetchReferenceProducts } from '../actions/ReferenceProductActions'
import { getReferenceProducts } from '../selectors/ReferenceProductsSelectors'
import { setEntityQuery } from '../actions/ApiActions'
import { getReferences } from '../selectors/ReferencesSelectors'
import Page, { PageHeader, PageActions } from '../components/Page'
import Button, { IconButton } from '../components/Button'
import ReferenceFilters from '../components/ReferenceFilters'
import { formatDate } from '../utils/formatUtils'
import Spinner from '../components/Spinner'
import { FlexContainer, Flex, Margin } from '../components/Grid'

class ReferenceListContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    fetchReferenceProducts: PropTypes.func.isRequired,
    removeReference: PropTypes.func.isRequired,
    fetchReferences: PropTypes.func.isRequired,
    fetchReferencesIfNeeded: PropTypes.func.isRequired,
    getReferenceListPDFLink: PropTypes.func.isRequired,
    invalidateReferences: PropTypes.func.isRequired,
    references: PropTypes.array.isRequired,
    setEntityQuery: PropTypes.func.isRequired,
    query: PropTypes.object,
    referenceProducts: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.performScheduledReload = this.performScheduledReload.bind(this)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
    this.openReferenceListPDF = this.openReferenceListPDF.bind(this)
  }

  componentDidMount() {
    this.props.fetchReferenceProducts()
  }

  fetchReferences(options) {
    const { fetchReferences } = this.props
    return fetchReferences(options)
  }

  scheduleReload() {
    this.unscheduleReload()
    this.reloadTimer = setTimeout(this.performScheduledReload, 250)
  }

  unscheduleReload() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer)
      this.reloadTimer = null
    }
  }

  performScheduledReload() {
    const { invalidateReferences, query } = this.props

    invalidateReferences()
    this.fetchReferences({ query })
  }

  handleQueryChange(query) {
    this.props.setEntityQuery('references', query)
    this.scheduleReload()
  }

  openReferenceListPDF() {
    const { getReferenceListPDFLink, query } = this.props
    window.open(getReferenceListPDFLink(query))
  }

  handleRemoveClick(reference) {
    const address = `${reference.addressStreetName} ${reference.addressStreetNumber}`
    const city = `${reference.addressZipCode} ${reference.addressCityName}`
    const message = `Er du sikker på at du vil slette ${address} ${city}?`

    if (window.confirm(message)) {
      this.props.removeReference(reference.id, reference.objectType)
    }
  }

  renderContent() {
    const { isFetching, references } = this.props

    if (isFetching) {
      return <Spinner center />
    }

    return (
      <FlexContainer horizontal>
        <Flex size={8} scrollY>
          <Margin all>
            <table>
              <tbody>
                {references.map((reference) => (
                  <tr key={reference.id}>
                    <td>
                      {reference.addressStreetName}{' '}
                      {reference.addressStreetNumber}
                    </td>
                    <td>
                      {reference.addressZipCode} {reference.addressCityName}
                    </td>
                    <td>{formatDate(reference.acceptedAt, 'Y')}</td>
                    <td>
                      <IconButton
                        icon="cross"
                        onClick={() => this.handleRemoveClick(reference)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Margin>
        </Flex>
        <Flex size={4}>
          <Margin all>
            <Button block onClick={this.openReferenceListPDF}>
              Åben PDF
            </Button>
          </Margin>
        </Flex>
      </FlexContainer>
    )
  }

  render() {
    const { references, query, referenceProducts } = this.props

    return (
      <Page>
        <PageHeader title="Referenceliste">
          <PageActions>
            <ReferenceFilters
              query={query}
              onChange={this.handleQueryChange}
              referenceProducts={referenceProducts}
            />
          </PageActions>
        </PageHeader>
        {references.length > 0 ? this.renderContent() : null}
      </Page>
    )
  }
}

const fetchedEntityTypes = ['reference_products', 'references']
const mapStateToProps = (state) => ({
  isFetching: getFetchCountForEntityTypes(state, fetchedEntityTypes) !== 0,
  references: getReferences(state),
  referenceProducts: getReferenceProducts(state),
  query: state.api.entityQueries.references || {},
})

export default connect(mapStateToProps, {
  ...ReferenceActions,
  setEntityQuery,
  fetchReferenceProducts,
})(ReferenceListContainer)
