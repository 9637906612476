import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import pick from 'lodash/pick'

const Input = (props) => {
  const className = classNames(
    'form-input',
    props.className,
    { 'form-input--error': props.touched && props.error },
    { 'form-input--seamless-top': props.seamless || props.seamlessTop },
    { 'form-input--seamless-bottom': props.seamless || props.seamlessBottom }
  )

  const inputProps = pick(props, [
    'type',
    'name',
    'value',
    'defaultValue',
    'onChange',
    'onInput',
    'style',
    'placeholder',
    'disabled',
    'step',
  ])

  return <input {...inputProps} className={className} />
}

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.any,
  seamlessTop: PropTypes.bool,
  seamlessBottom: PropTypes.bool,
  seamless: PropTypes.bool,
  touched: PropTypes.bool,
}

export default Input
