import * as types from '../constants/ActionTypes'
import {
  createEntity,
  fetchEntities,
  fetchEntity,
  updateEntity,
} from './ApiActions'
import { objectKeysToSnakeCase } from '../utils/formatUtils'
import { CALL_API } from '../middleware/api'

const attributesToFormData = (attributes) => {
  attributes = objectKeysToSnakeCase(attributes)
  const formData = new FormData()

  if (attributes.department) {
    attributes.department_id = attributes.department
  }

  for (const [name, value] of Object.entries(attributes)) {
    if (name === 'signature') {
      formData.append(`user[${name}]`, value, 'signature.png')
    } else {
      formData.append(`user[${name}]`, value)
    }
  }

  return formData
}

export const fetchUsers = (options) => fetchEntities('users', options)

export const createUser = (attributes) =>
  createEntity({ type: 'users' }, { body: attributesToFormData(attributes) })

export const updateUser = (id, attributes) =>
  updateEntity(
    { id, type: 'users' },
    {
      body: attributesToFormData(attributes),
    }
  )

export const updateUserPassword = (id, attributes) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/password_updates/${id}`,
    contentType: 'application/json',
    data: {
      user: {
        password: attributes.password,
        password_confirmation: attributes.password_confirmation,
      },
      current_password: attributes.currentPassword,
    },
    types: [
      types.API_CUSTOM_REQUEST,
      types.API_CUSTOM_SUCCESS,
      types.API_CUSTOM_FAILURE,
    ],
  },
})

export const fetchCurrentUser = () => fetchEntity({ type: 'users', id: 'me' })
