import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { fetchCustomer } from '../actions/CustomerActions'
import { getCurrentUser } from '../selectors/AuthSelectors'
import * as Buildings from '../selectors/BuildingSelectors'
import * as Reports from '../selectors/ReportSelectors'
import { fetchBuilding, updateBuilding } from '../actions/BuildingActions'
import { fetchReport, updateReport } from '../actions/ReportActions'
import Spinner from '../components/Spinner'
import Button from '../components/Button'
import { Margin, VerticalAlign, FlexContainer, Flex } from '../components/Grid'
import EnergyMarkInput, { EnergyMarkChar } from '../components/EnergyMarkInput'

class NewReportEnergyMarkContainer extends Component {
  static pageTitle = 'Energimærkning'

  constructor(props) {
    super(props)
    this.customer = false
    this.secret = 'g9i398P6i6tW'
  }

  static propTypes = {
    fetchCustomer: PropTypes.func.isRequired,
    fetchBuilding: PropTypes.func.isRequired,
    updateBuilding: PropTypes.func.isRequired,
    fetchReport: PropTypes.func.isRequired,
    updateReport: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    buildingId: PropTypes.string.isRequired,
    reportId: PropTypes.string.isRequired,
    building: PropTypes.object,
    report: PropTypes.object,
  }

  handleEnergyMarkChange = (energyMark) => {
    const { updateReport, report, reportId } = this.props
    if (report.energyMark !== energyMark) {
      updateReport(reportId, { energyMark })
    }
  }

  handleEnergyAfterImprovementsChange = (energyMark) => {
    const { updateReport, report, reportId } = this.props
    if (report.energyMarkAfterOptimalImprovements !== energyMark) {
      updateReport(reportId, { energyMarkAfterOptimalImprovements: energyMark })
    }
  }

  handleGenerateRapportClick = () => {
    const { reportId, currentUser } = this.props
    let apiUrl = `https://jydsktagteknik.dk/trustpilot-api/v1/rateAuto/${this.secret}/${reportId}/report/${currentUser.id}/${currentUser.role}/${currentUser.name}/${this.customer.name}/${this.customer.email}/${this.customer.phone}`
    console.log(apiUrl)
    fetch(apiUrl)
    this.props.push(`/reports/${reportId}`)
  }

  componentDidMount() {
    const { reportId, buildingId, customerId } = this.props
    let thisWrapper = this
    this.props.fetchBuilding(buildingId).then(function (response) {
      let customerId = response.rawData.relationships.customer.data.id
      thisWrapper.props.fetchCustomer(customerId).then(function (response) {
        thisWrapper.customer = response.rawData.attributes
      })
    })
    this.props.fetchReport(reportId)
    this.props.fetchBuilding(buildingId)
  }

  renderCurrentEnergyMark() {
    const { building } = this.props
    return (
      <Margin size={3} all>
        <p>
          Bemærk at vi via BBR registret kan se at kundens hus har energimærke
        </p>
        <EnergyMarkChar char={building.bbrEnergyMark} selected={true} />
      </Margin>
    )
  }

  renderContent() {
    const { building, report } = this.props
    const energyMark = report.energyMark || building.energyMark
    const improvedEnergyMark =
      report.energyMarkAfterOptimalImprovements ||
      building.energyMarkAfterOptimalImprovements
    return (
      <Flex>
        <Margin size={3} all>
          <strong>Vi har beregnet energimærke til:</strong>
          <EnergyMarkInput
            value={energyMark}
            onChange={this.handleEnergyMarkChange}
          />
          <p className="text-muted">
            Klik på et andet energimærke for at skifte
          </p>
        </Margin>
        <Margin size={3} all>
          <strong>Kunden kan opnå energimærke:</strong>
          <EnergyMarkInput
            value={improvedEnergyMark}
            onChange={this.handleEnergyAfterImprovementsChange}
          />
          <p className="text-muted">
            Klik på et andet energimærke for at skifte
          </p>
        </Margin>
        {/*this.renderCurrentEnergyMark()*/}
        <Margin size={3} all className="text-right">
          <Button active onClick={this.handleGenerateRapportClick}>
            Afslut rapport
          </Button>
        </Margin>
      </Flex>
    )
  }

  render() {
    const { isFetching, building, report } = this.props

    if (isFetching) {
      return <Spinner center />
    } else if (!building || !report) {
      return null
    }

    return (
      <VerticalAlign>
        <FlexContainer horizontal>
          <Flex />
          {this.renderContent()}
          <Flex />
        </FlexContainer>
      </VerticalAlign>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { buildingId, reportId } = props.routeParams

  return {
    buildingId,
    reportId,
    isFetching: Buildings.isFetching(state) || Reports.isFetching(state),
    building: Buildings.getBuilding(state, props),
    report: Reports.getReport(state, props),
    currentUser: getCurrentUser(state),
  }
}

export default connect(mapStateToProps, {
  fetchBuilding,
  fetchCustomer,
  updateBuilding,
  fetchReport,
  updateReport,
  push,
})(NewReportEnergyMarkContainer)
