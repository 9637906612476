import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'

export const getAssessmentCategoryId = (state, props) =>
  props.params.assessmentCategoryId
export const getAssessmentCategoryImages = createEntitiesSelector(
  'assessmentCategoryImages'
)

export const getAssessmentCategoryImagesForCategory = createSelector(
  getAssessmentCategoryImages,
  getAssessmentCategoryId,
  (images, categoryId) =>
    images.filter((t) => t.assessmentCategory === categoryId)
)
