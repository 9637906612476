import { createSelector } from 'reselect'
import {
  createEntitiesSelector,
  createIsFetchingSelector,
  createEntitySelector,
} from './ApiSelectors'

import { getBuildingPropertiesForBuilding } from '../selectors/BuildingPropertySelectors'
import { getPropertyCategoriesForWizard } from '../selectors/PropertyCategorySelectors'
import { getOrder } from '../selectors/OrderSelectors'
import { getUser } from '../selectors/UserSelectors'
import {
  getBuildingsForCustomer,
  getBuildingsForCustomerWithImages,
} from '../selectors/BuildingSelectors'

const getEntities = ({ api }) => api.entities
export const getWizardBuilding = createEntitySelector('wizardBuildings')
export const isFetching = createIsFetchingSelector('wizardBuildings')

export const getWizardBuildingWithRelations = createSelector(
  getWizardBuilding,
  createEntitiesSelector('steps'),
  createEntitiesSelector('wizardBuildingSteps'),
  getEntities,
  (wizardBuilding, steps, wizardBuildingSteps, entities) => {
    const { wizards = {}, orders = {} } = entities
    if (!wizardBuilding) return null

    const wizard = wizards[wizardBuilding.wizard]
    if (!wizard) return null

    return {
      ...wizardBuilding,
      wizard: {
        ...wizard,
        steps: steps.filter((s) => s.wizard === wizard.id),
      },
      wizardBuildingSteps: wizardBuildingSteps.filter(
        (wbs) => wbs.wizardBuilding === wizardBuilding.id
      ),
      order: orders[wizardBuilding.order],
    }
  }
)

export const getWizardBuildingNeedsAnyProperties = createSelector(
  getWizardBuilding,
  (state) => state,
  (wizardBuilding, state) => {
    if (!wizardBuilding) return false
    const { wizard: wizardId } = wizardBuilding
    const params = { wizardId }

    return getPropertyCategoriesForWizard(state, { params }).length !== 0
  }
)
export const getWizardBuildingIsMissingProperties = createSelector(
  getWizardBuilding,
  (state) => state,
  (wizardBuilding, state) => {
    if (!wizardBuilding) return false

    const { building: buildingId, wizard: wizardId } = wizardBuilding
    const params = { buildingId, wizardId }

    const buildingProperties = getBuildingPropertiesForBuilding(state, {
      params,
    })
    const propertyCategories = getPropertyCategoriesForWizard(state, { params })
    const providedCategoryIds = buildingProperties.map(
      (bp) => bp.property.propertyCategory
    )

    return !propertyCategories.every(
      (propertyCategory) =>
        providedCategoryIds.indexOf(propertyCategory.id) !== -1
    )
  }
)

export const getWizardBuildingIsMissingImages = createSelector(
  (state, props) =>
    getWizardBuilding(state, props, [
      'wizard.requiredImageTags',
      'building.buildingImages',
    ]),
  (wizardBuilding) => {
    if (!wizardBuilding) return false

    return !wizardBuilding.wizard.requiredImageTags.every((requiredImageTag) =>
      wizardBuilding.building.buildingImages.find(
        (buildingImage) => buildingImage.imageTag === requiredImageTag.imageTag
      )
    )
  }
)

export const getOrderUser = createSelector(
  getOrder,
  (state) => state,
  (order, state) => {
    if (!order) return null

    return getUser(state, { params: { userId: order.user } })
  }
)

export const getOrderUserBuildings = createSelector(
  getOrder,
  (state) => state,
  (order, state) => {
    if (!order) return null

    return getBuildingsForCustomer(state, {
      params: { customerId: order.customer },
    })
  }
)

export const getOrderUserBuildingsWithBuildingImages = createSelector(
  getOrder,
  (state) => state,
  (order, state) => {
    if (!order) return null

    return getBuildingsForCustomerWithImages(state, {
      params: { customerId: order.customer },
    })
  }
)
