import {
  isFetching,
  getReferenceProducts,
} from '../selectors/ReferenceProductsSelectors'
import { fetchEntities, invalidateEntities } from './ApiActions'

export const fetchReferenceProducts = (options) =>
  fetchEntities('referenceProducts', options)

export const invalidateReferenceProducts = (options) =>
  invalidateEntities('referenceProducts', options)

const shouldFetchReferenceProducts = (state) => {
  const products = getReferenceProducts(state)

  if (isFetching(state) || products.length > 0) {
    return false
  }

  return true
}

export const fetchReferenceProductsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchReferenceProducts(getState())) {
    dispatch(fetchReferenceProducts())
  }
}
