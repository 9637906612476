import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as OrderSelectors from '../../selectors/OrderSelectors'
import {
  updateOrderWorksheet,
  getWorksheetPDFLink,
} from '../../actions/OrderActions'
import { reduxForm } from 'redux-form'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
  DialogButton,
} from '../../components/Dialog'
import WorksheetForm from '../../components/WorksheetForm'

/**
 * Because ruby does not like empty strings
 * and react forms does not like 'null'
 */
const parseFinancedField = (value) => {
  if (value === 'null') return null
  else if (value === 'true') return true
  else if (value === 'false') return false
  else return value
}

/**
 *
 */
class PrintWorksheetDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  handleFormSubmit = (attrs) => {
    const { order, updateOrderWorksheet, getWorksheetPDFLink, onClose } =
      this.props
    attrs.financed = parseFinancedField(attrs.financed)
    return updateOrderWorksheet(order.id, attrs).then(() => {
      onClose()
      window.open(getWorksheetPDFLink(order))
    })
  }

  render() {
    const { fields, handleSubmit, submitting, onClose } = this.props
    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>Print arbejdsdokument</DialogTitle>
          <DialogButton
            disabled={submitting || !fields.financed.value}
            onClick={handleSubmit(this.handleFormSubmit)}
          >
            {submitting ? 'Gemmer…' : 'Gem og print'}
          </DialogButton>
        </DialogHeader>
        <WorksheetForm
          fields={fields}
          submitting={submitting}
          handleSubmit={handleSubmit(this.handleFormSubmit)}
        />
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = OrderSelectors.getOrderWithRelations(state, ownProps)

  return {
    order,
    initialValues: OrderSelectors.getOrderWorksheetSummary(state, ownProps),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateOrderWorksheet,
      getWorksheetPDFLink,
    },
    dispatch
  )

const ConnectedPrintWorksheetDialog = reduxForm({
  form: 'printWorksheet',
  fields: WorksheetForm.fields,
})(PrintWorksheetDialog)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedPrintWorksheetDialog)
