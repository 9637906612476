import * as types from '../constants/ActionTypes'
import { CALL_API } from '../middleware/api'

export function scheduleFollowupRequest({ orderId, followUpAt, comment }) {
  return {
    [CALL_API]: {
      method: 'POST',
      data: {
        mail: {
          order_id: orderId,
          follow_up_at: followUpAt,
          comment,
        },
      },
      endpoint: '/scheduled_follow_ups',
      types: [
        types.API_CUSTOM_REQUEST,
        types.API_CUSTOM_SUCCESS,
        types.API_CUSTOM_FAILURE,
      ],
    },
  }
}
