import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as WizardBuildingStepActions from '../actions/WizardBuildingStepActions'
import * as DialogActions from '../actions/DialogActions'

import { getStepProductsProductsWithProductVariants } from '../selectors/StepProductSelectors'
import { getWizardBuildingStep } from '../selectors/WizardBuildingStepSelectors'
import Cell from '../components/Cell'
import CollapsableListItem from '../components/CollapsableListItem'
import { List } from '../components/List'
import { Row, Margin } from '../components/Grid'
import OrderWizardStepTitle from '../components/OrderWizard/StepTitle'
import Spinner from '../components/Spinner'
import WizardBuildingStepTable from '../components/WizardBuildingStepTable'

class WizardBuildingStepContainer extends Component {
  static propTypes = {
    wizardBuildingStepId: PropTypes.string.isRequired,
    wizardBuildingStep: PropTypes.object,
    openDialog: PropTypes.func.isRequired,
    fetchWizardBuildingStep: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.fetchWizardBuildingStep()
  }

  componentDidUpdate(prevProps) {
    const { wizardBuildingStep } = this.props
    if (
      wizardBuildingStep &&
      wizardBuildingStep.id !== prevProps.wizardBuildingStep.id
    ) {
      this.fetchWizardBuildingStep()
    }
  }

  fetchWizardBuildingStep() {
    const { wizardBuildingStepId, fetchWizardBuildingStep } = this.props
    fetchWizardBuildingStep(wizardBuildingStepId, {
      query: {
        include: [
          'line_items',
          'line_items.building_property',
          'step',
          'step.step_products',
          'step.step_products.product',
          'step.step_products.product.product_variants',
        ],
      },
    })
  }

  handleProductVariantClick = (payload) => {
    const { openDialog, wizardBuildingStepId } = this.props
    openDialog('lineItemCreationDialog', { ...payload, wizardBuildingStepId })
  }

  renderLineItems() {
    const { wizardBuildingStep } = this.props
    if (wizardBuildingStep.lineItems.length === 0) return null

    return (
      <Margin all>
        <OrderWizardStepTitle>Tilføjede produkter</OrderWizardStepTitle>
        <WizardBuildingStepTable
          wizardBuildingStep={wizardBuildingStep}
          allowLineItemEditing
        />
      </Margin>
    )
  }

  renderStepProduct = (stepProduct) => (
    <CollapsableListItem
      key={stepProduct.id}
      title={stepProduct.product.name}
      thumbnail={stepProduct.product.thumbnailImageUrl}
      renderCollapse={() => this.renderStepProductCollapse(stepProduct)}
    />
  )

  renderStepProductCollapse = (stepProduct) => (
    <Row>
      {stepProduct.product.productVariants.map((productVariant) => (
        <Cell
          key={productVariant.id}
          size={3}
          imageUrl={productVariant.thumbnailImageUrl}
          clickPayload={{
            stepProductId: stepProduct.id,
            productVariantId: productVariant.id,
          }}
          onClick={this.handleProductVariantClick}
        >
          <p>{productVariant.name}</p>
        </Cell>
      ))}
    </Row>
  )

  renderStepProducts() {
    const {
      wizardBuildingStep: {
        step: { stepProducts },
      },
    } = this.props

    return (
      <Margin all>
        <OrderWizardStepTitle>Mulige produkter</OrderWizardStepTitle>
        <List
          noPadding
          items={stepProducts}
          itemRenderer={this.renderStepProduct}
        />
      </Margin>
    )
  }

  render() {
    const { wizardBuildingStep } = this.props

    const isLoading = !(
      wizardBuildingStep &&
      wizardBuildingStep.step &&
      wizardBuildingStep.step.stepProducts.every((sp) => sp.product)
    )

    if (isLoading) return <Spinner centerHorizontal />

    return (
      <div>
        {this.renderLineItems()}
        {this.renderStepProducts()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { wizardBuildingStepId } = props.params
  const params = { wizardBuildingStepId }

  return {
    wizardBuildingStepId: props.params.wizardBuildingStepId,
    wizardBuildingStep: getWizardBuildingStep(state, { params }, [
      'lineItems',
      'lineItems.buildingProperty',
      'step',
      'step.stepProducts',
      'step.stepProducts.product',
      'step.stepProducts.product.productVariants',
    ]),
    products: getStepProductsProductsWithProductVariants(state, props),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...WizardBuildingStepActions,
      ...DialogActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WizardBuildingStepContainer)
