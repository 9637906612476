import * as types from '../constants/ActionTypes'
import { createReducer } from '../utils/reducerUtils'

const initialState = {
  dialogType: null,
  dialogProps: null,
}

export default createReducer(initialState, {
  [types.DIALOG_OPEN](state, { dialogType, dialogProps }) {
    return {
      ...state,
      dialogType,
      dialogProps,
    }
  },
  [types.DIALOG_CLOSE]() {
    return initialState
  },
})
