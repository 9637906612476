import * as types from '../constants/ActionTypes'
import { CALL_API } from '../middleware/api'

const storeSessionInLocalStorage = (payload) =>
  localStorage.setItem('session', JSON.stringify(payload))

const removeSessionFromLocalStorage = () => localStorage.removeItem('session')

export function sendPasswordResetRequest(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      data,
      endpoint: '/password_resets',
      types: [
        types.SEND_PASSWORD_RESET_REQUEST,
        types.SEND_PASSWORD_RESET_SUCCESS,
        types.SEND_PASSWORD_RESET_FAILURE,
      ],
    },
  }
}

export function updatePasswordRequest(token, data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `/password_resets/${token}`,
      data: { user: data },
      types: [
        types.API_CUSTOM_REQUEST,
        types.API_CUSTOM_FAILURE,
        types.API_CUSTOM_SUCCESS,
      ],
    },
  }
}

export function login({ email, password }) {
  return (dispatch) =>
    dispatch({
      [CALL_API]: {
        method: 'POST',
        data: { email, password },
        endpoint: '/sessions',
        types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
      },
    }).then(storeSessionInLocalStorage)
}

export function logout() {
  return (dispatch) =>
    dispatch({
      [CALL_API]: {
        method: 'DELETE',
        endpoint: '/sessions',
        types: [
          types.LOGOUT_REQUEST,
          types.LOGOUT_COMPLETE,
          types.LOGOUT_COMPLETE,
        ],
      },
    }).then(removeSessionFromLocalStorage)
}
