import { CALL_API } from '../middleware/api'
import * as types from '../constants/ActionTypes'

const stringToUnderscore = (string) =>
  string.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)

export const invalidateEntities = (entityType) => ({
  type: types.API_INVALIDATE,
  entityType,
})

export const fetchEntity = (entity, options = {}) => ({
  [CALL_API]: {
    endpoint: `/${stringToUnderscore(entity.type)}/${entity.id}`,
    entityType: entity.type,
    types: [
      types.API_FETCH_REQUEST,
      types.API_FETCH_SUCCESS,
      types.API_FETCH_FAILURE,
    ],
    ...options,
  },
})

export const fetchEntities = (entityType, options) => ({
  [CALL_API]: {
    endpoint: `/${stringToUnderscore(entityType)}`,
    entityType,
    types: [
      types.API_FETCH_REQUEST,
      types.API_FETCH_SUCCESS,
      types.API_FETCH_FAILURE,
    ],
    ...options,
  },
})

export const deleteEntity = (entity, options = {}) => ({
  [CALL_API]: {
    method: 'DELETE',
    endpoint: `/${stringToUnderscore(entity.type)}/${entity.id}`,
    entity,
    types: [
      types.API_DELETE_REQUEST,
      types.API_DELETE_SUCCESS,
      types.API_DELETE_FAILURE,
    ],
    ...options,
  },
})

export const updateEntity = (entity, options = {}) => ({
  [CALL_API]: {
    method: 'PUT',
    endpoint: `/${stringToUnderscore(entity.type)}/${entity.id}`,
    entity,
    ...options,
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const createEntity = (entity, options = {}) => ({
  [CALL_API]: {
    method: 'POST',
    endpoint: `/${stringToUnderscore(entity.type)}`,
    entity,
    ...options,
    types: [
      types.API_UPDATE_REQUEST,
      types.API_UPDATE_SUCCESS,
      types.API_UPDATE_FAILURE,
    ],
  },
})

export const setEntityQuery = (entityType, query) => ({
  type: types.API_SET_ENTITY_QUERY,
  entityType,
  query,
})
