import React from 'react'
import PropTypes from 'react-proptypes'
import { formatDate } from '../../utils/formatUtils'
import './style.styl'

export const Comment = (props) => {
  const { comment } = props
  return (
    <div className="comment">
      <div className="comment__header">
        <span className="comment__author">{comment.user.name}</span>
        <span className="comment__date">
          {formatDate(comment.createdAt, 'j. F Y, k\\l: H:i')}
        </span>
      </div>
      <div className="comment__content">{comment.content}</div>
    </div>
  )
}

Comment.propTypes = {
  comment: PropTypes.object,
}

export default Comment
