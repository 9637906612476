import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'
import { getAssessmentsForAssessmentCategoryType } from './AssessmentSelectors'

export const getBuildingId = (state, props) => props.params.buildingId
export const getBuildingAssessments = createEntitiesSelector(
  'buildingAssessments'
)

export const getBuildingAssessmentsForBuilding = createSelector(
  getBuildingAssessments,
  getBuildingId,
  (assessments, buildingId) =>
    assessments.filter((a) => a.building === buildingId)
)

export const getBuildingAssessmentForBuildingAndCategoryType = createSelector(
  getAssessmentsForAssessmentCategoryType,
  getBuildingAssessmentsForBuilding,
  (assessments, buildingAssessments) =>
    buildingAssessments.find((ba) =>
      assessments.some((a) => a.id === ba.assessment)
    )
)
