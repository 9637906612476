import qs from 'qs'
import url from 'url'
import { camelizeString, formatResponseErrors } from '../utils/formatUtils'

export const CALL_API = 'CALL_API'

const validMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']

const handleIfUnauthorized = (response) => {
  if (response.status === 401) {
    return response.json().then((json) => {
      const error = json.errors[0].detail
      alert(error)
      localStorage.removeItem('session')
      location.reload()

      // Stall promise to prevent ui flashes etc.
      return null
    })
  }
  return response
}

const checkStatusAndMaybeParseJSON = (response) => {
  return Promise.resolve()
    .then(() => {
      if (response.status === 204) return null
      return response.json()
    })
    .then((json) => {
      if (!response.ok) throw json
      return json
    })
}

const normalizeEntity = ({ type, id, attributes, relationships }) => {
  const normalized = { type: camelizeString(type), id }

  if (relationships) {
    Object.keys(relationships).forEach((key) => {
      const { data } = relationships[key]

      if (!data) {
        normalized[camelizeString(key)] = null
      } else if (Array.isArray(data)) {
        normalized[camelizeString(key)] = data.map((item) => item.id)
      } else if (data && typeof data === 'object') {
        normalized[camelizeString(key)] = data.id
        if (data.type) {
          normalized[`${camelizeString(key)}Type`] = camelizeString(data.type)
        }
      }
    })
  }

  return Object.keys(attributes).reduce(
    (result, key) => ({
      ...result,
      [camelizeString(key)]: attributes[key],
    }),
    normalized
  )
}

const parseLinks = (links) =>
  links &&
  Object.keys(links).reduce((result, key) => {
    if (!links[key]) return
    const { query } = url.parse(links[key])
    const parsed = qs.parse(query)
    const number = parseInt(parsed.page.number, 10)
    const size = parseInt(parsed.page.size, 10)

    if (!isNaN(number) && !isNaN(size)) {
      return {
        ...result,
        [key]: { number, size },
      }
    }

    return result
  }, {})

const normalizeResponse = (result = {}) => {
  if (!result || !result.data) {
    return null
  }

  const { data, included, links } = result

  const collection = Array.isArray(data) ? data : [data]
  const entities = {}
  const results = {}
  const entityOrder = Array.isArray(data) ? data.map((e) => e.id) : null
  const pagination = parseLinks(links)

  collection
    .concat(included || [])
    .map(normalizeEntity)
    .forEach((entity) => {
      const { type, id } = entity
      if (!type || !id) return

      const camelizedType = camelizeString(type)

      if (!results.hasOwnProperty(camelizedType)) {
        results[camelizedType] = [id]
      } else {
        results[camelizedType].push(id)
      }

      if (!entities.hasOwnProperty(camelizedType)) {
        entities[camelizedType] = { [id]: entity }
      } else {
        entities[camelizedType][id] = entity
      }
    })

  return { entities, results, pagination, entityOrder, rawData: data }
}

const defaultOptions = {
  apiUrl: undefined,
  apiKey: undefined,
}

const defaultRequestOptions = {
  method: 'GET',
  contentType: null,
  headers: null,
  normalizer: normalizeResponse,
}

const validateOptions = (options) => {
  const { apiUrl, apiKey } = options

  if (typeof apiUrl !== 'string') {
    throw new Error('Expected a string API url.')
  }

  if (typeof apiKey !== 'string') {
    throw new Error('Expected a string API key.')
  }
}

const validateRequestOptions = (options) => {
  const { endpoint, types, method } = options

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  if (!validMethods.includes(method)) {
    throw new Error(`Invalid request method "${method}".`)
  }
}

const validateAuthState = (state) => {
  if (typeof state !== 'object') {
    throw new Error('Expected `auth` state to an object.')
  }

  if (!state.hasOwnProperty('token')) {
    throw new Error('Expected `token` property to exist on `auth` state.')
  }
}

const parseAndNormalizeQueryString = (query) => {
  if (!query) {
    return ''
  } else if (typeof query === 'string') {
    if (query.charCodeAt(0) === 64) {
      return query
    }

    return `?${query}`
  }

  return `?${qs.stringify(query, { arrayFormat: 'brackets', skipNulls: true })}`
}

export const prepareRequest = (action, state, apiOptions) => {
  const requestOptions = { ...defaultRequestOptions, ...action[CALL_API] }
  validateRequestOptions(requestOptions)

  const { token } = state.auth
  const { types, method, query, endpoint, data, normalizer } = requestOptions

  // Build full url
  const normalizedQuery = parseAndNormalizeQueryString(query)
  const fullUrl = `${apiOptions.apiUrl}${endpoint}${normalizedQuery}`

  // Build fetch options
  const headers = {
    Authorization: `Token token=${token || ''}@${apiOptions.apiKey}`,
    ...(requestOptions.headers || {}),
  }
  let contentType = requestOptions.contentType || null
  let body = null

  if (data) {
    contentType = 'application/json'
    body = JSON.stringify(data)
  } else {
    body = requestOptions.body
  }

  // Build headers
  if (contentType) {
    headers['Content-Type'] = contentType
  }

  const fetchOptions = { method, headers }

  if (contentType) {
    fetchOptions.contentType = contentType
  }

  if (body) {
    fetchOptions.body = body
  }

  const makeRequest = () =>
    fetch(fullUrl, fetchOptions)
      .then(handleIfUnauthorized)
      .then(checkStatusAndMaybeParseJSON)
      .then((json) =>
        typeof normalizer === 'function' ? normalizer(json) : json
      )
      .catch((error) => {
        const result = error.error || error.errors || error
        throw result
      })

  return { types, data, body, query, makeRequest }
}

export default function apiMiddlewareFactory(options = {}) {
  const apiOptions = { ...defaultOptions, ...options }

  validateOptions(apiOptions)

  return (store) => (next) => (action) => {
    if (!action.hasOwnProperty(CALL_API)) {
      return next(action)
    }

    const state = store.getState()
    validateAuthState(state.auth)

    const requestOptions = { ...defaultRequestOptions, ...action[CALL_API] }
    validateRequestOptions(requestOptions)

    const { types, data, body, query, makeRequest } = prepareRequest(
      action,
      state,
      apiOptions
    )

    const actionWith = (type, content = {}) => {
      next({
        type,
        request: { data, body, query, ...requestOptions },
        ...content,
      })
    }

    const [requestType, successType, failureType] = types

    actionWith(requestType)

    return makeRequest()
      .then((payload) => {
        actionWith(successType, { payload })
        return payload
      })
      .catch((error) => {
        actionWith(failureType, { error })
        throw error
      })
  }
}
