import { createSelector } from 'reselect'

import singularize from '../utils/singularize'
import attachRelations from '../utils/attachRelations'

const getEntity =
  (entityType) =>
  ({ api }, { params }) => {
    const entities = api.entities[entityType]
    const id = params[`${singularize(entityType)}Id`]
    return entities ? entities[id] : null
  }
export const getEntities =
  (entityType) =>
  ({ api }) =>
    api.entities[entityType] || {}
const getFetchCount =
  (entityType) =>
  ({ api }) =>
    api.isFetching[entityType]
const getPagination =
  (entityType) =>
  ({ api }) =>
    api.pagination[entityType] || {}
const getOrder =
  (entityType) =>
  ({ api }) =>
    api.entityOrders[entityType]
export const getEntityQueries =
  (entityType) =>
  ({ api }) =>
    api.entityQueries[entityType]

export const getFetchCountForEntityTypes = createSelector(
  (state) => state.api.isFetching,
  (state, entityTypes) =>
    (typeof entityTypes === 'string' && [entityTypes]) || entityTypes,
  (fetching, entityTypes) =>
    entityTypes.reduce((total, type) => total + (fetching[type] || 0), 0)
)

export const getActionCountForEntityTypes = createSelector(
  (state, action) =>
    state.api[`is${action.replace(/^./, (l) => l.toUpperCase())}`],
  (state, action, entityTypes) =>
    (typeof entityTypes === 'string' && [entityTypes]) || entityTypes,
  (actioning, entityTypes) =>
    entityTypes.reduce(
      (total, type) =>
        total + ((actioning[type] && actioning[type].length) || 0),
      0
    )
)

export const isActioningEntityTypes = createSelector(
  getActionCountForEntityTypes,
  (count) => count !== 0
)

export const createEntitiesSelector = (entityType) =>
  createSelector(
    getEntities(entityType),
    (state, _props, includes) => attachRelations(state, includes),
    (entities, attacher) =>
      Object.keys(entities || [])
        .map((id) => entities[id])
        .map(attacher)
  )

export const createOrderedEntitiesSelector = (entityType) =>
  createSelector(
    getEntities(entityType),
    getOrder(entityType),
    (entities, order) =>
      entities && order
        ? order.map((id) => entities[id]).filter((present) => present)
        : []
  )

export const createIsFetchingSelector = (entityType) =>
  createSelector(getFetchCount(entityType), (count) => count > 0)

export const createGetPaginationSelector = (entityType) =>
  createSelector(getPagination(entityType), ({ self, last }) =>
    self && last
      ? { pageNumber: last.number, currentPageNumber: self.number }
      : null
  )

export const createEntitySelector = (entityType) =>
  createSelector(
    getEntity(entityType),
    (state, _props, includes) => attachRelations(state, includes),
    (entity, attacher) => attacher(entity)
  )
