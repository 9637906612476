import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { userRoleLabels } from '../../constants/UserRoles'
import { isValidEmail } from '../../utils/validationUtils'
import { sendOrder, updateOrder } from '../../actions/OrderActions'
import { ManagerFollowUpTemplates } from '../../constants/FollowUpTemplates'
import { formatTemplate } from '../../utils/formatUtils'
import { getOrderWithRelations } from '../../selectors/OrderSelectors'
import { Margin } from '../../components/Grid'
import Input from '../../components/Input'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
  DialogButton,
} from '../../components/Dialog'
import Select from '../../components/Select'
import TextArea from '../../components/TextArea'
import PreviewPanel from '../../components/PreviewPanel'
import CustomerEmailInputContainer from '../CustomerEmailInputContainer'
import { getCurrentUser } from '../../selectors/AuthSelectors'

class ManagerOrderFollowUpDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const { order } = props

    this.state = {
      error: false,
      submitting: false,
      customerEmail: order.customer ? order.customer.email : '',
      emailBody: '',
      expectedStartUpTime: order.expectedStartUpTime || '',
    }
  }

  handleSendClick = () => {
    const { updateOrder, sendOrder, order, onClose, currentUser } = this.props
    const { expectedStartUpTime, emailBody, customerEmail } = this.state

    this.setState({ submitting: true })

    updateOrder(order.id, {
      expectedStartUpTime,
      followUpAt: null,
      adminFollowedUpAt: new Date(),
    })
      .then(() =>
        sendOrder({
          body: emailBody,
          order_id: order.id,
          email: customerEmail,
          from_email: currentUser.email,
        })
      )
      .then(() => this.setState({ submitting: false }, () => onClose()))
      .catch(() => {
        this.setState({ submitting: false, error: true })
      })
  }

  handleInputChange = ({ target }) => {
    const { name, value } = target
    this.setState({ [name]: value })
  }

  handleEmailTemplateChange = ({ target }) => {
    const { value } = target
    const selected = ManagerFollowUpTemplates[value]

    if (selected && selected.template) {
      const {
        order,
        order: { customer },
      } = this.props
      const body = formatTemplate(selected.template, {
        customerName: customer.name,
        orderId: order.id,
      })
      this.setState({
        emailBody: body,
      })
    }
  }

  optionsForTemplateSelector = () => {
    const placeholderOption = { value: '', label: 'Vælg skabelon' }
    const options = Object.keys(ManagerFollowUpTemplates).map((value) => ({
      value,
      label: ManagerFollowUpTemplates[value].label,
    }))

    return [placeholderOption].concat(options)
  }

  renderFollowUpTemplateSelector() {
    return (
      <Margin vertical>
        <Select
          style={{ width: '100%' }}
          onChange={this.handleEmailTemplateChange}
          options={this.optionsForTemplateSelector()}
        />
      </Margin>
    )
  }

  render() {
    const {
      onClose,
      order: { customer, user },
      currentUser,
    } = this.props
    const { submitting, customerEmail, emailBody, expectedStartUpTime } =
      this.state

    const valid = emailBody && isValidEmail(customerEmail)

    return (
      <Dialog style={{ width: '500px' }}>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>Opfølgende mail</DialogTitle>
          <DialogButton
            disabled={submitting || !valid}
            onClick={this.handleSendClick}
          >
            Send email
          </DialogButton>
        </DialogHeader>
        <Margin all>
          <CustomerEmailInputContainer
            placeholder="Modtagers E-mail-adresse"
            defaultValue={customerEmail}
            customerId={customer.id}
            customerEmail={customer.email}
            name="customerEmail"
            type="email"
            onChange={this.handleInputChange}
            disabled={submitting}
          />
          <Margin vertical>
            <Input
              name="expectedStartUpTime"
              value={expectedStartUpTime}
              placeholder="Opstartstidspunkt"
              onChange={this.handleInputChange}
              disabled={submitting}
            />
          </Margin>
          {this.renderFollowUpTemplateSelector()}
          <Margin vertical>
            <PreviewPanel seamlessBottom>{`Hej ${customer.name}`}</PreviewPanel>
            <TextArea
              name="emailBody"
              onChange={this.handleInputChange}
              placeholder="Besked til kunde"
              rows={7}
              value={emailBody}
              disabled={submitting}
              seamless
            />
            <PreviewPanel seamlessTop>
              Med venlig hilsen
              <br />
              {currentUser.name && (
                <span>
                  {currentUser.name}
                  <br />
                </span>
              )}
              {currentUser.role && (
                <span>
                  {userRoleLabels[currentUser.role]}
                  <br />
                </span>
              )}
              {currentUser.phone && (
                <span>
                  {currentUser.phone}
                  <br />
                </span>
              )}
              {currentUser.email && (
                <span>
                  {currentUser.email}
                  <br />
                </span>
              )}
              Jydsk Tagteknik A/S
            </PreviewPanel>
          </Margin>
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: getCurrentUser(state),
    order: getOrderWithRelations(state, {
      params: { orderId: ownProps.orderId },
    }),
  }
}

export default connect(mapStateToProps, {
  sendOrder,
  updateOrder,
})(ManagerOrderFollowUpDialog)
