import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import { FlexContainer, Flex } from '../Grid'
import { formatDate, formatUserName } from '../../utils/formatUtils'
import FollowUpDate from '../FollowUpDate'
import OrderStateLabel from '../OrderStateLabel'
import { IconButton } from '../Button'

const CustomerAddress = ({ customer }) => (
  <div>
    {customer.addressStreetName} {customer.addressStreetNumber}
    {customer.addressAdditionalInfo
      ? `, ${customer.addressAdditionalInfo}`
      : null}
    <br />
    {customer.addressZipCode} {customer.addressCityName}
  </div>
)

const OrderDates = ({ order }) => (
  <div className="order-dates">
    <p>
      Tilbud oprettet:{' '}
      <span className="order-date">
        {order.createdAt ? formatDate(order.createdAt, 'j. F Y') : 'Ikke sat'}
      </span>
    </p>
    <p>
      Tilbud godkendt:{' '}
      <span className="order-date">
        {order.acceptedAt ? formatDate(order.acceptedAt, 'j. F Y') : 'Ikke sat'}
      </span>
    </p>
    <p>
      Formand gennemgået:{' '}
      <span className="order-date">
        {order.reviewedAt ? formatDate(order.reviewedAt, 'j. F Y') : 'Ikke sat'}
      </span>
    </p>
    <p>
      Opfølgning dato: <FollowUpDate {...order} />
    </p>
    <p>
      Leveres dato:{' '}
      <span className="order-date">
        {order.deliveredAt
          ? formatDate(order.deliveredAt, 'j. F Y')
          : 'Ikke sat'}
      </span>
    </p>
    <p>
      Afvist dato:{' '}
      <span className="order-date">
        {order.rejectedAt ? formatDate(order.rejectedAt, 'j. F Y') : 'Ikke sat'}
      </span>
    </p>
    <p>
      Eksporteret dato:{' '}
      <span className="order-date">
        {order.exportedAt ? formatDate(order.exportedAt, 'j. F Y') : 'Ikke sat'}
      </span>
    </p>
    <p>
      Arkiveret dato:{' '}
      <span className="order-date">
        {order.archivedAt ? formatDate(order.archivedAt, 'j. F Y') : 'Ikke sat'}
      </span>
    </p>
    <p>
      Annulleret dato:{' '}
      <span className="order-date">
        {order.canceledAt ? formatDate(order.canceledAt, 'j. F Y') : 'Ikke sat'}
      </span>
    </p>
  </div>
)

CustomerAddress.propTypes = {
  customer: PropTypes.object,
}

const OrderPageHeader = ({
  order,
  customer,
  salesman,
  onCustomerEditClick,
}) => (
  <div>
    <FlexContainer style={{ marginBottom: 20 }}>
      <Flex size={6}>
        <label className="order-header__label">
          Kunde
          {customer.canUpdate && (
            <IconButton
              icon="compose"
              onClick={() => onCustomerEditClick(customer)}
            />
          )}
        </label>
        {customer.name}
        <CustomerAddress customer={customer} />
        <br />
      </Flex>
      <Flex size={6}>
        <OrderDates order={order} />
      </Flex>
    </FlexContainer>
    <FlexContainer>
      <Flex size={6}>
        <label className="order-header__label">Telefon</label>
        {customer.phone || 'Ikke angivet'}
        <br />
        <br />
        <label className="order-header__label">E-mail</label>
        {customer.email ? (
          <a href={`mailto:${customer.email}`}>{customer.email}</a>
        ) : (
          'Ikke angivet'
        )}
      </Flex>
      <Flex size={6}>
        <div className="order-header__section">
          <label className="order-header__label">Konsulent</label>
          {formatUserName(salesman)}
        </div>
      </Flex>
    </FlexContainer>
    <FlexContainer>
      <Flex size={6}>
        <div className="order-header__section">
          <label className="order-header__label">Status</label>
          <OrderStateLabel state={order.state} />
        </div>
      </Flex>
    </FlexContainer>
  </div>
)

OrderPageHeader.propTypes = {
  order: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
  }).isRequired,
  salesman: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onCustomerEditClick: PropTypes.func.isRequired,
}

export default OrderPageHeader
