import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { sendOrderConfirmationEmail } from '../../actions/OrderActions'
import { isValidEmail } from '../../utils/validationUtils'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
  DialogButton,
} from '../../components/Dialog'
import Button from '../../components/Button'
import TextArea from '../../components/TextArea'
import Input from '../../components/Input'
import { Margin } from '../../components/Grid'
import Alert from '../../components/Alert'

class SendOrderConfirmationDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    email: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      valid: isValidEmail(props.email),
      email: props.email,
      submitting: false,
      body: '',
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleBodyChange = this.handleBodyChange.bind(this)
    this.handleSendClick = this.handleSendClick.bind(this)
  }

  handleSaveClick() {
    this.formElement.submit()
  }

  handleInputChange({ target }) {
    const { value } = target

    this.setState({
      valid: isValidEmail(value),
      email: value,
    })
  }

  handleBodyChange({ target }) {
    this.setState({ body: target.value })
  }

  handleSendClick() {
    const { email, body } = this.state
    const { orderId, onClose } = this.props

    this.setState({ submitting: true, error: false })
    this.props
      .sendOrderConfirmationEmail({ id: orderId, body, email })
      .then(() => {
        this.setState({ submitting: true })
        onClose()
      })
      .catch(() => {
        this.setState({ submitting: false, error: true })
      })
  }

  render() {
    const { onClose } = this.props
    const { valid, email, submitting, error } = this.state

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>Send ordrebekræftelse</DialogTitle>
          <DialogButton
            onClick={this.handleSendClick}
            disabled={submitting || !valid}
          >
            Send
          </DialogButton>
        </DialogHeader>
        <Margin all>
          {error && (
            <Alert>
              Der opstod en uventet fejl. Ordrebekræftelsen blev ikke sendt.
            </Alert>
          )}
          <Margin bottom>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              disabled={submitting}
              onChange={this.handleInputChange}
            />
          </Margin>
          <TextArea
            placeholder="Besked til modtager"
            onChange={this.handleBodyChange}
          />
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

export default connect(mapStateToProps, {
  sendOrderConfirmationEmail,
})(SendOrderConfirmationDialog)
