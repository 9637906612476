import React from 'react'
import PropTypes from 'react-proptypes'

const SidebarMenu = (props) => (
  <ul className="sidebar__menu">{props.children}</ul>
)

SidebarMenu.propTypes = {
  children: PropTypes.any,
}

export default SidebarMenu
