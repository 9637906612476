import { createSelector } from 'reselect'
import {
  createEntitiesSelector,
  createIsFetchingSelector,
} from './ApiSelectors'

export const getAssessmentCategoryEntities = (state) =>
  state.api.entities.assessmentCategories
export const getAssessmentCategoryId = (state, props) =>
  props.params.assessmentCategoryId
export const getAssessmentCategoryTypes = createEntitiesSelector(
  'assessmentCategoryTypes'
)

export const isFetching = createIsFetchingSelector('assessmentCategories')
export const getAssessmentCategories = createEntitiesSelector(
  'assessmentCategories'
)

export const getAssessmentCategory = createSelector(
  getAssessmentCategoryId,
  getAssessmentCategoryEntities,
  (id, entities) => entities && entities[id]
)
