import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { formatDate, formatUserName } from '../../utils/formatUtils'
import omit from 'lodash/omit'
import Icon from '../Icon'
import FollowUpDate from '../FollowUpDate'
import OrderStateLabel from '../OrderStateLabel'

// Helpers
const getPrice = (order) =>
  order.humanizedTotalPriceWithDiscountsInclVatWithSymbol

const getCreatedAtDate = (order) => formatDate(order.createdAt, 'd/n/Y')

const getAcceptedAtDate = (order) =>
  (order.acceptedAt && formatDate(order.acceptedAt, 'j. F Y')) || '-'

export default class OrderTable extends Component {
  static propTypes = {
    orders: PropTypes.array.isRequired,
    onOrderClick: PropTypes.func.isRequired,
    onSortClick: PropTypes.func,
    sortedBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.renderSortIcon = this.renderSortIcon.bind(this)
    this.handleHeaderClick = this.handleHeaderClick.bind(this)
  }

  handleHeaderClick({ currentTarget }) {
    const fieldName = currentTarget.getAttribute('data-field')
    this.props.onSortClick(fieldName)
  }

  renderSortIcon(fieldName) {
    const { sortedBy, sortDirection } = this.props

    if (fieldName === sortedBy) {
      const icon = sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'
      return <Icon icon={icon} className="order-table__sort-icon" />
    }
    return null
  }

  render() {
    const { orders, onOrderClick } = this.props
    const otherProps = omit(this.props, [
      'orders',
      'onOrderClick',
      'onSortClick',
      'sortedBy',
      'sortDirection',
    ])
    const { handleHeaderClick, renderSortIcon } = this

    return (
      <table className="order-table" {...otherProps}>
        <thead>
          <tr>
            <th data-field="id">ID</th>
            <th data-field="name">Navn</th>
            <th data-field="total_price" className="text-right">
              Beløb
            </th>
            <th onClick={handleHeaderClick} data-field="created_at_timestamp">
              Dato {renderSortIcon('created_at_timestamp')}
            </th>
            <th
              onClick={handleHeaderClick}
              data-field="proposed_and_follow_up_at"
            >
              Opfølgningsdato {renderSortIcon('proposed_and_follow_up_at')}
            </th>
            <th onClick={handleHeaderClick} data-field="accepted_at">
              Tilbud godkendt {renderSortIcon('accepted_at')}
            </th>
            <th data-field="user_name">Sælger</th>
            <th data-field="state" className="text-right">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr
              key={order.objectID}
              onClick={(event) => onOrderClick(order, event)}
            >
              <td>{order.objectID}</td>
              <td className="order-table__name">{order.name}</td>
              <td className="text-right order-table__price">
                {getPrice(order)}
              </td>
              <td>{getCreatedAtDate(order)}</td>
              <td>
                <FollowUpDate
                  state={order.aasmState}
                  followUpAt={order.followUpAt}
                />
              </td>
              <td>{getAcceptedAtDate(order)}</td>
              <td className="order-table__user">
                {formatUserName(order.user)}
              </td>
              <td className="text-right">
                <OrderStateLabel state={order.aasmState} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}
