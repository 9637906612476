import { fetchEntities } from './ApiActions'
import { commentablelizeString } from '../utils/formatUtils'

export const fetchActivities = () => fetchEntities('activities')

export const fetchActivitiesForTrackable = (params) =>
  fetchEntities('activities', {
    query: {
      trackable_id: params.trackableId,
      trackable_types: params.trackableTypes.map(commentablelizeString),
      include: ['user'],
    },
  })
