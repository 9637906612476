import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import pick from 'lodash/pick'

export default class TextArea extends Component {
  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.any,
    children: PropTypes.any,
    onInput: PropTypes.func,
    seamless: PropTypes.bool,
    seamlessTop: PropTypes.bool,
    seamlessBottom: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.handleInput = this.handleInput.bind(this)
  }

  componentDidMount() {
    this.resize(this.inputElement)
  }

  componentDidUpdate() {
    this.resize(this.inputElement)
  }

  clear() {
    this.inputElement.value = ''
  }

  handleInput(e) {
    this.resize(e.target)

    if (this.props.onInput) {
      this.props.onInput(e)
    }
  }

  resize(target) {
    const { style } = target
    style.height = '0'
    style.height = `${target.scrollHeight + 2}px`
  }

  render() {
    const { error, seamlessTop, seamlessBottom, seamless } = this.props

    const className = classNames(
      'textarea',
      this.props.className,
      { 'textarea--error': error },
      { 'textarea--seamless-top': seamless || seamlessTop },
      { 'textarea--seamless-bottom': seamless || seamlessBottom }
    )

    const textAreaProps = pick(this.props, [
      'type',
      'name',
      'value',
      'defaultValue',
      'onChange',
      'onInput',
      'style',
      'placeholder',
      'disabled',
    ])

    return (
      <textarea
        ref={(element) => (this.inputElement = element)}
        {...textAreaProps}
        className={className}
        onInput={this.handleInput}
      />
    )
  }
}
