import React from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'

export const NewReportHeader = (props) => (
  <h1 className="new-report-header">{props.children}</h1>
)

NewReportHeader.propTypes = {
  children: PropTypes.any.isRequired,
}

export default NewReportHeader
