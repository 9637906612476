const FollowUpTemplates = {
  video: {
    label: 'Opfølgning med video',
    template:
      'Jeg tillader mig som opfølgning til mit besøg på jeres ejendom, samt ' +
      'efterfølgende afgivelse af tilbud at fremsende en lille ' +
      'appetitvækker.\n\n' +
      'Dette gøres i form af en lille video vedrørende netop det som vi ' +
      'skal udføre hos jer. Jeg håber dette falder i jeres interesse og ser ' +
      'frem til at høre fra jer igen.\n\n' +
      'Såfremt der måtte være yderligere spørgsmål, står jeg naturligvis ' +
      'til rådighed og vil besvare jeres spørgsmål så præcist som muligt.\n\n' +
      'Jeg vil til sidst blot gøre opmærksom på vores rabat ved svar' +
      'indenfor 7 dage, og såfremt jeg ikke høre fra jer vil jeg tillade mig ' +
      'at kontakte jer.\n\n' +
      'https://www.youtube.com/watch?v=nMYrq8me20E',
  },
  sendOrder: {
    label: 'Send tilbud',
    template:
      'Hermed fremsendes tilbuddet som aftalt.\n' +
      'Skulle der evt. være spørgsmål hertil, så kontakt mig gerne, enten på mail eller telefon.\n\n' +
      'Der gøres opmærksom på at, vores rabat er gældende i 7 dage.\n\n' +
      'Jeg ser frem til at høre fra dig/jer.',
  },
  one: {
    label: 'Opfølgning 1',
    template:
      'Tak for et godt og hyggeligt møde forleden, jeg håber mit besøg ' +
      'levede op til jeres forventninger.\n\n' +
      'Som opfølgning på mit besøg på jeres ejendom og efterfølgende ' +
      'afgivelse af tilbud herpå, tillader jeg mig venligst at spørge til ' +
      'jeres overvejelser herom.\n\n' +
      'Ligeledes er jeg til rådighed med svar på opståede spørgsmål.\n\n' +
      'Jeg gør samtidigt opmærksom på "her&nu" som er gældende i 7 dage.',
  },
  two: {
    label: 'Opfølgning 2',
    template:
      'Tak for yderst behagelig samtale igår.\n\n' +
      'Jeg håber mit besøg på jeres ejendom levede op til jeres ' +
      'forventninger og jeg håber ligeledes vi fik afdækket og drøftet de ' +
      'muligheder der umiddelbart foreligger.\n\n' +
      'Som opfølgning på besøget vil jeg venligst spørge til jeres ' +
      'overvejelser omkring tilbuddet og eventuelle spørgsmål.\n\n' +
      'Jeg ser frem til at høre fra jer.\n\n' +
      'Rigtig god dag.',
  },
  three: {
    label: 'Opfølgning 3',
    template:
      'Som opfølgning på mit besøg på jeres ejendom sidste uge og ' +
      'efterfølgende fremsendte opfølgningsmail vil jeg venligst spørge til ' +
      'jeres overvejelser herom.\n\n' +
      'Jeg gør samtidig opmærksom på rabatten ved svar inden 7 dage.\n\n' +
      'Jeg er ligeledes til rådighed såfremt der er opstået spørgsmål til ' +
      'det fremsendte.\n\n' +
      'Rigtig god dag.',
  },
  four: {
    label: 'Opfølgning 4',
    template:
      'I forlængelse af mit besøg den [[dato for besøg]] vil jeg høre om der ' +
      'er opstået yderligere spørgsmål?\n\n' +
      'Leveringstiden lige nu ligger på 6-8 uger, men jeg har en lille ' +
      'åbning for jer, således at jeg kan skubbe jer lidt frem.\n\n' +
      'Såfremt det er noget vi skal gå videre med, hører jeg gerne fra ' +
      'jer.\n\n' +
      'Jeg skal således blot bede jer om at acceptere tilbud nr. {{orderId}} ' +
      'i denne mail, så vil jeg sætte det i værk og i vil hurtigst muligt ' +
      'modtage en ordrebekræftelse.\n\n' +
      'Skulle der være yderligere spørgsmål er i meget velkomne til at ' +
      'kontakte mig.',
  },
  five: {
    label: 'Opfølgning 5',
    template:
      'Jeg tillader mig at rette henvendelse til dig vedrørende mit besøg ' +
      'på din ejendom den [[dato for besøg]].\n\n' +
      'Jeg har forsøgt at kontakte dig på telefon, desværre uden held.\n\n' +
      'Jeg vil høre om du har gjort dig yderligere tanker i forhold til mit ' +
      'tilbud på [[tilbudstype]], jeg kan informere om at leveringstiden nu ' +
      'er på 6-8 uger, men jeg har mulighed for ekstraordinært at skubbe ' +
      'dig lidt frem.\n\n' +
      'Samtidig har jeg holdt rabatten åben såfremt vi kan lave en aftale ' +
      'hurtigst muligt.\n\n' +
      'Såfremt vi skal gå videre med tilbuddet, skal jeg bede dig acceptere ' +
      'tilbud nr. {{orderId}} i denne mail.',
  },
}

export default FollowUpTemplates

export const ManagerFollowUpTemplates = {
  one: {
    label: 'Opfølgning 1',
    template:
      'Tak for ordren, som jeg nu har gennemset.\n\n' +
      'Jeg er formand på dit projekt med tilbudsnr. {{orderId}}, og jeg ser ' +
      'frem til at stå for opgaven i samarbejde med mine dygtige ' +
      'håndværkere.\n\n' +
      'Vi følger tidsplanen som oplyst i din ordrebekræftelse. Hvis der ' +
      'skulle ske ændringer, så hører du naturligvis fra mig.\n' +
      'Ellers kontakter jeg dig ca. 14 dage før opstart, så vi sammen kan ' +
      'aftale de nærmere detaljer.\n\n' +
      'Har du har nogle spørgsmål, så er du naturligvis altid velkommen til ' +
      'at kontakte mig.',
  },
}
