import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'
import { reducer as form } from 'redux-form'
import app from './app'
import auth from './auth'
import users from './users'
import dialog from './dialog'
import api from './api'
import algolia from './algolia'

export default combineReducers({
  routing,
  api,
  app,
  auth,
  users,
  dialog,
  form,
  algolia,
})
