import { omit } from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import { getActiveCoupons } from '../../selectors/CouponSelectors'
import { getActiveDiscountNames } from '../../selectors/DiscountNameSelectors'

import * as CouponActions from '../../actions/CouponActions'
import * as DiscountActions from '../../actions/DiscountActions'
import * as DiscountNameActions from '../../actions/DiscountNameActions'
import * as OrderActions from '../../actions/OrderActions'

import CouponRow from '../CouponRow'
import './style.styl'

import DraftWizardDiscountRow from '../DraftWizardDiscountRow'
import DraftDisplayedWizardDiscountRow from '../DraftDisplayedWizardDiscountRow'
import { openDialog } from '../../actions/DialogActions'

class DraftDiscountsTable extends Component {
  static propTypes = {
    discountNames: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    coupons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    order: PropTypes.shape({
      discounts: PropTypes.arrayOf(
        PropTypes.shape({
          humanizedDiscountWithSymbol: PropTypes.string,
          coupon: PropTypes.shape({
            name: PropTypes.string,
          }),
        })
      ),
      displayedDiscountSet: PropTypes.bool.isRequired,
      humanizedDiscountInclVatWithSymbol: PropTypes.string.isRequired,
      humanizedDisplayedDiscountWithSymbol: PropTypes.string.isRequired,
    }),
    fetchCoupons: PropTypes.func.isRequired,
    fetchDiscountNames: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    updateDiscount: PropTypes.func.isRequired,
    deleteDiscount: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { fetchCoupons, fetchDiscountNames } = this.props

    fetchCoupons()
    fetchDiscountNames()
  }

  handleToggleCouponActive = (coupon) => {
    const { order, deleteDiscount, updateOrder, fetchOrder } = this.props

    if (coupon.discount) {
      deleteDiscount(coupon.discount.id).then(() => fetchOrder(order.id))
    } else {
      const couponIds = order.discounts.filter(
        (d) => d.discountType === "coupon_discount"
      ).map((discount) => discount.coupon.id)
      const attributes = { couponIds: [...couponIds, coupon.id] }
      updateOrder(order.id, attributes, {
        query: { include: ['discounts'] },
      })
    }
  }

  handleCouponNameSelected = (coupon, name) => {
    const { updateDiscount } = this.props
    updateDiscount(coupon.discount.id, { name })
  }

  render() {
    const {
      coupons,
      discountNames,
      order: { discounts = [] },
    } = this.props;
    
    if (!discounts.length) return null;
    
    const discountsWithCoupon = discounts.filter(d => d.coupon);

    const couponsWithDiscounts = coupons.map((coupon) => ({
      ...coupon,
      discount: discounts.find((discount) => discount.coupon && discount.coupon.id === coupon.id),
    }));
  
    const orphanedDiscounts = discounts.filter(
      (d) => !coupons.find((c) => d.coupon && c.id === d.coupon.id)
    );
  
    const wizardDiscounts = orphanedDiscounts.filter(
      (d) => d.discountType === "wizard_discount"
    );
  
    const trulyOrphanedDiscounts = orphanedDiscounts.filter(
      (d) => d.discountType === "coupon_discount"
    );
  
    const inactiveCoupons = trulyOrphanedDiscounts.map((discount) => ({
      ...discount.coupon,
      discount: omit(discount, 'coupon'),
    }));
  
    const shownCoupons = [].concat(couponsWithDiscounts, inactiveCoupons);
  
    const hasWizardDiscounts = wizardDiscounts.length > 0;
    return (
      <table className="DraftDiscountsTable">
        <tbody>
          <tr key="header">
            <th>Rabatter</th>
            <th>Vist navn</th>
            <th className="text-right">Rabat (m. moms)</th>
            <th></th> {/* TODO: Empty cell for the icon buttons. Do not remove. */}
          </tr>
          {discountsWithCoupon.length > 0 && shownCoupons.map((coupon) => (
            <CouponRow
              key={coupon.id}
              coupon={coupon}
              discountNames={discountNames}
              onToggleActive={this.handleToggleCouponActive}
              onNameSelected={this.handleCouponNameSelected}
            />
          ))}
          {hasWizardDiscounts && (
            <DraftWizardDiscountRow
              discountNames={discountNames}
              order={this.props.order}
              wizardDiscounts={wizardDiscounts}
              updateDiscount={this.props.updateDiscount}
            />
          )}
          <DraftDisplayedWizardDiscountRow
            order={this.props.order}
          />
        </tbody>
      </table>
    );
  }  
}

const mapStateToProps = (state) => ({
  coupons: getActiveCoupons(state),
  discountNames: getActiveDiscountNames(state),
})

export default connect(mapStateToProps, {
  ...CouponActions,
  ...DiscountNameActions,
  ...DiscountActions,
  ...OrderActions,
  openDialog,
})(DraftDiscountsTable)
