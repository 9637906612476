import React from 'react'
import PropTypes from 'react-proptypes'
import { formatUnitType } from '../../utils/formatUtils'
import LineItemMenuButton from '../LineItemMenuButton'
import Label from '../Label'
import './style.styl'

function formatQuantityChange(quantity, quantityInitial) {
  const result = quantity - quantityInitial
  if (Math.sign(result) == 1) {
    return '+' + result
  }
  return result
}

function formatValueColor(value) {
  if (Math.sign(value) == 1) {
    return 'green'
  }
  return 'red'
}

function formatPriceCorrectionInclVat(value) {
  return Math.round(value) + ' kr.'
}

const WizardBuildingLineItemRow = ({
  stepTitle,
  totalItems,
  lineItem,
  allowEditing,
}) => {
  const {
    name,
    humanizedTotalPriceInclVatWithSymbol,
    priceCorrectionInclVat,
    quantity,
    quantityInitial,
    productUnitsPerQuantity,
    unitType,
    addition,
    buildingProperty,
    quantityJustification,
    priceCorrectionJustification,
  } = lineItem

  let rowClassName = 'WizardBuildingLineItemRow'
  if (addition) {
    rowClassName += ' WizardBuildingLineItemRow--addition'
  }
  let priceCorrectionJustificationContent = ''
  if (priceCorrectionJustification) {
    priceCorrectionJustificationContent +=
      'Bemærkning: ' + priceCorrectionJustification
  }
  let priceCorrectionStatistics = ''
  if (priceCorrectionInclVat != 0) {
    priceCorrectionStatistics +=
      'Justeret med: ' + formatPriceCorrectionInclVat(priceCorrectionInclVat)
  }
  let quantityJustificationContent = ''
  if (quantityJustification) {
    quantityJustificationContent += 'Bemærkning: ' + quantityJustification
  }
  let quantityStatistics = ''
  if (quantityInitial && quantity - quantityInitial != 0) {
    quantityStatistics +=
      'Justeret med: ' + formatQuantityChange(quantity, quantityInitial)
  }

  return (
    <tr className={rowClassName}>
      {stepTitle !== null && (
        <th rowSpan={totalItems} className="WizardBuildingLineItemRow__step">
          {stepTitle}
        </th>
      )}
      <td className="WizardBuildingLineItemRow__description">
        {addition && (
          <Label type="faded" className="WizardBuildingLineItemRow__label">
            Muligt tilvalg
          </Label>
        )}
        {name}
        {buildingProperty && (
          <div className="text-muted">{buildingProperty.name}</div>
        )}
      </td>
      <td className="WizardBuildingLineItemRow__quantity">
        {quantity}
        <div
          className={
            formatValueColor(quantity - quantityInitial) + ' statistic'
          }
        >
          {quantityStatistics}
        </div>
        <div className="justification">{quantityJustificationContent}</div>
      </td>
      <td className="WizardBuildingLineItemRow__amount">
        {quantity * productUnitsPerQuantity} {formatUnitType(unitType)}
      </td>
      <td className="WizardBuildingLineItemRow__price">
        {humanizedTotalPriceInclVatWithSymbol}
        <div
          className={formatValueColor(priceCorrectionInclVat) + ' statistic'}
        >
          {priceCorrectionStatistics}
        </div>
        <div className="justification">
          {priceCorrectionJustificationContent}
        </div>
      </td>
      {allowEditing ? <LineItemMenuButton lineItem={lineItem} /> : null}
    </tr>
  )
}

WizardBuildingLineItemRow.propTypes = {
  stepTitle: PropTypes.string,
  totalItems: PropTypes.number,
  allowEditing: PropTypes.bool,
  lineItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    humanizedTotalPriceInclVatWithSymbol: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    productUnitsPerQuantity: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired,
    addition: PropTypes.bool.isRequired,
  }).isRequired,
}

export default WizardBuildingLineItemRow
