import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'

export const getActivityEntities = (state) => state.api.entities.activities
export const getTrackableTypes = (state, props) => props.params.trackableTypes
export const getTrackableId = (state, props) => props.params.trackableId
export const getUsers = (state) => state.api.entities.users

export const getActivities = createEntitiesSelector('activities')

export const getActivitiesForTrackable = createSelector(
  getActivities,
  getTrackableTypes,
  getTrackableId,
  getUsers,
  (activities, type, id, users) =>
    activities
      .filter((a) => a.trackable === id && type.indexOf(a.trackableType) !== -1)
      .map((a) => ({ ...a, user: a.user && users[a.user] }))
)

export const getActivitiesForTrackableReversed = createSelector(
  getActivitiesForTrackable,
  (activities) => activities.slice().reverse()
)
