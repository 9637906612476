import { createSelector } from 'reselect'
import {
  createEntitiesSelector,
  createIsFetchingSelector,
} from './ApiSelectors'

export const getReportTemplateEntities = (state) =>
  state.api.entities.reportTemplates
export const getReportTemplateId = (state, props) => props.params.templateId

export const getReportTemplates = createEntitiesSelector('reportTemplates')
export const isFetching = createIsFetchingSelector('reportTemplates')

export const getReportTemplate = createSelector(
  getReportTemplateId,
  getReportTemplateEntities,
  (id, entities) => entities && entities[id]
)

export const getReportTemplateWithAssessmentCategoriesForPDF = createSelector(
  getReportTemplate,
  createEntitiesSelector('assessmentCategories'),
  (template, categories) => {
    if (template && categories) {
      return {
        ...template,
        assessmentCategoriesForPDF: categories.filter(
          (ac) =>
            // Category is part of template
            template.assessmentCategories.indexOf(ac.id.toString()) !== -1 ||
            // Or template includes energy section and category is energy related
            (template.includeEnergySection && ac.energyRelated)
        ),
      }
    }
    return null
  }
)
