import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'

import { formatUnitType } from '../../utils/formatUtils'

import { Margin, FlexContainer, Flex } from '../Grid'
import ResultCorrectionForm from '../ResultCorrectionForm'

export default class ResultValueCorrections extends Component {
  static propTypes = {
    buildingPropertyResultValue: PropTypes.object.isRequired,
    resultCorrections: PropTypes.array.isRequired,

    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  }

  handleSubmit = (id, attributes) => {
    const { onSubmit } = this.props
    onSubmit(id, attributes)
  }

  handleDelete = (resultCorrectionId) => {
    const { onDelete } = this.props
    if (confirm('Er du sikker på at du vil slette denne korrigering?')) {
      onDelete(resultCorrectionId)
    }
  }

  render() {
    const { buildingPropertyResultValue, resultCorrections } = this.props
    const { name, unitsPerQuantity, unitType } = buildingPropertyResultValue
    const newResultCorrection = {
      buildingProperty: buildingPropertyResultValue.buildingProperty,
      result: buildingPropertyResultValue.id,
    }

    return (
      <div className="ResultValueCorrections">
        <Margin all size={2}>
          <FlexContainer className="ResultValueCorrections__header" horizontal>
            <Flex>
              <h2>{name}</h2>
            </Flex>
            <Flex noGrow>
              <h2>
                {unitsPerQuantity
                  ? `${unitsPerQuantity} ${formatUnitType(unitType)}`
                  : 'Ugyldige mål'}
              </h2>
            </Flex>
          </FlexContainer>

          {unitsPerQuantity ? (
            <div>
              {resultCorrections.map((resultCorrection) => (
                <ResultCorrectionForm
                  key={resultCorrection.id}
                  unitType={unitType}
                  resultCorrection={resultCorrection}
                  onSubmit={this.handleSubmit}
                  onDelete={this.handleDelete}
                />
              ))}
              <ResultCorrectionForm
                key={`new-${resultCorrections.length}`}
                unitType={unitType}
                resultCorrection={newResultCorrection}
                onSubmit={this.handleSubmit}
                onDelete={this.handleDelete}
              />
            </div>
          ) : (
            <p className="text-muted">
              Denne værdi kunne ikke udregnes på baggrund af de indtastede mål.
              <br />
              Tjek at målene er indtastet korrekt.
            </p>
          )}
        </Margin>
      </div>
    )
  }
}
