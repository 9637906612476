import React from 'react'
import { FlexContainer, Flex } from '../Grid'
import './style.styl'

const OrderTreeHeader = () => (
  <FlexContainer horizontal className="order-tree-header">
    <Flex>ID</Flex>
    <Flex className="order-tree-header__date">Dato</Flex>
    <Flex className="order-tree-header__price">Beløb</Flex>
    <Flex className="order-tree-header__state">Status</Flex>
  </FlexContainer>
)

export default OrderTreeHeader
