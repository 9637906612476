import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import {
  fetchCommentsForCommentable,
  createComment,
} from '../actions/CommentActions'

import { getCommentsForCommentable } from '../selectors/CommentSelectors'
import {
  getFetchCountForEntityTypes,
  getActionCountForEntityTypes,
} from '../selectors/ApiSelectors'

import Spinner from '../components/Spinner'
import TextArea from '../components/TextArea'
import Button from '../components/Button'
import Comment from '../components/Comment'
import { Margin } from '../components/Grid'

class NewReportCategoryCommentsContainer extends Component {
  static propTypes = {
    buildingAssessmentCategoryTypeId: PropTypes.string.isRequired,
    comments: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,

    fetchCommentsForCommentable: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { commentContent: '' }
    this.handleInput = this.handleInput.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.saveComment = this.saveComment.bind(this)
  }

  componentWillMount() {
    const { buildingAssessmentCategoryTypeId } = this.props
    this.props.fetchCommentsForCommentable({
      commentableId: buildingAssessmentCategoryTypeId,
      commentableType: 'building-assessment-category-types',
    })
  }

  handleInput({ target: { value } }) {
    this.setState({ commentContent: value })
  }

  handleButtonClick() {
    this.saveComment()
  }

  saveComment() {
    const { buildingAssessmentCategoryTypeId } = this.props
    this.setState({ commentContent: '' })
    this.inputElement.clear()

    this.props.createComment({
      commentableId: buildingAssessmentCategoryTypeId,
      commentableType: 'building-assessment-category-types',
      content: this.state.commentContent,
    })
  }

  render() {
    const { isFetching, isSaving, comments } = this.props

    if (isFetching) {
      return <Spinner center />
    }

    const otherComments = comments.slice()
    const lastComment = otherComments.shift()

    return (
      <div style={{ position: 'relative' }}>
        {lastComment && (
          <div>
            <strong>Seneste kommentar</strong> <em> - vises i rapporten</em>
            <Comment comment={lastComment} />
          </div>
        )}

        <div>
          <TextArea
            ref={(element) => (this.inputElement = element)}
            onChange={this.handleInput}
            placeholder={`Skriv ${
              (lastComment && 'en ny') || 'din'
            } kommentar her`}
          />
          <div className="text-right">
            <Button
              disabled={isSaving}
              borderless={isSaving}
              onClick={this.handleButtonClick}
            >
              {(isSaving && 'Gemmer') || 'Gem'}
            </Button>
          </div>
        </div>

        {otherComments.length !== 0 && (
          <Margin top>
            <strong>Tidligere kommentarer</strong>
            {otherComments.map((c) => (
              <Comment key={c.id} comment={c} />
            ))}
          </Margin>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const selectorProps = {
    params: {
      commentableId: props.buildingAssessmentCategoryTypeId,
      commentableType: 'buildingAssessmentCategoryTypes',
    },
  }
  const isSaving =
    getActionCountForEntityTypes(state, 'updating', 'comments') !== 0

  return {
    ...props,
    comments: getCommentsForCommentable(state, selectorProps).slice().reverse(),
    isFetching: getFetchCountForEntityTypes(state, 'comments') !== 0,
    isSaving,
  }
}

export default connect(mapStateToProps, {
  fetchCommentsForCommentable,
  createComment,
})(NewReportCategoryCommentsContainer)
