import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Select from '../Select'
import Button from '../Button'
import { VerticalAlign, FlexContainer, Flex } from '../Grid'

class MergeOrderPage extends Component {
  static propTypes = {
    isMerging: PropTypes.bool.isRequired,
    onMerge: PropTypes.func.isRequired,
  }

  state = {
    selectedOrder: '',
  }

  handleMergableOrderChange = ({ target }) => {
    this.setState({ selectedOrder: target.value })
  }

  handleMergeClick = () => {
    const { onMerge, targetOrder } = this.props
    const { selectedOrder } = this.state

    onMerge(selectedOrder, targetOrder.id)
  }

  renderSelect() {
    const { mergableOrders } = this.props
    const { selectedOrder } = this.state

    const options = mergableOrders.map((mergableOrder) => ({
      value: mergableOrder.id,
      label: `${mergableOrder.name} (#${mergableOrder.id})`,
    }))

    return (
      <Select
        onChange={this.handleMergableOrderChange}
        value={this.selectedOrder}
        options={[{ value: '', label: 'Vælg tilbud…' }].concat(options)}
      />
    )
  }

  renderButton() {
    const { isMerging } = this.props
    const { selectedOrder } = this.state

    return (
      <Button
        disabled={!selectedOrder || isMerging}
        onClick={this.handleMergeClick}
      >
        {isMerging ? 'Slår sammen…' : 'Slå sammen'}
      </Button>
    )
  }

  renderTargetOrder() {
    const { targetOrder } = this.props
    return (
      <a href={`/#/orders/${targetOrder.id}`}>
        {`${targetOrder.name} (#${targetOrder.id})`}
      </a>
    )
  }

  renderEmptyState() {
    return (
      <VerticalAlign>
        Der blev ikke fundet nogle tilbud, der passer til{' '}
        {this.renderTargetOrder()}.
      </VerticalAlign>
    )
  }

  renderMergeContent() {
    const { targetOrder } = this.props

    return (
      <VerticalAlign>
        Slå tilbud {this.renderSelect()} sammen med {this.renderTargetOrder()} —{' '}
        {this.renderButton()}
      </VerticalAlign>
    )
  }

  render() {
    const { mergableOrders } = this.props

    return (
      <Flex className="text-center">
        {mergableOrders.length
          ? this.renderMergeContent()
          : this.renderEmptyState()}
      </Flex>
    )
  }
}

export default MergeOrderPage
