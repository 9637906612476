import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'

import { formatUnitType } from '../../utils/formatUtils'

import { Margin, FlexContainer, Flex } from '../Grid'
import Input from '../Input'
import TextArea from '../TextArea'
import Button from '../Button'
import ButtonAddon from '../ButtonAddon'

export default class ResultCorrectionForm extends Component {
  static propTypes = {
    unitType: PropTypes.string.isRequired,
    resultCorrection: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const { resultCorrection = {} } = props

    this.state = this.getStateFromResultCorrection(resultCorrection)
  }

  getStateFromResultCorrection(resultCorrection) {
    return {
      buildingPropertyId: resultCorrection.buildingProperty,
      resultId: resultCorrection.result,
      correctionValue: resultCorrection.correctionValue
        ? resultCorrection.correctionValue * 1
        : '',
      justification: resultCorrection.justification || '',
    }
  }

  handleInput = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { onSubmit, resultCorrection } = this.props
    onSubmit(resultCorrection.id, this.state)
  }

  handleDelete = () => {
    const { onDelete, resultCorrection } = this.props
    onDelete(resultCorrection.id)
  }

  hasChanged() {
    const { resultCorrection } = this.props
    const { correctionValue, justification } = this.state

    if (correctionValue * 1 !== resultCorrection.correctionValue * 1)
      return true
    if (justification !== resultCorrection.justification) return true

    return false
  }

  canSubmit() {
    const { correctionValue, justification } = this.state

    if (!/^-?([0-9])+[,.]?[0-9]*$/.test(correctionValue)) return false
    if (!justification) return false
    if (!this.hasChanged()) return false

    return true
  }

  render() {
    const { unitType, resultCorrection } = this.props
    const { correctionValue, justification } = this.state
    const isNew = !resultCorrection.id

    return (
      <form onSubmit={this.handleSubmit}>
        <Margin vertical size={1}>
          <FlexContainer horizontal>
            <Flex className="ResultCorrectionForm__correction-value">
              <FlexContainer horizontal className="btn-group">
                <Flex>
                  <Input
                    className="text-right"
                    placeholder="Justering"
                    type="number"
                    step="0.01"
                    onChange={this.handleInput}
                    name="correctionValue"
                    value={correctionValue}
                  />
                </Flex>
                <Flex noShrink noGrow>
                  <ButtonAddon className="ResultCorrectionForm__unit">
                    {formatUnitType(unitType)}
                  </ButtonAddon>
                </Flex>
              </FlexContainer>
            </Flex>
            <Flex>
              <Margin horizontal size={1}>
                <TextArea
                  placeholder="Begrundelse"
                  onChange={this.handleInput}
                  name="justification"
                  value={justification}
                />
              </Margin>
            </Flex>
            <Flex className="ResultCorrectionForm__actions">
              <FlexContainer horizontal>
                <Flex>
                  <Button block type="submit" disabled={!this.canSubmit()}>
                    {isNew ? 'Opret' : 'Gem'}
                  </Button>
                </Flex>
                <Flex>
                  <Margin left size={1}>
                    <Button block onClick={this.handleDelete} invisible={isNew}>
                      Slet
                    </Button>
                  </Margin>
                </Flex>
              </FlexContainer>
            </Flex>
          </FlexContainer>
        </Margin>
      </form>
    )
  }
}
