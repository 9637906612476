import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Input from '../Input'
import Button from '../Button'
import { FormField } from '../Form'
import Alert from '../Alert'

class LoginPage extends Component {
  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    isAuthenticating: PropTypes.bool,
    error: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      email: props.email || '',
      password: props.password || '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()

    const { onLogin } = this.props
    onLogin(this.state)
  }

  handleInputChange({ target }) {
    this.setState({
      [target.name]: target.value,
    })
  }

  render() {
    const { isAuthenticating, error } = this.props
    const { email, password } = this.state

    return (
      <div className="login-page">
        <form onSubmit={this.handleSubmit} className="login-page__form">
          {error ? <Alert type="danger">{error}</Alert> : null}
          <FormField>
            <Input
              type="email"
              name="email"
              placeholder="E-mail"
              disabled={isAuthenticating}
              value={email}
              onChange={this.handleInputChange}
            />
          </FormField>
          <FormField>
            <Input
              type="password"
              name="password"
              placeholder="Adgangskode"
              disabled={isAuthenticating}
              value={password}
              onChange={this.handleInputChange}
            />
            <div className="text-right">
              <a
                href={`/#/password-reset?email=${email}`}
                className="text-small"
              >
                Glemt adgangskode?
              </a>
            </div>
          </FormField>
          <Button block type="submit" disabled={isAuthenticating}>
            {isAuthenticating ? 'Logger ind' : 'Log ind'}
          </Button>
        </form>
      </div>
    )
  }
}

export default LoginPage
