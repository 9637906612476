import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import omit from 'lodash/omit'
import classNames from 'classnames'
import PanelBody from './PanelBody'

const Panel = (props) => {
  const { children, className, includeBody } = props
  const classes = classNames('panel', className)
  const otherProps = omit(props, 'className', 'includeBody')

  return (
    <div className={classes} {...otherProps}>
      {includeBody ? <PanelBody>{children}</PanelBody> : children}
    </div>
  )
}

Panel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  includeBody: PropTypes.bool.isRequired,
}

Panel.defaultProps = {
  includeBody: false,
}

export default Panel
export { PanelBody }
