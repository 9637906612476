import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Button from '../Button'
import Icon from '../Icon'

export default class OrderPageActions extends Component {
  static propTypes = {
    onOpenPDFClick: PropTypes.func.isRequired,
    onOpenConditionsPDFClick: PropTypes.func.isRequired,
    onOpenGuaranteesPDFClick: PropTypes.func.isRequired,
    onPrintClick: PropTypes.func.isRequired,
    onRejectClick: PropTypes.func.isRequired,
    onApproveClick: PropTypes.func.isRequired,
    onFollowUpClick: PropTypes.func.isRequired,
    onNewCommentClick: PropTypes.func.isRequired,
    onRateTrustpilotClick: PropTypes.func.isRequired,
    onExportToAxClick: PropTypes.func.isRequired,
    onReviewClick: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    onArchiveClick: PropTypes.func.isRequired,
    onOpenConfirmationPDFClick: PropTypes.func.isRequired,
    onOpenOrderWorklistPDFClick: PropTypes.func.isRequired,
    onResendConfirmationClick: PropTypes.func.isRequired,
    onPrintDetailsClick: PropTypes.func.isRequired,
    onPrintWorksheetClick: PropTypes.func.isRequired,
    onShowSignatureClick: PropTypes.func.isRequired,
    onResetOrderClick: PropTypes.func.isRequired,
    onProposeOrderClick: PropTypes.func.isRequired,
    onMergeOrderClick: PropTypes.func.isRequired,
    onManagerFollowUpClick: PropTypes.func.isRequired,
    orderState: PropTypes.string.isRequired,
    canManageOrder: PropTypes.bool.isRequired,
    canDisableReminders: PropTypes.bool.isRequired,
    orderRemindersEnabled: PropTypes.bool.isRequired,
    onDisableRemindersClick: PropTypes.func.isRequired,
    isCopying: PropTypes.bool.isRequired,
    canCopyOrder: PropTypes.bool.isRequired,
    canArchiveOrder: PropTypes.bool.isRequired,
    canShowSignature: PropTypes.bool.isRequired,
    canResetOrder: PropTypes.bool.isRequired,
    canMergeOrder: PropTypes.bool.isRequired,
    onCancelOrderClick: PropTypes.func.isRequired,
  }

  renderProposedActions() {
    const {
      onApproveClick,
      onFollowUpClick,
      onRejectClick,
      onArchiveClick,
      onCopyClick,
      isCopying,
      canCopyOrder,
      canArchiveOrder,
    } = this.props

    return (
      <div>
        <Button block active onClick={onFollowUpClick}>
          Følg op
          <Icon
            icon="email"
            size={22}
            className="order-actions__follow-up-icon"
          />
        </Button>
        <Button block active onClick={onApproveClick}>
          Godkend
        </Button>
        <Button block active onClick={onRejectClick}>
          Afvis
        </Button>
        {canCopyOrder && (
          <Button block active disabled={isCopying} onClick={onCopyClick}>
            {isCopying ? 'Kopierer...' : 'Kopiér'}
          </Button>
        )}
        <Button
          block
          active
          disabled={!canArchiveOrder}
          onClick={onArchiveClick}
        >
          Arkivér
          {canArchiveOrder || (
            <span className="btn_disclaimer">
              En ordre kan kun arkiveres hvis der er andre ikke-arkiverede
              ordrevarianter
            </span>
          )}
        </Button>
      </div>
    )
  }

  canOpenConfirmationPDF(state) {
    return state === 'reviewed_accepted' || state === 'exported_to_ax'
  }

  canPrintWorksheet(state) {
    return state === 'reviewed_accepted' || state === 'exported_to_ax'
  }

  canResendConfirmationEmail(state) {
    return state === 'reviewed_accepted' || state === 'exported_to_ax'
  }

  render() {
    const {
      canMergeOrder,
      onOpenPDFClick,
      onOpenConditionsPDFClick,
      onOpenGuaranteesPDFClick,
      onOpenConfirmationPDFClick,
      onResendConfirmationClick,
      onOpenOrderWorklistPDFClick,
      onPrintClick,
      onPrintWorksheetClick,
      onNewCommentClick,
      onRateTrustpilotClick,
      onExportToAxClick,
      onReviewClick,
      onPrintDetailsClick,
      orderState,
      canManageOrder,
      canShowSignature,
      canResetOrder,
      canDisableReminders,
      orderRemindersEnabled,
      onDisableRemindersClick,
      onShowSignatureClick,
      onResetOrderClick,
      onProposeOrderClick,
      onMergeOrderClick,
      onManagerFollowUpClick,
      onCancelOrderClick,
    } = this.props

    return (
      <div className="order-actions hide-print">
        {orderState === 'draft' && canManageOrder && (
          <Button block onClick={onProposeOrderClick}>
            Færdiggør tilbud
          </Button>
        )}
        {orderState === 'proposed' && this.renderProposedActions()}
        {orderState === 'accepted' && canManageOrder && (
          <Button block active onClick={onReviewClick}>
            Gennemse tilbud
          </Button>
        )}
        {orderState === 'reviewed_accepted' && canManageOrder && (
          <Button block active onClick={onExportToAxClick}>
            Læg i AX
          </Button>
        )}
        {orderState === 'exported_to_ax' && canManageOrder && (
          <Button block active onClick={onManagerFollowUpClick}>
            Opfølgende mail
          </Button>
        )}
        <Button block onClick={onOpenPDFClick}>
          Åben PDF
        </Button>
        <Button block onClick={onOpenPDFClick.bind(null, true)}>
          Åben PDF med logo
        </Button>
        <Button block onClick={onOpenConditionsPDFClick}>
          Åben betingelser
        </Button>
        <Button block onClick={onOpenGuaranteesPDFClick}>
          Åben garantier
        </Button>
        {this.canOpenConfirmationPDF(orderState) && canManageOrder && (
          <Button block onClick={onOpenConfirmationPDFClick}>
            Åben ordrebekræftelse
          </Button>
        )}
        {this.canResendConfirmationEmail(orderState) && canManageOrder && (
          <Button block onClick={onResendConfirmationClick}>
            Gensend ordrebekræftelse
          </Button>
        )}
        {canManageOrder && (
          <Button block onClick={onOpenOrderWorklistPDFClick}>
            Håndværkerliste
          </Button>
        )}
        {canManageOrder && (
          <Button block onClick={onPrintDetailsClick}>
            Udskriv detaljer
          </Button>
        )}
        <Button block onClick={onPrintClick}>
          Print
        </Button>
        {this.canPrintWorksheet(orderState) && canManageOrder && (
          <Button block onClick={onPrintWorksheetClick}>
            Print arbejdsdokument
          </Button>
        )}
        {orderState !== 'proposed' && (
          <Button block onClick={onNewCommentClick}>
            Tilføj note
          </Button>
        )}
        {canShowSignature && (
          <Button block onClick={onShowSignatureClick}>
            Vis underskrift/dokumentation
          </Button>
        )}
        {canResetOrder && (
          <Button block onClick={onResetOrderClick}>
            Nulstil status
          </Button>
        )}
        {canMergeOrder && (
          <Button block onClick={onMergeOrderClick}>
            Slå sammen med andet tilbud
          </Button>
        )}
        {orderState === 'exported_to_ax' && canManageOrder && (
          <Button block active onClick={onCancelOrderClick}>
            Annuller ordre
          </Button>
        )}

        <Button block onClick={onRateTrustpilotClick}>
          Bedøm besøg
        </Button>

        {canDisableReminders && (
          <Button block onClick={onDisableRemindersClick} disabled={!orderRemindersEnabled}>
            { orderRemindersEnabled ? 'Slå påmindelser fra' : 'Påmindelser er slået fra' }
          </Button>
        )}
      </div>
    )
  }
}
