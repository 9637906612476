import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { push } from 'react-router-redux'
import pick from 'lodash/pick'

import { getOrder } from '../selectors/OrderSelectors'
import { getOrderUserBuildingsWithBuildingImages } from '../selectors/WizardBuildingSelectors'
import { getWizards } from '../selectors/WizardSelectors'

import * as OrderActions from '../actions/OrderActions'
import * as BuildingActions from '../actions/BuildingActions'
import * as WizardActions from '../actions/WizardActions'
import * as WizardBuildingActions from '../actions/WizardBuildingActions'
import { openDialog } from '../actions/DialogActions'

import { Row, Column, Margin } from '../components/Grid'
import Page, { PageHeader, PageContent } from '../components/Page'
import WizardBuildingForm from '../components/WizardBuildingForm'

class NewWizardBuildingContainer extends Component {
  static propTypes = {
    orderId: PropTypes.string.isRequired,
    order: PropTypes.shape({
      customer: PropTypes.shape({
        buildings: PropTypes.array.isRequired,
      }),
    }),
    wizards: PropTypes.array.isRequired,
    push: PropTypes.func.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    fetchWizards: PropTypes.func.isRequired,
    createWizardBuilding: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { fetchWizards } = this.props
    fetchWizards()
    this.fetchOrder()
  }

  componentDidUpdate(oldProps) {
    const { orderId } = this.props
    if (orderId === oldProps.orderId) return
    this.fetchOrder()
  }

  fetchOrder() {
    const { orderId, fetchOrder } = this.props
    if (orderId)
      fetchOrder(orderId, { query: { include: ['customer.buildings'] } })
  }

  handleSubmit = (values) => {
    const { createWizardBuilding, orderId, push } = this.props
    const promise = createWizardBuilding(values)
    promise.then(({ results }) => {
      push(`/drafts/${orderId}/wizard-buildings/${results.wizardBuildings[0]}`)
    })
  }

  openNewBuildingDialog = () => {
    const { order, openDialog } = this.props
    const { customer } = order || {}
    const { buildings = [] } = customer || {}

    let initialValues
    if (buildings.length === 0) {
      initialValues = {
        name: 'Hovedhus',
        ...pick(customer, [
          'addressStreetName',
          'addressStreetNumber',
          'addressZipCode',
          'addressCityName',
          'addressCountryName',
        ]),
      }
    }
    openDialog('createBuilding', {
      customerId: order.customer.id,
      initialValues,
    })
  }

  render() {
    const { orderId, order, wizards } = this.props
    const { customer } = order || {}
    const { buildings = [] } = customer || {}

    const initialValues = {
      orderId,
    }

    return (
      <Page>
        <PageHeader>
          <div className="page__title">
            <Link to="/drafts/per-customer">Opret tilbud</Link>
            {' > '}
            <Link to={`/drafts/${orderId}`}>Kladde #{orderId}</Link>
            {' > '}
            <span>Tilføj tilbudspakke</span>
          </div>
        </PageHeader>
        <PageContent>
          <Margin bottom size={4}>
            <Row>
              <Column size={10} offset={1}>
                <WizardBuildingForm
                  buildings={buildings}
                  wizards={wizards}
                  initialValues={initialValues}
                  handleSubmit={this.handleSubmit}
                  onBuildingSelect={this.handleBuildingSelect}
                  onWizardSelect={this.handleWizardSelect}
                  onNewBuildingButtonClick={this.openNewBuildingDialog}
                />
              </Column>
            </Row>
          </Margin>
        </PageContent>
      </Page>
    )
  }
}

const mapStateToProps = (state, props) => ({
  orderId: props.params.orderId,
  order: getOrder(state, { params: { orderId: props.params.orderId } }, [
    'customer.buildings',
  ]),
  buildings: getOrderUserBuildingsWithBuildingImages(state, {
    params: { orderId: props.params.orderId },
  }),
  wizards: getWizards(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      openDialog,
      ...OrderActions,
      ...BuildingActions,
      ...WizardActions,
      ...WizardBuildingActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewWizardBuildingContainer)
