import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'

export const getCommentEntities = (state) => state.api.entities.comments
export const getCommentId = (state, props) => props.params.commentId
export const getCommentableType = (state, props) => props.params.commentableType
export const getCommentableId = (state, props) => props.params.commentableId

export const getComments = createEntitiesSelector('comments')

export const getCommentsForCommentable = createSelector(
  getComments,
  getCommentableType,
  getCommentableId,
  (state) => state.api.entities.users,
  (comments, type, id, users) =>
    comments
      .filter((c) => c.commentableType === type && c.commentable === id)
      .map((c) => ({ ...c, user: users[c.user] }))
)

export const getComment = createSelector(
  getCommentId,
  getCommentEntities,
  (id, entities) => entities && entities[id]
)
