import { createReducer } from '../utils/reducerUtils'
import * as types from '../constants/ActionTypes'

const initialState = {
  initialized: false,
  isInitializing: true,
  menuVisible: true,
}

export default createReducer(initialState, {
  [types.INITIALIZE_REQUEST](state) {
    return { ...state, isInitializing: true }
  },
  [types.INITIALIZE_SUCCESS](state) {
    return { ...state, isInitializing: false, initialized: true }
  },
  [types.INITIALIZE_FAILURE](state) {
    return { ...state, isInitializing: false, initialized: true }
  },

  [types.HIDE_MENU](state) {
    return { ...state, menuVisible: false }
  },
  [types.SHOW_MENU](state) {
    return { ...state, menuVisible: true }
  },
})
