import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { getCurrentUser } from '../../selectors/AuthSelectors'
import * as UserActions from '../../actions/UserActions'
import { normalizePhoneNumber } from '../../utils/normalizeUtils'
import { formatResponseErrors } from '../../utils/formatUtils'
import { reduxForm } from 'redux-form'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog'
import Input from '../../components/Input'
import SignatureInput from '../../components/SignatureInput'
import SettingsList, { SettingsListItem } from '../../components/SettingsList'

const normalizeAttributes = (attrs) => ({
  ...attrs,
  phone: normalizePhoneNumber(attrs.phone),
})

class UserSettingsDialog extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit(attributes) {
    const { userId, updateUser, onClose } = this.props

    return updateUser(userId, normalizeAttributes(attributes))
      .catch((errors) => Promise.reject(formatResponseErrors(errors)))
      .then(onClose)
  }

  render() {
    const {
      fields: { name, email, phone, signature },
      onClose,
      dirty,
      submitting,
      handleSubmit,
    } = this.props

    const disabled = submitting || !dirty

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>Indstillinger</DialogTitle>
          <DialogButton
            disabled={disabled}
            onClick={handleSubmit(this.handleFormSubmit)}
          >
            {submitting ? 'Gemmer...' : 'Gem'}
          </DialogButton>
        </DialogHeader>
        <form
          ref={(element) => (this.formElement = element)}
          onSubmit={handleSubmit(this.handleFormSubmit)}
        >
          <SettingsList>
            <SettingsListItem label="Navn">
              <Input {...name} autoFocus />
            </SettingsListItem>
            <SettingsListItem label="E-mail">
              <Input type="email" {...email} />
            </SettingsListItem>
          </SettingsList>
          <SettingsListItem label="Tlf. nr.">
            <Input {...phone} />
          </SettingsListItem>
          <SettingsListItem label="Underskrift">
            <SignatureInput {...signature} />
          </SettingsListItem>
          <button
            disabled={disabled}
            tabIndex="-1"
            type="submit"
            style={{
              position: 'absolute',
              left: '-99999px',
              width: '1px',
              height: '1px',
            }}
          />
        </form>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  const user = getCurrentUser(state)

  return {
    userId: user.id,
    initialValues: {
      ...user,
      signature: user.signatureUrl,
    },
  }
}

const mapComponentToReduxForm = (component) =>
  reduxForm({
    form: 'userSettings',
    fields: ['name', 'email', 'phone', 'signature'],
  })(component)

export default connect(mapStateToProps, {
  ...UserActions,
})(mapComponentToReduxForm(UserSettingsDialog))
