import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import pick from 'lodash/pick'

const Checkbox = (props) => {
  const otherProps = pick(props, [
    'name',
    'value',
    'checked',
    'onChange',
    'onInput',
    'style',
  ])
  const classes = classNames('form-checkbox', props.className)

  return <input {...otherProps} type="checkbox" className={classes} />
}

Checkbox.propTypes = {
  className: PropTypes.string,
}

export default Checkbox
