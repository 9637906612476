import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { Row, Column } from '../Grid'
import { BuildingFormField } from '../BuildingFormField'
import SettingsList, { SettingsListItem } from '../SettingsList'
import Input from '../Input'
import BuildingFormInput from '../BuildingFormInput'
import Button from '../Button'

export default class BuildingAddressForm extends Component {
  static fields = [
    'name',
    'addressStreetName',
    'addressStreetNumber',
    'addressZipCode',
    'addressCityName',
    'addressCountryName',
  ]

  static propTypes = {
    submitting: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: false,
  }

  submit() {
    this.button.dispatchEvent(new MouseEvent('click'))
  }

  render() {
    const {
      fields: {
        name,
        addressStreetName,
        addressStreetNumber,
        addressZipCode,
        addressCityName,
        addressCountryName,
      },
      handleSubmit,
      submitting,
    } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <SettingsList>
          <SettingsListItem label="Navn">
            <Input
              type="text"
              placeholder="Fe.ks. Hovedhus, Sommerhus eller Carport"
              disabled={submitting}
              autoFocus
              {...name}
            />
          </SettingsListItem>
          <SettingsListItem label="Gade">
            <Row>
              <Column size={8}>
                <Input
                  type="text"
                  placeholder="Gadenavn"
                  disabled={submitting}
                  {...addressStreetName}
                />
              </Column>
              <Column size={4}>
                <Input
                  type="text"
                  placeholder="Gadenr."
                  disabled={submitting}
                  {...addressStreetNumber}
                />
              </Column>
            </Row>
          </SettingsListItem>
          <SettingsListItem label="By">
            <Row>
              <Column size={4}>
                <Input
                  type="text"
                  placeholder="Postnummer"
                  disabled={submitting}
                  {...addressZipCode}
                />
              </Column>
              <Column size={8}>
                <Input
                  type="text"
                  placeholder="By"
                  disabled={submitting}
                  {...addressCityName}
                />
              </Column>
            </Row>
          </SettingsListItem>
          <SettingsListItem label="Land">
            <Input type="text" disabled={submitting} {...addressCountryName} />
          </SettingsListItem>
          <button
            ref={(element) => (this.button = element)}
            type="submit"
            style={{ display: 'none' }}
          />
        </SettingsList>
      </form>
    )
  }
}
