import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { updateOrder } from '../../actions/OrderActions'
import { fetchActivitiesForTrackable } from '../../actions/ActivityActions'
import { getOrder } from '../../selectors/OrderSelectors'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import { Margin } from '../../components/Grid'
import TextArea from '../../components/TextArea'
import Button from '../../components/Button'

class OrderEditFieldDialog extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    order: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.order[props.field] || '',
      submitting: false,
    }
    this.handleContentChange = this.handleContentChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  handleContentChange({ target: { value } }) {
    this.setState({ value })
  }

  handleButtonClick() {
    const { value } = this.state
    const { orderId, onClose, field } = this.props

    this.setState({ submitting: true })

    this.props
      .updateOrder(orderId, { [field]: value })
      // Close and reload order activities
      .then(() =>
        Promise.all([
          this.props.fetchActivitiesForTrackable({
            trackableTypes: ['orders'],
            trackableId: orderId,
          }),
          onClose(),
        ])
      )
      .catch(() => this.setState({ submitting: false }))
  }

  render() {
    const { onClose, title, field, order } = this.props
    const { submitting, inputs, value } = this.state
    const disabled = submitting || value === order[field]

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={submitting}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <Margin all>
          <TextArea
            value={value}
            name={field}
            onChange={this.handleContentChange}
          />
        </Margin>
        <Margin all className="text-right">
          <Button disabled={disabled} onClick={this.handleButtonClick}>
            {(submitting && 'Gemmer...') || 'Gem'}
          </Button>
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  order: getOrder(state, { params: { orderId: props.orderId } }),
})

export default connect(mapStateToProps, {
  updateOrder,
  fetchActivitiesForTrackable,
})(OrderEditFieldDialog)
