import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Button from '../Button'

export default class ReportSendButton extends Component {
  static propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    block: false,
    type: 'button',
    disabled: false,
    active: false,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { value: '' }
  }

  componentWillMount() {
    if (!this.state.value) {
      this.setState({ value: this.props.defaultValue })
    }
  }

  handleChange({ target: { value } }) {
    this.setState({ value })
  }

  handleClick() {
    this.props.onClick(this)
  }

  render() {
    const { disabled, className, children, defaultValue } = this.props

    return (
      <div className="report-send-button">
        <input
          className="report-send-button__input"
          type="text"
          defaultValue={defaultValue}
          onChange={this.handleChange}
          placeholder="Indtast kundens email"
        />
        <Button
          disabled={disabled || !this.state.value}
          className={className}
          className="report-send-button__btn"
          onClick={this.handleClick}
          children={children}
        />
      </div>
    )
  }
}
