import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import omit from 'lodash/omit'
import classNames from 'classnames'
import { Link } from 'react-router'
import OrderTreeHeader from '../OrderTreeHeader'
import OrderTreeItem from '../OrderTreeItem'

const OrderTree = (props) => {
  const { className, tree, currentId, first } = props
  const classes = classNames('order-tree', className, {
    'order-tree--current': currentId === tree.id,
  })
  return (
    <ul
      className={classes}
      {...omit(props, 'className', 'tree', 'currentId', 'first')}
    >
      {first && (
        <li>
          <OrderTreeHeader />
        </li>
      )}
      <li>
        {currentId === tree.id ? (
          <OrderTreeItem orderTreeOrder={tree} />
        ) : (
          <Link
            to={`/${tree.state === 'draft' ? 'drafts' : 'orders'}/${tree.id}`}
          >
            <OrderTreeItem orderTreeOrder={tree} />
          </Link>
        )}
        {tree.children.map((c) => (
          <OrderTree key={c.id} tree={c} currentId={currentId} first={false} />
        ))}
      </li>
    </ul>
  )
}

OrderTree.propTypes = {
  className: PropTypes.string,
  tree: PropTypes.object,
  currentId: PropTypes.string,
  first: PropTypes.bool,
}

OrderTree.defaultProps = {
  first: true,
}

export default OrderTree
