import React from 'react'
import PropTypes from 'react-proptypes'
import Panel from '../Panel'

const EmptyPanel = ({ children }) => (
  <Panel className="empty-panel text-center" includeBody>
    {children !== undefined
      ? children
      : 'Der blev ikke fundet nogle resultater'}
  </Panel>
)

EmptyPanel.propTypes = {
  children: PropTypes.any,
}

export default EmptyPanel
