import React from 'react'
import PropTypes from 'react-proptypes'
import Dialog, { DialogHeader, DialogTitle, DialogButton } from '../../Dialog'
import './style.styl'
import { formatDate } from '../../../utils/formatUtils'
import { Margin } from '../../Grid'

const renderLink = (url) => {
  return (
    <a target="_blank" href={url}>
      <img
        className="signature-dialog__image"
        alt="underskrift/dokumentation"
        src={url}
      />
    </a>
  )
}

const mayRenderCustomerSignature = (order) => {
  if (!order.customerSignatureUrl) return null

  return (
    <Margin vertical size={2}>
      <Margin vertical size={1}>
        <b>Customer signature:</b>
      </Margin>
      {renderLink(order.customerSignatureUrl)}
    </Margin>
  )
}

const mayRenderAcceptanceDocument = (order) => {
  if (!order.acceptanceDocumentUrl) return null

  return (
    <Margin vertical size={2}>
      <Margin vertical size={1}>
        <b>Acceptance Document:</b>
      </Margin>
      {renderLink(order.acceptanceDocumentUrl)}
    </Margin>
  )
}

const mayRenderIP = (order) => {
  if (!order.signedWithIpAddress) return null

  return (
    <Margin vertical size={2}>
      <Margin vertical size={1}>
        <b>Signed with IP address:</b>
      </Margin>
      {order.signedWithIpAddress}
    </Margin>
  )
}

const customerInformation = (customer) => (
  <div>
    {customer.name}
    <br />
    {customer.addressStreetName} {customer.addressStreetNumber}
    {customer.addressAdditionalInfo
      ? `, ${customer.addressAdditionalInfo}`
      : null}
    <br />
    {customer.addressZipCode} {customer.addressCityName}
  </div>
)

const orderNumber = (order) => <div>Tilbud #{order.id}</div>

const acceptanceDate = (order) => (
  <div className="signature-dialog__acceptance-date">
    {formatDate(order.acceptedAt, 'j. F Y, H:i')}
  </div>
)

const AcceptanceDialog = (props) => {
  const { order, onClose } = props
  return (
    <Dialog className="signature-dialog">
      <DialogHeader>
        <DialogTitle>Underskrift/dokumentation</DialogTitle>
        <DialogButton onClick={onClose}>Ok</DialogButton>
      </DialogHeader>
      <Margin all size={2}>
        {customerInformation(order.customer)}
        {orderNumber(order)}
        {mayRenderCustomerSignature(order)}
        {mayRenderAcceptanceDocument(order)}
        {mayRenderIP(order)}
        {acceptanceDate(order)}
      </Margin>
    </Dialog>
  )
}

AcceptanceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  order: PropTypes.shape({
    customerSignatureUrl: PropTypes.string,
    acceptanceDocumentUrl: PropTypes.string,
    acceptedAt: PropTypes.string,
  }).isRequired,
}

export default AcceptanceDialog
