import 'blueimp-canvas-to-blob'
import loadImage from 'blueimp-load-image'

const loadImagePromise = (image, options) =>
  new Promise((resolve, reject) => {
    loadImage(image, resolve, options)
  })

export const compressCanvas = (canvas, options) => {
  const mimeType = options.mimeType || 'image/jpeg'
  const quality = options.hasOwnProperty('quality') ? options.quality : 1

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(resolve, mimeType, quality)
    } catch (e) {
      reject(e)
    }
  })
}

export const compressImage = (imageFile, options = {}) =>
  loadImagePromise(imageFile, { ...options, orientation: true, canvas: true })
    .then((canvas) => compressCanvas(canvas, options))
    .then((blob) => {
      blob.name = imageFile.name
      return blob
    })

export const compressImages = (imageFiles, options = {}) => {
  let array = imageFiles
  if (!Array.isArray(imageFiles) && !FileList.prototype.isPrototypeOf(array)) {
    array = [array]
  }

  const promises = []

  for (let index = 0; index < array.length; index++) {
    promises.push(compressImage(array[index], options))
  }

  return Promise.all(promises)
}
