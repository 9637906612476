import React from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'
import { Column } from '../Grid'
import Icon from '../Icon'

export const BuildingImage = ({ image, tagEntities, size, onDelete }) => (
  <Column className="building-image" size={size || 3}>
    <a href={image.imageUrl} target="_blank">
      <div
        className="building-image__image"
        style={{ backgroundImage: `url('${image.thumbnailImageUrl}')` }}
      />
      {tagEntities && (
        <div className="building-image__name">
          {
            (tagEntities[image.imageTag] || { name: 'Billedeinfo mangler' })
              .name
          }
        </div>
      )}
    </a>
    {onDelete && (
      <Icon
        icon="cross"
        className="building-image__delete"
        onClick={() => onDelete(image.id)}
      />
    )}
  </Column>
)

BuildingImage.propTypes = {
  image: PropTypes.object.isRequired,
  tagEntities: PropTypes.object,
  onDelete: PropTypes.func,
  size: PropTypes.number,
}

export default BuildingImage
