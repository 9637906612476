import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { updateOrder } from '../../actions/OrderActions'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog'
import { SettingsListItem } from '../../components/SettingsList'
import Input from '../../components/Input'
import Alert from '../../components/Alert'

class AdditionalDiscountDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      discountValue: props.discount,
      totalPrice: Math.round(
        (props.totalPrice - props.discount - props.subsidy) * 1.25
      ),
      discount: Math.round(props.discount * 1.25),
      error: null,
      valid: true,
      changed: false,
    }
  }

  componentDidMount() {
    this.focusDiscountInput()
  }

  validate = () => {
    const { discountValue } = this.state

    this.setState({
      valid: !isNaN(discountValue),
      changed: Math.round(discountValue) !== Math.round(this.props.discount),
    })
  }

  handleDiscountChange = ({ target }) => {
    const { value, valueAsNumber } = target
    const { totalPrice, subsidy } = this.props
    let discount = isNaN(valueAsNumber)
      ? this.props.discount * 1.25
      : valueAsNumber

    this.setState(
      {
        discount: value,
        discountValue: discount,
        totalPrice: Math.round((totalPrice - subsidy) * 1.25 - discount),
      },
      this.validate
    )
  }

  handleSave = (event) => {
    const { orderId, onClose } = this.props
    const { discountValue, valid, changed } = this.state

    if (event) {
      event.preventDefault()
    }

    if (!valid || !changed) {
      return false
    }

    this.props
      .updateOrder(orderId, {
        additionalDiscountOerer: Math.round(discountValue * 0.8 * 100),
      })
      .then(onClose)
      .catch(() => this.handleSaveError())
  }

  handleSaveError() {
    this.setState({ error: 'Der opstod en fejl' })
  }

  focusDiscountInput() {
    const container = findDOMNode(this)
    const input = container.querySelector('input')

    if (input) {
      input.focus()
      input.select()
    }
  }

  handleTotalPriceChange = ({ target }) => {
    const { value, valueAsNumber } = target
    const { discount } = this.state
    const { totalPrice, subsidy } = this.props

    const priceWithSubsidy = (totalPrice - subsidy) * 1.25

    const newDiscount = isNaN(valueAsNumber)
      ? discount
      : priceWithSubsidy - valueAsNumber

    this.setState(
      {
        totalPrice: value,
        discount: Math.round(newDiscount),
        discountValue: newDiscount,
      },
      this.validate
    )
  }

  render() {
    const { onClose, isSubmitting } = this.props
    const { discount, changed, valid, error } = this.state

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={isSubmitting}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>Juster tilbudspris</DialogTitle>
          <DialogButton
            onClick={this.handleSave}
            disabled={isSubmitting || !valid || !changed}
          >
            {isSubmitting ? 'Gemmer' : 'Gem'}
          </DialogButton>
        </DialogHeader>
        <form onSubmit={this.handleSave}>
          {error ? <Alert type="danger">{error}</Alert> : null}
          <SettingsListItem label="Ny pris i kr.">
            <Input
              type="number"
              step="100"
              value={this.state.totalPrice}
              disabled={isSubmitting}
              onChange={this.handleTotalPriceChange}
            />
          </SettingsListItem>
          <SettingsListItem label="Nedjustering i kr.">
            <Input
              type="number"
              step="100"
              disabled={isSubmitting}
              value={discount}
              onChange={this.handleDiscountChange}
            />
          </SettingsListItem>
          <button type="submit" style={{ display: 'none' }} />
        </form>
      </Dialog>
    )
  }
}

AdditionalDiscountDialog.propTypes = {
  orderId: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  subsidy: PropTypes.number.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const {
    api: { isUpdating },
  } = state
  const { orderId } = ownProps
  const updatingOrders = isUpdating.orders || []

  return {
    isSubmitting: updatingOrders.indexOf(orderId) !== -1,
  }
}

export default connect(mapStateToProps, {
  updateOrder,
})(AdditionalDiscountDialog)

export { AdditionalDiscountDialog }
