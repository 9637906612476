import React, { Component } from 'react'
import PropTypes from 'react-proptypes'

import Button from '../Button'
import SelectableCellGroup from '../../components/SelectableCellGroup'
import SelectableCell from '../../components/SelectableCell'
import OrderWizardStepTitle from '../../components/OrderWizard/StepTitle'
import { Row } from '../Grid'
import Cell from '../Cell'
import Icon, { dataUrlForIcon } from '../Icon'
import { formatAddressLine } from '../../utils/formatUtils'

class WizardBuildingForm extends Component {
  static propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onNewBuildingButtonClick: PropTypes.func.isRequired,
    buildings: PropTypes.array.isRequired,
    wizards: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
  }

  static defaultProps = {
    submitting: false,
  }

  constructor(props) {
    super(props)
    this.state = props.initialValues
  }

  onBuildingSelect(buildingId, selected) {
    if (selected) {
      this.setState({ buildingId })
    } else {
      this.setState({ buildingId: undefined })
    }
  }

  onWizardSelect(wizardId, selected) {
    if (selected) {
      this.setState({ wizardId })
    } else {
      this.setState({ wizardId: undefined })
    }
  }

  valid() {
    return this.state.buildingId && this.state.wizardId
  }

  handleSubmit = (e) => {
    const { handleSubmit } = this.props

    e.preventDefault()
    handleSubmit(this.state)
  }

  buildingImageOrPlaceholder(building) {
    if (!building.buildingImages.length) {
      return dataUrlForIcon({ icon: 'home', viewBox: '-128 -128 768 768' })
    }

    return building.buildingImages[0].thumbnailImageUrl
  }

  renderBuilding = (building) => {
    return (
      <SelectableCell
        key={building.id}
        onClick={(selected) => this.onBuildingSelect(building.id, selected)}
        imageUrl={this.buildingImageOrPlaceholder(building)}
      >
        <p>{building.name}</p>
        <p className="text-muted text-small">{formatAddressLine(building)}</p>
      </SelectableCell>
    )
  }

  renderWizard = (wizard) => {
    return (
      <SelectableCell
        key={wizard.id}
        onClick={(selected) => this.onWizardSelect(wizard.id, selected)}
        imageUrl={wizard.thumbnailImageUrl}
      >
        <p>{wizard.name}</p>
      </SelectableCell>
    )
  }

  render() {
    const { submitting, buildings, wizards, onNewBuildingButtonClick } =
      this.props

    const canSubmit = !submitting && this.valid()

    return (
      <form onSubmit={this.handleSubmit}>
        <OrderWizardStepTitle>Vælg bygning</OrderWizardStepTitle>
        <Row>
          <SelectableCellGroup>
            {buildings ? buildings.map(this.renderBuilding) : undefined}
          </SelectableCellGroup>
          <Cell
            onClick={(e) => {
              e.preventDefault()
              onNewBuildingButtonClick()
            }}
            imageUrl={dataUrlForIcon({
              icon: 'plus',
              viewBox: '-128 -128 768 768',
            })}
          >
            <p>Ny bygning</p>
          </Cell>
        </Row>
        <OrderWizardStepTitle>Vælg tilbudspakke</OrderWizardStepTitle>
        <Row>
          <SelectableCellGroup>
            {wizards ? wizards.map(this.renderWizard) : undefined}
          </SelectableCellGroup>
        </Row>
        <Button type="submit" disabled={!canSubmit}>
          Gem
        </Button>
      </form>
    )
  }
}

export default WizardBuildingForm
