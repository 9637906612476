import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import './style.styl'

const Fader = (props) => {
  const { children, opacity = 16 / 9, disableClicks } = props

  const className = classNames('Fader', {
    'Fader--disable-clicks': disableClicks,
  })

  return (
    <div className={className} style={{ opacity }}>
      {children}
    </div>
  )
}

Fader.propTypes = {
  opacity: PropTypes.number.isRequired,
  disableClicks: PropTypes.bool,
  children: PropTypes.any,
}

export default Fader
