import React, { Component } from 'react'
import PropTypes from 'react-proptypes'

class SelectableCellGroup extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedIndex: undefined }
  }

  handleSelect = (index) => {
    this.setState({ selectedIndex: index })
  }

  isSelected = (index) => {
    return this.state.selectedIndex === index
  }

  render() {
    const { children } = this.props
    const childrenWithProps =
      children &&
      children.map((child, index) =>
        React.cloneElement(child, {
          handleSelect: () => this.handleSelect(index),
          selected: this.isSelected(index),
        })
      )

    return <div>{childrenWithProps}</div>
  }
}

SelectableCellGroup.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SelectableCellGroup
