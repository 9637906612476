import { fetchEntities, fetchEntity, createEntity } from './ApiActions'
import { getWizardBuildingWithRelations } from '../selectors/WizardBuildingSelectors'

import { snakeString } from '../utils/formatUtils'
import { hashToFormData } from '../utils/formUtils'

const wizardBuildingStepParams = (attributes) => {
  const params = {}
  for (const [name, value] of Object.entries(attributes)) {
    params[`wizard_building_step[${snakeString(name)}]`] = value
  }
  return params
}

export const fetchWizardBuildingSteps = (options = {}) =>
  fetchEntities('wizardBuildingSteps', options)

export const fetchWizardBuildingStep = (id, options = {}) =>
  fetchEntity({ id, type: 'wizardBuildingSteps' }, options)

export const createWizardBuildingStep = (attributes, options) =>
  createEntity(
    { type: 'wizardBuildingSteps' },
    {
      ...options,
      body: hashToFormData(wizardBuildingStepParams(attributes)),
    }
  )

export const createMissingWizardBuildingSteps =
  (wizardBuildingId) => (dispatch, getState) => {
    // Get wizard building with steps
    const state = getState()
    const wizardBuilding = getWizardBuildingWithRelations(state, {
      params: { wizardBuildingId },
    })
    const {
      mandatoryCreatedAt,
      wizardBuildingSteps,
      wizard: { steps },
    } = wizardBuilding
    const wizardBuildingHasMandatoryProducts = steps.some(
      (step) => step.mandatory
    )

    // If mandatory products have not been created yet, there shouldn't be any steps yet,
    // so no steps are missing
    if (wizardBuildingHasMandatoryProducts && !mandatoryCreatedAt)
      return Promise.resolve()

    // Filter OUT mandatory steps, these are created automatically by the API.
    // And filter OUT steps that already have a corresponding wizard step;
    // the connection could be lost while creating the wizard building steps
    const stepIdsToCreate = steps
      .filter((step) => !step.mandatory)
      .filter(
        (step) => !wizardBuildingSteps.some((wbs) => wbs.step === step.id)
      )
      .map((step) => step.id)

    // Create the steps in parallel
    return Promise.all(
      stepIdsToCreate.map((stepId) =>
        dispatch(createWizardBuildingStep({ wizardBuildingId, stepId }))
      )
    )
  }

export const initializeWizardBuildingSteps =
  (wizardBuildingId) => (dispatch, getState) => {
    // Get all steps
    const state = getState()
    const wizardBuilding = getWizardBuildingWithRelations(state, {
      params: { wizardBuildingId },
    })
    const {
      wizard: { steps },
    } = wizardBuilding

    // This should not be called if the mandatory products were already created
    if (wizardBuilding.mandatoryCreatedAt) {
      throw new Error('Wizard building steps are already initialized')
    }

    // Create the first non-mandatory step,
    // mandatory steps are created automatically by the API.
    const firstStep = steps.find((step) => !step.mandatory)
    if (!firstStep) return Promise.resolve()
    return (
      dispatch(
        createWizardBuildingStep(
          { wizardBuildingId, stepId: firstStep.id },
          {
            query: { include: ['wizard_building'] },
          }
        )
      )
        // Create all remaining steps
        .then(() =>
          dispatch(createMissingWizardBuildingSteps(wizardBuildingId))
        )
    )
  }
