import {
  isFetching,
  getReferenceProductVariants,
} from '../selectors/ReferenceProductVariantsSelectors'
import { fetchEntities, invalidateEntities } from './ApiActions'

export const fetchReferenceProductVariants = (options) =>
  fetchEntities('referenceProductVariants', options)

export const invalidateReferenceProductVariants = (options) =>
  invalidateEntities('referenceProductVariants', options)

const shouldFetchReferenceProductVariants = (state) => {
  const variants = getReferenceProductVariants(state)

  if (isFetching(state) || variants.length > 0) {
    return false
  }

  return true
}

export const fetchReferenceProductVariantsIfNeeded =
  () => (dispatch, getState) => {
    if (shouldFetchReferenceProductVariants(getState())) {
      dispatch(fetchReferenceProductVariants())
    }
  }
