import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'

const Alert = ({ type, children }) => (
  <div className={`alert alert--${type}`} role="alert">
    {children}
  </div>
)

Alert.propTypes = {
  type: PropTypes.oneOf(['danger', 'success']).isRequired,
}

Alert.defaultProps = {
  type: 'danger',
}

export default Alert
