import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import { Link } from 'react-router'
import classNames from 'classnames'
import IconButton from './IconButton'

const Button = (props) => {
  const classes = classNames(
    'btn',
    {
      'btn--block': props.block,
      'btn--active': props.active,
      'btn--borderless': props.borderless,
      'btn--invisible': props.invisible,
    },
    props.className
  )

  const button = (
    <button
      disabled={props.disabled}
      type={props.type}
      className={classes}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </button>
  )

  if (props.linkTo && !props.disabled)
    return <Link to={props.linkTo}>{button}</Link>
  return button
}

Button.propTypes = {
  children: PropTypes.any,
  block: PropTypes.bool,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  borderless: PropTypes.bool,
  invisible: PropTypes.bool,
  style: PropTypes.object,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  block: false,
  type: 'button',
  disabled: false,
  active: false,
}

export default Button
export { IconButton }
