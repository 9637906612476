import * as types from '../constants/ActionTypes'

export default (store) => (next) => (action) => {
  if (
    action.type === types.ALGOLIA_SET_QUERY &&
    action.entityType === 'orders'
  ) {
    if (action.query) {
      const currentQuery = store.getState().algolia.queries.orders || {}
      const newQuery = { ...currentQuery, ...action.query }
      localStorage.setItem('algoliaOrderQuery', JSON.stringify(newQuery))
    } else {
      localStorage.removeItem('algoliaOrderQuery')
    }
  }

  next(action)
}
