import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { ListItem } from '../List'

class CollapsableListItem extends Component {
  static propTypes = {
    renderCollapse: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  toggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  mayRenderCollapsedItem = () => {
    if (!this.state.collapsed) return null

    const { renderCollapse } = this.props

    return renderCollapse()
  }

  render() {
    return (
      <div>
        <ListItem {...this.props} onClick={this.toggleCollapse} />
        {this.mayRenderCollapsedItem()}
      </div>
    )
  }
}

export default CollapsableListItem
