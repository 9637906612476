import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Button from '../Button'
import { compressImage } from '../../utils/imageUtils'

class ImageInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    compress: PropTypes.shape({
      mimeType: PropTypes.string,
      quality: PropTypes.number,
      maxWidth: PropTypes.number.isRequired,
      maxHeight: PropTypes.number.isRequired,
    }),
    initialValue: PropTypes.any,
  }

  static defaultProps = {
    compress: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      previewResult: props.initialValue || undefined,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.handleChangeClick = this.handleChangeClick.bind(this)
  }

  reset() {
    this.inputElement.value = ''
    this.handleInputChange()
  }

  setPreviewImage(file) {
    this.previewElement.src = URL.createObjectURL(file)
    this.previewElement.onload = () => {
      URL.revokeObjectURL(this.previewElement.src)
    }
  }

  handleInputChange(event) {
    const { onChange, compress } = this.props

    if (!event) {
      this.setState({ previewResult: undefined })

      if (onChange) {
        onChange(null)
      }

      return
    }

    const { target } = event

    if (target.files && target.files[0]) {
      const file = target.files[0]

      this.setPreviewImage(file)

      if (compress) {
        return compressImage(file, compress).then(onChange)
      }

      onChange(file)
    }
  }

  handleChangeClick() {
    this.inputElement.click()
  }

  handleResetClick() {
    this.reset()
  }

  renderPreviewImage() {
    const { previewResult } = this.state

    return (
      <div className="image-input__preview-container">
        <img
          className="image-input__preview"
          ref={(element) => (this.previewElement = element)}
          src={previewResult}
          role="presentation"
        />
      </div>
    )
  }

  renderActions() {
    const { previewResult } = this.state

    if (!previewResult) {
      return (
        <div className="image-input__actions">
          <Button
            onClick={this.handleChangeClick}
            className="image-input__action"
          >
            Vælg billede
          </Button>
        </div>
      )
    }

    return (
      <div className="image-input__actions">
        <Button
          onClick={this.handleChangeClick}
          className="image-input__action"
        >
          Skift
        </Button>
        <Button onClick={this.handleResetClick} className="image-input__action">
          Nulstil
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div>
        <input
          ref={(element) => (this.inputElement = element)}
          type="file"
          accept="image/*"
          className="image-input__input"
          onChange={this.handleInputChange}
        />
        {this.renderPreviewImage()}
        {this.renderActions()}
      </div>
    )
  }
}

export default ImageInput
