import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { FormField } from '../Form'
import Input from '../Input'
import Button from '../Button'
import Alert from '../Alert'
import { VerticalAlign } from '../Grid'

class PasswordUpdatePage extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = { password: '', passwordConfirmation: '' }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange({ target }) {
    this.setState({ [target.name]: target.value })
  }

  validate() {
    const { password, passwordConfirmation } = this.state

    return password.length >= 4 && password === passwordConfirmation
  }

  render() {
    const { onSubmit, isSubmitting, error } = this.props
    const { state } = this
    const disabled = isSubmitting || !this.validate()

    return (
      <div className="password-reset-page">
        <VerticalAlign>
          <form
            className="password-reset-form"
            disabled={isSubmitting}
            onSubmit={(event) => {
              event.preventDefault()
              onSubmit(state)
            }}
          >
            {error ? <Alert type="danger">{error}</Alert> : null}
            <FormField>
              <Input
                type="password"
                name="password"
                placeholder="Din nye adgangskode"
                disabled={isSubmitting}
                onChange={this.handleInputChange}
                autoFocus
              />
            </FormField>
            <FormField>
              <Input
                type="password"
                name="passwordConfirmation"
                placeholder="Gentag din nye adgangskode"
                disabled={isSubmitting}
                onChange={this.handleInputChange}
              />
            </FormField>
            <Button disabled={disabled} type="submit" block>
              {isSubmitting ? 'Opdatere adgangskode...' : 'Opdatér adgangskode'}
            </Button>
          </form>
        </VerticalAlign>
      </div>
    )
  }
}

export default PasswordUpdatePage
