import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'
import classNames from 'classnames'
import { compressImage } from '../../utils/imageUtils'
import { Row, Column } from '../Grid'
import Spinner from '../Spinner'
import Icon from '../Icon'
import AddImageButton from '../AddImageButton'

export default class BuildingAssessmentCategoryImagesInput extends Component {
  static propTypes = {
    assessmentCategoryImages: PropTypes.array.isRequired,
    buildingAssessmentCategoryImages: PropTypes.array.isRequired,
    onImageAdd: PropTypes.func.isRequired,
    onImageRemove: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  compressOptions = {
    maxWidth: 1200,
    maxHeight: 1200,
    quality: 0.8,
  }

  constructor(props) {
    super(props)
    this.handleImageClick = this.handleImageClick.bind(this)
    this.handleNewImage = this.handleNewImage.bind(this)
    this.renderImage = this.renderImage.bind(this)
  }

  getBuildingAssessmentCategoryImageForAssessmentCategoryImage(image) {
    return this.props.buildingAssessmentCategoryImages.find(
      (baci) => baci.assessmentCategoryImage === image.id
    )
  }

  handleImageClick(image) {
    const { onImageAdd, onImageRemove, loading } = this.props
    if (loading) {
      return
    }

    let baci
    let isSampleImage = false
    if (image.type === 'assessmentCategoryImages') {
      baci =
        this.getBuildingAssessmentCategoryImageForAssessmentCategoryImage(image)
      isSampleImage = true
    } else {
      baci = image
    }

    if (!baci) {
      onImageAdd(image)
    } else {
      if (isSampleImage || confirm('Billedet vil blive slettet. Fortsæt?')) {
        onImageRemove(baci.id)
      }
    }
  }

  handleNewImage({ target }) {
    const { onImageAdd } = this.props
    if (target.files && target.files.length !== 0) {
      let promises = []

      for (let index = 0; index < target.files.length; index++) {
        promises.push(compressImage(target.files[index], this.compressOptions))
      }

      Promise.all(promises).then(onImageAdd)
    }
  }

  renderImage(image) {
    const style = { backgroundImage: `url(${image.imageUrl})` }

    let active = true
    if (image.type === 'assessmentCategoryImages') {
      active =
        !!this.getBuildingAssessmentCategoryImageForAssessmentCategoryImage(
          image
        )
    }
    const className = classNames(
      'building-assessment-category-images-input__image'
    )

    return (
      <Column
        size={4}
        key={image.imageUrl}
        className="building-assessment-category-images-input__item"
      >
        <div
          onClick={() => this.handleImageClick(image)}
          className={className}
          style={style}
        >
          {active && <Icon icon="checkmark" />}
        </div>
      </Column>
    )
  }

  render() {
    const {
      assessmentCategoryImages,
      buildingAssessmentCategoryImages,
      loading,
    } = this.props
    const className = classNames('building-assessment-category-images-input', {
      'building-assessment-category-images-input--loading': loading,
    })
    const nonSamples = buildingAssessmentCategoryImages.filter(
      (baci) =>
        !assessmentCategoryImages.some(
          (aci) => aci.id === baci.assessmentCategoryImage
        )
    )

    return (
      <Row className={className}>
        {loading && <Spinner dark center />}
        {nonSamples.map(this.renderImage)}
        {assessmentCategoryImages.map(this.renderImage)}

        <Column
          size={4}
          key="new-image"
          className="building-assessment-category-images-input__item"
        >
          <div className="building-assessment-category-images-input__sizer">
            <AddImageButton multiple onImageSelected={this.handleNewImage} />
          </div>
        </Column>
      </Row>
    )
  }
}
