import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { push } from 'react-router-redux'
import { fetchBuilding, updateBuilding } from '../actions/BuildingActions'
import * as Selectors from '../selectors/BuildingSelectors'
import Spinner from '../components/Spinner'
import { Row, Column, VerticalAlign } from '../components/Grid'
import BuildingDetailsForm from '../components/BuildingDetailsForm'
import NewReportHeader from '../components/NewReportHeader'

class NewReportBuildingDetailsContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    customerId: PropTypes.string.isRequired,
    buildingId: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    building: PropTypes.object,
    fetchBuilding: PropTypes.func.isRequired,
    updateBuilding: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  constructor(props) {
    super(props)
    this.handleOnBuildingFormSubmit = this.handleOnBuildingFormSubmit.bind(this)
  }

  componentDidMount() {
    const { buildingId } = this.props
    this.props.fetchBuilding(buildingId)
  }

  shouldComponentUpdate(nextProps) {
    const { isFetching, building } = nextProps

    // If the building's data has not been imported yet, reload it after a delay
    if (building && this.shouldWaitForBBRDataForBuilding(building)) {
      setTimeout(() => {
        if (!isFetching) {
          this.props.fetchBuilding(building.id)
        }
      }, 2000)
      return false
    }

    return true
  }

  shouldWaitForBBRDataForBuilding(building) {
    const creationDate = new Date(building.createdAt)
    const now = new Date()
    const fiveMinutesAgo = new Date().setMinutes(now.getMinutes() - 5)

    return creationDate > fiveMinutesAgo && !building.bbrImportedAt
  }

  handleOnBuildingFormSubmit(attributes) {
    const { customerId, templateId, buildingId } = this.props

    return this.props.updateBuilding(buildingId, { ...attributes }).then(() => {
      this.props.push(
        `/reports/new/${templateId}/${customerId}/${buildingId}/assessments`
      )
    })
  }

  render() {
    const { isFetching, building, fields, handleSubmit, submitting } =
      this.props
    const bbrLoading =
      building && this.shouldWaitForBBRDataForBuilding(building)

    if (isFetching || bbrLoading) {
      return <Spinner center />
    }

    return (
      <VerticalAlign>
        <Row>
          <Column size={6} offset={3}>
            <NewReportHeader>Tjek Bygningsdata</NewReportHeader>
            <BuildingDetailsForm
              fields={fields}
              submitting={submitting}
              handleSubmit={handleSubmit(this.handleOnBuildingFormSubmit)}
            />
          </Column>
        </Row>
      </VerticalAlign>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { customerId, templateId, buildingId } = props.routeParams
  return {
    isFetching: Selectors.isFetching(state),
    customerId,
    templateId,
    buildingId,
    building: Selectors.getBuilding(state, props),
  }
}

export default connect(mapStateToProps, {
  fetchBuilding,
  updateBuilding,
  push,
})(
  reduxForm(
    {
      form: 'building',
      fields: BuildingDetailsForm.fields,
    },
    (state, props) => ({ initialValues: Selectors.getBuilding(state, props) })
  )(NewReportBuildingDetailsContainer)
)
