import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as CustomerActions from '../../actions/CustomerActions'
import { formatResponseErrors } from '../../utils/formatUtils'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
  DialogButton,
} from '../../components/Dialog'
import CustomerForm from '../../components/CustomerForm'

class CustomerDialog extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    updateCustomer: PropTypes.func.isRequired,
    createCustomer: PropTypes.func.isRequired,
    onSubmitted: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
  }

  handleFormSubmit(attrs) {
    const { createCustomer, updateCustomer, customer, onClose, onSubmitted } =
      this.props
    const isNew = !customer.id

    let action
    if (isNew) {
      action = createCustomer({ ...attrs })
    } else {
      action = updateCustomer({ id: customer.id, type: 'customers', ...attrs })
    }

    return action
      .then((response) => {
        onClose()
        onSubmitted(response)
      })
      .catch((errors) => Promise.reject(formatResponseErrors(errors)))
  }

  handleSaveClick() {
    this.formElement.submit()
  }

  render() {
    const { customer, onClose, fields, submitting, dirty, handleSubmit } =
      this.props
    const isNew = !customer.id
    const disabled = !dirty || submitting

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>{isNew ? 'Opret kunde' : 'Rediger kunde'}</DialogTitle>
          <DialogButton onClick={this.handleSaveClick} disabled={disabled}>
            {submitting ? 'Gemmer...' : 'Gem'}
          </DialogButton>
        </DialogHeader>
        <CustomerForm
          fields={fields}
          submitting={submitting}
          dirty={dirty}
          onSubmit={handleSubmit(this.handleFormSubmit)}
          ref={(element) => (this.formElement = element)}
        />
      </Dialog>
    )
  }
}

const CustomerDialogWithForm = reduxForm({
  form: 'customer',
  fields: CustomerForm.fields,
})(CustomerDialog)

const mapStateToProps = (state, ownProps) => ({
  initialValues: { ...ownProps.customer },
})

export default connect(mapStateToProps, {
  ...CustomerActions,
})(CustomerDialogWithForm)
