import React from 'react'
import PropTypes from 'react-proptypes'
import { Link } from 'react-router'
import classNames from 'classnames'
import './style.styl'

export const Breadcrumb = ({ children, className, ...otherProps }) => {
  const paddedChildren = []

  ;[].concat(children).forEach((child, index) => {
    if (index !== 0) paddedChildren.push(' > ')
    paddedChildren.push(child)
  })

  return (
    <ol className={classNames('Breadcrumb', className)} {...otherProps}>
      {paddedChildren}
    </ol>
  )
}
Breadcrumb.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export const BreadcrumbItem = ({ linkTo, title, className, ...otherProps }) => (
  <li className={classNames('Breadcrumb__item', className)} {...otherProps}>
    {linkTo ? <Link to={linkTo}>{title}</Link> : title}
  </li>
)

BreadcrumbItem.propTypes = {
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  className: PropTypes.string,
}

export default Breadcrumb
