import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import Button from '../Button'

const DialogButton = (props) => (
  <Button {...props} className={classNames('dialog__btn', props.className)} />
)

DialogButton.propTypes = {
  className: PropTypes.string,
}

export default DialogButton
