import { createSelector } from 'reselect'
import {
  createIsFetchingSelector,
  createEntitiesSelector,
  createEntitySelector,
} from './ApiSelectors'

import { getBuildingImagesForBuilding } from './BuildingImageSelectors'

export const isFetching = createIsFetchingSelector('buildings')
export const getBuildings = createEntitiesSelector('buildings')
export const getBuildingEntities = (state) => state.api.entities.buildings
export const getBuildingId = (state, props) => props.params.buildingId
export const getBuildingsAssessments = createEntitiesSelector(
  'buildingAssessments'
)
export const getBuildingsAssessmentCategoryTypes = createEntitiesSelector(
  'buildingAssessmentCategoryTypes'
)
const getCustomerId = (state, props) => props.params.customerId

export const getBuildingsForCustomer = createSelector(
  getBuildings,
  getCustomerId,
  (buildings, customerId) => buildings.filter((b) => b.customer === customerId)
)

export const getBuilding = createEntitySelector('buildings')

export const getBuildingsForCustomerWithImages = createSelector(
  getBuildings,
  getCustomerId,
  (state) => state,
  (buildings, customerId, state) =>
    buildings
      .filter((b) => b.customer === customerId)
      .map((building) => ({
        ...building,
        buildingImages: getBuildingImagesForBuilding(state, {
          params: { buildingId: building.id },
        }),
      }))
)

export const getBuildingWithAssessmentRelationIds = createSelector(
  getBuilding,
  getBuildingsAssessments,
  getBuildingsAssessmentCategoryTypes,
  (building, assessments, types) => {
    if (building) {
      return {
        ...building,
        assessmentCategoryTypes: types
          .filter((t) => t.building === building.id)
          .map((t) => t.assessmentCategoryType),
        assessments: assessments
          .filter((a) => a.building === building.id)
          .map((a) => a.assessment),
      }
    }
    return null
  }
)
