import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import {
  getPropertyCategories,
  isFetching as getIsFetchingPropertyCategories,
} from '../../selectors/PropertyCategorySelectors'
import {
  getProperties,
  isFetching as getIsFetchingProperties,
} from '../../selectors/PropertySelectors'

import * as PropertyCategoryActions from '../../actions/PropertyCategoryActions'
import * as PropertyActions from '../../actions/PropertyActions'
import * as BuildingPropertyActions from '../../actions/BuildingPropertyActions'
import * as DialogActions from '../../actions/DialogActions'

import BuildingPropertyFormContainer from '../Forms/BuildingPropertyFormContainer'
import ResultCorrectionsContainer from '../ResultCorrectionsContainer'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import Spinner from '../../components/Spinner'
import Button from '../../components/Button'
import Cell from '../../components/Cell'
import { Margin, Row, FlexContainer, Flex } from '../../components/Grid'

import orderBy from '../../utils/orderBy'

const steps = [
  'propertyCategory',
  'property',
  'buildingProperty',
  'resultCorrections',
]

const getPreviousStep = (currentStep) => {
  const index = steps.indexOf(currentStep)
  return index !== -1 ? steps[index - 1] : undefined
}
const getNextStep = (currentStep) => {
  const index = steps.indexOf(currentStep)
  return index !== -1 ? steps[index + 1] : undefined
}

class BuildingPropertyCreationDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    buildingId: PropTypes.string.isRequired,
    propertyCategoryId: PropTypes.string,

    fetchPropertyCategories: PropTypes.func.isRequired,
    fetchProperties: PropTypes.func.isRequired,
    updateBuildingProperty: PropTypes.func.isRequired,
    createBuildingProperty: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,

    propertyCategories: PropTypes.array,
    isFetchingPropertyCategories: PropTypes.bool.isRequired,

    propertyCategory: PropTypes.object,
    properties: PropTypes.array,
    isFetchingProperties: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    const { propertyCategoryId } = props

    this.state = {
      propertyCategoryId,
      propertyId: null,
      buildingPropertyId: null,

      // Skip property category step if a category was provided
      step: propertyCategoryId ? 'property' : 'propertyCategory',
    }
  }

  componentDidMount() {
    const { fetchProperties, fetchPropertyCategories } = this.props

    // Fetch property categories if necessary
    if (this.state.step === 'propertyCategory') fetchPropertyCategories()

    // Always fetch properties, since we can't skip that step
    fetchProperties()
  }

  handleBackButtonClick = () => {
    const { step } = this.state

    const goToStep = getPreviousStep(step)

    if (!goToStep) throw Error(`Cannot go back from step: ${step}`)

    this.setState({ step: goToStep })
  }

  handlePropertyCategorySelected = (propertyCategoryId) => {
    this.setState({ propertyCategoryId, step: getNextStep(this.state.step) })
  }

  handlePropertySelected = (propertyId) => {
    this.setState({ propertyId, step: getNextStep(this.state.step) })
  }

  handleBuildingPropertySubmit = (values) => {
    const { updateBuildingProperty, createBuildingProperty } = this.props
    const { buildingPropertyId } = this.state

    let promise
    if (buildingPropertyId) {
      promise = updateBuildingProperty(buildingPropertyId, values)
    } else {
      promise = createBuildingProperty(values)
    }

    promise.then(({ results }) => {
      this.setState({
        buildingPropertyId: results.buildingProperties[0],
        step: getNextStep(this.state.step),
      })
    })
  }

  handleFinishedClick = () => {
    this.props.closeDialog()
  }

  renderPropertyCategoryStep() {
    const { isFetchingPropertyCategories, propertyCategories } = this.props

    if (isFetchingPropertyCategories) return this.renderLoader()

    const orderedPropertyCategories = propertyCategories
      .slice()
      .sort(orderBy('name'))

    return (
      <Margin all size={2}>
        <h2>Hvilket område på huset skal du opmåle?</h2>
        <Row>
          {orderedPropertyCategories.map((propertyCategory) => (
            <Cell
              key={propertyCategory.id}
              imageUrl={propertyCategory.imageUrl}
              onClick={this.handlePropertyCategorySelected}
              clickPayload={propertyCategory.id}
            >
              {propertyCategory.name}
            </Cell>
          ))}
        </Row>
      </Margin>
    )
  }

  renderPropertyStep() {
    const { isFetchingProperties, properties, propertyCategories } = this.props
    const { propertyCategoryId } = this.state
    const propertyCategory = propertyCategories.find(
      ({ id }) => id === propertyCategoryId
    )

    if (isFetchingProperties) return this.renderLoader()

    const orderedProperties = properties
      .filter((p) => p.propertyCategory === propertyCategoryId)
      .sort(orderBy('name'))

    return (
      <Margin all size={2}>
        <h2>
          Hvilken type af {propertyCategory.name.toLowerCase()} skal du opmåle?
        </h2>
        <Row>
          {orderedProperties.map((property) => (
            <Cell
              key={property.id}
              imageUrl={property.imageUrl}
              onClick={this.handlePropertySelected}
              clickPayload={property.id}
            >
              <p className="text-small">{property.name}</p>
            </Cell>
          ))}
        </Row>
        {!this.props.propertyCategoryId && (
          <Margin top size={2}>
            {this.renderBackButton()}
          </Margin>
        )}
      </Margin>
    )
  }

  renderBuildingPropertyStep() {
    const { buildingId } = this.props
    const { buildingPropertyId, propertyId } = this.state

    return (
      <div>
        <Margin all size={2}>
          <h2>Indtast data for opmålingen</h2>
        </Margin>
        <BuildingPropertyFormContainer
          buildingPropertyId={buildingPropertyId}
          propertyId={propertyId}
          buildingId={buildingId}
          onSubmit={this.handleBuildingPropertySubmit}
          renderSubmitButton={(disabled) => (
            <Button type="submit" disabled={disabled}>
              Næste
            </Button>
          )}
        >
          {this.renderBackButton()}
        </BuildingPropertyFormContainer>
      </div>
    )
  }

  renderResultCorrectionsStep() {
    const { buildingPropertyId } = this.state

    return (
      <div>
        <Margin all size={2}>
          <h2>Indtast data for opmålingen</h2>
        </Margin>
        <ResultCorrectionsContainer buildingPropertyId={buildingPropertyId} />

        <Margin all size={2}>
          <FlexContainer horizontal>
            <Flex>{this.renderBackButton()}</Flex>
            <Flex noGrow>
              <Button onClick={this.handleFinishedClick}>Færdig</Button>
            </Flex>
          </FlexContainer>
        </Margin>
      </div>
    )
  }

  renderCurrentStep() {
    switch (this.state.step) {
      case 'propertyCategory':
        return this.renderPropertyCategoryStep()
      case 'property':
        return this.renderPropertyStep()
      case 'buildingProperty':
        return this.renderBuildingPropertyStep()
      case 'resultCorrections':
        return this.renderResultCorrectionsStep()
      default:
        return null
    }
  }

  renderLoader() {
    return (
      <Margin all size={2}>
        <Spinner centerHorizontal />
      </Margin>
    )
  }

  renderBackButton = () => (
    <Button onClick={this.handleBackButtonClick}>Tilbage</Button>
  )

  render() {
    const { onClose } = this.props
    return (
      <Dialog>
        <DialogHeader>
          {onClose && (
            <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          )}
          <DialogTitle>Opret opmåling</DialogTitle>
        </DialogHeader>
        {this.renderCurrentStep()}
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  // Property category step
  propertyCategories: getPropertyCategories(state),
  isFetchingPropertyCategories: getIsFetchingPropertyCategories(state),

  // Property step
  properties: getProperties(state),
  isFetchingProperties: getIsFetchingProperties(state),
})

export default connect(mapStateToProps, {
  ...PropertyCategoryActions,
  ...PropertyActions,
  ...BuildingPropertyActions,
  ...DialogActions,
})(BuildingPropertyCreationDialog)
