import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import Select from '../Select';

class DraftWizardDiscountRow extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedDiscountName: this.props.wizardDiscounts[0].name };
  }

  handleNameSelected = ({ target: { value } }) => {
    const { wizardDiscounts, updateDiscount } = this.props;

    Promise.all(wizardDiscounts.map(discount => updateDiscount(discount.id, { name: value })))
      .then(() => {
        this.setState({ selectedDiscountName: value });
      })
      .catch(error => {
        console.error("Error updating discount:", error);
      });
  };

  render() {
    const { discountNames, order: { humanizedDiscountInclVatWithSymbol } } = this.props;
    const { selectedDiscountName } = this.state;

    const discountNameOptions = discountNames.map((discountName) => ({
      value: discountName.name,
      label: discountName.name,
    }));

    discountNameOptions.unshift({ value: 'Pakkerabatter', label: 'Brug oprindeligt navn' });

    return (
      <tr>
        <td>Pakkerabatter</td>
        <td>
          <Select
            value={selectedDiscountName}
            options={discountNameOptions}
            onChange={this.handleNameSelected}
          />
        </td>
        <td className="discount-amount">{humanizedDiscountInclVatWithSymbol}</td>
        <td></td>
      </tr>
    );
  }
}

DraftWizardDiscountRow.propTypes = {
  discountNames: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  order: PropTypes.shape({
    humanizedDiscountInclVatWithSymbol: PropTypes.string.isRequired,
  }).isRequired,
  updateDiscount: PropTypes.func.isRequired,
  wizardDiscounts: PropTypes.arrayOf(
    PropTypes.shape({
      discountInclVatOerer: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default DraftWizardDiscountRow;
