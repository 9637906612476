import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as DialogActions from '../../actions/DialogActions'
import * as BuildingPropertyActions from '../../actions/BuildingPropertyActions'

import BuildingPropertyFormContainer from '../Forms/BuildingPropertyFormContainer'
import ResultCorrectionsContainer from '../ResultCorrectionsContainer'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
  DialogTitleTab,
} from '../../components/Dialog'
import ToggleVisible from '../../components/ToggleVisible'
import Button from '../../components/Button'
import Spinner from '../../components/Spinner'

class BuildingPropertyDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    buildingPropertyId: PropTypes.string.isRequired,
    closeDialog: PropTypes.func.isRequired,
    updateBuildingProperty: PropTypes.func.isRequired,
    deleteBuildingProperty: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const { propertyCategoryId } = props

    this.state = {
      activeTab: 'property',
      isDeleting: false,
      propertyCategoryId,
      propertyId: null,
      buildingPropertyId: null,

      // Skip property category step if a category was provided
      step: propertyCategoryId ? 'property' : 'propertyCategory',
    }
  }

  handlePropertyClick = () => {
    this.setState({ activeTab: 'property' })
  }

  handleCorrectionsClick = () => {
    this.setState({ activeTab: 'corrections' })
  }

  handleBuildingPropertySubmit = (values) => {
    const { updateBuildingProperty, buildingPropertyId } = this.props
    updateBuildingProperty(buildingPropertyId, values)
  }

  handleBuildingPropertyDeleteClick = () => {
    const { deleteBuildingProperty, buildingPropertyId, closeDialog } =
      this.props
    if (confirm('Er du sikker på at du vil slette denne opmåling?')) {
      this.setState({ isDeleting: true })
      deleteBuildingProperty(buildingPropertyId)
        .then(() => closeDialog())
        .catch(() => this.setState({ isDeleting: false }))
    }
  }

  render() {
    const { onClose, buildingPropertyId } = this.props
    const { activeTab, isDeleting } = this.state
    return (
      <Dialog>
        <DialogHeader>
          {onClose && (
            <DialogCancelButton onClick={onClose}>Tilbage</DialogCancelButton>
          )}
          <DialogTitle>
            <DialogTitleTab
              active={activeTab === 'property'}
              onClick={this.handlePropertyClick}
            >
              Ret mål
            </DialogTitleTab>
            <DialogTitleTab
              active={activeTab === 'corrections'}
              onClick={this.handleCorrectionsClick}
            >
              Korrigeringer
            </DialogTitleTab>
          </DialogTitle>
        </DialogHeader>
        <ToggleVisible visible={activeTab === 'property'}>
          {isDeleting ? (
            <Spinner center />
          ) : (
            <BuildingPropertyFormContainer
              buildingPropertyId={buildingPropertyId}
              onSubmit={this.handleBuildingPropertySubmit}
            >
              <Button onClick={this.handleBuildingPropertyDeleteClick}>
                Slet
              </Button>
            </BuildingPropertyFormContainer>
          )}
        </ToggleVisible>
        {activeTab === 'corrections' && (
          <ResultCorrectionsContainer buildingPropertyId={buildingPropertyId} />
        )}
      </Dialog>
    )
  }
}

export default connect(undefined, {
  ...DialogActions,
  ...BuildingPropertyActions,
})(BuildingPropertyDialog)
