export const RoofTiltOptions = [
  { value: 1, label: 'Helt fladt tag' },
  { value: 2, label: 'Etplans hus' },
  { value: 3, label: '1,5 plans hus' },
]

export const HeatSourceOptions = [
  { value: 'Fjernvarme', label: 'Fjernvarme' },
  { value: 'Elvarme', label: 'Elvarme' },
  { value: 'Varmepumpe', label: 'Varmepumpe' },
  { value: 'Gas', label: 'Gas' },
  { value: 'Oliefyr', label: 'Oliefyr' },
  { value: 'Træpillefyr/Halmfyr', label: 'Træpillefyr/Halmfyr' },
]
