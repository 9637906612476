import { fetchEntities, createEntity, deleteEntity } from './ApiActions'
import { hashToFormData } from '../utils/formUtils'

const attributesToFormData = (attributes) =>
  hashToFormData({
    'building_image[image]': attributes.image,
    'building_image[building_id]': attributes.buildingId,
    'building_image[image_tag_id]': attributes.imageTagId || '',
  })

export const fetchBuildingImages = (options) =>
  fetchEntities('buildingImages', options)

export const createBuildingImage = (attributes) =>
  createEntity(
    { type: 'buildingImages' },
    { body: attributesToFormData(attributes) }
  )

export const deleteBuildingImage = (id) =>
  deleteEntity({ type: 'buildingImages', id })
