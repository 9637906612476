import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { getCurrentUser } from '../../selectors/AuthSelectors'
import * as UserActions from '../../actions/UserActions'
import { reduxForm } from 'redux-form'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog'
import Input from '../../components/Input'
import SettingsList, { SettingsListItem } from '../../components/SettingsList'

class UserPasswordDialog extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateUserPassword: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit(attributes) {
    const { userId, updateUserPassword, onClose } = this.props

    return updateUserPassword(userId, attributes).then(onClose)
  }

  render() {
    const {
      fields: { password, passwordConfirmation, currentPassword },
      onClose,
      dirty,
      submitting,
      handleSubmit,
    } = this.props

    const disabled = submitting || !dirty

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>Skift adgangskode</DialogTitle>
          <DialogButton
            disabled={disabled}
            onClick={handleSubmit(this.handleFormSubmit)}
          >
            {submitting ? 'Skifter...' : 'Skift'}
          </DialogButton>
        </DialogHeader>
        <form
          ref={(element) => (this.formElement = element)}
          onSubmit={handleSubmit(this.handleFormSubmit)}
        >
          <SettingsList>
            <SettingsListItem label="Nuværende adgangskode">
              <Input type="password" {...currentPassword} />
            </SettingsListItem>
            <SettingsListItem label="Ny adgangskode">
              <Input type="password" {...password} autoFocus />
            </SettingsListItem>
            <SettingsListItem label="Gentag ny adgangskode">
              <Input type="password" {...passwordConfirmation} />
            </SettingsListItem>
          </SettingsList>
          <button
            disabled={disabled}
            tabIndex="-1"
            type="submit"
            style={{
              position: 'absolute',
              left: '-99999px',
              width: '1px',
              height: '1px',
            }}
          />
        </form>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  const user = getCurrentUser(state)

  return {
    userId: user.id,
    initialValues: {
      ...user,
      signature: user.signatureUrl,
    },
  }
}

const mapComponentToReduxForm = (component) =>
  reduxForm({
    form: 'userPassword',
    fields: ['password', 'passwordConfirmation', 'currentPassword'],
  })(component)

export default connect(mapStateToProps, {
  ...UserActions,
})(mapComponentToReduxForm(UserPasswordDialog))
