import { createSelector } from 'reselect'
import {
  createOrderedEntitiesSelector,
  createEntitySelector,
  createIsFetchingSelector,
} from './ApiSelectors'

export const getUser = createEntitySelector('users')

export const getUsers = createOrderedEntitiesSelector('users')

export const getNonDeletedUsers = createSelector(getUsers, (users) =>
  users.filter((u) => !u.deletedAt)
)

export const isFetching = createIsFetchingSelector('users')

export const getReviewers = createSelector(getNonDeletedUsers, (users) =>
  users.filter(
    (user) => ['super_admin', 'admin', 'manager'].indexOf(user.role) !== -1
  )
)

export const getReviewersForOrder = createSelector(
  getReviewers,
  (state, order) => state.api.entities.users[order.user.id],
  (reviewers, salesman) => {
    if (!salesman.department) return reviewers
    return reviewers.filter(
      (reviewer) =>
        !reviewer.department || reviewer.department === salesman.department
    )
  }
)
