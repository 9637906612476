export const OrderStates = {
  archived: { label: 'Arkiveret', labelType: 'faded', progress: 0 },
  draft: { label: 'Kladde', labelType: 'default', progress: 1 },
  proposed: { label: 'Afventer', labelType: 'default', progress: 2 },
  rejected: { label: 'Afvist af kunde', labelType: 'danger', progress: 3 },
  accepted: { label: 'Godkendt af kunde', labelType: 'success', progress: 4 },
  reviewed_rejected: {
    label: 'Afvist af formand',
    labelType: 'danger',
    progress: 5,
  },
  reviewed_accepted: {
    label: 'Accepteret af formand',
    labelType: 'success',
    progress: 6,
  },
  exported_to_ax: { label: 'Lagt i AX', labelType: 'success', progress: 7 },
  cancelled: { label: 'Annulleret', labelType: 'danger', progress: 8 },
}

export const OrderStateOptions = Object.getOwnPropertyNames(OrderStates).map(
  (key) => ({ value: key, label: OrderStates[key].label })
)

export const orderProgressSameOrBefore = (state, otherState) =>
  OrderStates[state].progress <= OrderStates[otherState].progress

export const orderProgressSameOrAfter = (state, otherState) =>
  OrderStates[state].progress >= OrderStates[otherState].progress

export default OrderStates
