import { createSelector } from 'reselect'
import {
  createIsFetchingSelector,
  createEntitySelector,
  createEntitiesSelector,
} from './ApiSelectors'

const getBuildingId = (state, props) => props.params.buildingId

export const isFetching = createIsFetchingSelector('buildingProperties')
export const getBuildingProperties =
  createEntitiesSelector('buildingProperties')

export const getBuildingPropertiesForBuilding = createSelector(
  getBuildingProperties,
  getBuildingId,
  (state) => state.api.entities.properties || {},
  (buildingProperties, buildingId, propertyEntities) =>
    buildingProperties
      .filter((bp) => bp.building === buildingId)
      .map((bp) => ({ ...bp, property: propertyEntities[bp.property] }))
      .filter((bp) => bp.property)
)

export const getBuildingProperty = createEntitySelector('buildingProperties')
