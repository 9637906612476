import React from 'react'
import PropTypes from 'react-proptypes'
import { formatDate } from '../../utils/formatUtils'
import moment from 'moment'
import classNames from 'classnames'
import './style.styl'

export const FollowUpDate = ({ followUpAt, state }) => {
  const formatted =
    (followUpAt && formatDate(followUpAt, 'j. F Y')) || 'Ikke sat'
  const now = new Date()

  let irrelevant
  let exceeded

  switch (state) {
    case 'proposed':
      irrelevant = false
      exceeded =
        !followUpAt || moment(now).isSameOrAfter(followUpAt, 'followUpAt')
      break

    case 'exported_to_ax':
      irrelevant = !followUpAt
      exceeded =
        followUpAt && moment(now).isSameOrAfter(followUpAt, 'followUpAt')
      break

    default:
      irrelevant = true
      exceeded = false
      break
  }

  const classes = classNames({
    'follow-up-date--irrelevant': irrelevant,
    'follow-up-date--exceeded': exceeded,
  })

  return <span className={classes}>{formatted}</span>
}
FollowUpDate.propTypes = {
  order: PropTypes.object,
}

export default FollowUpDate
