import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { push } from 'react-router-redux'

import * as CustomerActions from '../actions/CustomerActions'
//import * as OrderActions from '../actions/OrderActions'
import * as DialogActions from '../actions/DialogActions'
import * as AlgoliaActions from '../actions/AlgoliaActions'
//import { fetchCurrentUser } from '../actions/UserActions'
import { getCurrentUser } from '../selectors/AuthSelectors'

//import { getCustomerDrafts } from '../selectors/OrderSelectors'

import CustomerInputContainer from './CustomerInputContainer'

import OrderWizardStepTitle from '../components/OrderWizard/StepTitle'
import Input from '../components/Input'
import Button from '../components/Button'
import Breadcrumb, { BreadcrumbItem } from '../components/Breadcrumb'
import {
  Row,
  Column,
  VerticalAlign,
  FlexContainer,
  Flex,
  Margin,
} from '../components/Grid'
import { PageHeader } from '../components/Page'

class ManagerRatingContainer extends Component {
  static propTypes = {
    customerId: PropTypes.string,
    push: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
    insertFakeAlgoliaResult: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      customerPhone: '',
    }
    this.customer = false
    this.secret = 'g9i398P6i6tW'
  }

  componentDidMount() {
    const { customerId } = this.props
    if (customerId) this.fetchCustomerPhone()
  }

  componentDidUpdate(oldProps) {
    if (!this.props.customerId) return

    if (oldProps.customerId !== this.props.customerId) {
      this.fetchCustomerPhone()
    }
  }

  fetchCustomerPhone() {
    const { fetchCustomer, customerId } = this.props
    let thisWrapper = this
    fetchCustomer(customerId).then(function (response) {
      thisWrapper.customer = response.rawData.attributes
      thisWrapper.setState({ customerPhone: response.rawData.attributes.phone })
    })
  }

  handleCustomerChange = (customerId) => {
    if (customerId) {
      this.props.push(`/manager-rating/${customerId}`)
    } else {
      this.props.push('/manager-rating')
    }
  }

  handleCustomerPhoneChange = ({ target: { value } }) => {
    this.setState({ customerPhone: value })
  }

  handleSendRatingSubmit = (event) => {
    event.preventDefault()
    const { currentUser } = this.props
    const { customerPhone, submitting } = this.state
    if (!customerPhone) return

    this.setState({ submitting: true })
    let apiUrl = `https://jydsktagteknik.dk/trustpilot-api/v1/rateManager/${this.secret}/${currentUser.id}/${currentUser.role}/${currentUser.name}/${this.customer.name}/${this.customer.email}/${customerPhone}/${this.customer['address-zip-code']}`

    fetch(apiUrl)
      .then(function (response) {})
      .then(() => Promise.all([this.setState({ submitting: false })]))
      .then(function () {
        alert('Bedømmelsen er nu sendt til kunden')
      })
  }

  renderSendRatingForm() {
    const { customerPhone, submitting } = this.state
    return (
      <form onSubmit={this.handleSendRatingSubmit}>
        <OrderWizardStepTitle>
          Google bedømmelse sendes via SMS til:
        </OrderWizardStepTitle>
        <FlexContainer horizontal>
          <Flex>
            <Input
              value={customerPhone}
              placeholder="Telefonnummer"
              onChange={this.handleCustomerPhoneChange}
            />
          </Flex>
          <Flex noGrow noShrink>
            <Margin size={1} left>
              <Button type="submit" disabled={!customerPhone}>
                {(submitting && 'Sender...') || 'Send'}
              </Button>
            </Margin>
          </Flex>
        </FlexContainer>
      </form>
    )
  }

  render() {
    const { customerId } = this.props

    return (
      <div>
        <PageHeader>
          <Breadcrumb className="page__title">
            <BreadcrumbItem title="Byggeleder bedømmelse" />
          </Breadcrumb>
        </PageHeader>
        <VerticalAlign>
          <Row>
            <Column size={8} offset={2}>
              <OrderWizardStepTitle>
                <FlexContainer horizontal style={{ alignItems: 'center' }}>
                  <Flex>Vælg kunde</Flex>
                </FlexContainer>
              </OrderWizardStepTitle>
              <CustomerInputContainer
                value={customerId}
                placeholder="Søg efter kunde"
                onChange={this.handleCustomerChange}
              />

              {customerId && this.renderSendRatingForm()}
            </Column>
          </Row>
        </VerticalAlign>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  customerId: props.params.customerId,
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      ...CustomerActions,
      ...DialogActions,
      ...AlgoliaActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerRatingContainer)
