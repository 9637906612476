import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as DialogActions from '../../actions/DialogActions'
import { formatAddressLine } from '../../utils/formatUtils'
import Button from '../Button'
import { Flex, FlexContainer } from '../Grid'
import Icon from '../Icon'
import WizardBuildingDiscountRow from '../WizardBuildingDiscountRow'
import WizardBuildingStepTableBody from '../WizardBuildingStepTableBody'
import './style.styl'
import DiscountAdjustmentDialog from '../DiscountAdjustmentDialog'

class WizardBuildingTable extends Component {
  static propTypes = {
    openDialog: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onUpdateDiscount: PropTypes.func,
    user: PropTypes.shape({
      humanizedWalletBalance: PropTypes.string.isRequired,
    }).isRequired,
    wizardBuilding: PropTypes.shape({
      id: PropTypes.string.isRequired,
      wizardBuildingSteps: PropTypes.array.isRequired,
      building: PropTypes.shape({
        name: PropTypes.string.isRequired,
        addressStreetName: PropTypes.string.isRequired,
        addressStreetNumber: PropTypes.string.isRequired,
        addressZipCode: PropTypes.number.isRequired,
        addressCityName: PropTypes.string.isRequired,
        addressAdditionalInfo: PropTypes.string,
      }).isRequired,
      discount: PropTypes.shape({
        id: PropTypes.string.isRequired,
        discountType: PropTypes.string.isRequired,
        discountPercentage: PropTypes.number.isRequired,
      }),
    }),
    allowLineItemEditing: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleBuildingInfoClick = () => {
    const {
      openDialog,
      wizardBuilding: { building },
    } = this.props
    openDialog('buildingInfo', { params: { buildingId: building.id } })
  }

  handleEdit = () => this.props.onEdit(this.props.wizardBuilding.id)
  handleDelete = () => this.props.onDelete(this.props.wizardBuilding.id)

  render() {
    const { onUpdateDiscount, user, wizardBuilding, allowLineItemEditing, onEdit, onDelete } =
      this.props
    const { wizard, building, wizardBuildingSteps } = wizardBuilding
    const hasActions = onEdit || onDelete
    const columnCount = allowLineItemEditing ? 6 : 5
    const { showModal } = this.state;

    return (
      <>
        <table className="WizardBuildingTable">
          <tbody key="header">
            <tr className="WizardBuildingTable__header-row">
              <th colSpan={hasActions ? 1 : columnCount}>
                <strong>{wizard.name}</strong>
              </th>
              {hasActions && (
                <td
                  colSpan={columnCount - 1}
                  className="text-right WizardBuildingTable__actions"
                >
                  {onDelete && (
                    <Button borderless onClick={this.handleDelete}>
                      Fjern tilbudspakke
                    </Button>
                  )}
                  {onEdit && (
                    <Button active onClick={this.handleEdit}>
                      Tilføj produkter
                    </Button>
                  )}
                </td>
              )}
            </tr>
            <tr className="WizardBuildingTable__header-row">
              <th colSpan={2}>
                <FlexContainer
                  className="WizardBuildingTable__building"
                  horizontal
                >
                  <Icon
                    onClick={this.handleBuildingInfoClick}
                    className="WizardBuildingTable__info"
                    icon="information"
                    size={24}
                  />
                  <Flex noGrow>
                    <p>
                      {building.name}
                      <span className="text-muted">
                        {' '}
                        - {formatAddressLine(building)}
                      </span>
                    </p>
                  </Flex>
                </FlexContainer>
              </th>
              <td>Antal</td>
              <td>Mængde</td>
              <td className="text-right">Pris (m. moms)</td>
              {allowLineItemEditing ? <td></td> : null}
            </tr>
          </tbody>

          {wizardBuildingSteps.map((wbs) => (
            <WizardBuildingStepTableBody
              key={wbs.id}
              allowLineItemEditing={allowLineItemEditing}
              {...wbs}
            />
          ))}
        
          <tbody>
            {wizardBuilding.discount && (
              <WizardBuildingDiscountRow
                user={this.props.user}
                wizardBuilding={wizardBuilding}
                allowLineItemEditing={allowLineItemEditing}
                onUpdateDiscount={this.props.onUpdateDiscount}
                openModal={this.openModal}
              />
            )}
          </tbody>
        </table>
        {showModal && (
          <DiscountAdjustmentDialog 
            closeModal={this.closeModal}
            onUpdateDiscount={onUpdateDiscount}
            user={user}
            wizardBuilding={wizardBuilding}
          />
        )}
      </>
    )
  }
}

export default connect(undefined, { ...DialogActions })(WizardBuildingTable)
