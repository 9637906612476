import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { fetchUsers } from '../actions/UserActions'
import { getUsers } from '../selectors/UserSelectors'
import { fetchDepartments } from '../actions/DepartmentActions'
import { getDepartments } from '../selectors/DepartmentSelectors'
import { fetchWizards } from '../actions/WizardActions'
import { getWizards } from '../selectors/WizardSelectors'
import * as AlgoliaActions from '../actions/AlgoliaActions'
import Spinner from '../components/Spinner'
import { IconButton } from '../components/Button'
import Page, { PageHeader, PageActions, PageContent } from '../components/Page'
import OrderTable from '../components/OrderTable'
import LoadMoreButton from '../components/LoadMoreButton'
import OrderFilters from '../components/OrderFilters'

class OrdersContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    query: PropTypes.object,
    fetchUsers: PropTypes.func.isRequired,
    fetchDepartments: PropTypes.func.isRequired,
    fetchWizards: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    orders: PropTypes.array.isRequired,
    users: PropTypes.array,
    departments: PropTypes.array,
    wizards: PropTypes.array,

    searchAlgolia: PropTypes.func.isRequired,
    searchMoreAlgolia: PropTypes.func.isRequired,
    setAlgoliaQuery: PropTypes.func.isRequired,
    clearAlgoliaCache: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleOrderClick = this.handleOrderClick.bind(this)
    this.handleReloadClick = this.handleReloadClick.bind(this)
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this)
    this.handleLoaded = this.handleLoaded.bind(this)
    this.handleSortClick = this.handleSortClick.bind(this)
    this.handleQueryChange = this.handleQueryChange.bind(this)
  }

  componentDidMount() {
    this.search(true)
    this.handleQueryChange({
      sortBy: 'created_at_timestamp',
      sortDirection: 'desc',
    })
    this.props.fetchUsers()
    this.props.fetchDepartments()
    this.props.fetchWizards()
  }

  componentDidUpdate({ query }) {
    if (query && query !== this.props.query) this.search()
  }

  search(clearCache) {
    if (clearCache) this.props.clearAlgoliaCache('orders')
    this.props.searchAlgolia('orders')
  }

  handleQueryChange(query) {
    this.props.setAlgoliaQuery('orders', query)
  }

  handleOrderClick({ objectID: id, aasmState: state }) {
    const url = state === 'draft' ? `/drafts/${id}` : `/orders/${id}`
    this.props.push(url)
  }

  handleReloadClick() {
    this.search(true)
  }

  handleLoadMoreClick() {
    this.props.searchMoreAlgolia('orders')
  }

  handleLoaded() {
    this.scrollToBottom()
  }

  handleSortClick(fieldName) {
    const { query } = this.props
    if (query.sortBy === fieldName) {
      if (query.sortDirection === 'desc') {
        this.props.setAlgoliaQuery('orders', { sortBy: '', sortDirection: '' })
      } else {
        this.props.setAlgoliaQuery('orders', {
          sortDirection: query.sortDirection === 'asc' ? 'desc' : 'asc',
        })
      }
    } else {
      this.props.setAlgoliaQuery('orders', {
        sortBy: fieldName,
        sortDirection: 'asc',
      })
    }
  }

  scrollToBottom() {
    const node = findDOMNode(this)
    const content = node && node.querySelector('.page__content')

    if (content) {
      content.scrollTop = content.scrollHeight
    }
  }

  renderContent() {
    const { orders, isLoading, query, pages } = this.props
    const canLoadMore = pages.current + 1 < pages.total

    if (!orders.length && isLoading) {
      return (
        <PageContent>
          <Spinner center />
        </PageContent>
      )
    }

    return (
      <PageContent>
        <OrderTable
          orders={orders}
          onOrderClick={this.handleOrderClick}
          onSortClick={this.handleSortClick}
          sortedBy={query.sortBy}
          sortDirection={query.sortDirection}
        />
        <div className="text-center" style={{ padding: '20px' }}>
          {canLoadMore && (
            <LoadMoreButton
              isLoading={isLoading}
              onClick={this.handleLoadMoreClick}
            />
          )}
        </div>
      </PageContent>
    )
  }

  render() {
    const { query, users, departments, wizards } = this.props

    return (
      <Page>
        <PageHeader title="Tilbud">
          <PageActions>
            <OrderFilters
              query={query}
              onChange={this.handleQueryChange}
              salesmen={users}
              departments={departments}
              wizards={wizards}
            />
            <IconButton
              icon="reload"
              size={30}
              onClick={this.handleReloadClick}
            />
          </PageActions>
        </PageHeader>
        {this.renderContent()}
      </Page>
    )
  }
}

const emptyObject = {}
const mapStateToProps = (state) => ({
  orders: state.algolia.hits.orders || [],
  users: getUsers(state),
  departments: getDepartments(state),
  wizards: getWizards(state),
  pages: state.algolia.pages.orders || emptyObject,
  query: state.algolia.queries.orders || emptyObject,
  isLoading: (state.algolia.loadCount.orders || 0) !== 0,
})

export default connect(mapStateToProps, {
  ...AlgoliaActions,
  fetchUsers,
  fetchDepartments,
  fetchWizards,
  push,
})(OrdersContainer)
