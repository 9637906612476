import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import omit from 'lodash/omit'
import { updateCustomer } from '../actions/CustomerActions'
import { isValidEmail } from '../utils/validationUtils'
import Input from '../components/Input'

class CustomerEmailInputContainer extends Component {
  static propTypes = {
    updateCustomer: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    customerEmail: PropTypes.string,
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      updating: false,
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeClick = this.handleChangeClick.bind(this)
  }

  handleInputChange(event) {
    const { target } = event
    const { onChange } = this.props

    this.setState({ email: target.value })

    onChange(event)
  }

  handleChangeClick() {
    const { customerId } = this.props
    const { email } = this.state

    this.setState({ updating: true })

    this.props
      .updateCustomer({ id: customerId, email })
      .then(() => this.setState({ updating: false, error: false }))
      .catch(() => this.setState({ updating: false, error: true }))
  }

  renderUpdateEmailPrompt() {
    const { updating, error } = this.state
    let action = 'Du kan trykke her for at opdatere den.'
    let message =
      'Den email-adresse du har indtastet, matcher ikke den vi ' +
      'har på kunden i systemet.'

    const style = {
      opacity: updating ? 0.5 : 1,
    }

    if (error) {
      action = 'Tryk her for at prøve igen.'
      message = 'Der opstod en fejl og e-mailadressen kunne ikke opdateres.'
    }

    return (
      <p style={style}>
        {message + ' '}
        <span className="a" onClick={this.handleChangeClick}>
          {action}
        </span>
      </p>
    )
  }

  render() {
    const { email, updating } = this.state
    const { customerEmail } = this.props
    const shouldRenderPrompt =
      (customerEmail && customerEmail.trim()) !== email && isValidEmail(email)
    const otherProps = omit(
      this.props,
      'customerId',
      'customerEmail',
      'onChange',
      'updateCustomer',
      updating ? 'disabled' : ''
    )

    return (
      <div>
        <Input
          type="email"
          onChange={this.handleInputChange}
          disabled={updating}
          {...otherProps}
        />
        {shouldRenderPrompt ? this.renderUpdateEmailPrompt() : null}
      </div>
    )
  }
}

export const UnconnectedCustomerEmailInputContainer =
  CustomerEmailInputContainer

export default connect(null, {
  updateCustomer,
})(CustomerEmailInputContainer)
