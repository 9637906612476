import { createSelector } from 'reselect'
import {
  createEntitiesSelector,
  createIsFetchingSelector,
  createEntitySelector,
  getEntities,
} from './ApiSelectors'

export const getWizards = createEntitiesSelector('wizards')
export const isFetching = createIsFetchingSelector('wizards')

export const getWizard = createEntitySelector('wizards')

export const getWizardsForWizardBuildings = createSelector(
  getEntities('wizards'),
  (state, wizardBuildings) => wizardBuildings.map((wb) => wb.wizard),
  (wizardEntities, wizardIds) =>
    wizardIds.reduce((result, id) => {
      const wizard = wizardEntities[id]
      if (result.indexOf(wizard) === -1) {
        result.push(wizard)
      }
      return result
    }, [])
)
