import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'

export const getAssessmentCategoryTypeId = (state, props) =>
  props.params.categoryTypeId
export const getAssessments = createEntitiesSelector('assessments')

export const getAssessmentsForAssessmentCategoryType = createSelector(
  getAssessments,
  getAssessmentCategoryTypeId,
  (assessments, typeId) =>
    assessments.filter((a) => a.assessmentCategoryType === typeId)
)
