import {
  fetchEntities,
  fetchEntity,
  createEntity,
  updateEntity,
} from './ApiActions'

const stringToUnderscore = (string) =>
  string.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)

const attributesToFormData = (attributes) => {
  const key = 'building'
  const formData = new FormData()

  Object.getOwnPropertyNames(attributes).forEach((name) => {
    const value = attributes[name]

    // Send values that are truthy
    if (value) {
      const fieldName = `${key}[${stringToUnderscore(name)}]`

      if (value instanceof Blob && value.name) {
        formData.append(fieldName, attributes[name], value.name)
      } else {
        formData.append(fieldName, attributes[name])
      }
    }
  })

  return formData
}

export const fetchBuildings = (options) => fetchEntities('buildings', options)
export const fetchBuilding = (id) => fetchEntity({ type: 'buildings', id })

export const fetchBuildingsForCustomer = (customerId) =>
  fetchEntities('buildings', {
    query: { customer_id: customerId, include: ['building_images'] },
  })

export const createBuilding = (attributes) =>
  createEntity(
    { type: 'buildings' },
    { body: attributesToFormData(attributes) }
  )

export const updateBuilding = (id, attributes) =>
  updateEntity(
    { type: 'buildings', id },
    { body: attributesToFormData(attributes) }
  )
