import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import UserRoles from '../../constants/UserRoles'
import { formatResponseErrors, formatUserRole } from '../../utils/formatUtils'
import { createUser, updateUser } from '../../actions/UserActions'
import * as DepartmentActions from '../../actions/DepartmentActions'
import * as DepartmentSelectors from '../../selectors/DepartmentSelectors'
import { normalizePhoneNumber } from '../../utils/normalizeUtils'
import Spinner from '../../components/Spinner'
import UserForm from '../../components/UserForm'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog'

const normalizeAttributes = (attributes) => ({
  ...attributes,
  phone: attributes.phone ? normalizePhoneNumber(attributes.phone) : null,
})

const userRoleOptions = UserRoles.map((userRole) => ({
  value: userRole,
  label: formatUserRole(userRole),
}))

class UserDialog extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    fields: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    isFetchingDepartments: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchDepartments: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleCreateClick = this.handleCreateClick.bind(this)
  }

  componentDidMount() {
    const { fetchDepartments } = this.props
    fetchDepartments()
  }

  handleCreateClick() {
    this.formElement.submit()
  }

  handleFormSubmit(attributes) {
    const { onClose, user } = this.props
    const normalizedAttributes = normalizeAttributes(attributes)

    const request = user
      ? this.props.updateUser(user.id, normalizedAttributes)
      : this.props.createUser(normalizedAttributes)

    return request
      .then(() => onClose())
      .catch((err) => Promise.reject(formatResponseErrors(err)))
  }

  render() {
    const {
      isFetchingDepartments,
      departments,
      user,
      fields,
      handleSubmit,
      submitting,
      dirty,
      onClose,
    } = this.props

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton disabled={submitting} onClick={onClose}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>
            {user ? `Rediger ${fields.name.value}` : 'Opret bruger'}
          </DialogTitle>
          <DialogButton
            onClick={this.handleCreateClick}
            disabled={submitting || !dirty}
          >
            Gem
          </DialogButton>
        </DialogHeader>
        {isFetchingDepartments ? (
          <Spinner center />
        ) : (
          <UserForm
            user={user}
            dirty={dirty}
            fields={fields}
            userRoles={userRoleOptions}
            departments={departments}
            handleSubmit={handleSubmit(this.handleFormSubmit)}
            submitting={submitting}
            ref={(element) => (this.formElement = element)}
          />
        )}
      </Dialog>
    )
  }
}

const getInitialValues = (user) =>
  user ? { ...user, signature: user.signatureUrl } : user

const mapStateToProps = (state, { user }) => ({
  user,
  isFetchingDepartments: DepartmentSelectors.isFetching(state),
  departments: DepartmentSelectors.getDepartments(state),
  initialValues: getInitialValues(user),
})

export default connect(mapStateToProps, {
  createUser,
  updateUser,
  ...DepartmentActions,
})(
  reduxForm({
    form: 'user',
    fields: UserForm.fields,
  })(UserDialog)
)
