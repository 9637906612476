// Taken from here (and updated):
// https://dzone.com/articles/javascript-inflector-update

const singulars = [
  [/(quiz)zes$/i, '$1'],
  [/(matr)ices$/i, '$1ix'],
  [/(vert|ind)ices$/i, '$1ex'],
  [/^(ox)en/i, '$1'],
  [/(alias|status)es$/i, '$1'],
  [/(octop|vir)i$/i, '$1us'],
  [/(cris|ax|test)es$/i, '$1is'],
  [/(shoe)s$/i, '$1'],
  [/(o)es$/i, '$1'],
  [/(bus)es$/i, '$1'],
  [/([m|l])ice$/i, '$1ouse'],
  [/(x|ch|ss|sh)es$/i, '$1'],
  [/(m)ovies$/i, '$1ovie'],
  [/(s)eries$/i, '$1eries'],
  [/([^aeiouy]|qu)ies$/i, '$1y'],
  [/([lr])ves$/i, '$1f'],
  [/(tive)s$/i, '$1'],
  [/(hive)s$/i, '$1'],
  [/([^f])ves$/i, '$1fe'],
  [/(^analy)ses$/i, '$1sis'],
  [/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$/i, '$1$2sis'],
  [/([ti])a$/i, '$1um'],
  [/(n)ews$/i, '$1ews'],
  [/s$/i, ''],
]

const irregulars = [
  ['move', 'moves'],
  ['sex', 'sexes'],
  ['child', 'children'],
  ['man', 'men'],
  ['person', 'people'],
]

const uncountables = [
  'sheep',
  'fish',
  'series',
  'species',
  'money',
  'rice',
  'information',
  'equipment',
]

export default (word) => {
  for (let i = 0; i < uncountables.length; i++) {
    const uncountable = uncountables[i]

    if (word === uncountable) {
      return word
    }
  }

  for (let i = 0; i < irregulars.length; i++) {
    const [singular, plural] = irregulars[i]

    if (word === singular || word === plural) {
      return plural
    }
  }

  for (let i = 0; i < singulars.length; i++) {
    const [regex, replaceString] = singulars[i]

    if (regex.test(word)) {
      return word.replace(regex, replaceString)
    }
  }

  return word
}
