import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { push } from 'react-router-redux'
import * as ReportTemplateActions from '../actions/ReportTemplateActions'
import * as Selectors from '../selectors/ReportTemplateSelectors'
import { connect } from 'react-redux'

import { Row, Column, VerticalAlign } from '../components/Grid'
import { List, ListItem } from '../components/List'
import Spinner from '../components/Spinner'
import NewReportHeader from '../components/NewReportHeader'

class NewReportTemplateContainer extends Component {
  static propTypes = {
    fetchReportTemplates: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    reportTemplates: PropTypes.array.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  constructor(props) {
    super(props)
    this.renderReportTemplate = this.renderReportTemplate.bind(this)
  }

  componentDidMount() {
    const { fetchReportTemplates } = this.props
    fetchReportTemplates()
  }

  handleReportTemplateClick(reportTemplate) {
    this.props.push(`/reports/new/${reportTemplate.id}`)
  }

  renderReportTemplate(reportTemplate) {
    return (
      <ListItem
        thumbnail={reportTemplate.thumbnailImageUrl}
        title={reportTemplate.name}
        key={reportTemplate.id}
        onClick={() => this.handleReportTemplateClick(reportTemplate)}
      />
    )
  }

  render() {
    const { isFetching, reportTemplates } = this.props

    if (isFetching) {
      return <Spinner center />
    }

    return (
      <VerticalAlign>
        <Row>
          <Column size={6} offset={3}>
            <NewReportHeader>Vælg rapporttype</NewReportHeader>
            <List
              items={reportTemplates}
              itemRenderer={this.renderReportTemplate}
              noPadding
            />
          </Column>
        </Row>
      </VerticalAlign>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: Selectors.isFetching(state),
  reportTemplates: Selectors.getReportTemplates(state),
})

export default connect(mapStateToProps, {
  ...ReportTemplateActions,
  push,
})(NewReportTemplateContainer)
