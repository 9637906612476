const compare = (valueA, valueB, ascending = true) => {
  let result = 0

  if (valueA > valueB) {
    result = 1
  } else if (valueB > valueA) {
    result = -1
  }

  return ascending ? result : -result
}

const orderBy =
  (optionOrOptions, optionAscending = true) =>
  (a, b) => {
    let comparisonOptions
    if (typeof optionOrOptions === 'string') {
      comparisonOptions = [[optionOrOptions, optionAscending]]
    } else {
      comparisonOptions = optionOrOptions
    }

    for (const comparisonOption of comparisonOptions) {
      let property = comparisonOption
      let ascending = true

      if (Array.isArray(comparisonOption)) {
        property = comparisonOption[0]
        ascending = comparisonOption[1] !== false
      }

      const comparisonResult = compare(a[property], b[property], ascending)

      if (comparisonResult !== 0) {
        return comparisonResult
      }
    }

    return 0
  }

export default orderBy
