import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { getFetchCountForEntityTypes } from '../../selectors/ApiSelectors'
import { setEntityQuery } from '../../actions/ApiActions'
import * as ReferenceProductVariantActions from '../../actions/ReferenceProductVariantActions'
import { getReferenceProductVariants } from '../../selectors/ReferenceProductVariantsSelectors'
import './style.styl'

import Input from '../Input'
import { IconButton } from '../Button'
import { Select } from '../Select'

class ReferenceFilters extends Component {
  static propTypes = {
    query: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    referenceProducts: PropTypes.array,
    fetchReferenceProductVariants: PropTypes.func.isRequired,
    referenceProductVariants: PropTypes.array,
    setEntityQuery: PropTypes.func.isRequired,
    invalidateReferenceProductVariants: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      zipFrom: '',
      zipTo: '',
      productId: '',
      productVariantId: '',
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleReferenceProductChange =
      this.handleReferenceProductChange.bind(this)
    this.performScheduledReload = this.performScheduledReload.bind(this)
  }

  scheduleReload() {
    this.unscheduleReload()
    this.reloadTimer = setTimeout(this.performScheduledReload, 250)
  }

  unscheduleReload() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer)
      this.reloadTimer = null
    }
  }

  performScheduledReload() {
    const { invalidateReferenceProductVariants, query } = this.props

    invalidateReferenceProductVariants()
    this.fetchReferenceProductVariants({ query })
  }

  handleInputChange({ target: { name, value } }) {
    this.setState({ [name]: value })
  }

  handleReferenceProductChange({ target: { value } }) {
    this.props.setEntityQuery('referenceProductVariants', { product_id: value })
    this.scheduleReload()
    this.setState({
      productId: value,
    })
  }

  isFormValid() {
    return this.state.zipFrom && this.state.zipTo && this.state.productId
  }

  handleQueryChange() {
    this.props.onChange({
      zip_from: this.state.zipFrom,
      zip_to: this.state.zipTo,
      product_id: this.state.productId,
      product_variant_id: this.state.productVariantId || null,
    })
  }

  fetchReferenceProductVariants(options) {
    const { fetchReferenceProductVariants } = this.props
    return fetchReferenceProductVariants(options)
  }

  entitiesToOptions(entities) {
    const options = entities.map((c) => ({
      value: c.id,
      label: c.name,
    }))
    options.unshift({ label: 'Vælg kategori', value: '' })

    return options
  }

  render() {
    const referenceProducts = this.entitiesToOptions(
      this.props.referenceProducts
    )
    const variants = this.entitiesToOptions(this.props.referenceProductVariants)

    return (
      <div className="order-filters">
        Fra
        <Input
          type="number"
          name="zipFrom"
          defaultValue={this.state.zipFrom}
          onChange={this.handleInputChange}
          placeholder="zip fra"
          className="reference-filter__zip-code"
        />
        Til
        <Input
          type="text"
          name="zipTo"
          defaultValue={this.state.zipTo}
          onChange={this.handleInputChange}
          placeholder="zip til"
          className="reference-filter__zip-code"
        />
        <Select
          options={referenceProducts}
          value={this.state.productId}
          onChange={this.handleReferenceProductChange}
          name="productId"
          className="reference-filter__select"
        />
        {this.state.productId ? (
          <Select
            options={variants}
            value={this.state.productVariantId}
            onChange={this.handleInputChange}
            name="productVariantId"
            className="reference-filter__select"
          />
        ) : null}
        <IconButton
          icon="reload"
          size={30}
          onClick={this.handleQueryChange}
          disabled={!this.isFormValid()}
          title={
            this.isFormValid()
              ? 'Load reference list'
              : 'Please select zip codes and product!'
          }
        />
      </div>
    )
  }
}

const fetchedEntityTypes = ['reference_product_variants']
const mapStateToProps = (state) => ({
  isFetching: getFetchCountForEntityTypes(state, fetchedEntityTypes) !== 0,
  referenceProductVariants: getReferenceProductVariants(state),
  query: state.api.entityQueries.referenceProductVariants || {},
})

export default connect(mapStateToProps, {
  ...ReferenceProductVariantActions,
  setEntityQuery,
})(ReferenceFilters)
