import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import omit from 'lodash/omit'
import classNames from 'classnames'

const Row = (props) => {
  const { className, horizontalMargin, verticalMargin } = props
  const classes = classNames('row', className, {
    'row--horizontal-margin': horizontalMargin,
    'row--vertical-margin': verticalMargin,
  })
  const otherProps = omit(
    props,
    'className',
    'verticalMargin',
    'horizontalMargin'
  )

  return <div className={classes} {...otherProps} />
}

Row.propTypes = {
  className: PropTypes.string,
  verticalMargin: PropTypes.bool,
  horizontalMargin: PropTypes.bool,
}

const Column = (props) => {
  const { className, size, offset } = props

  const classes = classNames(`col-${size}`, className, {
    [`col-offset-${offset}`]: typeof offset === 'number',
  })
  const otherProps = omit(props, 'className', 'size', 'offset')

  return <div className={classes} {...otherProps} />
}

Column.propTypes = {
  size: PropTypes.number.isRequired,
  offset: PropTypes.number,
  className: PropTypes.string,
}

const VerticalAlign = ({ children }) => (
  <div className="va__container">
    <div className="va__content">{children}</div>
  </div>
)

VerticalAlign.propTypes = {
  children: PropTypes.any,
}

export const FlexContainer = ({
  className,
  children,
  horizontal,
  fill,
  style,
}) => {
  const classes = classNames(className, 'flex-container', {
    'flex-container--horizontal': horizontal,
    'flex-container--fill': fill,
  })
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  )
}

FlexContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  fill: PropTypes.bool,
  style: PropTypes.object,
}

export const Flex = ({
  children,
  className,
  size,
  noShrink,
  noGrow,
  scrollY,
  scrollX,
  style,
}) => {
  const classes = classNames(className, size && `flex--${size}`, 'flex', {
    'flex--grow': !noGrow,
    'flex--shrink': !noShrink,
    'flex--scroll-y': scrollY,
    'flex--scroll-x': scrollX,
  })
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  )
}

Flex.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.number,
  noShrink: PropTypes.bool,
  noGrow: PropTypes.bool,
  style: PropTypes.object,
  scrollY: PropTypes.bool,
  scrollX: PropTypes.bool,
}

export const Margin = (props) => {
  let { left = false, right = false, top = false, bottom = false } = props
  const {
    size = 2,
    vertical = false,
    horizontal = false,
    all = false,
    children,
  } = props

  if (all) {
    left = right = top = bottom = true
  }
  if (horizontal) {
    left = right = true
  }
  if (vertical) {
    top = bottom = true
  }

  const className = classNames(props.className, 'margin', {
    [`margin--left-${size}`]: left,
    [`margin--right-${size}`]: right,
    [`margin--top-${size}`]: top,
    [`margin--bottom-${size}`]: bottom,
  })
  return <div className={className}>{children}</div>
}

Margin.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.number,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  all: PropTypes.bool,
}

export { Row, Column, VerticalAlign }
