import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Icon from '../../Icon'
import PopoverMenu from '../../PopoverMenu'

class SidebarHeader extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    onSettingsClick: PropTypes.func.isRequired,
    onPasswordClick: PropTypes.func.isRequired,
    onSignOutClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleItemClick = this.handleItemClick.bind(this)
  }

  getMenuItems() {
    const { onSettingsClick, onPasswordClick, onSignOutClick } = this.props

    return [
      {
        label: 'Indstillinger',
        handler: onSettingsClick,
        onClick: this.handleItemClick,
      },
      {
        label: 'Skift adgangskode',
        handler: onPasswordClick,
        onClick: this.handleItemClick,
      },
      {
        label: 'Log ud',
        handler: onSignOutClick,
        onClick: this.handleItemClick,
      },
    ]
  }

  handleItemClick(item) {
    item.handler()
  }

  render() {
    const { user } = this.props

    return (
      <div className="sidebar__header">
        <PopoverMenu items={this.getMenuItems()}>
          <div className="sidebar__header__user">
            {user.name}
            <Icon icon="caret" size={11} />
          </div>
        </PopoverMenu>
      </div>
    )
  }
}

export default SidebarHeader
