import React from 'react'
import PropTypes from 'react-proptypes'
import { FormField } from '../Form'
import './style.styl'

export const BuildingFormField = (props) => (
  <FormField className="building-form-field">
    <div className="building-form-field--label">{props.label}</div>
    {props.children}
  </FormField>
)

BuildingFormField.propTypes = {
  children: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
}

export default BuildingFormField
