import { createEntity, updateEntity, deleteEntity } from './ApiActions'
import { hashToFormData } from '../utils/formUtils'

const attributesToFormData = ({
  buildingPropertyId,
  resultId,
  correctionValue,
  justification,
}) =>
  hashToFormData({
    'result_correction[building_property_id]': buildingPropertyId,
    'result_correction[result_id]': resultId,
    'result_correction[correction_value]': correctionValue,
    'result_correction[justification]': justification,
  })

export const updateResultCorrection = (id, attributes) =>
  updateEntity(
    { id, type: 'resultCorrections' },
    {
      body: attributesToFormData(attributes),
    }
  )

export const createResultCorrection = (attributes) =>
  createEntity(
    { type: 'resultCorrections' },
    {
      body: attributesToFormData(attributes),
    }
  )

export const deleteResultCorrection = (id) =>
  deleteEntity({ type: 'resultCorrections', id })
