import React from 'react'
import SimpleTimeField from 'react-simple-timefield'
import PropTypes from 'prop-types'

import './style.styl'

function attachTimeToDate(date, hours, minutes, seconds) {
  const changedDate = new Date(date)
  changedDate.setHours(hours)
  changedDate.setMinutes(minutes)
  changedDate.setSeconds(seconds)
  return changedDate
}

function twoDigitFormat(number) {
  return ('0' + number).slice(-2)
}

function toTimeString(date) {
  const hoursInTwoDigitFormat = twoDigitFormat(date.getHours())
  const minutesInTwoDigitFormat = twoDigitFormat(date.getMinutes())
  return `${hoursInTwoDigitFormat}:${minutesInTwoDigitFormat}`
}

const TimePicker = ({ date, onDateChange, ...props }) => {
  const handleChange = (event) => {
    const [hours, minutes] = event.target.value.split(':')
    const changedDate = attachTimeToDate(date, hours, minutes, 0)
    onDateChange(changedDate)
  }
  return (
    <SimpleTimeField
      {...props}
      className="time-picker"
      style={{ width: '62px' }}
      value={toTimeString(date)}
      onChange={handleChange}
    />
  )
}

TimePicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
}

export default TimePicker
