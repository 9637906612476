import { createSelector } from 'reselect'
import {
  createIsFetchingSelector,
  createEntitiesSelector,
  createGetPaginationSelector,
} from './ApiSelectors'
import { formatDate } from '../utils/formatUtils'

export const getReportId = (state, props) => props.params.reportId
export const getReportEntities = (state) => state.api.entities.reports
export const getReportsSearch = (state) =>
  state.api.entityQueries.reports && state.api.entityQueries.reports.q
export const getPagination = createGetPaginationSelector('reports')

const getReportTemplateEntities = (state) => state.api.entities.reportTemplates
const getCustomerEntities = (state) => state.api.entities.customers
const getBuildingEntities = (state) => state.api.entities.buildings
const getUserEntities = (state) => state.api.entities.users

const reportWithRelations =
  (templateEntities, buildingEntities, customerEntities, userEntities) =>
  (report) => {
    if (
      !report ||
      !templateEntities ||
      !buildingEntities ||
      !customerEntities ||
      !userEntities
    ) {
      return null
    }
    const template = templateEntities[report.reportTemplate]
    const building = buildingEntities[report.building]
    const customer = building && customerEntities[building.customer]
    const user = userEntities[report.user]
    if (!template || !building || !customer || !user) {
      return null
    }

    return { ...report, template, building, customer, user }
  }

export const getReports = createEntitiesSelector('reports')
export const isFetching = createIsFetchingSelector('reports')

export const getReport = createSelector(
  getReportEntities,
  getReportId,
  (entities, id) => entities && entities[id]
)

export const getReportsWithRelations = createSelector(
  getReports,
  getReportTemplateEntities,
  getBuildingEntities,
  getCustomerEntities,
  getUserEntities,
  (
    reports,
    templateEntities,
    buildingEntities,
    customerEntities,
    userEntities
  ) => {
    if (!reports) {
      return null
    }
    return reports
      .map(
        reportWithRelations(
          templateEntities,
          buildingEntities,
          customerEntities,
          userEntities
        )
      )
      .filter((report) => report)
  }
)

export const getReportWithRelations = createSelector(
  getReport,
  getReportTemplateEntities,
  getBuildingEntities,
  getCustomerEntities,
  getUserEntities,
  (
    report,
    templateEntities,
    buildingEntities,
    customerEntities,
    userEntities
  ) =>
    reportWithRelations(
      templateEntities,
      buildingEntities,
      customerEntities,
      userEntities
    )(report)
)
