import qs from 'qs'
import apiKey from '../constants/ApiKey'
import { getToken } from '../selectors/AuthSelectors'
import { isFetching, getReferences } from '../selectors/ReferencesSelectors'
import { fetchEntities, invalidateEntities, deleteEntity } from './ApiActions'

export const fetchReferences = (options) => fetchEntities('references', options)

export const invalidateReferences = (options) =>
  invalidateEntities('references', options)

const shouldFetchReferences = (state) => {
  const references = getReferences(state)

  if (isFetching(state) || references.length > 0) {
    return false
  }

  return true
}

export const fetchReferencesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchReferences(getState())) {
    dispatch(fetchReferences())
  }
}

export const getReferenceListPDFLink = (query) => (dispatch, getState) => {
  const queryString = qs.stringify(
    {
      ...query,
      api_key_token: apiKey,
      user_token: getToken(getState()),
    },
    { skipNulls: true }
  )

  return `/api/v1/references.pdf?${queryString}`
}

export const removeReference = (id, objectType) =>
  deleteEntity(
    {
      id,
      type: 'references',
    },
    {
      data: {
        object_type: objectType,
      },
    }
  )
