import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'

const FormField = ({ className, children }) => (
  <div className={`${className} form-field`}>{children}</div>
)

FormField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export default FormField
