import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { fetchCustomer } from '../actions/CustomerActions'
import { getCurrentUser } from '../selectors/AuthSelectors'
import { fetchBuilding, updateBuilding } from '../actions/BuildingActions'
import { fetchAssessmentCategories } from '../actions/AssessmentCategoryActions'
import { fetchAssessmentCategoryImages } from '../actions/AssessmentCategoryImageActions'
import { fetchBuildingAssessmentCategoryTypes } from '../actions/BuildingAssessmentCategoryTypeActions'
import { fetchBuildingAssessmentCategoryImages } from '../actions/BuildingAssessmentCategoryImageActions'
import { createReport } from '../actions/ReportActions'
import { getBuilding } from '../selectors/BuildingSelectors'
import { getReportTemplateWithAssessmentCategoriesForPDF } from '../selectors/ReportTemplateSelectors'
import { getBuildingAssessmentCategoryTypeForBuildingWithRelations } from '../selectors/BuildingAssessmentCategoryTypeSelectors'
import { getActionCountForEntityTypes } from '../selectors/ApiSelectors'
import Spinner from '../components/Spinner'
import { FlexContainer, Flex, Margin } from '../components/Grid'
import ReportAssessmentsNavigation from '../components/ReportAssessmentsNavigation'
import NewReportHeader from '../components/NewReportHeader'
import ImageInput from '../components/ImageInput'
import Button from '../components/Button'

class NewReportAssessmentsContainer extends Component {
  static propTypes = {
    children: PropTypes.any,
    push: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,

    building: PropTypes.object,
    template: PropTypes.object,

    // Params
    customerId: PropTypes.string.isRequired,
    buildingId: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    assessmentCategoryId: PropTypes.string,
    buildingAssessmentCategoryTypes: PropTypes.array,

    // Actions
    fetchCustomer: PropTypes.func.isRequired,
    fetchBuilding: PropTypes.func.isRequired,
    fetchAssessmentCategories: PropTypes.func.isRequired,
    fetchAssessmentCategoryImages: PropTypes.func.isRequired,
    fetchBuildingAssessmentCategoryTypes: PropTypes.func.isRequired,
    fetchBuildingAssessmentCategoryImages: PropTypes.func.isRequired,
    createReport: PropTypes.func.isRequired,
    updateBuilding: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  static pageTitle = 'Rapporter > Ny Rapport > Vurderinger'

  constructor(props) {
    super(props)
    this.handleNavigationClick = this.handleNavigationClick.bind(this)
    this.handleCreateReportClick = this.handleCreateReportClick.bind(this)
    this.handleBuildingImageChange = this.handleBuildingImageChange.bind(this)
    this.customer = false
    this.secret = 'g9i398P6i6tW'
  }

  componentDidMount() {
    const { buildingId, customerId } = this.props
    let thisWrapper = this
    this.props.fetchCustomer(customerId).then(function (response) {
      thisWrapper.customer = response.rawData.attributes
    })
    this.props.fetchBuilding(buildingId)
    this.props.fetchAssessmentCategories()
    this.props.fetchAssessmentCategoryImages()
    this.props.fetchBuildingAssessmentCategoryImages({
      query: { building_id: buildingId },
    })
    this.props.fetchBuildingAssessmentCategoryTypes({
      query: {
        building_id: buildingId,
        include: ['assessment_category_type', 'building_assessment.assessment'],
      },
    })
  }

  componentWillReceiveProps(nextProps) {
    const { template, customerId, buildingId } = nextProps
    if (
      !this.props.children &&
      template &&
      typeof template.assessmentCategories[0] === 'object'
    ) {
      const cat = template.assessmentCategories.find((c) => !c.hideInPdf)
      this.props.push(
        `/reports/new/${template.id}/${customerId}/${buildingId}/assessments/${cat.id}`
      )
    }
  }

  handleCreateReportClick() {
    const { buildingId, template, templateId, customerId, currentUser } =
      this.props
    this.props
      .createReport({ buildingId, templateId })
      .then(({ results: { reports } }) => {
        const reportId = reports[0]
        if (template.includeEnergySection) {
          this.props.push(`/reports/new/${buildingId}/${reportId}/energy-mark`)
        } else {
          let apiUrl = `https://jydsktagteknik.dk/trustpilot-api/v1/rateAuto/${this.secret}/${reportId}/report/${currentUser.id}/${currentUser.role}/${currentUser.name}/${this.customer.name}/${this.customer.email}/${this.customer.phone}`
          fetch(apiUrl)
          this.props.push(`/reports/${reportId}`)
        }
      })
  }

  handleNavigationClick(id) {
    const { template, customerId, buildingId } = this.props
    this.props.push(
      `/reports/new/${template.id}/${customerId}/${buildingId}/assessments/${id}`
    )
  }

  handleBuildingImageChange(file) {
    const { buildingId } = this.props
    const attributes = file
      ? { streetViewImage: file }
      : { removeStreetViewImage: true }
    this.props.updateBuilding(buildingId, attributes)
  }

  render() {
    const {
      children,
      isFetching,
      isUpdating,
      template,
      assessmentCategoryId,
      buildingAssessmentCategoryTypes,
      building,
    } = this.props

    if (isFetching || !template || !building) {
      return <Spinner center />
    }

    const categoriesWithTypeAndAssessment =
      template.assessmentCategoriesForPDF.map((ac) => {
        const bact = buildingAssessmentCategoryTypes.find(
          (b) => b.assessmentCategoryType.assessmentCategory === ac.id
        )
        return {
          ...ac,
          assessment: bact && bact.assessment,
          assessmentCategoryType: bact && bact.assessmentCategoryType,
        }
      })

    const hasAssessedCategories = categoriesWithTypeAndAssessment.some(
      (cat) => !cat.hideInPdf && cat.assessment && cat.assessmentCategoryType
    )

    return (
      <FlexContainer horizontal style={{ height: '100%' }}>
        <Flex size={3} scrollY>
          <Margin all>
            <NewReportHeader>Tjekliste</NewReportHeader>
            <ReportAssessmentsNavigation
              currentCategoryId={assessmentCategoryId}
              categoriesWithCategoryTypeAndAssessment={
                categoriesWithTypeAndAssessment
              }
              buildingAssessmentCategoryTypes={buildingAssessmentCategoryTypes}
              onNavigationClick={this.handleNavigationClick}
            />
          </Margin>
          <Margin all>
            <NewReportHeader>Forsidebillede</NewReportHeader>
            {isUpdating ? (
              <Spinner centerHorizontal />
            ) : (
              <ImageInput
                initialValue={building.streetViewImageUrl}
                onChange={this.handleBuildingImageChange}
                compress={{
                  quality: 0.8,
                  maxWidth: 1200,
                  maxHeight: 1200,
                }}
              />
            )}
          </Margin>
          <Margin all>
            <Button
              disabled={!hasAssessedCategories}
              onClick={this.handleCreateReportClick}
              active
            >
              Afslut rapport
            </Button>
          </Margin>
        </Flex>
        <Flex
          size={9}
          scrollY
          className="padding-20"
          style={{
            // Overflow scrolling was causing the content to not be displayed correctly under some
            // circumstances
            webkitOverflowScrolling: 'initial',
          }}
        >
          {children}
        </Flex>
      </FlexContainer>
    )
  }
}

const fetchedTypes = ['buildings', 'reportTemplates', 'assessmentCategories']

const mapStateToProps = (state, props) => {
  const { customerId, templateId, buildingId } = props.routeParams

  return {
    isFetching:
      getActionCountForEntityTypes(state, 'fetching', fetchedTypes) !== 0,
    isUpdating:
      getActionCountForEntityTypes(state, 'updating', 'buildings') !== 0,
    customerId,
    templateId,
    buildingId,
    assessmentCategoryId: props.params.assessmentCategoryId,
    buildingAssessmentCategoryTypes:
      getBuildingAssessmentCategoryTypeForBuildingWithRelations(state, props),
    building: getBuilding(state, props),
    template: getReportTemplateWithAssessmentCategoriesForPDF(state, props),
    currentUser: getCurrentUser(state),
  }
}

export default connect(mapStateToProps, {
  fetchBuilding,
  fetchCustomer,
  fetchAssessmentCategories,
  fetchAssessmentCategoryImages,
  fetchBuildingAssessmentCategoryTypes,
  fetchBuildingAssessmentCategoryImages,
  updateBuilding,
  createReport,
  push,
})(NewReportAssessmentsContainer)
