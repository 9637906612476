import React, { Component } from 'react'
import PropTypes from 'react-proptypes'

import Input from '../Input'
import Button from '../Button'
import { Margin, FlexContainer, Flex } from '../Grid'
import SettingsList, { SettingsListItem } from '../SettingsList'
import ButtonAddon from '../ButtonAddon'
import { reduxForm } from 'redux-form'
import './style.styl'
import { formatUnitType } from '../../utils/formatUtils'

class BuildingPropertyForm extends Component {
  static propTypes = {
    submitting: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    children: PropTypes.any,
    renderSubmitButton: PropTypes.func,
    pristine: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    property: PropTypes.object,
    imageUrl: PropTypes.string.isRequired,
    measurements: PropTypes.array.isRequired,
  }

  static defaultProps = {
    submitting: false,
  }

  renderMeasurement = (measurement) => {
    const { submitting } = this.props
    const field = this.props.fields[measurement.id]

    return (
      <SettingsListItem
        key={measurement.id}
        label={measurement.description}
        error={field.touched && field.error}
      >
        <FlexContainer horizontal className="btn-group">
          <Flex>
            <Input disabled={submitting} step="0.01" type="number" {...field} />
          </Flex>
          <Flex noShrink noGrow>
            <ButtonAddon className="BuildingPropertyForm__unit">
              {formatUnitType(measurement.unitType)}
            </ButtonAddon>
          </Flex>
        </FlexContainer>
      </SettingsListItem>
    )
  }

  render() {
    const {
      fields: { name, quantity },
      handleSubmit,
      pristine,
      submitting,
      valid,
      children,
      renderSubmitButton,
      imageUrl,
      measurements,
    } = this.props

    const canSubmit = !pristine && !submitting && valid

    return (
      <form onSubmit={handleSubmit}>
        {imageUrl && (
          <Margin all size={2}>
            <div
              className="BuildingPropertyForm__image"
              style={{ backgroundImage: `url('${imageUrl}')` }}
            />
          </Margin>
        )}
        <SettingsList>
          <SettingsListItem label="Navn" error={name.touched && name.error}>
            <Input
              disabled={submitting}
              placeholder="F.eks. Hovedtag, karnap eller tagvinduer"
              type="text"
              {...name}
            />
          </SettingsListItem>
          <SettingsListItem
            label="Antal"
            description="Hvor mange enheder af denne type har huset?"
            error={quantity.touched && quantity.error}
          >
            <Input disabled={submitting} type="number" {...quantity} />
          </SettingsListItem>

          {measurements.map(this.renderMeasurement)}
        </SettingsList>

        <Margin all size={2}>
          <FlexContainer horizontal>
            {children && <Flex noGrow>{children}</Flex>}
            <Flex />
            <Flex noGrow>
              {renderSubmitButton ? (
                renderSubmitButton(!canSubmit)
              ) : (
                <Button type="submit" disabled={!canSubmit}>
                  Gem
                </Button>
              )}
            </Flex>
          </FlexContainer>
        </Margin>
      </form>
    )
  }
}

export default reduxForm({
  form: 'buildingProperty',
  validate: (values) => {
    const errors = {}
    Object.entries(values).forEach(([name, value]) => {
      if (value === '') errors[name] = 'Påkrævet'
    })
    return errors
  },
})(BuildingPropertyForm)
