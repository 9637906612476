import { fetchEntity } from './ApiActions'

export const fetchKpiReport = (id, query, options) => (dispatch, _getState) => {
  return dispatch(
    fetchEntity(
      {
        type: 'kpiReports',
        id,
        ...options,
      },
      {
        query: {
          query,
        },
      }
    )
  )
}
