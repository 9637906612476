import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import { fetchAssessmentCategoryTypesForCategory } from '../actions/AssessmentCategoryTypeActions'
import {
  createBuildingAssessmentCategoryType,
  updateBuildingAssessmentCategoryType,
  deleteBuildingAssessmentCategoryType,
} from '../actions/BuildingAssessmentCategoryTypeActions'
import {
  createBuildingAssessmentCategoryImage,
  deleteBuildingAssessmentCategoryImage,
} from '../actions/BuildingAssessmentCategoryImageActions'
import { deleteBuildingAssessment } from '../actions/BuildingAssessmentActions'

import { getAssessmentCategory } from '../selectors/AssessmentCategorySelectors'
import { getReportTemplate } from '../selectors/ReportTemplateSelectors'
import { getAssessmentCategoryTypesForCategory } from '../selectors/AssessmentCategoryTypeSelectors'
import { getAssessmentCategoryImagesForCategory } from '../selectors/AssessmentCategoryImageSelectors'
import { getBuildingAssessmentCategoryTypeForBuildingAndCategory } from '../selectors/BuildingAssessmentCategoryTypeSelectors'
import { getBuildingAssessmentForBuildingAndCategoryType } from '../selectors/BuildingAssessmentSelectors'
import { getBuildingAssessmentCategoryImagesForBuildingAndCategory } from '../selectors/BuildingAssessmentCategoryImageSelectors'

import {
  getFetchCountForEntityTypes,
  getActionCountForEntityTypes,
} from '../selectors/ApiSelectors'

import NewReportAssessmentContainer from './NewReportAssessmentContainer'
import NewReportCategoryCommentsContainer from './NewReportCategoryCommentsContainer'

import Spinner from '../components/Spinner'
import { BuildingFormSelect } from '../components/BuildingFormSelect'
import { BuildingFormField } from '../components/BuildingFormField'
import NewReportHeader from '../components/NewReportHeader'
import BuildingAssessmentCategoryImagesInput from '../components/BuildingAssessmentCategoryImagesInput'

class NewReportAssessmentCategoryContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isUpdatingImages: PropTypes.bool.isRequired,

    buildingId: PropTypes.string.isRequired,
    assessmentCategoryId: PropTypes.string.isRequired,

    assessmentCategory: PropTypes.object,
    assessmentCategoryTypes: PropTypes.array,
    assessmentCategoryImages: PropTypes.array,
    buildingAssessmentCategoryType: PropTypes.object,
    selectedAssessmentCategoryType: PropTypes.object,
    buildingAssessmentCategoryImages: PropTypes.array,
    buildingAssessment: PropTypes.object,
    reportTemplate: PropTypes.object,

    fetchAssessmentCategoryTypesForCategory: PropTypes.func.isRequired,
    createBuildingAssessmentCategoryType: PropTypes.func.isRequired,
    updateBuildingAssessmentCategoryType: PropTypes.func.isRequired,
    deleteBuildingAssessmentCategoryType: PropTypes.func.isRequired,
    deleteBuildingAssessment: PropTypes.func.isRequired,
    createBuildingAssessmentCategoryImage: PropTypes.func.isRequired,
    deleteBuildingAssessmentCategoryImage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  constructor(props) {
    super(props)
    this.onCategoryTypeChange = this.onCategoryTypeChange.bind(this)
    this.handleImageAdd = this.handleImageAdd.bind(this)
    this.addImage = this.addImage.bind(this)
    this.handleImageRemove = this.handleImageRemove.bind(this)
  }

  componentWillMount() {
    const { assessmentCategoryId } = this.props
    this.props.fetchAssessmentCategoryTypesForCategory(assessmentCategoryId)
  }

  componentWillReceiveProps(nextProps) {
    const { assessmentCategoryId } = nextProps
    if (assessmentCategoryId !== this.props.assessmentCategoryId) {
      this.props.fetchAssessmentCategoryTypesForCategory(assessmentCategoryId)
    }
  }

  onCategoryTypeChange({ target: { value } }) {
    const { buildingId, buildingAssessmentCategoryType, buildingAssessment } =
      this.props
    const attributes = { assessmentCategoryTypeId: value, buildingId }

    if (buildingAssessment) {
      this.props.deleteBuildingAssessment(buildingAssessment.id)
    }

    if (value === '') {
      this.props.deleteBuildingAssessmentCategoryType(
        buildingAssessmentCategoryType.id
      )
    } else if (buildingAssessmentCategoryType) {
      this.props.updateBuildingAssessmentCategoryType(
        buildingAssessmentCategoryType.id,
        attributes
      )
    } else {
      this.props.createBuildingAssessmentCategoryType(attributes)
    }
  }

  addImage(image) {
    const { buildingId, assessmentCategoryId } = this.props
    const attributes = { buildingId, assessmentCategoryId }

    // If the image is a string, use if as a url
    if (
      File.prototype.isPrototypeOf(image) ||
      Blob.prototype.isPrototypeOf(image)
    ) {
      attributes.image = image
    } else {
      attributes.remoteImageUrl = image.imageUrl
      attributes.assessmentCategoryImageId = image.id
    }

    this.props.createBuildingAssessmentCategoryImage(attributes)
  }

  handleImageAdd(images) {
    let array = images
    if (!Array.isArray(images) && !FileList.prototype.isPrototypeOf(array)) {
      array = [array]
    }
    for (let i = 0; i < array.length; i++) {
      this.addImage(array[i])
    }
  }

  handleImageRemove(id) {
    this.props.deleteBuildingAssessmentCategoryImage(id)
  }

  render() {
    const {
      isFetching,
      isUpdatingImages,
      buildingId,
      assessmentCategory,
      assessmentCategoryTypes,
      assessmentCategoryImages,
      buildingAssessmentCategoryType,
      selectedAssessmentCategoryType,
      buildingAssessmentCategoryImages,
    } = this.props

    if (isFetching || !assessmentCategory || !assessmentCategoryTypes) {
      return <Spinner center />
    }

    const selectOptions = assessmentCategoryTypes.map((t) => ({
      value: t.id,
      label: t.name,
    }))

    selectOptions.unshift({
      value: '',
      label: `Medtag ikke ${assessmentCategory.name.toLowerCase()} i rapport`,
    })

    return (
      <div>
        <NewReportHeader>{assessmentCategory.name}</NewReportHeader>
        <BuildingFormField label="Vælg type">
          <BuildingFormSelect
            options={selectOptions}
            value={
              (selectedAssessmentCategoryType &&
                selectedAssessmentCategoryType.id) ||
              ''
            }
            onChange={this.onCategoryTypeChange}
          />
        </BuildingFormField>
        {selectedAssessmentCategoryType && (
          <div>
            <NewReportAssessmentContainer
              buildingId={buildingId}
              categoryTypeId={selectedAssessmentCategoryType.id}
              buildingAssessmentCategoryTypeId={
                buildingAssessmentCategoryType.id
              }
            />
            <NewReportHeader>Tilknyt foto</NewReportHeader>
            <BuildingAssessmentCategoryImagesInput
              assessmentCategoryImages={assessmentCategoryImages}
              buildingAssessmentCategoryImages={
                buildingAssessmentCategoryImages
              }
              onImageAdd={this.handleImageAdd}
              onImageRemove={this.handleImageRemove}
              loading={isUpdatingImages}
            />
            <NewReportHeader>Kommentarer</NewReportHeader>
            <NewReportCategoryCommentsContainer
              buildingAssessmentCategoryTypeId={
                buildingAssessmentCategoryType.id
              }
            />
          </div>
        )}
      </div>
    )
  }
}

const fetchedTypes = [
  'assessmentCategories',
  'assessmentCategoryTypes',
  'assessmentCategoryImages',
  'buildingAssessmentCategoryTypes',
  'buildingAssessmentCategoryImages',
]

const mapStateToProps = (state, props) => {
  const { buildingId, assessmentCategoryId } = props.params

  const assessmentCategoryTypes = getAssessmentCategoryTypesForCategory(
    state,
    props
  )
  const buildingAssessmentCategoryType =
    getBuildingAssessmentCategoryTypeForBuildingAndCategory(state, props)
  const selectedAssessmentCategoryType =
    (buildingAssessmentCategoryType &&
      assessmentCategoryTypes.find(
        (t) => t.id === buildingAssessmentCategoryType.assessmentCategoryType
      )) ||
    null

  const buildingAssessment =
    (selectedAssessmentCategoryType &&
      getBuildingAssessmentForBuildingAndCategoryType(state, {
        params: {
          ...props.params,
          categoryTypeId: selectedAssessmentCategoryType.id,
        },
      })) ||
    null

  const imageUpdateCount = getActionCountForEntityTypes(
    state,
    'updating',
    'buildingAssessmentCategoryImages'
  )
  const imageDeleteCount = getActionCountForEntityTypes(
    state,
    'deleting',
    'buildingAssessmentCategoryImages'
  )

  return {
    isFetching: getFetchCountForEntityTypes(state, fetchedTypes) !== 0,
    isUpdatingImages: imageUpdateCount + imageDeleteCount !== 0,
    buildingId,
    assessmentCategoryId,
    assessmentCategory: getAssessmentCategory(state, props),
    assessmentCategoryImages: getAssessmentCategoryImagesForCategory(
      state,
      props
    ),
    buildingAssessment,
    assessmentCategoryTypes,
    buildingAssessmentCategoryType,
    selectedAssessmentCategoryType,
    buildingAssessmentCategoryImages:
      getBuildingAssessmentCategoryImagesForBuildingAndCategory(state, props),
    reportTemplate: getReportTemplate(state, props),
  }
}

export default connect(mapStateToProps, {
  fetchAssessmentCategoryTypesForCategory,
  createBuildingAssessmentCategoryType,
  updateBuildingAssessmentCategoryType,
  deleteBuildingAssessmentCategoryType,
  deleteBuildingAssessment,
  createBuildingAssessmentCategoryImage,
  deleteBuildingAssessmentCategoryImage,
})(NewReportAssessmentCategoryContainer)
