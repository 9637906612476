// Items flagged with `external` are being forwarded to the old app
const SidebarItems = [
  {
    label: 'Tilbud',
    path: '/orders',
    icon: 'folder',
    roles: ['super_admin', 'admin', 'salesman', 'manager'],
  },
  {
    label: 'Opret tilbud',
    path: '/drafts/per-customer',
    icon: 'plus',
    roles: ['super_admin', 'admin', 'salesman', 'manager'],
  },
  {
    label: 'Salgsprocenter',
    path: '/conversion-rates',
    icon: 'stats',
    roles: ['super_admin', 'admin', 'salesman'],
  },
  {
    label: 'Rapporter',
    path: '/reports',
    icon: 'pie',
    roles: ['super_admin', 'admin', 'salesman'],
  },
  {
    label: 'Referenceliste',
    path: '/reference-list',
    roles: ['super_admin', 'admin', 'salesman'],
    icon: 'paperclip',
  },
  {
    label: 'Byggeleder bedømmelse',
    path: '/manager-rating',
    roles: ['super_admin', 'admin', 'manager'],
    icon: 'checkbox-checked',
  },
]

export default SidebarItems
