import { createSelector } from 'reselect'
import {
  createEntitiesSelector,
  createIsFetchingSelector,
} from './ApiSelectors'
import filterDuplicates from '../utils/filterDuplicates'
import filterUndefined from '../utils/filterUndefined'

export const getPropertyCategories =
  createEntitiesSelector('propertyCategories')
export const isFetching = createIsFetchingSelector('propertyCategories')

const getStepsForWizard = createSelector(
  (state, props) => props.params.wizardId,
  createEntitiesSelector('steps'),
  (wizardId, steps) => steps.filter((s) => s.wizard === wizardId)
)

const getStepProductsForWizard = createSelector(
  getStepsForWizard,
  createEntitiesSelector('stepProducts'),
  (steps, stepProducts) => {
    const stepIds = steps.map((step) => step.id)
    return stepProducts.filter(
      (stepProduct) => stepIds.indexOf(stepProduct.step) !== -1
    )
  }
)

const getResultsForWizard = createSelector(
  getStepProductsForWizard,
  (state) => state.api.entities.results || {},
  (stepProducts, resultEntities) =>
    stepProducts.map((sp) => resultEntities[sp.result]).filter(filterUndefined)
)

export const getPropertyCategoriesForWizard = createSelector(
  getResultsForWizard,
  (state) => state.api.entities.propertyCategories || {},
  (results, propertyCategoryEntities) => {
    const wizardPropertyCategoryIds = results
      .map((r) => r.propertyCategory)
      .filter(filterDuplicates)
    return wizardPropertyCategoryIds.map((id) => propertyCategoryEntities[id])
  }
)
