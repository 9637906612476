import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const CHAR_COLORS = {
  A: '#00A750',
  B: '#47BA41',
  C: '#BFD80F',
  D: '#F3CD00',
  E: '#FFB800',
  F: '#F56F0A',
  G: '#F11717',
}

const CHARS = Object.keys(CHAR_COLORS)

export class EnergyMarkChar extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    selected: PropTypes.bool.isRequired,
    char: PropTypes.oneOf(CHARS),
  }

  static defaultProps = {
    selected: false,
  }

  handleClick = () => {
    const { onClick, char } = this.props

    if (onClick) {
      onClick(char)
    }
  }

  render() {
    const { char, selected } = this.props
    const backgroundColor = CHAR_COLORS[char]
    const classes = classNames('energy-mark-input__char', {
      'energy-mark-input__char--selected': selected,
    })

    return (
      <span
        onClick={this.handleClick}
        className={classes}
        style={{ backgroundColor }}
      >
        {char}
      </span>
    )
  }
}

export default class EnergyMark extends Component {
  static propTypes = {
    value: PropTypes.oneOf(CHARS),
    onChange: PropTypes.func,
  }

  render() {
    const { value, onChange } = this.props

    return (
      <div className="energy-mark-input">
        {CHARS.map((char) => (
          <EnergyMarkChar
            onClick={onChange}
            key={char}
            char={char}
            selected={value === char}
          />
        ))}
      </div>
    )
  }
}
