import { createSelector } from 'reselect'

const getUsers = (state) => state.api.entities.users
export const getCurrentUserId = (state) => state.auth.userId
export const getToken = (state) => state.auth.token

export const isAuthenticated = createSelector(
  getCurrentUserId,
  getToken,
  (userId, token) => userId != null && token != null
)

export const getCurrentUser = createSelector(
  getCurrentUserId,
  getUsers,
  (userId, usersEntities) => usersEntities[userId]
)

export const getCurrentUserRole = createSelector(
  getCurrentUser,
  (user) => user && user.role
)
