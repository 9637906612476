import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import SettingsList, { SettingsListItem } from '../SettingsList'
import Input from '../Input'
import Select from '../Select'
import RadioGroup from '../RadioGroup'
import { Creatable } from 'react-select'

const stringToSelectOption = (value) => ({ value, label: value })

const TITLE_OPTIONS = [
  'Hulmursisolering',
  'Loftsisolering',
  'Quadro by icopal',
  'Decra Classic',
  'Decra Elegance',
  'Renovering',
  'Tegl',
  'Beton',
  'Pap',
  'Eternit',
  'Vinduer/døre',
  'Isoleringstjek',
  'Varmepumper',
  'Solceller',
  'Ruder',
].map(stringToSelectOption)

const GUTTER_OPTIONS = [
  'Plastik',
  'Galvaniseret stål',
  'Icopal Dark Silver',
  'Alu-Zink',
  'Zink',
].map(stringToSelectOption)

const FIELDS = [
  { key: 'worksheet_pdf_title', label: 'Titel', options: TITLE_OPTIONS },
  { key: 'worksheet_pdf_project_number', label: 'Projektnr.' },
  { key: 'worksheet_pdf_products_summary_roof_color', label: 'Farve' },
  { key: 'worksheet_pdf_products_summary_area', label: 'Areal' },
  { key: 'worksheet_pdf_products_summary_tilt', label: 'Hældning' },
  {
    key: 'worksheet_pdf_products_summary_gutters',
    label: 'Tagrender',
    options: GUTTER_OPTIONS,
  },
  { key: 'worksheet_pdf_products_summary_skylights', label: 'Ovenlys' },
  { key: 'worksheet_pdf_products_summary_iso', label: 'ISO' },
  { key: 'worksheet_pdf_portable_office', label: 'Skurvogn' },
  {
    key: 'financed',
    label: 'Finansiering',
    type: 'radio',
    options: [
      { label: 'Ja', value: 'true' },
      { label: 'Nej', value: 'false' },
      { label: 'Ved ikke', value: 'null' },
    ],
  },
]
const FIELD_NAMES = FIELDS.map((field) => field.key)

class WorksheetTitleField extends Component {
  promptTextCreator = (label) => {
    return `Brug "${label}" som titel`
  }

  handleChange = (option) => {
    this.props.onChange(option ? option.value : '')
  }

  handleBlur = () => {
    this.props.onBlur()
  }

  render() {
    const { field, onChange, onBlur, ...props } = this.props

    return (
      <Creatable
        options={field.options}
        promptTextCreator={this.promptTextCreator}
        placeholder="Vælg eller indtast titel…"
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        {...props}
      />
    )
  }
}

const WorksheetFormField = ({ field, ...props }) => {
  if (field.key === 'worksheet_pdf_title') {
    return <WorksheetTitleField field={field} {...props} />
  }

  if (field.key === 'financed') {
    return <RadioGroup options={field.options} {...props} />
  }

  if (field.options) {
    return <Select options={field.options} {...props} block />
  }

  return <Input type="text" {...props} />
}

class WorksheetForm extends Component {
  static fields = FIELD_NAMES

  constructor(props) {
    super(props)
    this.fields = [...FIELDS]
  }

  renderFormField = (field, index) => {
    const { fields, submitting } = this.props

    return (
      <SettingsListItem key={field.key} label={field.label}>
        <WorksheetFormField
          {...fields[field.key]}
          field={field}
          autoFocus={index === 0}
          disabled={submitting}
        />
      </SettingsListItem>
    )
  }

  render() {
    const { submitting, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <SettingsList>{this.fields.map(this.renderFormField)}</SettingsList>
        <button
          type="submit"
          style={{ display: 'none' }}
          disabled={submitting}
        />
      </form>
    )
  }
}

export default WorksheetForm
