import { createSelector } from 'reselect'
import { createEntitiesSelector } from './ApiSelectors'

export const getAssessmentCategoryId = (state, props) =>
  props.params.assessmentCategoryId
export const getAssessmentCategoryTypes = createEntitiesSelector(
  'assessmentCategoryTypes'
)

export const getAssessmentCategoryTypesForCategory = createSelector(
  getAssessmentCategoryTypes,
  getAssessmentCategoryId,
  (types, categoryId) =>
    types.filter((t) => t.assessmentCategory === categoryId)
)
