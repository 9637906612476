import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import ListItem from './ListItem'
import ListItemActions from './ListItemActions'
import omit from 'lodash/omit'

const List = (props) => {
  const { items, className, itemRenderer, noPadding, children } = props
  const classes = classNames('list', className, {
    'list--no-padding': noPadding,
  })
  const otherProps = omit(
    props,
    'items',
    'className',
    'noPadding',
    'itemRenderer'
  )

  let renderedItems
  if (itemRenderer && items) {
    renderedItems = items.map(itemRenderer)
  }

  return (
    <div {...otherProps} className={classes}>
      {renderedItems}
      {children}
    </div>
  )
}

List.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  itemRenderer: PropTypes.func,
  children: PropTypes.any,
}

export default List
export { List, ListItem, ListItemActions }
