import { createSelector } from 'reselect'
import { kebabToSnakeCase } from '../utils/formatUtils'
import {
  createEntitiesSelector,
  createOrderedEntitiesSelector,
  createIsFetchingSelector,
  createGetPaginationSelector,
  createEntitySelector,
} from './ApiSelectors'

const getUsers = ({ api }) => api.entities.users || {}
const getEntities = ({ api }) => api.entities

export const getOrders = createOrderedEntitiesSelector('orders')
export const getOrder = createEntitySelector('orders')
export const isFetching = createIsFetchingSelector('orders')
export const getPagination = createGetPaginationSelector('orders')
export const getOrdersWithUser = createSelector(
  getOrders,
  getUsers,
  (orders, users) =>
    orders.map((order) => ({
      ...order,
      user: users[order.user] || {},
    }))
)

export const canCopyOrder = createSelector(
  getOrder,

  // An order cannot be copied if there's any "finished" orders in the
  // family tree. So, this selector tests for any finished orders in the tree.
  (order) => {
    if (!order || !order.familyTree) {
      return false
    }

    // State strings that defines the order as "finished"
    const finishedStates = ['accepted', 'reviewed_accepted', 'rejected']

    // Recursively test if an order or its children are "finished"
    const isFinished = ({ state, children }) =>
      finishedStates.includes(state) || children.some(isFinished)

    return !isFinished(order.familyTree)
  }
)

export const canArchiveOrder = createSelector(
  getOrder,

  // An order cannot be archive if all other orders in the family tree are archived
  (order) => {
    if (!order || !order.familyTree) {
      return false
    }

    // Recursively test if one of the other orders is non-archived
    const hasNonArchived = ({ id, state, children }) =>
      (id !== order.id && state !== 'archived') || children.some(hasNonArchived)

    return hasNonArchived(order.familyTree)
  }
)

export const getCustomerDrafts = createSelector(
  (state, { params: { customerId } }) => customerId,
  createEntitiesSelector('orders'),
  (customerId, orders) =>
    orders.filter((o) => o.customer === customerId && o.state === 'draft')
)

export const getOrderWithRelations = createSelector(
  getOrder,
  getEntities,
  (order, entities) => {
    const { users, customers, wizardBuildings } = entities

    if (
      !order ||
      !order.wizardBuildings ||
      !users ||
      !customers ||
      !wizardBuildings
    ) {
      return null
    }

    return {
      ...order,
      customer: customers[order.customer],
      user: users[order.user],
      wizardBuildings: order.wizardBuildings.map((id) => wizardBuildings[id]),
    }
  }
)

export const getDraft = createSelector(
  getOrder,
  createEntitiesSelector('wizardBuildings'),
  getEntities,
  (order, wizardBuildings, entities) => {
    const { buildings = {}, wizards = {}, customers = {} } = entities

    if (!order) {
      return null
    }

    return {
      ...order,
      wizardBuildings: wizardBuildings
        .filter((wb) => wb.order === order.id)
        .map((wizardBuilding) => ({
          ...wizardBuilding,
          building: buildings[wizardBuilding.building],
          wizard: wizards[wizardBuilding.wizard],
        })),
      customer: customers[order.customer],
    }
  }
)

/**
 *
 */
const getStringifiedFinancedValue = (value) => {
  if (value === null) return 'null'
  else if (value === true) return 'true'
  else if (value === false) return 'false'
  else return value
}

export const getOrderWorksheetSummary = createSelector(getOrder, (order) => {
  const title = order.worksheetPdfTitle || order.name
  const projectNumber = order.worksheetPdfProjectNumber
  const summaryItems = {
    ...order.worksheetPdfProductsSummary,
    financed: getStringifiedFinancedValue(order.financed),
  }

  const values = {
    worksheet_pdf_title: title,
    worksheet_pdf_project_number: projectNumber,
  }

  // Convert kebab-case key names from `summaryItems` to snake case
  return Object.keys(summaryItems).reduce((acc, key) => {
    const normalized = kebabToSnakeCase(key)
    return {
      ...acc,
      [normalized]: summaryItems[key],
    }
  }, values)
})
