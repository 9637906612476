import React from 'react'
import PropTypes from 'react-proptypes'
import CustomerEmailInputContainer from '../../containers/CustomerEmailInputContainer'
import { Margin } from '../Grid'
import Input from '../Input'
import TextArea from '../TextArea'
import Select from '../Select'
import Icon from '../Icon'

const OrderReviewAcceptForm = ({
  submitting,
  values,
  order,
  userOptions,
  onChange,
}) => {
  const { user, customer } = order
  const selectedUserName = findSelectedUserByEmail(values.replyTo)
  function findSelectedUserByEmail(email) {
    const selectedUser = userOptions.find((user) => user.value === email)
    if (typeof selectedUser !== 'undefined') {
      return selectedUser.label.substring(0, selectedUser.label.indexOf('('))
    }
  }

  return (
    <Margin all>
      <Margin vertical>
        <Input seamlessBottom disabled value="Introduktion" />
        <TextArea
          disabled={submitting}
          value={values.introductionText}
          name="introductionText"
          onChange={onChange}
        />
      </Margin>

      <Margin vertical>
        <Input seamlessBottom disabled value="Betalingsbetingelser" />
        <TextArea
          disabled={submitting}
          value={values.termsOfPayment}
          name="termsOfPayment"
          onChange={onChange}
        />
      </Margin>

      <Margin vertical>
        <Input seamlessBottom disabled value="Praktiske informationer" />
        <TextArea
          disabled={submitting}
          value={values.practicalInformation}
          name="practicalInformation"
          onChange={onChange}
        />
      </Margin>

      <Margin vertical>
        <Input seamlessBottom disabled value="Bemærkninger (i PDF)" />
        <TextArea
          disabled={submitting}
          value={values.note}
          name="note"
          onChange={onChange}
        />
      </Margin>

      <Margin vertical>
        <Input
          disabled={submitting}
          placeholder="Forventet opstart"
          value={values.startDate}
          name="startDate"
          onChange={onChange}
        />
      </Margin>

      <Margin vertical>
        <Select
          disabled={submitting}
          name="replyTo"
          style={{ width: '100%' }}
          options={userOptions}
          onChange={onChange}
        />
      </Margin>

      <Margin vertical>
        <CustomerEmailInputContainer
          disabled={submitting}
          customerId={customer.id}
          customerEmail={customer.email}
          placeholder="Modtagers e-mail-adresse"
          value={values.email}
          onChange={onChange}
          name="email"
        />
      </Margin>

      <Margin vertical>
        <TextArea
          disabled={submitting}
          value={values.body}
          name="body"
          onChange={onChange}
          seamlessBottom
        />
        <TextArea
          disabled
          seamlessTop
          value={`Venlig hilsen\n\n${
            selectedUserName || user.name
          }\nJydsk Tagteknik A/S`}
        />
      </Margin>

      <div
        style={{
          verticalAlign: 'middle',
          display: 'inline-block',
          marginRight: '5px',
          marginBottom: '-3px',
        }}
      >
        <Icon icon="paperclip" />
      </div>
      {`ordrebekræftelse-${order.id}.pdf`}
    </Margin>
  )
}

OrderReviewAcceptForm.propTypes = {
  submitting: PropTypes.bool,
  values: PropTypes.object,
  order: PropTypes.object,
  userOptions: PropTypes.array,
  onChange: PropTypes.func,
}

export default OrderReviewAcceptForm
