import { createSelector } from 'reselect'
import {
  createEntitiesSelector,
  createEntitySelector,
  createIsFetchingSelector,
} from './ApiSelectors'
import { getProducts } from './ProductSelectors'

export const getStepProducts = createEntitiesSelector('stepProducts')
export const getStepProduct = createEntitySelector('stepProducts')
export const getProductVariants = createEntitiesSelector('productVariants')
export const getIsFetching = createIsFetchingSelector('stepProducts')

export const getStepProductsProducts = createSelector(
  (state, props) => props.params.stepId,
  getStepProducts,
  getProducts,
  (stepId, stepProducts, products) => {
    const filteredStepProducts = stepProducts.filter(
      (stepProduct) => stepProduct.step === stepId
    )

    return products.filter(
      (product) =>
        filteredStepProducts.filter((fsp) => fsp.product === product.id).length
    )
  }
)

export const getStepProductsProductsWithProductVariants = createSelector(
  getStepProductsProducts,
  getProductVariants,
  (products, productVariants) => {
    return products.map((product) => ({
      ...product,
      productVariants: productVariants.filter(
        (productVariant) => productVariant.product === product.id
      ),
    }))
  }
)
