import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { closeDialog } from '../actions/DialogActions'
import UserDialog from './Dialogs/UserDialog'
import OrderFollowUpDialog from './Dialogs/OrderFollowUpDialog'
import OrderRejectDialog from './Dialogs/OrderRejectDialog'
import OrderAcceptDialog from './Dialogs/OrderAcceptDialog'
import OrderNewCommentDialog from './Dialogs/OrderNewCommentDialog'
import OrderRateTrustpilotDialog from './Dialogs/OrderRateTrustpilotDialog'
import OrderEditFieldDialog from './Dialogs/OrderEditFieldDialog'
import UserSettingsDialog from './Dialogs/UserSettingsDialog'
import UserPasswordDialog from './Dialogs/UserPasswordDialog'
import OrderReviewDialog from './Dialogs/OrderReviewDialog'
import OrderCancelDialog from './Dialogs/OrderCancelDialog'
import CustomerDialog from './Dialogs/CustomerDialog'
import BuildingInfoDialog from './Dialogs/BuildingInfoDialog'
import AcceptanceDialog from '../components/Dialogs/AcceptanceDialog'
import AdditionalDiscountDialog from './Dialogs/AdditionalDiscountDialog'
import SendOrderConfirmationDialog from './Dialogs/SendOrderConfirmationDialog'
import PrintWorksheetDialog from './Dialogs/PrintWorksheetDialog'
import ManagerOrderFollowUpDialog from './Dialogs/ManagerOrderFollowUpDialog'
import CreateBuildingDialog from './Dialogs/CreateBuildingDialog'
import BuildingPropertyCreationDialog from './Dialogs/BuildingPropertyCreationDialog'
import BuildingPropertyDialog from './Dialogs/BuildingPropertyDialog'
import BuildingImagesForTagDialog from './Dialogs/BuildingImagesForTagDialog'
import LineItemCreationDialog from './Dialogs/LineItemCreationDialog'
import LineItemDialog from './Dialogs/LineItemDialog'
import AdjustDisplayedDiscountDialog from './Dialogs/AdjustDisplayedDiscountDialog'

const dialogsByTypeName = {
  user: UserDialog,
  orderFollowUp: OrderFollowUpDialog,
  orderReject: OrderRejectDialog,
  orderAccept: OrderAcceptDialog,
  orderNewComment: OrderNewCommentDialog,
  orderRateTrustpilot: OrderRateTrustpilotDialog,
  orderEditField: OrderEditFieldDialog,
  userSettings: UserSettingsDialog,
  userPassword: UserPasswordDialog,
  orderReview: OrderReviewDialog,
  orderCancel: OrderCancelDialog,
  customer: CustomerDialog,
  buildingInfo: BuildingInfoDialog,
  buildingPropertyCreation: BuildingPropertyCreationDialog,
  buildingProperty: BuildingPropertyDialog,
  acceptance: AcceptanceDialog,
  additionalDiscount: AdditionalDiscountDialog,
  sendOrderConfirmation: SendOrderConfirmationDialog,
  printWorksheet: PrintWorksheetDialog,
  managerOrderFollowUp: ManagerOrderFollowUpDialog,
  createBuilding: CreateBuildingDialog,
  buildingImagesForTag: BuildingImagesForTagDialog,
  lineItemCreationDialog: LineItemCreationDialog,
  lineItem: LineItemDialog,
  adjustDisplayedDiscount: AdjustDisplayedDiscountDialog,
}

class DialogContainer extends Component {
  static propTypes = {
    dialogType: PropTypes.string,
    dialogProps: PropTypes.any,
    closeDialog: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.props.closeDialog()
  }

  render() {
    const { dialogType, dialogProps } = this.props

    if (!dialogType) {
      return null
    }

    const DialogComponent = dialogsByTypeName[dialogType]

    if (!DialogComponent) {
      throw new Error('Invalid dialog component')
    }

    return <DialogComponent onClose={this.handleClose} {...dialogProps} />
  }
}

function mapStateToProps({ dialog }) {
  return dialog
}

export default connect(mapStateToProps, {
  closeDialog,
})(DialogContainer)
