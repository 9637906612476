import { createReducer } from '../utils/reducerUtils'
import * as types from '../constants/ActionTypes'
import { camelizeKeys } from '../utils/formatUtils'

const initialState = {
  hits: {},
  pages: {},
  queries: {},
  loadCount: {},
}

export default createReducer(initialState, {
  [types.ALGOLIA_SEARCH_REQUEST](state, { entityType }) {
    return {
      ...state,
      loadCount: {
        ...state.loadCount,
        [entityType]: (state.loadCount[entityType] || 0) + 1,
      },
    }
  },
  [types.ALGOLIA_SEARCH_FAILURE](state, { entityType }) {
    return {
      ...state,
      loadCount: {
        ...state.loadCount,
        [entityType]: (state.loadCount[entityType] || 0) + 1,
      },
    }
  },
  [types.ALGOLIA_SEARCH_SUCCESS](state, { content, entityType }) {
    return {
      ...state,
      hits: {
        ...state.hits,
        [entityType]: camelizeKeys(content.hits),
      },
      pages: {
        ...state.pages,
        [entityType]: {
          current: content.page,
          total: content.nbPages,
        },
      },
      loadCount: {
        ...state.loadCount,
        [entityType]: (state.loadCount[entityType] || 0) - 1,
      },
    }
  },

  [types.ALGOLIA_SEARCH_MORE_SUCCESS](state, { content, entityType }) {
    return {
      ...state,
      hits: {
        ...state.hits,
        [entityType]: [
          ...state.hits[entityType],
          ...camelizeKeys(content.hits),
        ],
      },
      pages: {
        ...state.pages,
        [entityType]: {
          current: content.page,
          total: content.nbPages,
        },
      },
      loadCount: {
        ...state.loadCount,
        [entityType]: (state.loadCount[entityType] || 0) - 1,
      },
    }
  },

  [types.ALGOLIA_SET_QUERY](state, { entityType, query }) {
    return {
      ...state,
      queries: {
        ...state.queries,
        [entityType]: { ...state.queries[entityType], ...query },
      },
    }
  },

  [types.ALGOLIA_INSERT_FAKE_RESULT](state, { result: { type, data } }) {
    return {
      ...state,
      hits: {
        ...state.hits,
        [type]: [...state.hits, data],
      },
    }
  },

  [types.ALGOLIA_SEARCH_FAILURE](
    state,
    { entityType, errorType, errorMessage }
  ) {
    // If the index is not allowed, it's likely because the sorting replicas have been changed.
    // So we'll reset the ordering, to make the app use the default index instead of a replica.
    if (errorMessage === 'Index not allowed with this API key') {
      return {
        ...state,
        queries: {
          ...state.queries,
          [entityType]: {
            ...state.queries[entityType],
            sortBy: null,
            sortDirection: null,
          },
        },
      }
    }
  },

  // Set default query for orders
  [types.LOGIN_SUCCESS](state, { payload }) {
    const userId = payload.results.users[0]
    const user = payload.entities.users[userId]

    if (user.role === 'salesman') {
      return {
        ...state,
        queries: {
          ...state.queries,
          orders: state.queries.orders || {
            'user.id': parseInt(userId),
            sortBy: 'proposed_and_follow_up_at',
            sortDirection: 'asc',
          },
        },
      }
    }

    if (user.role === 'manager') {
      return {
        ...state,
        queries: {
          ...state.queries,
          orders: state.queries.orders || {
            aasm_state: ['accepted'],
            'department.id':
              user.departmentId != null ? [user.departmentId] : [],
          },
        },
      }
    }

    return state
  },
})
