import * as types from '../constants/ActionTypes'
import { isAuthenticated } from '../selectors/AuthSelectors'
import { fetchCurrentUser } from './UserActions'

function initializeSuccess() {
  return { type: types.INITIALIZE_SUCCESS }
}

function initializeFailure() {
  return { type: types.INITIALIZE_FAILURE }
}

export function initialize() {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({ type: types.INITIALIZE_REQUEST })

    if (isAuthenticated(state)) {
      dispatch(fetchCurrentUser()).then(
        () => dispatch(initializeSuccess()),
        () => {
          dispatch(initializeFailure())
          localStorage.removeItem('session')
        }
      )
    } else {
      dispatch(initializeSuccess())
    }
  }
}

export const hideMenu = () => ({ type: types.HIDE_MENU })

export const showMenu = () => ({ type: types.SHOW_MENU })
