import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Icon from '../Icon'
import { ListItem } from '../List'
import './style.styl'

export const AssessmentOptionItem = (props) => {
  const { active, assessment, onClick } = props

  const before = (
    <div className="assessment-option-item__smiley-container">
      <Icon
        icon={`smiley-${assessment.smiley}`}
        size={40}
        className={`assessment-option-item__smiley--${assessment.smiley}`}
      />
    </div>
  )

  return (
    <ListItem
      className="assessment-option-item"
      title={assessment.name}
      description={assessment.description}
      onClick={() => onClick(assessment.id)}
      children={<Icon icon={(active && 'radio-checked') || 'radio'} />}
      before={before}
    />
  )
}

AssessmentOptionItem.propTypes = {
  active: PropTypes.bool.isRequired,
  assessment: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default AssessmentOptionItem
