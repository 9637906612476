/* eslint-disable no-undef */

export function createReducer(initialState, handlers) {
  if (process.env.NODE_ENV !== 'production') {
    if (handlers.hasOwnProperty('undefined')) {
      /* eslint-disable no-console */
      console.warn(
        "Reducer contains an 'undefined' action type name. " +
          'Have you misspelled a constant?'
      )
      /* eslint-enable no-console */
    }
  }

  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    }
    return state
  }
}
