import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as DialogActions from '../actions/DialogActions'

import { getWizardBuilding } from '../selectors/WizardBuildingSelectors'

import { Margin, Row } from '../components/Grid'
import OrderWizardStepTitle from '../components/OrderWizard/StepTitle'
import Cell from '../components/Cell'
import Icon, { dataUrlForIcon } from '../components/Icon'

class WizardBuildingImagesContainer extends Component {
  static propTypes = {
    building: PropTypes.object.isRequired,
    requiredImageTags: PropTypes.array.isRequired,
    imageTagsById: PropTypes.object.isRequired,

    openDialog: PropTypes.func.isRequired,
  }

  handleImageCategoryClick = (imageTagId) => {
    const { building, openDialog, imageTagsById } = this.props
    openDialog('buildingImagesForTag', {
      imageTag: imageTagsById[imageTagId],
      buildingId: building.id,
    })
  }

  render() {
    const { requiredImageTags, building } = this.props
    const imageTags = requiredImageTags.map(
      (requiredImageTag) => requiredImageTag.imageTag
    )
    imageTags.push({ id: null, name: 'Diverse', optional: true })

    const imageTagsWithImages = imageTags.map((imageTag) => ({
      ...imageTag,
      buildingImages: building.buildingImages.filter(
        (buildingImage) => buildingImage.imageTag === imageTag.id
      ),
    }))
    const placeholderUrl = dataUrlForIcon({
      icon: 'camera',
      viewBox: '-128 -128 768 768',
    })

    return (
      <Margin all size={2}>
        <OrderWizardStepTitle>Tilføj billeder</OrderWizardStepTitle>
        <Row>
          {imageTagsWithImages.map(({ id, name, buildingImages, optional }) => (
            <Cell
              key={id}
              size={4}
              imageUrl={
                buildingImages.length
                  ? buildingImages[0].imageUrl
                  : placeholderUrl
              }
              onClick={this.handleImageCategoryClick}
              clickPayload={id}
            >
              <p>{name}</p>
              <Margin top size={1}>
                <Icon
                  icon={
                    !optional && !buildingImages.length
                      ? 'exclamation-circle'
                      : 'checkmark'
                  }
                />
              </Margin>
            </Cell>
          ))}
        </Row>
      </Margin>
    )
  }
}

const mapStateToProps = (state, props) => {
  // We assume that all attached relations where fetched earlier by a parent
  const wizardBuilding = getWizardBuilding(state, props, [
    'wizard.requiredImageTags.imageTag',
    'building.buildingImages',
  ])

  return {
    requiredImageTags: wizardBuilding.wizard.requiredImageTags,
    building: wizardBuilding.building,
    imageTagsById: state.api.entities.imageTags || {},
  }
}

export default connect(mapStateToProps, {
  ...DialogActions,
})(WizardBuildingImagesContainer)
