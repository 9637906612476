import React from 'react'
import PropTypes from 'react-proptypes'
import WizardBuildingStepTableBody from '../WizardBuildingStepTableBody'
import './style.styl'

const WizardBuildingStepTable = ({
  wizardBuildingStep,
  allowLineItemEditing,
}) => (
  <table className="WizardBuildingStepTable">
    <tbody key="header">
      <tr className="WizardBuildingStepTable__header-row">
        <th>Produkt</th>
        <td>Antal</td>
        <td>Mængde</td>
        <td className="text-right">Pris (m. moms)</td>
        {allowLineItemEditing ? <td></td> : null}
      </tr>
    </tbody>
    <WizardBuildingStepTableBody
      {...wizardBuildingStep}
      allowLineItemEditing={allowLineItemEditing}
      noStepTitle
    />
  </table>
)

WizardBuildingStepTable.propTypes = {
  allowLineItemEditing: PropTypes.bool,
  wizardBuildingStep: PropTypes.object.isRequired,
  noStepTitle: PropTypes.bool,
}

export default WizardBuildingStepTable
