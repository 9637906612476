import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { updateOrder } from '../../actions/OrderActions';
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import { SettingsListItem } from '../../components/SettingsList';

class AdjustDisplayedDiscountDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discountValue: props.discount || '',
      error: null,
      valid: true,
    };
  }

  componentDidMount() {
    this.focusDiscountInput();
  }

  validate = () => {
    const { discountValue } = this.state;
    const { maxDiscount } = this.props;

    const isValid = !isNaN(discountValue) && parseFloat(discountValue) * 100 <= maxDiscount;

    this.setState({
      valid: isValid,
    });
  }

  handleDiscountChange = ({ target }) => {
    const value = target.value;

    this.setState({
      discountValue: value,
    }, this.validate);
  }

  handleSave = () => {
    const { orderId, onClose } = this.props;
    const { discountValue, valid } = this.state;

    if (!valid) {
      return;
    }

    this.props.updateOrder(orderId, { displayedDiscount: discountValue })
      .then(onClose)
      .catch(this.handleSaveError);
  }

  handleSaveError = () => {
    this.setState({ error: 'An error occurred while saving.' });
  }

  focusDiscountInput() {
    const container = findDOMNode(this);
    const input = container.querySelector('input');

    if (input) {
      input.focus();
      input.select();
    }
  }

  render() {
    const { onClose, isSubmitting } = this.props;
    const { discountValue, valid, error } = this.state;

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={isSubmitting}>Annuller</DialogCancelButton>
          <DialogTitle>Juster vist rabat</DialogTitle>
          <DialogButton
            onClick={this.handleSave}
            disabled={isSubmitting || !valid}
          >
            {isSubmitting ? 'Gemmer' : 'Gem'}
          </DialogButton>
        </DialogHeader>
        <form onSubmit={this.handleSave}>
          {error && <Alert type="danger">{error}</Alert>}
          <SettingsListItem label="Vist rabat (inkl. moms)">
            <Input
              type="number"
              value={discountValue}
              onChange={this.handleDiscountChange}
              disabled={isSubmitting}
            />
          </SettingsListItem>
          <button type="submit" style={{ display: 'none' }}>Submit</button>
        </form>
      </Dialog>
    );
  }
}

AdjustDisplayedDiscountDialog.propTypes = {
  orderId: PropTypes.string.isRequired,
  discount: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    api: { isUpdating },
  } = state;
  const { orderId } = ownProps;

  const updatingOrders = isUpdating.orders || []

  return {
    isSubmitting: updatingOrders.indexOf(orderId) !== -1,
  };
};

export default connect(mapStateToProps, { updateOrder })(AdjustDisplayedDiscountDialog);
