import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import { Margin } from '../../components/Grid'
import Button from '../../components/Button'

class OrderRateTrustpilotDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      submittingYes: false,
      submittingNo: false,
      customerPhone: this.props.customer.phone,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleYesButtonClick = this.handleYesButtonClick.bind(this)
    this.handleNoButtonClick = this.handleNoButtonClick.bind(this)
    this.secret = 'g9i398P6i6tW'
  }

  handleChange({ target: { value } }) {
    this.setState({ customerPhone: value })
  }

  handleYesButtonClick() {
    const { orderId, status, type, onClose, user, customer } = this.props

    this.setState({ submittingYes: true })
    let apiUrl = `https://jydsktagteknik.dk/trustpilot-api/v1/rate/${this.secret}/true/${orderId}/${type}/${user.id}/${user.name}/${customer.name}/${customer.email}/${this.state.customerPhone}`
    fetch(apiUrl)
      .then(function (response) {})
      .then(() => Promise.all([onClose()]))
      .catch(() => this.setState({ submittingYes: false }))
  }
  handleNoButtonClick() {
    const { orderId, status, type, onClose, user, customer } = this.props

    this.setState({ submittingNo: true })
    let apiUrl = `https://jydsktagteknik.dk/trustpilot-api/v1/rate/${this.secret}/false/${orderId}/${type}/${user.id}/${user.name}/${customer.name}/${customer.email}`
    fetch(apiUrl)
      .then(function (response) {})
      .then(() => Promise.all([onClose()]))
      .catch(() => this.setState({ submittingNo: false }))
  }

  render() {
    const { orderId, status, type, onClose, user, customer } = this.props
    const { submittingYes, submittingNo } = this.state

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={submittingYes}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>Bedøm besøg</DialogTitle>
        </DialogHeader>
        <Margin all>
          <p>Vil du bedømme din oplevelse af vores konsulents besøg?</p>
          <p className="margin--top-1">
            Telefonnunmmer som invitation sendes til:
          </p>
          <input
            className="rateTrustpilot-phone form-input margin--bottom-1"
            type="text"
            defaultValue={customer.phone}
            onChange={this.handleChange}
            placeholder="Kundens telefonnunmmer"
          />
          <div className="text-center margin--top-1">
            <Button
              className="margin--left-1 btn--green"
              onClick={this.handleYesButtonClick}
              disabled={!this.state.customerPhone}
            >
              {(submittingYes && 'Sender...') || 'Ja'}
            </Button>
            <Button
              className="margin--left-1 btn--red"
              onClick={this.handleNoButtonClick}
            >
              {(submittingNo && 'Lukker...') || 'NEJ'}
            </Button>
          </div>
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  orderId: props.orderId,
})

export default connect(mapStateToProps, {})(OrderRateTrustpilotDialog)
