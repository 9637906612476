import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import omit from 'lodash/omit'

export const BuildingFormSelectOption = ({ value, label }) => (
  <option key={value} value={value}>
    {label}
  </option>
)

BuildingFormSelectOption.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
}

export const BuildingFormSelect = (props) => (
  <select {...omit(props, 'options')} className="building-form-select">
    {props.value === '' && !props.options.some((o) => o.value === '') && (
      <BuildingFormSelectOption key="" value="" label="" selected />
    )}
    {props.options.map(BuildingFormSelectOption)}
  </select>
)

BuildingFormSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
}

export default BuildingFormSelect
