import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

import { Margin, Row, Column } from '../Grid'
import Spacer from '../Spacer'
import './style.styl'

export default class ImageGallery extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    widthHeightRatio: PropTypes.number,
    emptyText: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = { shownIndex: 0 }
  }

  go(increment) {
    const { images } = this.props

    let positiveIncrement = increment
    while (positiveIncrement < 0) positiveIncrement += images.length

    const shownIndex =
      (this.state.shownIndex + positiveIncrement) % images.length
    this.setState({ shownIndex })
  }

  handlePreviousClick = () => this.go(-1)
  handleNextClick = () => this.go(1)
  handleThumbnailClick = (index) => this.setState({ shownIndex: index })

  renderThumbnail = (image, index) => {
    const { widthHeightRatio } = this.props
    const isSelected = index === this.state.shownIndex
    const className = classNames('ImageGallery__thumbnail', {
      'ImageGallery__thumbnail--selected': isSelected,
    })

    return (
      <Column
        className="ImageGallery__thumbnail-container"
        size={4}
        key={image.id}
      >
        <Spacer
          onClick={() => this.handleThumbnailClick(index)}
          className={className}
          widthHeightRatio={widthHeightRatio}
          style={{ backgroundImage: `url(${image.thumbnailImageUrl})` }}
        />
      </Column>
    )
  }

  render() {
    const {
      images,
      widthHeightRatio,
      emptyText = 'Der er ingen billeder',
    } = this.props
    const { shownIndex } = this.state
    const shownImage = images[shownIndex]

    if (!images.length) {
      return (
        <Spacer
          widthHeightRatio={widthHeightRatio}
          className="ImageGallery__shown-image"
        >
          <div className="ImageGallery__no-images-placeholder">
            <div className="ImageGallery__no-images-placeholder-inner">
              {emptyText}
            </div>
          </div>
        </Spacer>
      )
    }

    return (
      <div>
        <Spacer
          widthHeightRatio={widthHeightRatio}
          style={{ backgroundImage: `url(${shownImage.imageUrl})` }}
          className="ImageGallery__shown-image"
        >
          <a
            className="ImageGallery__previous-button"
            onClick={this.handlePreviousClick}
          />
          <a
            className="ImageGallery__next-button"
            onClick={this.handleNextClick}
          />
        </Spacer>
        <Margin top>
          <Row className="ImageGallery__thumbnails">
            {images.map(this.renderThumbnail)}
          </Row>
        </Margin>
      </div>
    )
  }
}
