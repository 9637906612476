import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import './style.styl'
import debounce from 'lodash/debounce'

import { formatUnitType } from '../../utils/formatUtils'

import * as LineItemActions from '../../actions/LineItemActions'

import { getFetchCountForEntityTypes } from '../../selectors/ApiSelectors'

import { Margin, FlexContainer, Flex } from '../Grid'
import Button from '../Button'
import Checkbox from '../Forms/Checkbox'
import Input from '../Input'
import ButtonAddon from '../ButtonAddon'

class LineItemCreateFormWithoutResult extends Component {
  static propTypes = {
    stepProductId: PropTypes.string.isRequired,
    productVariantId: PropTypes.string.isRequired,
    wizardBuildingStepId: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired,

    fetchLineItemPrice: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,

    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const { stepProductId, productVariantId, wizardBuildingStepId } = props

    this.state = {
      price: null,
      formData: {
        stepProductId,
        productVariantId,
        wizardBuildingStepId,
        addition: false,
        quantity: 1,
        productUnitsPerQuantity: 1,
      },
    }

    this.debouncedFetchPrice = debounce(this.fetchPrice, 300)
  }

  componentDidMount() {
    this.fetchPrice()
  }

  componentDidUpdate(
    _prevProps,
    { formData: { quantity, productUnitsPerQuantity } }
  ) {
    const quantityChanged =
      quantity !== this.state.formData.quantity ||
      productUnitsPerQuantity !== this.state.formData.productUnitsPerQuantity

    if (quantityChanged) this.debouncedFetchPrice()
  }

  fetchPrice() {
    if (!this.isValid()) return

    const { fetchLineItemPrice } = this.props
    fetchLineItemPrice({ ...this.state.formData }).then(({ rawData }) => {
      const price =
        rawData.attributes['humanized-total-price-incl-vat-with-symbol']
      this.setState({ price })
    })
  }

  isValid() {
    if (!/^[1-9][0-9]*$/.test(this.state.formData.quantity)) return false
    if (!/^[1-9]+.?[0-9]*$/.test(this.state.formData.productUnitsPerQuantity))
      return false
    return true
  }

  handleInput = ({ target: { type, name, value, checked } }) => {
    const { formData } = this.state
    switch (type) {
      case 'checkbox':
        this.setState({ formData: { ...formData, [name]: checked } })
        break

      default:
        this.setState({ formData: { ...formData, [name]: value } })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { onSubmit } = this.props
    onSubmit(this.state.formData)
  }

  render() {
    const { isLoading, unitType } = this.props
    const {
      price,
      formData: { addition, quantity, productUnitsPerQuantity },
    } = this.state
    const canSave = this.isValid() && !isLoading

    const showUnits = unitType !== 'pcs'

    return (
      <div className="LineItemCreateFormWithoutResult">
        <Margin horizontal size={1}>
          <form onSubmit={this.handleSubmit}>
            <table>
              <tbody className="LineItemCreateFormWithoutResult__table">
                <tr>
                  <th className="text-center">Antal</th>
                  {showUnits && <th className="text-center">Mængde</th>}
                  <th className="text-right">Pris</th>
                </tr>

                <tr>
                  <td>
                    <Input
                      className="text-right"
                      type="number"
                      name="quantity"
                      value={quantity}
                      onChange={this.handleInput}
                    />
                  </td>
                  {showUnits && (
                    <td>
                      <FlexContainer horizontal className="btn-group">
                        <Flex>
                          <Input
                            className="text-right"
                            type="number"
                            step="0.01"
                            name="productUnitsPerQuantity"
                            value={productUnitsPerQuantity}
                            onChange={this.handleInput}
                          />
                        </Flex>
                        <Flex>
                          <ButtonAddon>{formatUnitType(unitType)}</ButtonAddon>
                        </Flex>
                      </FlexContainer>
                    </td>
                  )}
                  <td className="text-right">{price}</td>
                </tr>
              </tbody>
            </table>

            <Margin top size={1}>
              <label>
                <Checkbox
                  name="addition"
                  checked={addition}
                  onChange={this.handleInput}
                />
                <span>Tilføj som tillægsproduct</span>
              </label>
            </Margin>
            <Margin vertical size={1}>
              <Button type="submit" block active disabled={!canSave}>
                Tilføj til tilbud
              </Button>
            </Margin>
          </form>
        </Margin>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getFetchCountForEntityTypes(state, 'lineItems') !== 0,
})

export default connect(mapStateToProps, { ...LineItemActions })(
  LineItemCreateFormWithoutResult
)
