import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { findDOMNode } from 'react-dom'
import * as AlgoliaActions from '../actions/AlgoliaActions'
import * as Selectors from '../selectors/ReportSelectors'
import Spinner from '../components/Spinner'
import Page, { PageHeader, PageContent, PageActions } from '../components/Page'
import { Row, Column, VerticalAlign, Margin } from '../components/Grid'
import EmptyPanel from '../components/EmptyPanel'
import { IconButton } from '../components/Button'
import ReportList from '../components/ReportList'
import SearchInput from '../components/SearchInput'
import LoadMoreButton from '../components/LoadMoreButton'

class ReportsContainer extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
    reports: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    searchString: PropTypes.string,
    invalidateReports: PropTypes.func,

    searchAlgolia: PropTypes.func.isRequired,
    searchMoreAlgolia: PropTypes.func.isRequired,
    setAlgoliaQuery: PropTypes.func.isRequired,
    clearAlgoliaCache: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isLoading: true,
  }

  constructor(props) {
    super(props)

    this.handleReloadClick = this.handleReloadClick.bind(this)
    this.handleCreateClick = this.handleCreateClick.bind(this)
    this.handleReportClick = this.handleReportClick.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this)
    this.handleLoaded = this.handleLoaded.bind(this)
  }

  componentDidMount() {
    this.search(true)
  }

  componentDidUpdate({ query }) {
    if (query && query !== this.props.query) this.search()
  }

  search(clearCache) {
    if (clearCache) this.props.clearAlgoliaCache('reports')
    this.props.searchAlgolia('reports')
  }

  handleSearchSubmit(query) {
    this.props.setAlgoliaQuery('reports', { q: query })
  }

  handleReloadClick() {
    this.search(true)
  }

  handleLoadMoreClick() {
    this.props.searchMoreAlgolia('reports')
  }

  handleLoaded() {
    this.scrollToBottom()
  }

  handleCreateClick() {
    this.props.push('/reports/new')
  }

  handleReportClick(id) {
    this.props.push(`/reports/${id}`)
  }

  scrollToBottom() {
    const node = findDOMNode(this)
    const content = node && node.querySelector('.page__content')

    if (content) {
      content.scrollTop = content.scrollHeight
    }
  }

  renderReportList() {
    const { isLoading, reports } = this.props

    if (!isLoading && reports.length === 0) {
      return <EmptyPanel />
    }

    return (
      <ReportList
        style={{ opacity: isLoading ? 0.5 : 1 }}
        reports={reports.slice().reverse()}
        onClick={this.handleReportClick}
      />
    )
  }

  renderEmptyPanel() {
    return (
      <VerticalAlign>
        <Row>
          <Column size={8} offset={2}>
            <EmptyPanel />
          </Column>
        </Row>
      </VerticalAlign>
    )
  }

  renderContent() {
    const { reports } = this.props

    if (reports.length > 0) {
      return this.renderReportList()
    }
    return this.renderEmptyPanel()
  }

  render() {
    const { isLoading, reports, searchString, pages } = this.props
    const canLoadMore = pages.current + 1 < pages.total

    return (
      <Page>
        <PageHeader title="Rapporter">
          <PageActions>
            <SearchInput
              placeholder="Søg efter rapporter…"
              defaultValue={searchString}
              onSubmit={this.handleSearchSubmit}
            />
            <IconButton
              icon="reload"
              size={30}
              disabled={isLoading}
              onClick={this.handleReloadClick}
            />
            <IconButton
              icon="plus"
              size={30}
              onClick={this.handleCreateClick}
            />
          </PageActions>
        </PageHeader>
        <PageContent>
          {isLoading && reports.length === 0 ? (
            <Spinner center />
          ) : (
            this.renderContent()
          )}
          {canLoadMore && (
            <Margin vertical className="text-center">
              <LoadMoreButton
                canLoadMore
                isLoading={isLoading}
                onClick={this.handleLoadMoreClick}
              />
            </Margin>
          )}
        </PageContent>
      </Page>
    )
  }
}

const emptyObject = {}
const mapStateToProps = (state) => ({
  reports: state.algolia.hits.reports || [],
  pages: state.algolia.pages.reports || emptyObject,
  query: state.algolia.queries.reports || emptyObject,
  isLoading: (state.algolia.loadCount.reports || 0) !== 0,
})

export default connect(mapStateToProps, {
  ...AlgoliaActions,
  push,
})(ReportsContainer)
