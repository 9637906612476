import React from 'react'
import { connect } from 'react-redux'

import * as DialogActions from '../../actions/DialogActions'
import * as LineItemActions from '../../actions/LineItemActions'
import * as WizardBuildingStepActions from '../../actions/WizardBuildingStepActions'

import Icon from '../Icon'
import PopoverMenu from '../PopoverMenu'

const getMenuItems = (props) => {
  const {
    openDialog,
    lineItem,
    updateLineItem,
    deleteLineItem,
    fetchWizardBuildingStep,
  } = props

  return [
    {
      label: 'Justér',
      icon: 'gear',
      onClick: () => openDialog('lineItem', { lineItemId: lineItem.id }),
    },
    {
      label: 'Vis pris i PDF',
      icon: 'folder',
      iconRight: lineItem.showPrice ? 'checkbox-checked' : 'checkbox',
      onClick: () =>
        updateLineItem(lineItem.id, { showPrice: !lineItem.showPrice }),
    },
    {
      label: 'Tillægsprodukt',
      icon: 'plus',
      iconRight: lineItem.addition ? 'checkbox-checked' : 'checkbox',
      onClick: () => {
        // These are included because line item's and the order's prices will possibly have changed
        // and we want that change to apply globally
        const query = {
          include: ['wizard_building_step.wizard_building.order'],
        }
        updateLineItem(lineItem.id, { addition: !lineItem.addition }, { query })
      },
    },
    {
      label: 'Slet',
      icon: 'trash',
      onClick: () => {
        if (
          confirm('Er du sikker på at du vil fjerne dette produkt fra kladden?')
        ) {
          // These are included because line item's and the order's prices will possibly have
          // changed and we want that change to apply globally
          const query = { include: ['wizard_building.order'] }
          deleteLineItem(lineItem.id).then(() =>
            fetchWizardBuildingStep(lineItem.wizardBuildingStep, { query })
          )
        }
      },
    },
  ]
}

const LineItemMenuButton = (props) => (
  <td>
    <PopoverMenu position="bottom right" items={getMenuItems(props)}>
      <Icon icon="gear" size={24} />
    </PopoverMenu>
  </td>
)

export default connect(undefined, {
  ...DialogActions,
  ...LineItemActions,
  ...WizardBuildingStepActions,
})(LineItemMenuButton)
