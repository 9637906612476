import React from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as OrderActions from '../../actions/OrderActions'

import './style.styl'

const OrderPriceTable = ({ order, updateOrder }) => {
  const {
    id,
    includeEnergySubsidy,
    humanizedEnergySubsidyInclVatWithSymbol,
    humanizedTotalPriceWithDiscountsWithSymbol,
    humanizedTotalPriceWithDiscountsVatWithSymbol,
    humanizedTotalPriceWithDiscountsInclVatWithSymbol,
    humanizedTotalPriceWithDiscountsAndEnergySubsidyInclVatWithSymbol,
  } = order

  const hasEnergySubsidy =
    humanizedTotalPriceWithDiscountsInclVatWithSymbol !==
    humanizedTotalPriceWithDiscountsAndEnergySubsidyInclVatWithSymbol
  const showEnergySubsidy = hasEnergySubsidy && includeEnergySubsidy
  return (
    <table className="order-price-summary">
      <tbody>
        <tr>
          <th>Samlet</th>
          <th>
            {includeEnergySubsidy && (
              <label>
                Vis energitilskud:
                <input
                  type="checkbox"
                  checked={includeEnergySubsidy}
                  onChange={({ target: { checked } }) =>
                    updateOrder(id, { includeEnergySubsidy: checked })
                  }
                />
              </label>
            )}
          </th>
        </tr>
        <tr>
          <td>Netto</td>
          <td>{humanizedTotalPriceWithDiscountsWithSymbol}</td>
        </tr>
        <tr>
          <td>Moms</td>
          <td>{humanizedTotalPriceWithDiscountsVatWithSymbol}</td>
        </tr>
        <tr>
          <td>{(showEnergySubsidy && 'Total') || <strong>Total</strong>}</td>
          <td>
            {(showEnergySubsidy &&
              humanizedTotalPriceWithDiscountsInclVatWithSymbol) || (
              <strong>
                {humanizedTotalPriceWithDiscountsInclVatWithSymbol}
              </strong>
            )}
          </td>
        </tr>
        {showEnergySubsidy && (
          <tr>
            <td>Energitilskud</td>
            <td>-{humanizedEnergySubsidyInclVatWithSymbol}</td>
          </tr>
        )}
        {showEnergySubsidy && (
          <tr>
            <td>
              <strong>Total efter energitilskud</strong>
            </td>
            <td>
              <strong>
                {
                  humanizedTotalPriceWithDiscountsAndEnergySubsidyInclVatWithSymbol
                }
              </strong>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

OrderPriceTable.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    humanizedTotalPriceWithDiscountsWithSymbol: PropTypes.string,
    humanizedTotalPriceWithDiscountsVatWithSymbol: PropTypes.string,
    humanizedTotalPriceWithDiscountsInclVatWithSymbol: PropTypes.string,
    humanizedEnergySubsidyInclVatWithSymbol: PropTypes.string,
    humanizedTotalPriceWithDiscountsAndEnergySubsidyInclVatWithSymbol:
      PropTypes.string,
    includeEnergySubsidy: PropTypes.bool,
  }),
  updateOrder: PropTypes.func.isRequired,
}

export default connect(undefined, {
  ...OrderActions,
})(OrderPriceTable)
