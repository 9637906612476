import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import DatePicker from '../../components/DatePicker'
import { Margin } from '../../components/Grid'
import { cancelOrder } from '../../actions/OrderActions'
import * as OrderSelectors from '../../selectors/OrderSelectors'
import { fetchActivitiesForTrackable } from '../../actions/ActivityActions'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog'

class CancelOrderDialog extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    const { order } = props
    const cancelledAt = new Date()
    this.state = {
      error: null,
      submitting: false,
      cancelledAt,
    }
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleCancellAtDateChange = this.handleCancellAtDateChange.bind(this)
  }

  handleCancelClick(event) {
    event.preventDefault()

    const { cancelledAt } = this.state
    const { order, onClose } = this.props
    this.setState({ submitting: true })

    let promise = this.props.cancelOrder(order.id, cancelledAt)
    // Close and reload order activities
    promise
      .then(() =>
        Promise.all([
          this.props.fetchActivitiesForTrackable({
            trackableTypes: ['orders'],
            trackableId: order.id,
          }),
          onClose(),
        ])
      )
      .catch(() => this.setState({ submitting: false }))
  }

  handleCancellAtDateChange(dateString, cancelledAt) {
    this.setState({ cancelledAt })
  }

  render() {
    const { order, onClose } = this.props
    const { cancelledAt } = this.state

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>Anuller ordrer</DialogTitle>
          <DialogButton onClick={this.handleCancelClick}>Fortsæt</DialogButton>
        </DialogHeader>
        <Margin all className="text-center">
          Dato
          <div style={{ margin: '0 12px', display: 'inline-block' }}>
            <DatePicker
              value={cancelledAt}
              onChange={this.handleCancellAtDateChange}
            />
          </div>
        </Margin>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  order: OrderSelectors.getOrderWithRelations(state, props),
})

export default connect(mapStateToProps, {
  cancelOrder,
  fetchActivitiesForTrackable,
})(CancelOrderDialog)
