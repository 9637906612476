import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Icon from '../Icon'
import './style.styl'

export default class AddImageButton extends Component {
  static propTypes = {
    onImageSelected: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
  }

  click() {
    this.input.click()
  }

  render() {
    const { onImageSelected, multiple } = this.props
    return (
      <label className="add-image-button">
        <div className="add-image-button__icon">
          <Icon icon="camera" size={40} />
        </div>
        <input
          type="file"
          ref={(element) => (this.input = element)}
          accept="image/*"
          onChange={onImageSelected}
          multiple={multiple}
        />
      </label>
    )
  }
}
