import singularize from './singularize'

const getEntity = (state, type, id) => (state.api.entities[type] || {})[id]

const getEntities = (state, type) =>
  Object.values(state.api.entities[type] || {})

const getRelation = (state, entity, foreignName) => {
  const id = entity[foreignName]
  const type = entity[`${foreignName}Type`]
  return getEntity(state, type, id)
}

const getRelations = (state, entity, type, foreignName) => {
  const usedForeignName = foreignName || singularize(entity.type)
  return getEntities(state, type).filter(
    (relation) =>
      relation[usedForeignName] === entity.id &&
      relation[`${usedForeignName}Type`] === entity.type
  )
}

const passThroughFunction = (model) => model
const attachRelations = (state, includeArray = []) => {
  if (includeArray.length === 0) return passThroughFunction

  return (model) => {
    if (!model) return model

    const result = { ...model }

    const nestedIncludes = {}
    const rootIncludes = []

    // Determine root includes and nested includes
    includeArray.forEach((include) => {
      const dotIndex = include.indexOf('.')

      if (dotIndex !== -1) {
        const root = include.substr(0, dotIndex)
        nestedIncludes[root] = nestedIncludes[root] || []
        nestedIncludes[root].push(include.substr(dotIndex + 1))
        rootIncludes.push(root)
      } else if (rootIncludes.indexOf(include) === -1) {
        rootIncludes.push(include)
      }
    })

    // Add each root include
    rootIncludes.forEach((include) => {
      // If the key is an array, attach an array of relations based on a foreign key
      if (Array.isArray(model[include])) {
        const relations = getRelations(state, model, include)

        // Recursively add nested includes
        result[include] = relations.map(
          attachRelations(state, nestedIncludes[include])
        )
      } else if (model[include]) {
        // If the model has a single foreign key for the include, attach a single relation
        const relation = getRelation(state, model, include)

        // Recursively add nested includes
        result[include] = attachRelations(
          state,
          nestedIncludes[include]
        )(relation)
      }
    })

    return result
  }
}

export const attachResponseRelations = (response, includeArray) => {
  const fakeState = { api: { entities: response.entities } }
  return attachRelations(fakeState, includeArray)
}

export default attachRelations
