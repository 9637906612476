import { fetchEntity, createEntity, updateEntity } from './ApiActions'
import { objectKeysToSnakeCase } from '../utils/formatUtils'

export const fetchCustomer = (id, options) =>
  fetchEntity({ type: 'customers', id }, options)

export const createCustomer = (attributes) =>
  createEntity(
    { type: 'customers' },
    {
      data: {
        customer: objectKeysToSnakeCase(attributes),
      },
    }
  )

export const updateCustomer = (customer, options) =>
  updateEntity(
    { type: 'customers', ...customer },
    {
      data: {
        customer: objectKeysToSnakeCase(customer),
      },
    }
  )
