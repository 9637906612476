import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import DialogHeader from './DialogHeader'
import DialogTitle from './DialogTitle'
import DialogTitleTab from './DialogTitleTab'
import DialogButton from './DialogButton'
import DialogCancelButton from './DialogCancelButton'

const Dialog = ({ children, style, className }) => (
  <div className={`dialog__overlay ${className || ''}`}>
    <div className="dialog" style={style}>
      {children}
    </div>
  </div>
)

Dialog.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default Dialog
export {
  DialogHeader,
  DialogTitle,
  DialogTitleTab,
  DialogButton,
  DialogCancelButton,
}
