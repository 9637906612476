import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { Row, Column } from '../Grid'
import { BuildingFormField } from '../BuildingFormField'
import BuildingFormInput from '../BuildingFormInput'
import BuildingFormSelect from '../BuildingFormSelect'
import Button from '../Button'
import {
  RoofTiltOptions,
  HeatSourceOptions,
} from '../../constants/BuildingFormOptions'

export default class BuildingDetailsForm extends Component {
  static fields = [
    'area',
    'roofTilt',
    'age',
    'roofAge',
    'windowsAge',
    'heatSource',
  ]

  static propTypes = {
    submitting: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: false,
  }

  render() {
    const {
      fields: { area, roofTilt, age, roofAge, windowsAge, heatSource },
      handleSubmit,
      submitting,
    } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Column size={6}>
            <BuildingFormField label="Grundplan">
              <BuildingFormInput type="text" disabled={submitting} {...area} />
            </BuildingFormField>
          </Column>
          <Column size={6}>
            <BuildingFormField label="Tagets hældning">
              <BuildingFormSelect
                disabled={submitting}
                options={RoofTiltOptions}
                {...roofTilt}
              />
            </BuildingFormField>
          </Column>

          <Column size={6}>
            <BuildingFormField label="Husets alder">
              <BuildingFormInput type="text" disabled={submitting} {...age} />
            </BuildingFormField>
          </Column>
          <Column size={6}>
            <BuildingFormField label="Tagets alder">
              <BuildingFormInput
                type="text"
                disabled={submitting}
                {...roofAge}
              />
            </BuildingFormField>
          </Column>

          <Column size={6}>
            <BuildingFormField label="Vinduernes alder">
              <BuildingFormInput
                type="text"
                disabled={submitting}
                {...windowsAge}
              />
            </BuildingFormField>
          </Column>
          <Column size={6}>
            <BuildingFormField label="Varmekilde">
              <BuildingFormSelect
                disabled={submitting}
                options={HeatSourceOptions}
                {...heatSource}
              />
            </BuildingFormField>
          </Column>

          <Column size={12} className="text-right">
            <Button type="submit" disabled={submitting}>
              {(submitting && 'Gemmer') || 'Næste'}
            </Button>
          </Column>
        </Row>
      </form>
    )
  }
}
