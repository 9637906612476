import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Cell from '../Cell/index'
import './style.styl'

class SelectableCell extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.selected,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.props.selected)
      this.setState({ selected: nextProps.selected })
  }

  className() {
    return this.state.selected ? 'selectable-cell--selected' : ''
  }

  handleClick = () => {
    const { onClick, handleSelect } = this.props
    const { selected } = this.state

    const selectedNow = !selected
    this.setState({ selected: selectedNow })
    onClick(selectedNow)
    handleSelect()
  }

  render() {
    return (
      <Cell
        {...this.props}
        className={this.className()}
        onClick={this.handleClick}
      />
    )
  }
}

SelectableCell.propTypes = {
  selected: PropTypes.boolean,
  onClick: PropTypes.func,
  handleSelect: PropTypes.func,
}

SelectableCell.defaultProps = {
  selected: false,
}

export default SelectableCell
