import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import './style.styl'

const Spacer = (props) => {
  const {
    widthHeightRatio = 16 / 9,
    children,
    style = {},
    className,
    ...otherProps
  } = props

  return (
    <div
      className={classNames('Spacer', className)}
      style={{ ...style, paddingTop: `${(1 / widthHeightRatio) * 100}%` }}
      {...otherProps}
    >
      <div className="Spacer__inner">{children}</div>
    </div>
  )
}

Spacer.propTypes = {
  widthHeightRatio: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Spacer
