import React from 'react'
import PropTypes from 'react-proptypes'
import { FlexContainer, Flex } from '../Grid'
import OrderStateLabel from '../OrderStateLabel'
import { formatDate } from '../../utils/formatUtils'
import './style.styl'

const OrderTreeItem = ({ orderTreeOrder }) => (
  <FlexContainer horizontal className="order-tree-item">
    <Flex>
      <strong>#{orderTreeOrder.id}</strong>
    </Flex>
    <Flex className="order-tree-item__date">
      {formatDate(orderTreeOrder['created-at'], 'd/m-Y')}
    </Flex>
    <Flex className="order-tree-item__price">
      {
        orderTreeOrder[
          'humanized-total-price-with-discounts-incl-vat-with-symbol'
        ]
      }
    </Flex>
    <Flex className="order-tree-item__state">
      <OrderStateLabel state={orderTreeOrder.state} />
    </Flex>
  </FlexContainer>
)

OrderTreeItem.propTypes = {
  orderTreeOrder: PropTypes.object,
}

export default OrderTreeItem
