import React from 'react'
import PropTypes from 'react-proptypes'

const DialogHeader = ({ children }) => (
  <div className="dialog__header">{children}</div>
)

DialogHeader.propTypes = {
  children: PropTypes.any,
}

export default DialogHeader
