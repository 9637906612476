import algoliasearch from 'algoliasearch/lite'

export default class AlgoliaClient {
  constructor(applicationId, apiKey, apiSessionToken, type) {
    this.applicationId = applicationId
    this.type = type
    this.apiKey = apiKey
    this.apiSessionToken = apiSessionToken
    this.initialized = false
    this.indiceCache = {}
  }

  initialize() {
    if (this.getIsExpired()) this.initializePromise = null
    if (this.initializePromise) return this.initializePromise

    const headers = {
      Authorization: `Token token=${this.apiSessionToken || ''}@${this.apiKey}`,
    }
    return (this.initializePromise = fetch(`/api/v1/${this.type}`, { headers })
      .then((response) => response.json())
      .then((json) => {
        const { token, index_name: indexName, valid_until: validUntil } = json

        this.indexName = indexName
        this.validUntil = new Date(validUntil * 1000)
        this.client = algoliasearch(this.applicationId, token)
      }))
  }

  getIsExpired() {
    if (!this.validUntil) return false
    const timeBuffer = 500
    return this.validUntil * 1 <= new Date() * 1 - timeBuffer
  }

  getIndex(sortBy, sortDirection) {
    return this.initialize().then(() => {
      const name = [this.indexName, sortBy, sortDirection]
        .filter((isPresent) => isPresent)
        .join('_')

      if (this.indiceCache[name]) return this.indiceCache[name]

      this.indiceCache[name] = this.client.initIndex(name)
      return this.indiceCache[name]
    })
  }

  makeFilterString(key, value) {
    if (Array.isArray(value)) {
      if (value.length === 0) return ''
      let arrayFilters
      arrayFilters = value.map((arrayValue) =>
        this.makeFilterString(key, arrayValue)
      )
      return `(${arrayFilters.join(' OR ')})`
    } else if (typeof value === 'string') {
      // Keys starting with '_' are interpreted as tags
      return value[0] === '_' ? `${key}:${value}` : `${key}:"${value}"`
    } else if (typeof value === 'number') {
      return `${key} = ${value}`
    } else {
      // Objects work like this:
      //
      //  "somekey": {
      //    "comparison operator 1": "value",
      //    "comparison operator 2": "value",
      //  }
      return Object.entries(value)
        .map(([operator, objectValue]) => `${key}${operator}${objectValue}`)
        .join(' AND ')
    }
  }

  computeFilters(query) {
    const filters = []

    for (let [key, value] of Object.entries(query)) {
      if (['q', 'sortBy', 'sortDirection'].indexOf(key) !== -1) continue // q is the search string

      if (value === null || value === undefined) continue
      filters.push(this.makeFilterString(key, value))
    }

    return filters.filter((present) => present).join(' AND ')
  }

  search(query, page = 0) {
    return this.getIndex(query.sortBy, query.sortDirection).then((index) =>
      index.search({
        query: query.q,
        filters: this.computeFilters(query),
        page,
      })
    )
  }

  clearCache() {
    Object.values(this.indiceCache).forEach((index) => index.clearCache())
  }
}
