const normalizeLegacyRoles = (roles) => {
  if (!Array.isArray(roles)) {
    roles = [roles]
  }

  // Role precedence - higher to lower
  return ['super_admin', 'admin', 'manager', 'salesman'].find((role) =>
    roles.includes(role)
  )
}

// Normalize session data when the user created the session from the old app.
const normalizeLegacySession = (data) => {
  return {
    entities: {
      users: {
        [data.id]: {
          id: data.id,
          authToken: data.user_token.auth_token,
          authTokenExpiresAt: data.user_token.auth_token_expires_at,
          departmentId: data.department_id,
          email: data.email,
          name: data.name,
          role: normalizeLegacyRoles(data.roles || data.role),
          type: 'users',
        },
      },
    },
    results: {
      users: [data.id],
    },
  }

  /*
  return {
    data: {
      attributes: {
        'auth-token': data.user_token.auth_token,
        'auth-token-expires-at': data.user_token.auth_token_expires_at,
        'department-id': data.department_id,
        'email': data.email,
        'name': data.name,
        'role': normalizeLegacyRoles(data.roles || data.role)
      }
    },
    id: data.id,
    type: 'users'
  }
  */
}

const isLegacySession = (data) => Object.keys(data).includes('user_token')

const parseSession = (sessionString) => {
  let data

  if (!sessionString || typeof sessionString !== 'string') {
    return null
  }

  try {
    data = JSON.parse(sessionString)
  } catch (err) {
    return null
  }

  if (isLegacySession(data)) {
    data = normalizeLegacySession(data)
  }

  return data
}

export default parseSession
