import orderAcceptanceUrl from '../constants/OrderAcceptanceUrl'

const translatedUserRoles = {
  super_admin: 'superadmin',
  admin: 'administrator',
  salesman: 'sælger',
  manager: 'formand',
}

export function formatUserRole(role = '') {
  return translatedUserRoles[role] || ''
}

const translatedUnitTypes = {
  deg: '°',
  pcs: 'stk.',
  m2: 'm²',
  m3: 'm³',
}

export function formatUnitType(unitType = '') {
  return translatedUnitTypes[unitType] || unitType
}

export const formatTemplate = (template = '', locals = {}) => {
  const INTERPOLATION_REGEXP = /{{(.*?)}}/g

  return template.replace(INTERPOLATION_REGEXP, (context, localName) =>
    locals && locals.hasOwnProperty(localName) ? locals[localName] : localName
  )
}

/**
 * Reduce API error messages to a format understanded by redux-form
 */
export const formatResponseErrors = (errors) =>
  errors.reduce((normalized, error) => {
    const pointer = error.source.pointer

    if (pointer) {
      const keys = pointer.split('/')
      const key = camelizeString(keys[keys.length - 1])
      return { ...normalized, [key]: error.detail }
    }

    return normalized
  }, {})

export const formatOrderAcceptanceUrl = (orderId, orderToken) => {
  if (!orderAcceptanceUrl) {
    throw new Error("Couldn't find ORDER_ACCEPTANCE_URL env variable")
  }

  return `${orderAcceptanceUrl}/${orderId}/${orderToken}`
}

export const formatAddressLine = (entity) => {
  const {
    addressStreetName,
    addressStreetNumber,
    addressZipCode,
    addressCityName,
    addressAdditionalInfo,
  } = entity
  let address = `${addressStreetName} ${addressStreetNumber}`

  if (addressAdditionalInfo && addressAdditionalInfo !== '') {
    address += `, ${addressAdditionalInfo}`
  }

  if (addressZipCode && addressCityName) {
    address += `, ${addressZipCode} ${addressCityName}`
  }

  return address
}

export const pad = (stringOrNumber, digits) => {
  let string = stringOrNumber.toString()
  while (string.length < digits) {
    string = `0${string}`
  }
  return string
}

export const monthNumberToString = (n, shortString) => {
  if (shortString) {
    return [
      'jan',
      'feb',
      'mar',
      'apr',
      'maj',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'dec',
    ][n]
  }
  return [
    'januar',
    'februar',
    'marts',
    'april',
    'maj',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'december',
  ][n]
}

export const dayNumberToString = (n, shortString) => {
  if (shortString) {
    return ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'][n]
  }
  return [
    'søndag',
    'mandag',
    'tirsdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lørdag',
  ][n]
}

export const formatDate = (dateOrString, format) => {
  let date
  if (typeof dateOrString === 'string') {
    date = new Date(dateOrString)
  } else if (dateOrString) {
    date = dateOrString
  } else {
    date = new Date()
  }

  const replacements = {
    j: () => date.getDate(),
    d: () => pad(date.getDate(), 2),
    N: () => date.getDay() + 1,
    l: () => dayNumberToString(date.getDay()),
    D: () => dayNumberToString(date.getDay(), true),
    Y: () => date.getFullYear(),
    n: () => date.getMonth() + 1,
    m: () => pad(date.getMonth() + 1, 2),
    F: () => monthNumberToString(date.getMonth()),
    M: () => monthNumberToString(date.getMonth(), true),
    G: () => date.getHours(),
    H: () => pad(date.getHours(), 2),
    i: () => pad(date.getMinutes(), 2),
    s: () => pad(date.getSeconds(), 2),
  }

  let ret = ''
  let skipNext = false
  let replaced = false
  let char
  let magicChar
  let replacement

  for (let i = 0; i < format.length; i++) {
    char = format[i]
    if (skipNext) {
      ret += char
      skipNext = false
      continue
    }

    replaced = false

    if (char === '\\') {
      skipNext = true
      continue
    }

    for ([magicChar, replacement] of Object.entries(replacements)) {
      if (magicChar === char) {
        ret += replacement()
        replaced = true
        break
      }
    }

    if (!replaced) {
      ret += char
    }
  }
  return ret
}

export const snakeString = (string) =>
  string.replace(/([A-Z])/g, (match, group) => `_${group.toLowerCase()}`)

export const objectKeysToSnakeCase = (object) =>
  Object.keys(object).reduce((formatted, key) => {
    return {
      ...formatted,
      [snakeString(key)]: object[key],
    }
  }, {})

export const camelizeString = (string) =>
  string.replace(/(?!^.)[-_](.)/g, (match, group) => group.toUpperCase())

export const camelizeKeys = (object) => {
  if (!object) {
    return object
  } else if (Array.isArray(object)) {
    return object.map(camelizeKeys)
  } else if (typeof object === 'object') {
    return Object.entries(object).reduce((result, [key, value]) => {
      result[camelizeString(key)] = camelizeKeys(value)
      return result
    }, {})
  } else {
    return object
  }
}

export const kebabToSnakeCase = (string) => string.replace(/\-/g, '_')

export const commentablelizeString = (string) =>
  string
    .replace(/-(.)/g, (match, group) => group.toUpperCase())
    .replace(/^(.)/, (match, group) => group.toUpperCase())
    .replace(/ies$/, 'y')
    .replace(/i$/, 'o')
    .replace(/s$/, '')

export const formatArray = (array) => {
  const length = array.length

  if (length < 3) {
    return array.join(' og ')
  } else {
    const last = array[array.length - 1]
    const rest = array.slice(0, -1)
    return `${rest.join(', ')} og ${last}`
  }
}

export const formatUserName = (user) => {
  if (!user) return 'Slettet bruger'
  return (user.name || '').trim() + ((user.deletedAt && ' - slettet') || '')
}
