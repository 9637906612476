import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { fetchReportWithRelations, sendReport } from '../actions/ReportActions'
import * as Selectors from '../selectors/ReportSelectors'
import { formatDate } from '../utils/formatUtils'

import Spinner from '../components/Spinner'
import { VerticalAlign, FlexContainer, Flex } from '../components/Grid'
import NewReportHeader from '../components/NewReportHeader'
import Page, { PageHeader, PageContent } from '../components/Page'
import Button from '../components/Button'
import ButtonAddon from '../components/ButtonAddon'
import ReportSendButton from '../components/ReportSendButton'
import { Link } from 'react-router'
import { openDialog } from '../actions/DialogActions'

class ReportContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    reportId: PropTypes.string.isRequired,
    report: PropTypes.object,
    fetchReportWithRelations: PropTypes.func.isRequired,
    sendReport: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isFetching: true,
  }

  static initialState = {
    sending: false,
    sent: false,
  }

  constructor(props) {
    super(props)
    this.state = { ...ReportContainer.initialState }
    this.handleSendEmailClick = this.handleSendEmailClick.bind(this)
    this.handleRateTrustpilotClick = this.handleRateTrustpilotClick.bind(this)
  }

  componentDidMount() {
    const { reportId } = this.props
    this.props.fetchReportWithRelations(reportId)
  }

  shouldComponentUpdate(nextProps) {
    const { isFetching, report } = nextProps

    // Poll for report PDF
    if (report && !report.pdfUrl) {
      setTimeout(() => {
        if (!isFetching) {
          this.props.fetchReportWithRelations(report.id)
        }
      }, 2000)

      // If we are polling, only update the component if this is the first fetch
      return !this.props.report
    }

    return true
  }

  componentWillUnmount() {
    if (this.sentTimeout) {
      clearTimeout(this.sentTimeout)
    }
  }

  handleSendEmailClick(component) {
    const email = component.state.value
    const { report } = this.props

    this.setState({ sending: true })

    this.props
      .sendReport(report.id, { query: { email } })
      .then(() => {
        this.setState({ sending: false, sent: true })
        this.sentTimeout = setTimeout(
          () => this.setState({ sent: false }),
          1500
        )
      })
      .catch(() => this.setState({ sending: false }))
  }

  handleRateTrustpilotClick() {
    this.props.openDialog('orderRateTrustpilot', {
      orderId: this.props.reportId,
      type: 'report',
      user: this.props.report.user,
      customer: this.props.report.customer,
    })
  }

  render() {
    const { isFetching, report } = this.props
    const reportLoading = report && !report.pdfUrl

    if (isFetching || !report) {
      return <Spinner center />
    }

    const formattedDate = formatDate(report.createdAt, 'j. F Y')
    const { user, customer } = report
    const { sending, sent } = this.state

    return (
      <Page>
        <PageHeader title="Rapporter" />
        <PageContent>
          <VerticalAlign>
            <FlexContainer horizontal>
              <Flex />
              {(reportLoading && (
                <Flex className="text-center" size={6}>
                  <NewReportHeader>Bygger rapport</NewReportHeader>
                  <Spinner />
                </Flex>
              )) || (
                <Flex className="text-center" size={7}>
                  <NewReportHeader>Åben eller send</NewReportHeader>
                  <p>
                    Rapporten blev oprettet af {user.name} den {formattedDate}
                  </p>

                  <FlexContainer
                    horizontal
                    className="margin--top-2 margin--bottom-2"
                  >
                    <ButtonAddon borderless>Åben PDF</ButtonAddon>
                    <Flex noGrow noShrink className="btn-group margin--right-2">
                      <a href={report.mergedPdfUrl} target="_blank">
                        <Button disabled={!report.mergedPdfUrl}>
                          Med logo
                        </Button>
                      </a>
                      <a href={report.pdfUrl} target="_blank">
                        <Button disabled={!report.pdfUrl}>Uden logo</Button>
                      </a>
                    </Flex>
                    <Flex>
                      <ReportSendButton
                        defaultValue={customer.email}
                        onClick={this.handleSendEmailClick}
                        disabled={sending || sent}
                      >
                        {(sent && 'Sendt') ||
                          (sending && 'Sender...') ||
                          'Send'}
                      </ReportSendButton>
                    </Flex>
                  </FlexContainer>

                  <Link to="/reports">Afslut og gå til oversigten</Link>
                  <Flex className="margin--top-2 margin--bottom-2">
                    <Button onClick={this.handleRateTrustpilotClick}>
                      Bedøm besøg
                    </Button>
                  </Flex>
                </Flex>
              )}
              <Flex />
            </FlexContainer>
          </VerticalAlign>
        </PageContent>
      </Page>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { reportId } = props.params
  return {
    isFetching: Selectors.isFetching(state),
    reportId,
    report: Selectors.getReportWithRelations(state, props),
  }
}

export default connect(mapStateToProps, {
  fetchReportWithRelations,
  sendReport,
  openDialog,
})(ReportContainer)
