import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import {
  acceptOrder,
  sendOrderAcceptanceEmail,
} from '../../actions/OrderActions'
import { fetchActivitiesForTrackable } from '../../actions/ActivityActions'

import * as OrderSelectors from '../../selectors/OrderSelectors'
import * as AuthSelectors from '../../selectors/AuthSelectors'

import CustomerEmailInputContainer from '../CustomerEmailInputContainer'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import RadioGroup from '../../components/RadioGroup'
import { Margin } from '../../components/Grid'
import TextArea from '../../components/TextArea'
import Button from '../../components/Button'
import SignatureInput from '../../components/SignatureInput'
import PreviewPanel from '../../components/PreviewPanel'

import { isValidEmail } from '../../utils/validationUtils'
import { formatOrderAcceptanceUrl } from '../../utils/formatUtils'
import { userRoleLabels } from '../../constants/UserRoles'

const acceptTypes = [
  { label: 'Send e-mail med bekræftelseslink', value: 'email' },
  { label: 'Upload dokumentation på kundens accept', value: 'upload' },
  { label: 'Underskriv på skærm', value: 'signature' },
]

class OrderAcceptDialog extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    order: PropTypes.shape({
      customer: PropTypes.object.isRequired,
      token: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    acceptOrder: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
    sendOrderAcceptanceEmail: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      acceptType: 'email',
      emailInputs: {},
      signatureInputs: { customerSignature: null },
      uploadInputs: { acceptanceDocument: null },
      submitting: false,
    }
    this.handleAcceptTypeChange = this.handleAcceptTypeChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSignatureChange = this.handleSignatureChange.bind(this)
    this.handleUploadChange = this.handleUploadChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  componentWillMount() {
    this.setState({
      emailInputs: {
        ...this.state.emailInputs,
        email: this.props.order.customer.email,
      },
    })
  }

  componentWillReceiveProps(props) {
    this.setState({
      emailInputs: {
        ...this.state.emailInputs,
        email: props.order.customer.email,
      },
    })
  }

  handleAcceptTypeChange(acceptType) {
    if (this.state.acceptType !== acceptType) {
      this.setState({
        acceptType,
        signatureInputs: { customerSignature: null },
        uploadInputs: { acceptanceDocument: null },
      })
    }
  }

  handleInputChange({ target: { value, name } }) {
    if (name === 'emailInputs[email]') {
      this.setState({
        emailInputs: { ...this.state.emailInputs, email: value },
      })
    } else if (name === 'emailInputs[content]') {
      this.setState({
        emailInputs: { ...this.state.emailInputs, content: value },
      })
    }
  }

  handleSignatureChange(customerSignature) {
    this.setState({ signatureInputs: { customerSignature } })
  }

  handleUploadChange({ target: { files } }) {
    this.setState({ uploadInputs: { acceptanceDocument: files[0] } })
  }

  handleButtonClick() {
    const { acceptType, emailInputs } = this.state
    const { order, onClose, currentUser } = this.props
    this.setState({ submitting: true })

    let promise = Promise.resolve()

    if (acceptType === 'email') {
      const email = {
        id: order.id,
        email: emailInputs.email,
        body: emailInputs.content,
        token: order.token,
        cc: currentUser.email,
      }
      promise = promise.then(() => this.props.sendOrderAcceptanceEmail(email))
    } else {
      let attributes
      if (acceptType === 'signature') {
        attributes = this.state.signatureInputs
      } else {
        attributes = this.state.uploadInputs
      }
      this.setState({ submitting: true })
      promise = promise.then(() => this.props.acceptOrder(order.id, attributes))
    }

    // Close and reload order activities
    promise
      .then(() =>
        Promise.all([
          this.props.fetchActivitiesForTrackable({
            trackableTypes: ['orders'],
            trackableId: order.id,
          }),
          onClose(),
        ])
      )
      .catch(() => this.setState({ submitting: false }))
  }

  renderEmailForm() {
    const { emailInputs, submitting } = this.state
    const { currentUser, order } = this.props
    const acceptanceUrl = formatOrderAcceptanceUrl(order.id, order.token)
    const disabled =
      submitting || !emailInputs.content || !isValidEmail(emailInputs.email)

    return (
      <Margin all>
        <CustomerEmailInputContainer
          customerId={order.customer.id}
          customerEmail={order.customer.email}
          placeholder="Modtagers E-mail-adresse"
          defaultValue={emailInputs.email}
          name="emailInputs[email]"
          type="email"
          onChange={this.handleInputChange}
        />

        <Margin vertical>
          <PreviewPanel seamlessBottom>Hej {order.customer.name}</PreviewPanel>
          <TextArea
            defaultValue={emailInputs.content}
            name="emailInputs[content]"
            onChange={this.handleInputChange}
            seamless
          />
          <PreviewPanel seamlessTop>
            <a href={acceptanceUrl} target="_blank">
              Accepter tilbud
            </a>
            <br />
            <br />
            Med venlig hilsen
            <br />
            {currentUser.name && (
              <span>
                {currentUser.name}
                <br />
              </span>
            )}
            {currentUser.role && (
              <span>
                {userRoleLabels[currentUser.role]}
                <br />
              </span>
            )}
            {currentUser.phone && (
              <span>
                {currentUser.phone}
                <br />
              </span>
            )}
            {currentUser.email && (
              <span>
                {currentUser.email}
                <br />
              </span>
            )}
            Jydsk Tagteknik A/S
          </PreviewPanel>
        </Margin>
        <Margin top className="text-right">
          <Button disabled={disabled} onClick={this.handleButtonClick}>
            {(submitting && 'Sender...') || 'Send'}
          </Button>
        </Margin>
      </Margin>
    )
  }

  renderUploadForm() {
    const { submitting, uploadInputs } = this.state
    const disabled = submitting || !uploadInputs.acceptanceDocument

    return (
      <Margin all>
        <p>Vælg dokumentation</p>
        <input
          type="file"
          accept="image/*"
          onChange={this.handleUploadChange}
        />
        <Margin top className="text-right">
          <Button disabled={disabled} onClick={this.handleButtonClick}>
            {(submitting && 'Gemmer...') || 'Gem'}
          </Button>
        </Margin>
      </Margin>
    )
  }

  renderSignatureForm() {
    const { customer } = this.props.order
    const { submitting, signatureInputs } = this.state
    const disabled = submitting || !signatureInputs.customerSignature

    return (
      <Margin all>
        <p>Underskriv herunder</p>
        <SignatureInput
          onChange={this.handleSignatureChange}
          name={customer.name}
        >
          <Button
            style={{ float: 'right' }}
            disabled={disabled}
            onClick={this.handleButtonClick}
          >
            {(submitting && 'Gemmer...') || 'Gem'}
          </Button>
        </SignatureInput>
      </Margin>
    )
  }

  render() {
    const { onClose } = this.props
    const { acceptType, submitting } = this.state

    return (
      <Dialog style={{ width: '500px' }}>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={submitting}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>Godkend tilbud</DialogTitle>
        </DialogHeader>
        <Margin all>
          <RadioGroup
            options={acceptTypes}
            value={acceptType}
            onChange={this.handleAcceptTypeChange}
          />
        </Margin>
        {acceptType === 'email' && this.renderEmailForm()}
        {acceptType === 'upload' && this.renderUploadForm()}
        {acceptType === 'signature' && this.renderSignatureForm()}
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: AuthSelectors.getCurrentUser(state),
  order: OrderSelectors.getOrderWithRelations(state, props),
})

export default connect(mapStateToProps, {
  acceptOrder,
  sendOrderAcceptanceEmail,
  fetchActivitiesForTrackable,
})(OrderAcceptDialog)
