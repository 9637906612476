import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'

class RadioGroup extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
          .isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { value } }) {
    const { onChange } = this.props
    onChange(value)
  }

  renderOption(option) {
    const { value } = this.props

    return (
      <label key={option.value} className="radio-group__item">
        <input
          type="radio"
          value={option.value}
          checked={value === option.value}
          onChange={this.handleChange}
        />{' '}
        {option.label}
      </label>
    )
  }

  render() {
    const { options } = this.props

    return (
      <div className="radio-group">{options.map(this.renderOption, this)}</div>
    )
  }
}

export default RadioGroup
