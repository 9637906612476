import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import omit from 'lodash/omit'
import classNames from 'classnames'

export const SelectOption = ({ key, value, label }) => (
  <option key={key || value} value={value}>
    {label}
  </option>
)

SelectOption.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export const Select = ({ block, className, options, ...props }) => (
  <div className={classNames('select', className, { 'select--block': block })}>
    <select {...props}>
      {props.value === '' && !options.some((o) => o.value === '') && (
        <SelectOption key="" value="" label="" selected />
      )}
      {options.map(SelectOption)}
    </select>
    <div className="select__arrow" />
  </div>
)

Select.propTypes = {
  block: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
}

export default Select
