import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const Spinner = (props) => {
  const classes = classNames('spinner', {
    'spinner--center': props.center,
    'spinner--dark': props.dark,
    'spinner--center-horizontal': props.centerHorizontal,
  })

  return <div className={classes} />
}

Spinner.propTypes = {
  center: PropTypes.bool,
  centerHorizontal: PropTypes.bool,
  dark: PropTypes.bool,
}

Spinner.defaultProps = {
  center: false,
  centerHorizontal: false,
  dark: false,
}

export default Spinner
