import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const ListItemThumbnail = ({ src }) => (
  <div
    className="list__item__thumbnail"
    style={{ backgroundImage: `url(${src})` }}
  />
)

ListItemThumbnail.propTypes = {
  src: PropTypes.string.isRequired,
}

const ListItem = (props) => {
  const classes = classNames('list__item', props.className)
  const styles = {
    cursor: props.onClick ? 'pointer' : 'default',
  }

  return (
    <div style={styles} className={classes} onClick={props.onClick}>
      {props.thumbnail ? <ListItemThumbnail src={props.thumbnail} /> : null}
      <div className="list__item__inner">
        {props.before}
        <div className="list__item__info">
          {props.title ? (
            <div className="list__item__title">{props.title}</div>
          ) : null}
          {props.description ? (
            <div className="list__item__description">{props.description}</div>
          ) : null}
        </div>
        {props.actions ? props.actions : null}
        {props.children}
      </div>
    </div>
  )
}

ListItem.propTypes = {
  thumbnail: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.any,
  children: PropTypes.any,
  before: PropTypes.any,
  onClick: PropTypes.func,
}

export default ListItem
