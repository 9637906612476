import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { createBuilding } from '../../actions/BuildingActions'
import { formatResponseErrors } from '../../utils/formatUtils'
import { getCurrentUserId } from '../../selectors/AuthSelectors'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog'
import BuildingAddressForm from '../../components/BuildingAddressForm'

class CreateBuildingDialog extends Component {
  handleCreateClick = () => {
    this.formElement.submit()
  }

  handleFormSubmit = (attrs) => {
    const { createBuilding, customerId, userId, onClose } = this.props

    return createBuilding({ ...attrs, customerId, userId })
      .then((building) => {
        onClose()
        return building
      })
      .catch((err) => Promise.reject(formatResponseErrors(err)))
  }

  render() {
    const { onClose, handleSubmit, submitting, fields, initialValues } =
      this.props

    return (
      <Dialog>
        <DialogHeader>
          <DialogCancelButton onClick={onClose}>Annuller</DialogCancelButton>
          <DialogTitle>Opret bygning</DialogTitle>
          <DialogButton onClick={this.handleCreateClick}>Opret</DialogButton>
        </DialogHeader>
        <BuildingAddressForm
          ref={(element) => (this.formElement = element)}
          initialValues={initialValues}
          fields={fields}
          submitting={submitting}
          handleSubmit={handleSubmit(this.handleFormSubmit)}
        />
      </Dialog>
    )
  }
}

const CreateBuildingDialogWithForm = reduxForm({
  form: 'building',
  fields: BuildingAddressForm.fields,
})(CreateBuildingDialog)

const mapStateToProps = (state) => ({
  userId: getCurrentUserId(state),
})

export default connect(mapStateToProps, {
  createBuilding,
})(CreateBuildingDialogWithForm)
