import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const Pre = (props) => {
  const className = classNames('pre', props.className)
  return <pre className={className} {...props} />
}

Pre.propTypes = {
  className: PropTypes.string,
}

export default Pre
