export const hashToFormData = (attributes) => {
  const d = new FormData()
  for (const [name, value] of Object.entries(attributes)) {
    const usedValue = [undefined, null].indexOf(value) === -1 ? value : ''
    const isFile = [Blob, File].indexOf(usedValue.constructor) !== -1

    if (isFile) {
      d.append(name, usedValue, usedValue.name) // Append files with their names
    } else {
      d.append(name, usedValue)
    }
  }
  return d
}
