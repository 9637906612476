import { CALL_API } from '../middleware/api'
import {
  fetchEntities,
  createEntity,
  updateEntity,
  fetchEntity,
  invalidateEntities,
} from './ApiActions'
import { objectKeysToSnakeCase } from '../utils/formatUtils'
import * as types from '../constants/ActionTypes'

const attributesToFormData = (attributes) => {
  const formData = new FormData()
  const { buildingId, templateId } = attributes
  formData.append('report[building_id]', buildingId)
  formData.append('report[report_template_id]', templateId)
  return formData
}

const relationIncludes = [
  'building',
  'building.customer',
  'report_template',
  'user',
]

export const invalidateReports = (options) =>
  invalidateEntities('reports', options)
export const fetchReports = (options) => fetchEntities('reports', options)
export const fetchReportsWithRelations = (options = {}) => {
  const preparedOptions = { query: {}, ...options }
  preparedOptions.query.include = relationIncludes
  return fetchReports(preparedOptions)
}

export const fetchReport = (id, options) =>
  fetchEntity({ type: 'reports', id }, options)
export const fetchReportWithRelations = (id) =>
  fetchReport(id, { query: { include: relationIncludes } })

export const createReport = (attributes) =>
  createEntity({ type: 'reports' }, { body: attributesToFormData(attributes) })

export const sendReport = (id, options = {}) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: `/reports/${id}/send_mail`,
    types: [
      types.API_CUSTOM_REQUEST,
      types.API_CUSTOM_SUCCESS,
      types.API_CUSTOM_FAILURE,
    ],
    ...options,
  },
})

export const updateReport = (id, attributes) =>
  updateEntity(
    { type: 'reports', id },
    {
      data: {
        report: objectKeysToSnakeCase(attributes),
      },
    }
  )
