import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { push } from 'react-router-redux'

import * as CustomerActions from '../actions/CustomerActions'
import * as OrderActions from '../actions/OrderActions'
import * as DialogActions from '../actions/DialogActions'
import * as AlgoliaActions from '../actions/AlgoliaActions'

import { getCustomerDrafts } from '../selectors/OrderSelectors'
import { isFetching } from '../selectors/CustomerSelectors'
import { getCurrentUserId } from '../selectors/AuthSelectors'

import CustomerInputContainer from './CustomerInputContainer'

import OrderWizardStepTitle from '../components/OrderWizard/StepTitle'
import Spinner from '../components/Spinner'
import Input from '../components/Input'
import Button from '../components/Button'
import Breadcrumb, { BreadcrumbItem } from '../components/Breadcrumb'
import {
  Row,
  Column,
  VerticalAlign,
  FlexContainer,
  Flex,
  Margin,
} from '../components/Grid'
import { PageHeader } from '../components/Page'

import { camelizeKeys } from '../utils/formatUtils'

class DraftsPerCustomerContainer extends Component {
  static propTypes = {
    customerId: PropTypes.string,
    currentUserId: PropTypes.string,

    orders: PropTypes.array,
    isFetching: PropTypes.bool,

    push: PropTypes.func.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    createOrder: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
    insertFakeAlgoliaResult: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      newOrderName: '',
    }
  }

  componentDidMount() {
    const { customerId } = this.props
    if (customerId) this.fetchCustomerDrafts()
  }

  componentDidUpdate(oldProps) {
    if (!this.props.customerId) return

    if (oldProps.customerId !== this.props.customerId) {
      this.fetchCustomerDrafts()
    }
  }

  fetchCustomerDrafts() {
    const { fetchCustomer, customerId } = this.props
    fetchCustomer(customerId, {
      query: {
        include: 'orders',
        fields: { orders: ['name', 'state', 'customer'] },
      },
    })
  }

  handleCreateCustomerClick = () => {
    this.props.openDialog('customer', {
      customer: {},
      onSubmitted: this.handleCustomerCreated,
    })
  }

  handleCustomerCreated = ({ entities: { customers } }) => {
    const customer = Object.values(customers)[0]

    // Insert fake algolia result for the customer, so that it can be shown as
    // selected in the customer selector
    const fakeAlgoliaHit = { objectID: customer.id, ...camelizeKeys(customer) }
    this.props.insertFakeAlgoliaResult('customers', fakeAlgoliaHit)

    this.props.push(`/drafts/per-customer/${customer.id}`)
  }

  handleCustomerChange = (customerId) => {
    if (customerId) {
      this.props.push(`/drafts/per-customer/${customerId}`)
    } else {
      this.props.push('/drafts/per-customer')
    }
  }

  handleNewOrderNameChange = ({ target: { value } }) => {
    this.setState({ newOrderName: value })
  }

  handleNewOrderSubmit = (event) => {
    event.preventDefault()

    const { customerId, currentUserId: userId } = this.props
    const { newOrderName: name } = this.state

    if (!name) return

    this.props
      .createOrder({ name, customerId, userId })
      .then(({ results }) => this.props.push(`/drafts/${results.orders[0]}`))
  }

  renderDrafts() {
    const { orders } = this.props

    if (this.props.isFetching) {
      return <Spinner />
    }

    return (
      <div>
        <OrderWizardStepTitle>Vælg kladde</OrderWizardStepTitle>
        {orders.length ? (
          orders.map((order) => (
            <p key={order.id}>
              <Link to={`/drafts/${order.id}`}>
                #{order.id} {order.name}
              </Link>
            </p>
          ))
        ) : (
          <p>Kunden har ingen tilbud</p>
        )}
      </div>
    )
  }

  renderNewOrderForm() {
    const { newOrderName } = this.state

    return (
      <form onSubmit={this.handleNewOrderSubmit}>
        <OrderWizardStepTitle>Eller opret en ny kladde</OrderWizardStepTitle>
        <FlexContainer horizontal>
          <Flex>
            <Input
              value={newOrderName}
              placeholder="Tilbudsnavn"
              onChange={this.handleNewOrderNameChange}
            />
          </Flex>
          <Flex noGrow noShrink>
            <Margin size={1} left>
              <Button type="submit" disabled={!newOrderName}>
                Opret
              </Button>
            </Margin>
          </Flex>
        </FlexContainer>
      </form>
    )
  }

  render() {
    const { customerId } = this.props

    return (
      <div>
        <PageHeader>
          <Breadcrumb className="page__title">
            <BreadcrumbItem title="Opret tilbud" />
          </Breadcrumb>
        </PageHeader>
        <VerticalAlign>
          <Row>
            <Column size={8} offset={2}>
              <OrderWizardStepTitle>
                <FlexContainer horizontal style={{ alignItems: 'center' }}>
                  <Flex>Vælg kunde</Flex>
                  <Flex noGrow>
                    <Button onClick={this.handleCreateCustomerClick}>
                      Opret kunde
                    </Button>
                  </Flex>
                </FlexContainer>
              </OrderWizardStepTitle>
              <CustomerInputContainer
                value={customerId}
                placeholder="Søg efter kunde"
                onChange={this.handleCustomerChange}
              />

              {customerId && this.renderDrafts()}
              {customerId && this.renderNewOrderForm()}
            </Column>
          </Row>
        </VerticalAlign>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  customerId: props.params.customerId,
  orders: getCustomerDrafts(state, props),
  isFetching: isFetching(state),
  currentUserId: getCurrentUserId(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      ...CustomerActions,
      ...OrderActions,
      ...DialogActions,
      ...AlgoliaActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftsPerCustomerContainer)
