import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'

export default class PriceDisplay extends Component {
  static propTypes = {
    wizardBuildingPrice: PropTypes.any,
    orderPrice: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.state = { animate: false }
  }

  componentDidMount() {
    this.boxElement.addEventListener('animationend', this.animate)
  }

  componentWillUpdate(nextProps) {
    const { wizardBuildingPrice, orderPrice } = this.props

    const priceHasChanged =
      nextProps.wizardBuildingPrice !== wizardBuildingPrice ||
      nextProps.orderPrice !== orderPrice

    if (priceHasChanged) {
      this.setState({ animate: true })
    }
  }

  componentWillUnmount() {
    this.boxElement.removeEventListener('animationend', this.animate)
  }

  animate = () => {
    this.setState({ animate: false })
  }

  render() {
    const { wizardBuildingPrice, orderPrice } = this.props
    const { animate } = this.state

    return (
      <div
        ref={(element) => (this.boxElement = element)}
        className={`PriceDisplay ${animate ? 'PriceDisplay--animate' : ''}`}
      >
        <dl className="PriceDisplay__header">
          <dt>
            <h3>Samlet pris</h3>
          </dt>
          <dd>(m. moms)</dd>
        </dl>
        <dl className="PriceDisplay__description">
          <dt>Pakke</dt>
          <dd>{wizardBuildingPrice}</dd>
        </dl>
        <dl className="PriceDisplay__description PriceDisplay__description--large">
          <dt>Tilbud</dt>
          <dd>{orderPrice}</dd>
        </dl>
      </div>
    )
  }
}
