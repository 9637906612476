import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as DialogActions from '../../actions/DialogActions'
import * as BuildingImageActions from '../../actions/BuildingImageActions'

import { getBuildingImagesForBuildingAndImageTag } from '../../selectors/BuildingImageSelectors'
import { getActionCountForEntityTypes } from '../../selectors/ApiSelectors'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
} from '../../components/Dialog'
import Spacer from '../../components/Spacer'
import Fader from '../../components/Fader'
import { Margin, Row, Column } from '../../components/Grid'
import AddImageButton from '../../components/AddImageButton'
import Spinner from '../../components/Spinner'
import BuildingImage from '../../components/BuildingImage'

import { compressImages } from '../../utils/imageUtils'

const compressOptions = {
  maxWidth: 1200,
  maxHeight: 1200,
  quality: 0.8,
}

class BuildingImagesForTagDialog extends Component {
  static propTypes = {
    imageTag: PropTypes.object,
    buildingId: PropTypes.string.isRequired,
    buildingImages: PropTypes.array.isRequired,
    createBuildingImage: PropTypes.func.isRequired,
    deleteBuildingImage: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }

  handleBuildingImageAdd = ({ target: { files } }) => {
    const { buildingId, imageTag, createBuildingImage } = this.props
    compressImages(files, compressOptions)
      .then((images) =>
        Promise.all(
          images.map((image) => {
            const attributes = {
              imageTagId: imageTag ? imageTag.id : null,
              buildingId,
              image,
            }
            return createBuildingImage(attributes)
          })
        )
      )
      .catch(() =>
        alert(
          `Billed${
            files.length > 0 ? 'erne' : 'et'
          } kunne ikke oprettes, prøv venligst igen`
        )
      )
  }

  handleBuildingImageDelete = (buildingImageId) => {
    const { deleteBuildingImage } = this.props
    deleteBuildingImage(buildingImageId).catch(() =>
      alert('Billedet kunne ikke slettes, prøv venligst igen')
    )
  }

  render() {
    const { closeDialog, buildingImages, imageTag, isLoading } = this.props

    return (
      <Dialog>
        <DialogHeader>
          <DialogTitle>
            {imageTag
              ? `Billeder af ${imageTag.name.toLowerCase()}`
              : 'Diverse billeder'}
          </DialogTitle>
          <DialogButton onClick={closeDialog}>Ok</DialogButton>
        </DialogHeader>
        <Fader opacity={isLoading ? 0.5 : 1} disableClicks={isLoading}>
          <Margin horizontal size={2}>
            <Margin vertical size={1}>
              <Row>
                {buildingImages.map((image) => (
                  <BuildingImage
                    image={image}
                    key={image.id}
                    size={4}
                    onDelete={this.handleBuildingImageDelete}
                  />
                ))}

                <Column size={4} key="new-image">
                  <Margin vertical size={1}>
                    <Spacer widthHeightRatio={1 / 0.66}>
                      <AddImageButton
                        multiple
                        onImageSelected={this.handleBuildingImageAdd}
                      />
                    </Spacer>
                  </Margin>
                </Column>
              </Row>
            </Margin>
          </Margin>

          {isLoading && <Spinner center />}
        </Fader>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, { buildingId, imageTag }) => {
  const params = { buildingId, imageTagId: imageTag ? imageTag.id : null }
  const isCreating =
    getActionCountForEntityTypes(state, 'updating', 'buildingImages') !== 0
  const isDeleting =
    getActionCountForEntityTypes(state, 'deleting', 'buildingImages') !== 0

  return {
    buildingImages: getBuildingImagesForBuildingAndImageTag(state, { params }),
    isLoading: isCreating || isDeleting,
  }
}

export default connect(mapStateToProps, {
  ...DialogActions,
  ...BuildingImageActions,
})(BuildingImagesForTagDialog)
