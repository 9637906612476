import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as WizardBuildingActions from '../../actions/WizardBuildingActions'
import * as WizardBuildingStepActions from '../../actions/WizardBuildingStepActions'

import { isActioningEntityTypes } from '../../selectors/ApiSelectors'
import {
  getWizardBuildingIsMissingImages,
  getWizardBuildingIsMissingProperties,
  getWizardBuildingNeedsAnyProperties,
  getWizardBuildingWithRelations,
  isFetching,
} from '../../selectors/WizardBuildingSelectors'
import { getOptionalOrderedWizardBuildingStepsForWizardBuilding } from '../../selectors/WizardBuildingStepSelectors'

import Breadcrumb, { BreadcrumbItem } from '../../components/Breadcrumb'
import Button from '../../components/Button'
import ChecklistNavigationListItem from '../../components/ChecklistNavigationListItem'
import { Flex, FlexContainer, Margin } from '../../components/Grid'
import List from '../../components/List'
import OrderWizardStepTitle from '../../components/OrderWizard/StepTitle'
import Page, { PageContent, PageHeader } from '../../components/Page'
import PriceDisplay from '../../components/PriceDisplay'
import Spinner from '../../components/Spinner'

import NavigationButtons from './NavigationButtons'
import WizardBuildingSteps from './WizardBuildingSteps'

class DraftWizardBuildingContainer extends Component {
  static propTypes = {
    router: PropTypes.object,
    location: PropTypes.object,
    params: PropTypes.object,
    children: PropTypes.any,

    wizardBuildingId: PropTypes.string.isRequired,
    wizardBuilding: PropTypes.object,
    shownWizardBuildingSteps: PropTypes.array.isRequired,

    fetchWizardBuilding: PropTypes.func.isRequired,
    createMissingWizardBuildingSteps: PropTypes.func.isRequired,
    initializeWizardBuildingSteps: PropTypes.func.isRequired,

    isLoading: PropTypes.bool.isRequired,
    isCreatingWizardBuildingSteps: PropTypes.bool.isRequired,
    showPropertiesStep: PropTypes.bool.isRequired,
    isMissingProperties: PropTypes.bool.isRequired,
    isMissingImages: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { wizardBuildingId } = this.props
    if (wizardBuildingId) this.fetchWizardBuilding()
  }

  componentDidUpdate(oldProps) {
    if (!this.props.wizardBuildingId) return
    if (oldProps.wizardBuildingId !== this.props.wizardBuildingId)
      this.fetchWizardBuilding()
  }

  getBaseURL() {
    const { orderId, wizardBuildingId } = this.props.params
    return `/drafts/${orderId}/wizard-buildings/${wizardBuildingId}`
  }

  fetchWizardBuilding() {
    const {
      wizardBuildingId,
      fetchWizardBuilding,
      createMissingWizardBuildingSteps,
    } = this.props

    // Include EVERYTHING that we need for this container and sub routes
    fetchWizardBuilding(wizardBuildingId, {
      query: {
        include: [
          'discount',
          'order',
          'wizard',
          'wizard.steps',
          'wizard.steps.step_products',
          'wizard.steps.step_products.result',
          'wizard.steps.step_products.result.property_category',
          'wizard.required_image_tags',
          'wizard.required_image_tags.image_tag',
          'wizard_building_steps',
          'wizard_building_steps.line_items.building_property',
          'building',
          'building.building_properties',
          'building.building_images',
          'building.building_properties.property',
          'building.building_properties.property.property_category',
        ],
      },
    }).then(() => {
      createMissingWizardBuildingSteps(wizardBuildingId)

      const {
        showPropertiesStep,
        router: { push },
        children,
      } = this.props
      if (!children)
        push(
          `${this.getBaseURL()}/${showPropertiesStep ? 'properties' : 'images'}`
        )
    })
  }

  handleCreateMandatoryProductsClick = () => {
    const {
      initializeWizardBuildingSteps,
      wizardBuildingId,
      router: { push },
    } = this.props
    initializeWizardBuildingSteps(wizardBuildingId)
      .then(() => {
        const { shownWizardBuildingSteps } = this.props
        push(
          `${this.getBaseURL()}/wizard-building-steps/${
            shownWizardBuildingSteps[0].id
          }`
        )
      })
      .catch(() => {
        alert(
          [
            'Produkterne kunne ikke oprettes.',
            'Det skyldes sædvanligvis at der er fejl på en eller flere af de påkrævede opmålinger.',
          ].join('\n')
        )
      })
  }

  renderCreateMandatoryProductsButton = () => {
    const {
      isCreatingWizardBuildingSteps,
      isMissingProperties,
      isMissingImages,
    } = this.props
    const ready = !isMissingProperties && !isMissingImages
    const disabled = isCreatingWizardBuildingSteps || !ready

    return (
      <Button
        onClick={this.handleCreateMandatoryProductsClick}
        disabled={disabled}
        active
      >
        {ready
          ? 'Tilføj påkrævede produkter'
          : 'Opret påkrævede målinger og billeder for at kunne fortsætte'}
      </Button>
    )
  }

  render() {
    const {
      showPropertiesStep,
      wizardBuilding,
      children,
      isMissingProperties,
      isMissingImages,
      isLoading,
      params,
      params: { orderId },
      shownWizardBuildingSteps,
      isCreatingWizardBuildingSteps,
      location,
    } = this.props

    if (isLoading || !wizardBuilding) return <Spinner center />
    const {
      mandatoryCreatedAt,
      wizard: { steps },
    } = wizardBuilding

    const hasMandatoryProducts = steps.some((step) => step.mandatory)
    const showCreateMandatoryWizardBuildingStepsButton =
      hasMandatoryProducts && !mandatoryCreatedAt

    return (
      <Page>
        <PageHeader>
          <Breadcrumb className="page__title">
            <BreadcrumbItem
              title="Opret tilbud"
              linkTo="/drafts/per-customer"
            />
            <BreadcrumbItem
              title={`Kladde #${orderId}`}
              linkTo={`/drafts/${orderId}`}
            />
            <BreadcrumbItem title={wizardBuilding.wizard.name} />
          </Breadcrumb>
        </PageHeader>
        <PageContent>
          <FlexContainer horizontal fill>
            <Flex size={3}>
              <FlexContainer fill>
                <Flex scrollY>
                  <Margin all>
                    <OrderWizardStepTitle>
                      {showPropertiesStep ? 'Mål og billeder' : 'Billeder'}
                    </OrderWizardStepTitle>
                    <List noPadding>
                      {showPropertiesStep && (
                        <ChecklistNavigationListItem
                          title="Opret mål"
                          linkTo={`${this.getBaseURL()}/properties`}
                          icon={
                            isMissingProperties
                              ? 'exclamation-circle'
                              : 'checkmark'
                          }
                        />
                      )}

                      <ChecklistNavigationListItem
                        title="Opret billeder"
                        linkTo={`${this.getBaseURL()}/images`}
                        icon={
                          isMissingImages ? 'exclamation-circle' : 'checkmark'
                        }
                      />
                    </List>

                    <OrderWizardStepTitle>Produkter</OrderWizardStepTitle>
                    {showCreateMandatoryWizardBuildingStepsButton ? (
                      this.renderCreateMandatoryProductsButton()
                    ) : (
                      <WizardBuildingSteps
                        baseURL={this.getBaseURL()}
                        wizardBuildingSteps={shownWizardBuildingSteps}
                      />
                    )}
                  </Margin>
                </Flex>
                <Flex noShrink noGrow>
                  {wizardBuilding.order && (
                    <PriceDisplay
                      wizardBuildingPrice={
                        wizardBuilding.humanizedTotalPriceInclVatWithSymbol
                      }
                      orderPrice={
                        wizardBuilding.order
                          .humanizedTotalPriceInclVatWithSymbol
                      }
                    />
                  )}
                </Flex>
              </FlexContainer>
            </Flex>
            <Flex size={9} scrollY>
              <FlexContainer fill>
                <Flex scrollY>{children}</Flex>
                <Flex noShrink noGrow>
                  <Margin all className="text-right">
                    <NavigationButtons
                      location={location}
                      params={params}
                      wizardBuildingStepIds={shownWizardBuildingSteps.map(
                        (wbs) => wbs.id
                      )}
                      showPropertiesStep={showPropertiesStep}
                      isLoading={isCreatingWizardBuildingSteps}
                      hasMandatoryProducts={hasMandatoryProducts}
                      mandatoryProductsCreated={
                        !!wizardBuilding.mandatoryCreatedAt
                      }
                      renderCreateMandatoryProductsButton={
                        this.renderCreateMandatoryProductsButton
                      }
                    />
                  </Margin>
                </Flex>
              </FlexContainer>
            </Flex>
          </FlexContainer>
        </PageContent>
      </Page>
    )
  }
}

const mapStateToProps = (state, props) => ({
  wizardBuildingId: props.params.wizardBuildingId,
  wizardBuilding: getWizardBuildingWithRelations(state, props),
  shownWizardBuildingSteps:
    getOptionalOrderedWizardBuildingStepsForWizardBuilding(state, props),
  isCreatingWizardBuildingSteps: isActioningEntityTypes(
    state,
    'updating',
    'wizardBuildingSteps'
  ),
  isLoading: isFetching(state),
  showPropertiesStep: getWizardBuildingNeedsAnyProperties(state, props),
  isMissingProperties: getWizardBuildingIsMissingProperties(state, props),
  isMissingImages: getWizardBuildingIsMissingImages(state, props),
})

export default connect(mapStateToProps, {
  ...WizardBuildingActions,
  ...WizardBuildingStepActions,
})(DraftWizardBuildingContainer)
