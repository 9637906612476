import React from 'react'
import PropTypes from 'react-proptypes'
import Icon from '../../Icon'

const IconButton = (props) => (
  <button
    disabled={props.disabled}
    className="icon-btn hide-print"
    onClick={props.onClick}
    title={props.title}
  >
    <Icon icon={props.icon} size={props.size} />
  </button>
)

IconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  size: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.string,
}

IconButton.defaultProps = {
  disabled: false,
}

export default IconButton
