import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as DialogActions from '../../actions/DialogActions'
import * as LineItemActions from '../../actions/LineItemActions'
import * as StepProductActions from '../../actions/StepProductActions'
import * as ProductVariantActions from '../../actions/ProductVariantActions'
import * as WizardBuildingStepActions from '../../actions/WizardBuildingStepActions'

import { getProductVariant } from '../../selectors/ProductVariantSelectors'
import { getStepProduct } from '../../selectors/StepProductSelectors'
import { getFetchCountForEntityTypes } from '../../selectors/ApiSelectors'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
} from '../../components/Dialog'
import { FlexContainer, Flex, Margin } from '../../components/Grid'
import Spinner from '../../components/Spinner'
import ImageGallery from '../../components/ImageGallery'
import LineItemCreateFormWithResult from '../../components/LineItemCreateFormWithResult'
import LineItemCreateFormWithoutResult from '../../components/LineItemCreateFormWithoutResult'

class LineItemCreationDialog extends Component {
  static propTypes = {
    stepProductId: PropTypes.string.isRequired,
    productVariantId: PropTypes.string.isRequired,
    wizardBuildingStepId: PropTypes.string.isRequired,

    isLoading: PropTypes.bool.isRequired,
    stepProduct: PropTypes.object,
    productVariant: PropTypes.object,

    closeDialog: PropTypes.func.isRequired,
    fetchStepProduct: PropTypes.func.isRequired,
    fetchProductVariant: PropTypes.func.isRequired,
    createLineItem: PropTypes.func.isRequired,
    fetchWizardBuildingStep: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      stepProduct,
      fetchStepProduct,
      stepProductId,
      fetchProductVariant,
      productVariantId,
    } = this.props

    if (!stepProduct) fetchStepProduct(stepProductId)
    fetchProductVariant(productVariantId, {
      query: {
        include: [
          'product_variant_images',
          'product',
          'product.product_images',
        ],
      },
    })
  }

  handleLineItemSubmit = (submittedAttributes) => {
    const {
      createLineItem,
      closeDialog,
      fetchWizardBuildingStep,
      wizardBuildingStepId,
    } = this.props
    const attributesArray = [].concat(submittedAttributes)

    Promise.all(attributesArray.map((attributes) => createLineItem(attributes)))
      .then(() => {
        const query = {
          include: ['wizard_building.order', 'building_property'],
        }
        fetchWizardBuildingStep(wizardBuildingStepId, { query })
        closeDialog()
      })
      .catch(() => alert('Der opstod en uventet fejl, prøv venligst igen'))
  }

  renderContent() {
    const { stepProductId, wizardBuildingStepId, productVariant, stepProduct } =
      this.props
    const { name, description, productVariantImages, product } = productVariant
    const { unitType, productImages } = product

    const lineItemFormProps = {
      stepProductId,
      productVariantId: productVariant.id,
      wizardBuildingStepId,
      unitType,
    }
    const galleryImages =
      productVariantImages.length !== 0 ? productVariantImages : productImages

    const LineItemForm = stepProduct.result
      ? LineItemCreateFormWithResult
      : LineItemCreateFormWithoutResult

    return (
      <Margin all>
        <FlexContainer horizontal>
          <Flex size={1}>
            <Margin right>
              <ImageGallery
                images={galleryImages}
                widthHeightRatio={1 / 0.66}
                emptyText="Der er ingen billeder af dette produkt"
              />
            </Margin>
          </Flex>
          <Flex size={1}>
            <h1 className="text-large">{name}</h1>
            <Margin vertical size={2}>
              <p>{description}</p>
            </Margin>
            <LineItemForm
              {...lineItemFormProps}
              onSubmit={this.handleLineItemSubmit}
            />
          </Flex>
        </FlexContainer>
      </Margin>
    )
  }

  render() {
    const { isLoading, closeDialog } = this.props

    return (
      <Dialog style={{ width: 'calc(100vw - 40px)', maxWidth: '900px' }}>
        <DialogHeader>
          <DialogCancelButton onClick={closeDialog}>Tilbage</DialogCancelButton>
          <DialogTitle>Tilføj produkt</DialogTitle>
        </DialogHeader>
        {isLoading ? (
          <Margin all>
            <Spinner centerHorizontal />
          </Margin>
        ) : (
          this.renderContent()
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => {
  const params = props

  const stepProduct = getStepProduct(state, { params })
  const productVariant = getProductVariant(state, { params }, [
    'productVariantImages',
    'product',
    'product.productImages',
  ])
  const fetchCount = getFetchCountForEntityTypes(state, [
    'stepProducts',
    'productVariants',
  ])
  const isLoading =
    !stepProduct || !stepProduct.product || !productVariant || fetchCount !== 0

  return {
    isLoading,
    stepProduct,
    productVariant,
  }
}

export default connect(mapStateToProps, {
  ...DialogActions,
  ...LineItemActions,
  ...StepProductActions,
  ...ProductVariantActions,
  ...WizardBuildingStepActions,
})(LineItemCreationDialog)
