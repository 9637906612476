import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { formatAddressLine } from '../utils/formatUtils'
import * as AlgoliaActions from '../actions/AlgoliaActions'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

const customerOptionRenderer = (option) => (
  <div>
    {option.label}
    <div>{formatAddressLine(option.customer)}</div>
  </div>
)

class CustomerInputContainer extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    customers: PropTypes.array.isRequired,
    searchAlgolia: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }

  componentDidMount() {
    this.props.clearAlgoliaCache('customers')
    if (this.props.value) this.findCurrentId()
  }

  componentDidUpdate({ query, value }) {
    if (query && query !== this.props.query) {
      this.search()
    } else if (this.props.value && this.props.value !== value) {
      this.findCurrentId()
    }
  }

  findCurrentId() {
    // If we don't have the selected value among the current algolia results, find it
    const { value, customers } = this.props
    if (!value) return
    if (customers.find((c) => value === c.objectID)) return

    this.props.setAlgoliaQuery('customers', { q: undefined, objectID: value })
  }

  search() {
    this.props.searchAlgolia('customers')
  }

  handleInputChange = (q) => {
    if (q) this.props.setAlgoliaQuery('customers', { q, objectID: undefined })
  }

  handleChange = (option) => {
    const { onChange } = this.props

    if (option && option.value) {
      return onChange(option.value)
    }

    return onChange(null)
  }

  getCustomersAsOptions() {
    const { customers } = this.props

    return customers.map((customer) => ({
      value: customer.objectID,
      label: customer.name || 'Ikke navngivet',
      customer,
    }))
  }

  filterOptions(options, search) {
    const preparedSearch = search.toLowerCase().trim()
    if (!search) {
      return []
    }
    return options.filter((option) => {
      let entry
      let value

      // Search formatted address
      if (
        formatAddressLine(option.customer)
          .toLowerCase()
          .indexOf(preparedSearch) !== -1
      ) {
        return true
      }

      // Search each customer field individually
      for (entry of Object.entries(option.customer)) {
        value = entry[1]
        if (
          typeof value === 'string' &&
          value.toLowerCase().indexOf(preparedSearch) !== -1
        ) {
          return true
        }
      }
      return false
    })
  }

  render() {
    const { isLoading, value, placeholder } = this.props

    return (
      <Select
        placeholder={placeholder || ''}
        value={value}
        noResultsText="Ingen resultater"
        options={this.getCustomersAsOptions()}
        isLoading={isLoading}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        optionRenderer={customerOptionRenderer}
        filterOptions={this.filterOptions}
      />
    )
  }
}

const emptyObject = {}
const mapStateToProps = (state) => ({
  customers: state.algolia.hits.customers || [],
  query: state.algolia.queries.customers || emptyObject,
  isLoading: (state.algolia.loadCount.customers || 0) !== 0,
})

export default connect(mapStateToProps, {
  ...AlgoliaActions,
})(CustomerInputContainer)
