import React, { Component } from 'react'
import PropTypes from 'react-proptypes';
import IconButton from '../Button/IconButton';
import * as DialogActions from '../../actions/DialogActions'
import { connect } from 'react-redux';

class DraftDisplayedWizardDiscountRow extends Component {
  handleOpenDialog = () => {
    const { order, openDialog } = this.props

    openDialog('adjustDisplayedDiscount', {
      orderId: order.id,
      maxDiscount: order.discountInclVatOerer,
    });
  }

  render() {
    const { displayedDiscountSet, humanizedDiscountInclVatWithSymbol, humanizedDisplayedDiscountWithSymbol } = this.props.order;

    const discount = displayedDiscountSet ? humanizedDisplayedDiscountWithSymbol : humanizedDiscountInclVatWithSymbol;

    return (
      <tr>
        <td>Heraf vises følgende rabat i tilbud</td>
        <td></td>
        <td className="discount-amount">{discount}</td>
        <td>
          <IconButton
            icon="pencil"
            onClick={this.handleOpenDialog}
          />
        </td>
      </tr>
    );
  }
}

DraftDisplayedWizardDiscountRow.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    discountInclVatOerer: PropTypes.number.isRequired,
    displayedDiscountSet: PropTypes.bool.isRequired,
    humanizedDiscountInclVatWithSymbol: PropTypes.string.isRequired,
    humanizedDisplayedDiscountWithSymbol: PropTypes.string.isRequired,
  }).isRequired,
  openDialog: PropTypes.func.isRequired,
};

export default connect(undefined, {
  ...DialogActions,
})(DraftDisplayedWizardDiscountRow)

