import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Popover from '../Popover'
import Icon from '../Icon'

class PopoverMenu extends Component {
  static itemShape = PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
  })

  static propTypes = {
    items: PropTypes.arrayOf(PopoverMenu.itemShape).isRequired,
    children: PropTypes.any.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  handleMenuItemClick = (event, item) => {
    event.preventDefault()
    item.onClick(item)
    this.setState({ open: false })
  }

  handleClick = () => {
    this.setState({ open: !this.state.open })
  }

  children() {
    const { children } = this.props
    return <div onClick={this.handleClick}>{children}</div>
  }

  renderMenuItems(items) {
    return (
      <ul className="popover-menu" role="menu">
        {items.map(this.renderMenuItem)}
      </ul>
    )
  }

  renderMenuItem = (item) => (
    <li
      className="popover-menu__item"
      role="presentation"
      key={item.label}
      onClick={(event) => this.handleMenuItemClick(event, item)}
    >
      {item.icon && (
        <Icon icon={item.icon} size={20} className="margin--right-1" />
      )}
      <a className="popover-menu__item-label" href="#" role="menuitem">
        {item.label}
      </a>
      {item.iconRight && (
        <Icon icon={item.iconRight} size={20} className="margin--left-1" />
      )}
    </li>
  )

  render() {
    const { items } = this.props
    const { open } = this.state

    return (
      <Popover
        {...this.props}
        open={open}
        onOuterClick={this.handleClick}
        body={this.renderMenuItems(items)}
        children={this.children()}
      />
    )
  }
}

export default PopoverMenu
