import { createSelector } from 'reselect'
import { createIsFetchingSelector } from './ApiSelectors'

export const getKpiReportId = (state, props) => props.params.kpiReportId
export const getKpiReportEntities = (state) => state.api.entities.kpiReports

export const isFetching = createIsFetchingSelector('kpiReports')

export const getKpiReport = createSelector(
  getKpiReportEntities,
  getKpiReportId,
  (entities, id) => entities && entities[id]
)
