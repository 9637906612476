import { CALL_API } from '../middleware/api'
import * as types from '../constants/ActionTypes'

export const fetchQuantities = (buildingPropertyId, stepProductId) => ({
  [CALL_API]: {
    method: 'GET',
    endpoint: '/quantities',
    entityType: 'quantities',
    query: {
      building_property_id: buildingPropertyId,
      step_product_id: stepProductId,
    },
    types: [
      types.API_FETCH_REQUEST,
      types.API_FETCH_SUCCESS,
      types.API_FETCH_FAILURE,
    ],
  },
})
