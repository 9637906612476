import * as types from '../constants/ActionTypes'
import SoftDeletedEntityTypes from '../constants/SoftDeletedEntityTypes'
import { createReducer } from '../utils/reducerUtils'

const initialState = {
  entities: {},
  isFetching: {},
  isUpdating: {},
  isDeleting: {},
  invalidated: {},
  pagination: {},
  entityQueries: {},
  entityOrders: {},
  errors: {},
}

const adjustIsFetchingCount = (state, entityType, amount) => {
  const { isFetching } = state
  const count = isFetching[entityType]

  if (!count && amount < 0) {
    return state
  }

  return {
    ...state,
    isFetching: { ...isFetching, [entityType]: (count || 0) + amount },
  }
}

const updateEntityFlag = (state, entity, flagName, check) => {
  const flags = state[flagName]
  const values = flags[entity.type] || []
  const newValues = values.slice()

  if (!check) {
    const index = newValues.findIndex((id) => id !== entity.id)
    newValues.splice(index, 1)
  } else {
    newValues.push(entity.id)
  }

  return {
    ...state,
    [flagName]: {
      ...flags,
      [entity.type]: newValues,
    },
  }
}

const mergeEntities = (entities = {}, newEntities) => {
  return Object.keys(newEntities)
    .map((id) => newEntities[id])
    .reduce((result, entity) => {
      return {
        ...result,
        [entity.id]: { ...entities[entity.id], ...entity },
      }
    }, entities)
}

const updateOrInsertEntities = (state, entities) => {
  const entityTypes = Object.keys(entities)

  const newEntities = entityTypes.reduce(
    (result, entityType) => {
      const invalidated = state.invalidated[entityType]

      if (invalidated) {
        return { ...result, [entityType]: entities[entityType] }
      }

      return {
        ...result,
        [entityType]: mergeEntities(result[entityType], entities[entityType]),
      }
    },
    { ...state.entities }
  )

  return {
    ...state,
    entities: newEntities,
    invalidated: entityTypes.reduce(
      (result, entityType) => ({
        ...result,
        [entityType]: false,
      }),
      state.invalidated
    ),
  }
}

const updateEntityOrder = (state, { entityType, entityOrder, pagination }) => {
  if (!entityOrder) {
    return state
  }
  let newOrder
  if (pagination && pagination.self && pagination.self.number !== 1) {
    // We assume that the new entities should always be appended to the existing
    // This is not very advanced, but enough to make "load more" buttons work
    newOrder = state.entityOrders[entityType]
    newOrder.push.apply(newOrder, entityOrder)
  } else {
    newOrder = entityOrder
  }

  return {
    ...state,
    entityOrders: {
      ...state.entityOrders,
      [entityType]: newOrder,
    },
  }
}

const removeEntityFromState = (state, entity) => {
  const entities = state.entities[entity.type]

  if (!entities || !entities[entity.id]) {
    return state
  }

  const newEntities = { ...entities }
  newEntities[entity.id] = undefined
  delete newEntities[entity.id]

  return {
    ...state,
    entities: {
      ...state.entities,
      [entity.type]: newEntities,
    },
  }
}

const updatePagination = (state, entityType, pagination) => ({
  ...state,
  pagination: {
    ...state.pagination,
    [entityType]: pagination,
  },
})

export default createReducer(initialState, {
  [types.API_INVALIDATE](state, { entityType }) {
    return {
      ...state,
      invalidated: {
        ...state.invalidated,
        [entityType]: true,
      },
    }
  },

  [types.API_FETCH_REQUEST](state, { request }) {
    return adjustIsFetchingCount(state, request.entityType, 1)
  },
  [types.API_FETCH_SUCCESS](state, { request, payload }) {
    const { entityType } = request
    const { entityOrder, pagination, entities } = payload
    return updateOrInsertEntities(
      updateEntityOrder(
        adjustIsFetchingCount(
          updatePagination(state, entityType, pagination),
          entityType,
          -1
        ),
        { entityType, entityOrder, pagination }
      ),
      { [entityType]: [], ...entities }
    )
  },
  [types.API_FETCH_FAILURE](state, { request }) {
    return adjustIsFetchingCount(state, request.entityType, -1)
  },

  [types.API_DELETE_REQUEST](state, { request }) {
    const { entity } = request
    return updateEntityFlag(state, entity, 'isDeleting', true)
  },
  [types.API_DELETE_SUCCESS](state, { request, payload }) {
    const { entity } = request

    if (SoftDeletedEntityTypes.indexOf(entity.type) !== -1) {
      return updateOrInsertEntities(
        updateEntityFlag(state, entity, 'isDeleting', false),
        payload.entities
      )
    } else {
      return removeEntityFromState(
        updateEntityFlag(state, entity, 'isDeleting', false),
        entity
      )
    }
  },
  [types.API_DELETE_FAILURE](state, { request }) {
    return updateEntityFlag(state, request.entity, 'isDeleting', false)
  },

  [types.API_UPDATE_REQUEST](state, { request }) {
    return updateEntityFlag(state, request.entity, 'isUpdating', true)
  },
  [types.API_UPDATE_SUCCESS](state, { request, payload }) {
    return updateOrInsertEntities(
      updateEntityFlag(state, request.entity, 'isUpdating', false),
      payload.entities
    )
  },
  [types.API_UPDATE_FAILURE](state, { request }) {
    return updateEntityFlag(state, request.entity, 'isUpdating', false)
  },
  [types.API_SET_ENTITY_QUERY](state, { entityType, query }) {
    return {
      ...state,
      entityQueries: {
        ...state.entityQueries,
        [entityType]: { ...state.entityQueries[entityType], ...query },
      },
    }
  },

  [types.LOGIN_SUCCESS](state, { payload }) {
    const userId = payload.results.users[0]
    const user = payload.entities.users[userId]
    return {
      ...state,
      entities: {
        ...state.entities,
        users: { [user.id]: user },
      },
      entityOrders: {
        ...state.entityOrders,
        users: [user.id],
      },
    }
  },
})
