import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Button from '../../components/Button'
import { FlexContainer, Flex } from '../../components/Grid'

export default class NavigationButtons extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    wizardBuildingStepIds: PropTypes.array.isRequired,

    showPropertiesStep: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    mandatoryProductsCreated: PropTypes.bool.isRequired,
    hasMandatoryProducts: PropTypes.bool.isRequired,
    renderCreateMandatoryProductsButton: PropTypes.func.isRequired,
  }

  getBaseURL() {
    const { wizardBuildingId, orderId } = this.props.params
    return `/drafts/${orderId}/wizard-buildings/${wizardBuildingId}`
  }

  getStepPath() {
    const { location } = this.props
    return location.pathname.replace(`${this.getBaseURL()}/`, '')
  }

  getOrderUrl() {
    const { orderId } = this.props.params
    return `/drafts/${orderId}/`
  }

  renderPreviousButton() {
    const {
      wizardBuildingStepIds,
      showPropertiesStep,
      isLoading,
      params: { wizardBuildingStepId },
    } = this.props

    const baseURL = this.getBaseURL()
    const stepPath = this.getStepPath()
    const buttonProps = { children: 'Forrige trin', disabled: isLoading }

    if (wizardBuildingStepId) {
      const index = wizardBuildingStepIds.indexOf(wizardBuildingStepId)

      if (index !== 0) {
        buttonProps.linkTo = `${baseURL}/wizard-building-steps/${
          wizardBuildingStepIds[index - 1]
        }`
      } else {
        buttonProps.linkTo = `${baseURL}/images`
      }
    } else if (stepPath === 'images' && showPropertiesStep) {
      buttonProps.linkTo = `${baseURL}/properties`
    } else {
      buttonProps.linkTo = this.getOrderUrl()
      buttonProps.children = 'Tilbage til ordren'
    }

    if (!buttonProps.linkTo && !buttonProps.onClick) return null
    return <Button {...buttonProps} />
  }

  renderNextButton() {
    const {
      wizardBuildingStepIds,
      mandatoryProductsCreated,
      hasMandatoryProducts,
      isLoading,
      renderCreateMandatoryProductsButton,
      params: { wizardBuildingStepId },
    } = this.props

    const baseURL = this.getBaseURL()
    const stepPath = this.getStepPath()

    const buttonProps = { children: 'Næste trin', disabled: isLoading }

    if (stepPath === 'properties') {
      buttonProps.linkTo = `${baseURL}/images`
    } else if (stepPath === 'images') {
      // In this situation we might have to return a special button that creates mandatory products
      if (hasMandatoryProducts && !mandatoryProductsCreated) {
        return renderCreateMandatoryProductsButton()
      }
      buttonProps.linkTo = `${baseURL}/wizard-building-steps/${wizardBuildingStepIds[0]}`
    } else if (wizardBuildingStepId) {
      const index = wizardBuildingStepIds.indexOf(wizardBuildingStepId)

      if (index !== wizardBuildingStepIds.length - 1) {
        buttonProps.linkTo = `${baseURL}/wizard-building-steps/${
          wizardBuildingStepIds[index + 1]
        }`
      } else {
        buttonProps.linkTo = this.getOrderUrl()
        buttonProps.children = 'Til ordren'
      }
    }

    if (!buttonProps.linkTo && !buttonProps.onClick) return null
    return <Button active {...buttonProps} />
  }

  render() {
    return (
      <FlexContainer horizontal>
        {this.renderPreviousButton()}
        <Flex />
        {this.renderNextButton()}
      </FlexContainer>
    )
  }
}
