import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import './style.styl'
import { Column } from '../Grid'
import AddImageButton from '../AddImageButton'
import Spinner from '../Spinner'
import Select from '../Select'
import Button from '../Button'

export default class BuildingImageForm extends Component {
  static propTypes = {
    saving: PropTypes.bool.isRequired,
    imageTags: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSelectClick = this.handleSelectClick.bind(this)
    this.handleImageSelected = this.handleImageSelected.bind(this)
    this.handleTagSelected = this.handleTagSelected.bind(this)
    this.handleReselectClick = this.handleReselectClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)

    this.state = { file: null, tagId: '' }
  }

  handleSelectClick({ target }) {
    // Forward click to AddImageButton if necessary
    if (target === this.textElement || target === this.linkElement) {
      this.inputElement.click()
    }
  }

  handleReselectClick() {
    this.reselectElement.click()
  }

  handleImageSelected({ target: { files } }) {
    this.setState({ file: files[0] })
  }

  handleTagSelected({ target: { value } }) {
    this.setState({ tagId: value })
  }

  handleSaveClick() {
    const { file, tagId } = this.state
    this.props
      .onSubmit({
        image: file,
        imageTagId: tagId,
      })
      .then(() => this.setState({ file: null, tagId: '' }))
      .catch(() =>
        alert(
          'Kunne ikke gemme billedet.\nTjek din internetforbindelse og prøv venligst igen.'
        )
      )
  }

  renderLoader() {
    return (
      <div>
        <div className="building-image-form__sizer">
          <Spinner center />
        </div>
        <div className="building-image-form__name">Gemmer...</div>
      </div>
    )
  }

  renderStep1() {
    return (
      <div
        className="a"
        onClick={this.handleSelectClick}
        ref={(element) => (this.linkElement = element)}
      >
        <div className="building-image-form__sizer">
          <AddImageButton
            ref={(element) => (this.inputElement = element)}
            onImageSelected={this.handleImageSelected}
          />
        </div>
        <div
          className="building-image-form__name"
          ref={(element) => (this.textElement = element)}
        >
          Tag et nyt billede
        </div>
      </div>
    )
  }

  renderStep2() {
    const { file, tagId } = this.state
    const tagOptions = this.props.imageTags.map((t) => ({
      label: t.name,
      value: t.id,
    }))
    tagOptions.unshift({ label: 'Vælg kategori', value: '' })

    return (
      <div
        onClick={this.handleSelectClick}
        ref={(element) => (this.linkElement = element)}
      >
        <div
          className="building-image-form__sizer"
          style={{ backgroundImage: `url('${URL.createObjectURL(file)}')` }}
        >
          <Button
            className="building-image-form__reselect-button"
            onClick={this.handleReselectClick}
          >
            Vælg et andet billede
          </Button>
          <input
            type="file"
            ref={(element) => (this.reselectElement = element)}
            className="building-image-form__reselect-input"
            onChange={this.handleImageSelected}
          />
        </div>
        <div
          className="building-image-form__toolbar btn-group"
          ref={(element) => (this.textElement = element)}
        >
          <Select
            className="building-image-form__select"
            options={tagOptions}
            value={tagId}
            onChange={this.handleTagSelected}
          />
          <Button disabled={!(file && tagId)} onClick={this.handleSaveClick}>
            Gem
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { saving } = this.props
    const { file } = this.state
    let content

    if (saving) {
      content = this.renderLoader()
    } else if (!file) {
      content = this.renderStep1()
    } else {
      content = this.renderStep2()
    }

    return (
      <Column className="building-image-form" size={3}>
        {content}
      </Column>
    )
  }
}
