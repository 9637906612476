import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const DialogTitleTab = ({ className, active, ...props }) => {
  const classes = classNames(
    'dialog__title-tab',
    {
      'dialog__title-tab--active': active,
    },
    className
  )
  return <div className={classes} {...props} />
}

DialogTitleTab.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
}

export default DialogTitleTab
