import { fetchEntities, createEntity, deleteEntity } from './ApiActions'

const attributesToFormData = (attributes) => {
  const key = 'building_assessment_category_image'
  const formData = new FormData()
  const {
    buildingId,
    assessmentCategoryId,
    image,
    remoteImageUrl,
    assessmentCategoryImageId,
  } = attributes

  formData.append(`${key}[building_id]`, buildingId)
  formData.append(`${key}[assessment_category_id]`, assessmentCategoryId)

  if (assessmentCategoryImageId) {
    formData.append(
      `${key}[assessment_category_image_id]`,
      assessmentCategoryImageId
    )
  }

  if (image) {
    formData.append(`${key}[image]`, image, image.name)
  } else if (remoteImageUrl) {
    formData.append(`${key}[remote_image_url]`, remoteImageUrl)
  }

  return formData
}

export const fetchBuildingAssessmentCategoryImages = (options) =>
  fetchEntities('buildingAssessmentCategoryImages', options)

export const deleteBuildingAssessmentCategoryImage = (id) =>
  deleteEntity({ type: 'buildingAssessmentCategoryImages', id })

export const createBuildingAssessmentCategoryImage = (attributes) =>
  createEntity(
    { type: 'buildingAssessmentCategoryImages' },
    {
      body: attributesToFormData(attributes),
    }
  )
