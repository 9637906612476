import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import CustomerInputContainer from './CustomerInputContainer'
import { Row, Column, VerticalAlign } from '../components/Grid'
import NewReportHeader from '../components/NewReportHeader'
import BuildingFormField from '../components/BuildingFormField'

class NewReportCustomerContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    templateId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleOnCustomerChange = this.handleOnCustomerChange.bind(this)
  }

  handleOnCustomerChange(customerId) {
    const { templateId } = this.props
    this.props.push(`/reports/new/${templateId}/${customerId}`)
  }

  render() {
    return (
      <VerticalAlign>
        <Row>
          <Column size={6} offset={3}>
            <NewReportHeader>Hvem er kunden?</NewReportHeader>
            <BuildingFormField label="Søg på navn eller adresse">
              <CustomerInputContainer onChange={this.handleOnCustomerChange} />
            </BuildingFormField>
          </Column>
        </Row>
      </VerticalAlign>
    )
  }
}

const mapStateToProps = (state, { params }) => ({
  templateId: params.templateId,
})

export default connect(mapStateToProps, {
  push,
})(NewReportCustomerContainer)
