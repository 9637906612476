import React from 'react'
import PropTypes from 'react-proptypes'

const getTipPoints = (direction, size) => {
  const main = size / 2

  switch (direction) {
    default:
    case 'bottom':
      return `0,${main} ${main},0 ${size},${main}`
    case 'up':
      return `0,0 ${main},${main}, ${size},0`
  }
}

const Tip = ({ size, direction }) => {
  const tipProps = { width: size, height: size / 2 }
  const points = getTipPoints(direction, size)
  const classes = `popover__tip popover__tip--${direction}`

  return (
    <div
      className={classes}
      style={{ width: tipProps.width, height: tipProps.height }}
    >
      <svg {...tipProps}>
        <polygon points={points} />
      </svg>
    </div>
  )
}

Tip.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  size: PropTypes.number.isRequired,
}

Tip.defaultProps = {
  size: 16,
}

export default Tip
