import './style.styl'
import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'

const Page = ({ children, className }) => (
  <div className={classNames('page', className)}>{children}</div>
)

Page.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

const PageHeader = ({ children, title, className }) => (
  <div className={classNames('page__header', className)}>
    {title ? <div className="page__title">{title}</div> : null}
    {children}
  </div>
)

PageHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

const PageContent = ({ children, className }) => (
  <div className={classNames('page__content', className)}>{children}</div>
)

PageContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

const PageSection = ({ children }) => (
  <div className="page__section">{children}</div>
)

PageSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

const PageActions = ({ children }) => (
  <div className="page__actions">{children}</div>
)

PageActions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export default Page
export { PageHeader, PageContent, PageSection, PageActions }
