import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { render, unmountComponentAtNode, findDOMNode } from 'react-dom'
import Tip from './Tip'
import classNames from 'classnames'

const createElement = (tagName) => document.createElement(tagName)
const appendToBody = (element) => document.body.appendChild(element)
const removeElementFromBody = (element) => document.body.removeChild(element)

class Popover extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    position: PropTypes.string.isRequired,
    body: PropTypes.any,
    tipSize: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onOuterClick: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    position: 'bottom left',
    tipSize: 16,
    open: false,
    onOuterClick: function noop() {},
  }

  constructor(props) {
    super(props)
    this.handleOuterBodyClick = this.handleOuterBodyClick.bind(this)
  }

  componentWillMount() {
    this.containerNode = createElement('div')
  }

  componentDidMount() {
    appendToBody(this.containerNode)
    this.targetEl = findDOMNode(this)
    this.renderLayer()
  }

  componentDidUpdate(prevProps) {
    this.renderLayer()
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.addOuterActionEventListener()
      } else {
        this.removeOuterActionEventListener()
      }
    }
  }

  componentWillUnmount() {
    this.removeOuterActionEventListener()
    unmountComponentAtNode(this.containerNode)
    removeElementFromBody(this.containerNode)
  }

  getPosition(element) {
    return element.getBoundingClientRect()
  }

  getTop(y, target, container, tipSize) {
    return y === 'bottom'
      ? target.height + target.top + tipSize - 1
      : target.top - (container.height + tipSize + 1)
  }

  getLeft(x, target, container) {
    switch (x) {
      default:
      case 'left':
        return target.left
      case 'center':
        return target.left + (target.width - container.width) / 2
      case 'right':
        return target.left + target.width - container.width
    }
  }

  getTipLeft(x, target, container, tipSize) {
    switch (x) {
      default:
      case 'left':
        return (target.width - tipSize) / 2
      case 'center':
        return container.width / 2 - tipSize / 2
      case 'right':
        return container.width - (target.width / 2 + tipSize / 2)
    }
  }

  getTipTop(y, target, container, tipSize) {
    switch (y) {
      default:
      case 'bottom':
        return -(tipSize / 2) + 1
      case 'top':
        return container.height - 1
    }
  }

  setPosition() {
    const { tipSize } = this.props
    const [y, x] = this.props.position.split(/\s+/)
    const targetBounds = this.getPosition(this.targetEl)
    const containerBounds = this.getPosition(this.containerEl)
    const top = this.getTop(y, targetBounds, containerBounds, tipSize)
    const left = this.getLeft(x, targetBounds, containerBounds)
    const tipLeft = this.getTipLeft(x, targetBounds, containerBounds, tipSize)
    const tipTop = this.getTipTop(y, targetBounds, containerBounds, tipSize)

    // Container styles
    this.containerEl.style.top = `${top}px`
    this.containerEl.style.left = `${left}px`
    this.containerEl.style.minWidth = `${targetBounds.width}px`

    // Tip styles
    this.tipEl.style.left = `${tipLeft}px`
    this.tipEl.style.top = `${tipTop}px`
  }

  setTipTopOrientation() {
    const [y] = this.props.position.split(/\s+/)
    const polygon = this.tipEl.querySelector('polygon')
    const degs = y === 'top' ? 180 : 0
    polygon.style.transform = `rotate(${degs}deg)`
  }

  addOuterActionEventListener() {
    document.body.addEventListener('click', this.handleOuterBodyClick)
  }

  removeOuterActionEventListener() {
    document.body.removeEventListener('click', this.handleOuterBodyClick)
  }

  handleOuterBodyClick(event) {
    const { onOuterClick } = this.props

    if (!this.isEventTargetInsidePopover(event.target)) {
      onOuterClick()
    }
  }

  isEventTargetInsidePopover(target) {
    return this.containerEl.contains(target) || this.targetEl.contains(target)
  }

  renderLayer() {
    const reactElement = this.renderPopover()

    if (reactElement) {
      render(reactElement, this.containerNode, () => {
        this.containerEl = this.containerNode.firstChild
        this.tipEl = this.containerEl.querySelector('.popover__tip')
        this.setPosition()
        this.setTipTopOrientation()
      })
    } else {
      unmountComponentAtNode(this.containerNode)
      this.containerEl = null
    }
  }

  renderPopover() {
    const { open, position, tipSize, body } = this.props

    if (!open) {
      return null
    }

    return (
      <div className="popover-container">
        <Tip direction={position.split(/\s+/)[0]} tipSize={tipSize} />
        <div className="popover">{body}</div>
      </div>
    )
  }

  render() {
    const { className } = this.props
    return (
      <div className={classNames('popover--trigger', className)}>
        {this.props.children}
      </div>
    )
  }
}

export default Popover
