import React from 'react'
import PropTypes from 'react-proptypes'
import classNames from 'classnames'
import omit from 'lodash/omit'

const PanelBody = (props) => {
  const classes = classNames('panel__body', props.className)
  const otherProps = omit(props, 'className')

  return (
    <div className={classes} {...otherProps}>
      {props.children}
    </div>
  )
}

PanelBody.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default PanelBody
