import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { fetchBuildingPropertyResultValues } from '../../../actions/BuildingPropertyResultValueActions'
import {
  isFetching,
  getBuildingPropertyResultValues,
} from '../../../selectors/BuildingPropertyResultValueSelectors'
import Spinner from '../../../components/Spinner'
import BuildingProperty from '../../../components/BuildingProperty'
import BuildingPropertyResultValue from '../../../components/BuildingPropertyResultValue'
import { Row } from '../../../components/Grid'

class BuildingInfoDialogPropertyContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    buildingProperty: PropTypes.object,
    buildingPropertyResultValues: PropTypes.array,
    fetchBuildingPropertyResultValues: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { buildingProperty } = this.props
    this.props.fetchBuildingPropertyResultValues(buildingProperty.id)
  }

  render() {
    const { isFetching, buildingProperty, buildingPropertyResultValues } =
      this.props
    return (
      <BuildingProperty {...buildingProperty}>
        {isFetching ? (
          <Spinner />
        ) : (
          <Row>
            {buildingPropertyResultValues.map((bprv) => (
              <BuildingPropertyResultValue {...bprv} />
            ))}
          </Row>
        )}
      </BuildingProperty>
    )
  }
}

const mapStateToProps = (state, props) => {
  const fakeProps = {
    params: { buildingPropertyId: props.buildingProperty.id },
  }
  return {
    isFetching: isFetching(state),
    buildingProperty: props.buildingProperty,
    buildingPropertyResultValues: getBuildingPropertyResultValues(
      state,
      fakeProps
    ),
  }
}

export default connect(mapStateToProps, {
  fetchBuildingPropertyResultValues,
})(BuildingInfoDialogPropertyContainer)
