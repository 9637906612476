import { fetchEntity, createEntity, deleteEntity } from './ApiActions'
import { hashToFormData } from '../utils/formUtils'

export const fetchWizardBuilding = (id, options = {}) =>
  fetchEntity({ type: 'wizardBuildings', id }, options)

const attributesToFormData = (attributes) => {
  return hashToFormData({
    'wizard_building[order_id]': attributes.orderId,
    'wizard_building[building_id]': attributes.buildingId,
    'wizard_building[wizard_id]': attributes.wizardId,
  })
}

export const createWizardBuilding = (attributes) =>
  createEntity(
    { type: 'wizardBuildings' },
    { body: attributesToFormData(attributes) }
  )

export const deleteWizardBuilding = (id) =>
  deleteEntity({ type: 'wizardBuildings', id })
