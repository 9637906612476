import { createSelector } from 'reselect'
import { getEntities, createIsFetchingSelector } from './ApiSelectors'

const getOrder =
  (entityType) =>
  ({ api }) =>
    api.entityOrders[entityType]
export const getReferenceProducts = createSelector(
  getEntities('products'),
  getOrder('referenceProducts'),
  (entities, order) =>
    entities && order ? order.map((id) => entities[id]) : []
)

export const isFetching = createIsFetchingSelector('referenceProducts')
