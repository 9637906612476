import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import { fetchOrder, mergeOrder } from '../actions/OrderActions'
import * as AlgoliaActions from '../actions/AlgoliaActions'

import { getOrder } from '../selectors/OrderSelectors'

import Spinner from '../components/Spinner'
import MergeOrder from '../components/MergeOrder'

class MergeOrderContainer extends Component {
  static propTypes = {
    params: PropTypes.shape({
      orderId: PropTypes.string.isRequired,
    }).isRequired,

    targetOrder: PropTypes.object,
    mergableOrders: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,

    searchAlgolia: PropTypes.func.isRequired,
    clearAlgoliaCache: PropTypes.func.isRequired,
  }

  state = {
    isMerging: false,
  }

  componentDidMount() {
    this.fetchOrderIfNeeded().then(() => this.fetchMergableOrders())
  }

  fetchOrderIfNeeded(orderId) {
    const { targetOrder, params } = this.props

    if (!targetOrder) return this.props.fetchOrder(params.orderId)
    return Promise.resolve()
  }

  fetchMergableOrders() {
    const { targetOrder } = this.props
    const { state, customer } = targetOrder

    this.props.clearAlgoliaCache('orders')
    this.props.searchAlgolia('orders', {
      'customer.id': parseInt(customer),
      aasm_state: state,
    })
  }

  handleMerge = (sourceOrderId, destinationOrderId) => {
    this.setState({ isMerging: true })

    this.props
      .mergeOrder(sourceOrderId, destinationOrderId)
      .then((res) => {
        window.location.replace(`/#/orders/${destinationOrderId}`)
        window.location.reload()
      })
      .catch((error) => {
        this.setState({ isMerging: false })
        throw error
      })
  }

  render() {
    const { isFetching, targetOrder, mergableOrders } = this.props
    const { isMerging } = this.state

    if (isFetching) {
      return <Spinner center />
    } else if (!targetOrder) {
      return null
    }

    return (
      <MergeOrder
        targetOrder={targetOrder}
        mergableOrders={mergableOrders}
        isMerging={isMerging}
        onMerge={this.handleMerge}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const targetOrder = getOrder(state, ownProps)

  return {
    targetOrder,
    mergableOrders: (state.algolia.hits.orders || []).filter(
      (order) =>
        order.objectID !== targetOrder.id &&
        order.aasmState === targetOrder.state &&
        order.customer.id === parseInt(targetOrder.customer)
    ),
    isLoading: (state.algolia.loadCount.orders || 0) !== 0,
  }
}

export default connect(mapStateToProps, {
  fetchOrder,
  mergeOrder,
  ...AlgoliaActions,
})(MergeOrderContainer)
