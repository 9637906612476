import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import * as LineItemActions from '../../actions/LineItemActions'

import './style.styl'

import Input from '../Input'
import TextArea from '../TextArea'
import SettingsList, { SettingsListItem } from '../SettingsList'
import Checkbox from '../Forms/Checkbox'
import { FlexContainer, Flex } from '../Grid'

import { formatUnitType } from '../../utils/formatUtils'

class LineItemForm extends Component {
  static fields = [
    'name',
    'description',
    'showPrice',
    'addition',
    'quantity',
    'quantityJustification',
    'productUnitsPerQuantity',
    'priceCorrectionJustification',
    'priceCorrectionInclVat',
  ]

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    fetchLineItemPrice: PropTypes.func.isRequired,

    lineItem: PropTypes.shape({
      humanizedTotalPriceBeforeCorrectionInclVatWithSymbol:
        PropTypes.string.isRequired,
      totalPriceBeforeCorrectionInclVat: PropTypes.string.isRequired,
      unitType: PropTypes.string.isRequired,
      stepProduct: PropTypes.shape({
        result: PropTypes.string,
      }).isRequired,
    }),
  }

  constructor(props) {
    super(props)

    const {
      fields,
      lineItem: {
        totalPriceBeforeCorrectionInclVat,
        humanizedTotalPriceBeforeCorrectionInclVatWithSymbol,
      },
    } = props

    const totalPrice = Math.floor(totalPriceBeforeCorrectionInclVat)

    this.state = {
      humanizedTotalPriceBeforeCorrectionInclVatWithSymbol,
      totalPrice,
      correctedPrice: totalPrice + fields.priceCorrectionInclVat.value,
    }
  }

  componentDidUpdate({ fields: { quantity, productUnitsPerQuantity } }) {
    const { fields } = this.props

    const quantityHasChanged =
      quantity.value * productUnitsPerQuantity.value !==
      fields.quantity.value * fields.productUnitsPerQuantity.value
    if (quantityHasChanged) this.scheduleFetchNewPrice()
  }

  scheduleFetchNewPrice() {
    clearTimeout(this.priceFetchTimeout)
    this.priceFetchTimeout = setTimeout(this.performFetchNewPrice, 300)
  }

  performFetchNewPrice = () => this.fetchNewPrice()

  fetchNewPrice() {
    const { fetchLineItemPrice, lineItem, fields } = this.props
    const { quantity, productUnitsPerQuantity } = fields

    return fetchLineItemPrice({
      id: lineItem.id,
      productUnitsPerQuantity: productUnitsPerQuantity.value,
      quantity: quantity.value,
    })
      .then(({ rawData: { attributes } }) => {
        const humanizedTotalPriceBeforeCorrectionInclVatWithSymbol =
          attributes[
            'humanized-total-price-before-correction-incl-vat-with-symbol'
          ]
        const totalPrice = Math.floor(
          attributes['total-price-before-correction-incl-vat']
        )

        this.setState({
          humanizedTotalPriceBeforeCorrectionInclVatWithSymbol,
          totalPrice,
          correctedPrice: totalPrice + fields.priceCorrectionInclVat.value,
        })
      })
      .catch(() => {})
  }

  submit() {
    this.buttonElement.dispatchEvent(new MouseEvent('click'))
  }

  handleCorrectedPriceChange = ({ target: { value } }) => {
    const { totalPrice } = this.state

    this.setState({ correctedPrice: value })

    let priceCorrectionInclVat
    if (value <= 0) {
      priceCorrectionInclVat = NaN
    } else {
      priceCorrectionInclVat = parseFloat(value) - totalPrice
    }

    this.props.change(
      'lineItem',
      'priceCorrectionInclVat',
      priceCorrectionInclVat
    )
  }

  render() {
    const {
      lineItem,
      lineItem: { unitType, stepProduct },
      fields: {
        name,
        description,
        showPrice,
        addition,
        quantity,
        quantityJustification,
        productUnitsPerQuantity,
        priceCorrectionJustification,
        priceCorrectionInclVat,
      },
      submitting,
      handleSubmit,
    } = this.props
    const {
      correctedPrice,
      humanizedTotalPriceBeforeCorrectionInclVatWithSymbol,
    } = this.state
    const needsQuantityJustification = !!stepProduct.result
    const quantitiesChanged =
      quantity.value !== lineItem.quantity ||
      productUnitsPerQuantity.value !==
        parseFloat(lineItem.productUnitsPerQuantity)
    const canChangeQuantityJustification =
      quantitiesChanged || lineItem.quantityJustification

    return (
      <form
        ref={(element) => (this.formElement = element)}
        onSubmit={handleSubmit}
      >
        <FlexContainer horizontal>
          <Flex size={1} className="LineItemForm__column--border-right">
            <SettingsList>
              <SettingsListItem label="Navn" error={name.error}>
                <Input disabled={submitting} type="text" {...name} />
              </SettingsListItem>

              <SettingsListItem label="Beskrivelse" error={description.error}>
                <TextArea disabled={submitting} {...description} />
              </SettingsListItem>

              <FlexContainer horizontal>
                <Flex>
                  <label>
                    <SettingsListItem
                      borderless
                      label="Vis pris i PDF"
                      error={showPrice.error}
                    >
                      <Checkbox disabled={submitting} {...showPrice} />
                    </SettingsListItem>
                  </label>
                </Flex>
                <Flex>
                  <label>
                    <SettingsListItem
                      borderless
                      label="Tillægsprodukt"
                      error={addition.error}
                    >
                      <Checkbox disabled={submitting} {...addition} />
                    </SettingsListItem>
                  </label>
                </Flex>
              </FlexContainer>
            </SettingsList>
          </Flex>

          <Flex size={1}>
            <SettingsList>
              <FlexContainer horizontal>
                <Flex size={1}>
                  <label>
                    <SettingsListItem
                      narrow
                      borderless
                      label="Antal"
                      error={quantity.error}
                    >
                      <Input
                        className="LineItemForm__input--number"
                        type="number"
                        disabled={submitting}
                        {...quantity}
                      />
                    </SettingsListItem>
                  </label>
                </Flex>
                <Flex size={3}>
                  <label>
                    <SettingsListItem
                      narrow
                      borderless
                      label="Mængde per antal"
                      error={productUnitsPerQuantity.error}
                    >
                      <Input
                        className="LineItemForm__input--number"
                        type="number"
                        step="0.01"
                        disabled={submitting}
                        {...productUnitsPerQuantity}
                      />
                    </SettingsListItem>
                  </label>
                </Flex>
              </FlexContainer>

              <SettingsListItem
                label="Mængde"
                borderless={needsQuantityJustification}
              >
                <div className="text-right">
                  {quantity.value * productUnitsPerQuantity.value}{' '}
                  {formatUnitType(unitType)}
                </div>
              </SettingsListItem>

              {needsQuantityJustification && (
                <SettingsListItem
                  label="Begrundelse"
                  error={quantityJustification.error}
                >
                  <TextArea
                    disabled={submitting || !canChangeQuantityJustification}
                    {...quantityJustification}
                  />
                </SettingsListItem>
              )}

              <FlexContainer horizontal style={{ alignItems: 'center' }}>
                <Flex size={1} s>
                  <label>
                    <SettingsListItem narrow borderless label="Pris">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {humanizedTotalPriceBeforeCorrectionInclVatWithSymbol}
                      </span>
                    </SettingsListItem>
                  </label>
                </Flex>
                <Flex size={3}>
                  <label>
                    <SettingsListItem
                      narrow
                      borderless
                      label="Justeret pris"
                      error={priceCorrectionInclVat.error}
                    >
                      <Input
                        className="LineItemForm__input--number"
                        type="number"
                        step="0.01"
                        disabled={submitting}
                        value={correctedPrice}
                        touched
                        error={priceCorrectionInclVat.error}
                        onChange={this.handleCorrectedPriceChange}
                      />
                    </SettingsListItem>
                  </label>
                </Flex>
              </FlexContainer>

              <SettingsListItem borderless label="Difference">
                <div className="text-right">
                  {Math.round(priceCorrectionInclVat.value)} kr
                </div>
              </SettingsListItem>

              <SettingsListItem
                borderless
                label="Begrundelse"
                error={priceCorrectionJustification.error}
              >
                <TextArea
                  disabled={submitting}
                  {...priceCorrectionJustification}
                />
              </SettingsListItem>
            </SettingsList>
          </Flex>
        </FlexContainer>
        <button
          ref={(element) => (this.buttonElement = element)}
          type="submit"
          style={{ display: 'none' }}
        />
      </form>
    )
  }
}

export default connect(undefined, { change, ...LineItemActions }, undefined, {
  withRef: true,
})(LineItemForm)
