import { createEntity, updateEntity } from './ApiActions'
import { hashToFormData } from '../utils/formUtils'

const attributesToFormData = ({ buildingPropertyId, measurementId, value }) =>
  hashToFormData({
    'building_property_measurement[building_property_id]': buildingPropertyId,
    'building_property_measurement[measurement_id]': measurementId,
    'building_property_measurement[value]': value,
  })

export const updateBuildingPropertyMeasurement = (id, attributes) =>
  updateEntity(
    { id, type: 'buildingPropertyMeasurements' },
    {
      body: attributesToFormData(attributes),
    }
  )

export const createBuildingPropertyMeasurement = (attributes) =>
  createEntity(
    { type: 'buildingPropertyMeasurements' },
    {
      body: attributesToFormData(attributes),
    }
  )
