import './style.styl'
import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Button from '../Button'
import SignaturePad from 'signature_pad'

const dataURIToBlob = (dataURI) => {
  let byteString

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ia = new Uint8Array(byteString.length)

  for (let index = 0; index < byteString.length; index++) {
    ia[index] = byteString.charCodeAt(index)
  }

  return new Blob([ia], { type: mimeString })
}

export default class SignatureInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.any,
  }

  constructor(props) {
    super(props)

    this.state = {
      isEmpty: true,
      image: props.value && props.value !== '' ? props.value : null,
    }

    this.handleResetClick = this.handleResetClick.bind(this)
    this.handlePointerEnd = this.handlePointerEnd.bind(this)
    this.handleCanvasPointerStart = this.handleCanvasPointerStart.bind(this)
  }

  componentDidMount() {
    this.signaturePad = new SignaturePad(this.canvasElement, {
      maxWidth: 1.5,
    })
  }

  componentWillUnmount() {
    this.signaturePad.clear()
    this.signaturePad.off()
    this.removeEventListeners()
  }

  addEventListeners() {
    document.addEventListener('mouseup', this.handlePointerEnd)
    document.addEventListener('touchend', this.handlePointerEnd)
  }

  removeEventListeners() {
    document.removeEventListener('mouseup', this.handlePointerEnd)
    document.removeEventListener('touchend', this.handlePointerEnd)
  }

  syncStateWithSignaturePad() {
    this.setState({
      isEmpty: this.signaturePad.isEmpty(),
    })
  }

  handleResetClick() {
    this.signaturePad.clear()
    this.setState({ image: null })
    this.handleChange()
  }

  handleChange() {
    const { onChange } = this.props
    const { signaturePad } = this

    this.syncStateWithSignaturePad()

    if (onChange) {
      onChange(
        signaturePad.isEmpty()
          ? null
          : dataURIToBlob(signaturePad.toDataURL('image/png'))
      )
    }
  }

  handlePointerEnd() {
    this.handleChange()
    this.removeEventListeners()
  }

  handleCanvasPointerStart() {
    this.addEventListeners()
  }

  renderWithImage() {}

  render() {
    const { isEmpty, image } = this.state
    const { name, children } = this.props

    return (
      <div className="signature-pad">
        <div className="signature-pad__canvas">
          <div className="signature-pad__guideline">{name}</div>
          {image && (
            <img
              src={image}
              className="signature-pad__image"
              role="presentation"
            />
          )}
          <canvas
            ref={(element) => (this.canvasElement = element)}
            width={500}
            height={175}
            onTouchStart={this.handleCanvasPointerStart}
            onMouseDown={this.handleCanvasPointerStart}
          />
        </div>
        <Button disabled={!image && isEmpty} onClick={this.handleResetClick}>
          Nulstil
        </Button>
        {children}
      </div>
    )
  }
}
